import { notification } from 'antd'
import { AxiosResponse } from 'axios'
import { TFunction } from 'i18next'
import { flatten } from 'lodash-es'
import ObjectUtils from '../../utils/Object'
import { getDefaultBaseUrl } from '../../utils/WebApi'
import authService from '../auth/Auth.service'
import fileIndexDatabaseService, { FileDocument } from '../browser-storage/index-database/FileIndexDatabase.service'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import navigatorService from '../navigator/Navigator.service'
import filesResourceService from '../rest-resources/FilesResource.service'

class FilesService {
  public async onFileClick(filePath: string, t: TFunction): Promise<void> {
    if (filePath) {
      const file = await fileIndexDatabaseService.getFileDocumentByPath(filePath)

      if (file?.document?.originFileObj) {
        const url = window.URL || window.webkitURL
        const fileUrl = url.createObjectURL(file.document.originFileObj)
        window.open(fileUrl)
      } else if (await navigatorService.isOnline()) {
        if (process.env.REACT_APP_NODE_ENV === 'local-development') {
          window.open(
            `${getDefaultBaseUrl()}/file-document/${encodeURIComponent(filePath)}?authorization=Bearer ${
              authService.authToken
            }`
          )
        } else {
          window.open(
            `${window.location.protocol}//${window.location.hostname}/api/file-document/${encodeURIComponent(
              filePath
            )}?authorization=Bearer ${authService.authToken}`
          )
        }
      } else {
        notification.error({
          message: t('fileUpload.offlineError'),
        })
      }
    }
  }

  public async syncCaseFiles(caseData: Record<string, never>): Promise<Array<number | undefined>> {
    let filePaths: Array<string> = []
    const caseFiles: Array<Record<string, never>> | undefined = ObjectUtils.getAt(caseData, 'files')
    const fileLocations: Array<string> = this.getValue(caseFiles, 'location')
    filePaths = [...filePaths, ...fileLocations]
    const fileDocuments: Array<FileDocument> = await fileIndexDatabaseService.getFilesForSync(filePaths)

    return Promise.all(fileDocuments.map(async fileDocument => this.syncFile(fileDocument)))
  }

  public async syncFollowUpFiles(followUpData: Record<string, never>): Promise<Array<number | undefined>> {
    let filePaths: Array<string> = []
    const sourceDataReports: Array<Record<string, never>> | undefined = ObjectUtils.getAt(
      followUpData,
      'sourceDataReports'
    )
    const reportLocations: Array<string> = this.getValue(sourceDataReports, 'reportLocation')
    filePaths = [...filePaths, ...reportLocations]

    const fileDocuments: Array<FileDocument> = await fileIndexDatabaseService.getFilesForSync(filePaths)
    return Promise.all(fileDocuments.map(async fileDocument => this.syncFile(fileDocument)))
  }

  private async syncFile(fileDocument: FileDocument): Promise<number | undefined> {
    const response: AxiosResponse | undefined = await this.uploadFileToBackend(fileDocument)
    if (response?.status === 201) {
      return fileIndexDatabaseService.deleteFileDocumentByPath(fileDocument.document.filePath)
    }
    return undefined
  }

  private async uploadFileToBackend(fileDocument: FileDocument): Promise<AxiosResponse | undefined> {
    try {
      if (fileDocument.document.originFileObj !== undefined) {
        return await filesResourceService.uploadFile(fileDocument.document)
      }
    } catch (error) {
      await remoteLoggingService.logError(
        `Failed to sync the file ${JSON.stringify(fileDocument.document.filePath)} due to ${error}`
      )
      throw error
    }
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getValue(input: Array<any> | undefined, property: string): Array<string> {
    const values = []
    if (input !== undefined) {
      // eslint-disable-next-line no-restricted-syntax
      for (const ip of input) {
        const value = ObjectUtils.getAt(ip, property)
        if (value !== undefined) {
          values.push(value)
        }
      }
    }
    return flatten(values)
  }
}

const filesService = new FilesService()
export default filesService
