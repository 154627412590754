import { AxiosResponse } from 'axios'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import { WebApi } from '../../utils/WebApi'

class FollowUpsResourceService extends WebApi {
  public async fetchFollowUpDocuments(clinicId: number): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/clinics/${clinicId}/followUpDocuments`)
  }

  public fetchSubmittedFollowUpDocumentsByCaseNo(caseNo: string): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/follow-up-document/submitted/${caseNo}`)
  }

  public async fetchExistingFollowUpDocumentsByCaseNo(caseNo: string): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/follow-up-document/existingByCaseNo/${caseNo}`)
  }

  public async getCaseDataByCaseNo(caseNo: string): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/case-document/relatedData/caseNo/${caseNo}`)
  }

  public async updateFollowUpDocument(followUpDocument: FollowUpDocument): Promise<AxiosResponse> {
    return this.request({
      url: `/follow-up-document/${followUpDocument.document.uuid}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(followUpDocument.document),
    })
  }
}

const followUpsResourceService = new FollowUpsResourceService()
export default followUpsResourceService
