import { AxiosResponse } from 'axios'
import { File } from '../../components/ui/file-upload/FileUpload'
import { WebApi } from '../../utils/WebApi'

class FilesResourceService extends WebApi {
  public async uploadFile(fileDocument: File): Promise<AxiosResponse> {
    return this.request({
      url: '/file-document',
      method: 'POST',
      data: fileDocument.originFileObj,
      headers: {
        'Content-Type': 'application/octet-stream',
        'File-Path': `${fileDocument.filePath}`,
        'File-Type': `${fileDocument.type}`,
      },
    })
  }
}

const filesResourceService = new FilesResourceService()
export default filesResourceService
