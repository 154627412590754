import { AuditLogEntry } from '../../../shared/interfaces/AuditLogEntry.interface'
import { CaseData } from '../../../shared/interfaces/CaseData.interface'
import { FollowUpData } from '../../../shared/interfaces/FollowUpData.interface'
import BaseChangeLogService from './BaseChangeLog.service'

class FollowUpChangeLogService extends BaseChangeLogService {
  public getUpdatedChangeLogEntries = (
    dataObject: { data: CaseData | FollowUpData },
    changeLogEntry: AuditLogEntry
  ): Array<AuditLogEntry> => dataObject.data.changeLog.entries || []
}

const followUpChangeLogService = new FollowUpChangeLogService()
export default followUpChangeLogService
