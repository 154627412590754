import { Select } from 'antd'
import { flatten } from 'lodash-es'
import React from 'react'
import useContextStore from '../../state/Context'
import { EnumOptions } from '../indiform-ui/Enum'

export interface GroupedListItem {
  groupName: string
  groupDisplayText: string
  values: Array<EnumOptions>
}

interface Props {
  isClearingAllowed: boolean
  options: Array<GroupedListItem>
  placeholder: string
  value: number | undefined
  onMenuItemClick: (value: EnumOptions | undefined) => void
  metaDataPath: string
}

const GroupedDropDown = (props: Props): React.ReactElement => {
  const { value, onMenuItemClick, options, isClearingAllowed, placeholder, metaDataPath } = props
  const { Option, OptGroup } = Select
  const dataContext = useContextStore()
  const [selectedValue, setValue] = React.useState<number | undefined>(value)

  React.useEffect(() => {
    setValue(value)
  }, [dataContext.formDocument, value])

  const onDropdownValueChange = (id: number | undefined): void => {
    if (id === undefined) {
      onMenuItemClick(id)
    } else {
      const values: Array<EnumOptions> = flatten(options.map(option => option.values))
      const selectedOption: EnumOptions | undefined = values.find(val => val.id === id)
      onMenuItemClick(selectedOption)
    }
  }

  return (
    <Select
      showSearch
      allowClear={isClearingAllowed}
      className="w-100"
      filterOption
      optionFilterProp="children"
      placeholder={placeholder}
      value={selectedValue}
      onChange={onDropdownValueChange}
      data-testid={metaDataPath}
    >
      {options.map(option => (
        <OptGroup key={option.groupName} label={option.groupDisplayText}>
          {option.values.map(val => (
            <Option key={val.id} value={val.id}>
              {val.text}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  )
}

export default GroupedDropDown
