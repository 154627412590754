import axios, { AxiosResponse } from 'axios'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { WebApi } from '../../utils/WebApi'
import IndiFormType from '../indiform/IndiFormType.enum'

class ClinicsResourceService extends WebApi {
  public fetchClinics(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest('/clinics')
  }

  public fetchClinicsFormMetaData(
    clinics: Array<Clinic>,
    indiFormType: IndiFormType = IndiFormType.CASE
  ): Promise<Array<AxiosResponse<ClinicFormMetaData>>> {
    const formMetaDataRequest = clinics.map(clinic =>
      this.buildJsonContentTypeRequest(
        indiFormType === IndiFormType.CASE
          ? `/clinics/${clinic.id}/meta`
          : `/clinics/${clinic.id}/meta?type=${indiFormType}`
      )
    )
    return axios.all(formMetaDataRequest)
  }
}

const clinicsResourceService = new ClinicsResourceService()
export default clinicsResourceService
