import { Button, Col, Row } from 'antd'
import { differenceBy } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import { ChangeLogData } from '../../shared/interfaces/ChangeLogData.interface'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormModal from '../ui/FormModal'
import FormSelectButton from '../ui/FormSelectButton'
import SearchInput from '../ui/SearchInput'

export interface MultiEnumOption {
  backgroundColor?: string
  id?: number
  displayLabel: string
  text: string
  value: string | number
}

interface Props {
  header: string
  hasFooter: boolean
  metaDataPath: string
  isFilterShown: boolean
  enumType: string
}
const MultiEnum = (props: Props): React.ReactElement => {
  const { metaDataPath, enumType, header, hasFooter, isFilterShown } = props
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const indiFormStore = useIndiFormStore()
  const multiEnumOptions: Array<MultiEnumOption> = indiFormService.getMultiEnumOptions(indiFormStore, enumType, t)
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string | undefined>(undefined)
  const [filteredOptions, setFilteredOptions] = React.useState<Array<MultiEnumOption> | undefined>(undefined)
  const [values, setValues] = React.useState<Array<MultiEnumOption> | undefined>(undefined)

  React.useEffect(() => {
    if (searchText) {
      const filterOptions = multiEnumOptions.filter(option =>
        option.displayLabel.toLowerCase().includes(searchText.toLowerCase())
      )
      setFilteredOptions(filterOptions)
    } else {
      setFilteredOptions(undefined)
    }
  }, [searchText])

  React.useEffect(() => {
    const getInitialValues = (): Array<MultiEnumOption> | undefined => {
      const initialValues: Array<EnumValue> | Array<string> | undefined = dataContext.getDataPathValue(metaDataPath)
      if (initialValues === undefined) {
        return initialValues
      }
      return (initialValues as Array<EnumValue>).map((value: EnumValue) => ({
        displayLabel: t(`enums.${enumType}.values.${value.enumId}`) || value.enumText,
        text: value.enumText,
        value: value.enumId,
      }))
    }
    setValues(getInitialValues())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const renderValues = (): React.ReactElement | null => {
    if (values !== undefined) {
      return (
        <div className="align-self-center">
          {values.length === 0 ? null : values.map(value => value.displayLabel).join(', ')}
        </div>
      )
    }
    return null
  }

  const renderButton = (): React.ReactElement => {
    const title: string = values === undefined || values.length === 0 ? t('addButton') : t('changeButton')
    return (
      <Button className="mx-2" onClick={() => toggleModal(true)} data-testid={metaDataPath}>
        {title}
      </Button>
    )
  }

  const getChangeLogValues = (
    previousValues: Array<EnumValue>,
    currentValues: Array<EnumValue>,
    action: AuditLogAction
  ): Array<EnumValue> => {
    switch (action) {
      case AuditLogAction.ARRAY_ADD:
        return differenceBy(currentValues, previousValues, 'enumId')
      case AuditLogAction.ARRAY_REMOVE:
        return differenceBy(previousValues, currentValues, 'enumId')
      default:
        return []
    }
  }

  const updateValuesAndCase = async (options: Array<MultiEnumOption>): Promise<void> => {
    const previousValues: Array<EnumValue> = dataContext.getDataPathValue(metaDataPath) || []
    let selectedValues: Array<EnumValue> = []
    let action: AuditLogAction
    let changeLogValues: Array<EnumValue> | undefined
    selectedValues = options.map(option => indiFormService.getEnumValueObject(option.value as number, option.text))
    if (options.length === 0) {
      action = AuditLogAction.DELETE
    } else {
      action = options.length > previousValues.length ? AuditLogAction.ARRAY_ADD : AuditLogAction.ARRAY_REMOVE
      changeLogValues = getChangeLogValues(previousValues, selectedValues, action)
    }
    const changeLogData: ChangeLogData = {
      path: `data.case.${metaDataPath}`,
      value: changeLogValues,
    }
    await dataContext.updateData(metaDataPath, selectedValues, action, changeLogData)
  }

  const renderFooter = (): React.ReactElement => (
    <div className="d-flex justify-content-between">
      <div>
        <Button onClick={() => updateValuesAndCase(multiEnumOptions)}>{t('allButton')}</Button>
        <Button onClick={() => updateValuesAndCase([])}>{t('clearButton')}</Button>
      </div>
      <div>
        <Button
          onClick={() => {
            toggleModal(false)
            setSearchText(undefined)
          }}
        >
          {t('closeButton')}
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <div className="d-inline-flex">
        {renderValues()}
        {renderButton()}
      </div>
      <FormModal
        header={t(`form.labels.${header}`)}
        footer={hasFooter && renderFooter()}
        onCancel={() => {
          toggleModal(false)
          setSearchText(undefined)
        }}
        isVisible={showModal}
        isClosable
        isDismissableMask={false}
        width="unset"
      >
        {isFilterShown && (
          <Row>
            <Col span={24}>
              <SearchInput isAutoFocused onSearchInputChange={setSearchText} />
            </Col>
            <hr />
          </Row>
        )}
        <FormSelectButton
          multiple
          options={multiEnumOptions}
          filteredOptions={filteredOptions ?? multiEnumOptions}
          renderBackgroundColor={multiEnumOptions.length === 0}
          values={values}
          onChange={(selectedOption: Array<MultiEnumOption>) => updateValuesAndCase(selectedOption)}
        />
      </FormModal>
    </>
  )
}

export default MultiEnum
