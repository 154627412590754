import { NotificationInstance } from 'antd/es/notification/interface'
import { TFunction } from 'i18next'
import { Case } from '../../shared/interfaces/Case.interface'
import { ColumnListItem } from '../../shared/interfaces/ColumnListItem.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { RelatedDataObject } from '../../shared/interfaces/RelatedDataObject.interface'
import ObjectUtils from '../../utils/Object'
import IndiFormType from '../indiform/IndiFormType.enum'
import ApplicationType from './ApplicationType.enum'

class ExistingDocumentModalService {
  public getDocumentsColumn(
    foundDocumentObject: FollowUp | RelatedDataObject,
    indiFormType: IndiFormType,
    t: TFunction
  ): Array<ColumnListItem> {
    if (indiFormType === IndiFormType.CASE) {
      const relatedObjectDataPaths = {
        hospital: 'data.case.hospital.enumText',
        startAt: 'data.case.startAt',
        gender: 'data.case.patient.gender',
        yearOfBirth: 'data.case.patient.yearOfBirth',
      }
      return [
        {
          title: t('documentList.hospital'),
          value: ObjectUtils.getAt(foundDocumentObject, relatedObjectDataPaths.hospital),
        },
        {
          title: t('documentList.table.columns.startDate'),
          value: ObjectUtils.getAt(foundDocumentObject, relatedObjectDataPaths.startAt),
        },
        {
          title: t('documentList.table.columns.sex'),
          value: t(`options.patient.gender.${ObjectUtils.getAt(foundDocumentObject, relatedObjectDataPaths.gender)}`),
        },
        {
          title: t('documentList.table.columns.yearOfBirth'),
          value: ObjectUtils.getAt(foundDocumentObject, relatedObjectDataPaths.yearOfBirth),
        },
      ]
    }
    const followUpObjectDataPaths = {
      followUpNo: 'data.followUp.followUpNo',
      caseNo: 'data.followUp.caseNo',
      followUpDate: 'data.followUp.followUpDate',
    }

    return [
      {
        title: t('documentList.table.columns.followUpNo'),
        value: ObjectUtils.getAt(foundDocumentObject, followUpObjectDataPaths.followUpNo),
      },
      {
        title: t('documentList.table.columns.caseNo'),
        value: ObjectUtils.getAt(foundDocumentObject, followUpObjectDataPaths.caseNo),
      },
      {
        title: t('documentList.table.columns.dataCollectionDate'),
        value: ObjectUtils.getAt(foundDocumentObject, followUpObjectDataPaths.followUpDate),
      },
    ]
  }

  private getHospitalName(document: Case | FollowUp, documentType: IndiFormType): string {
    return ObjectUtils.getAt(document, `data.${documentType}.hospital.enumText`)
  }

  /* eslint-disable-next-line max-params*/
  public showSuccessNotification(
    document: Case | FollowUp,
    application: ApplicationType,
    documentType: IndiFormType,
    documentNo: string,
    notification: NotificationInstance,
    t: TFunction
  ): void {
    const hospitalName: string = this.getHospitalName(document, documentType) ?? ''
    notification.success({
      message: t('documentList.loadDocumentModal.loadDocumentSuccess', {
        documentType,
        documentNo,
        application,
        hospitalName,
      }),
    })
  }

  public showErrorNotification(
    documentType: IndiFormType,
    documentNo: string,
    notification: NotificationInstance,
    t: TFunction
  ): void {
    notification.error({
      message: t('documentList.loadDocumentModal.loadDocumentError', { documentType, documentNo }),
    })
  }
}

const existingDocumentModalService = new ExistingDocumentModalService()
export default existingDocumentModalService
