class DocumentStateHelper {
  public buildDataPath(path: string, index: number | null = null): string {
    let metaDataPath = ''
    if (path !== '') {
      metaDataPath = metaDataPath.concat(path)
    }
    if (index !== null) {
      metaDataPath = metaDataPath.concat(`[${index}]`)
    }
    return metaDataPath
  }

  public buildMetaDataPath = (metaDataPath: string, variable: string): string => {
    if (metaDataPath !== '' && variable !== '') {
      return metaDataPath.concat(`.${variable}`)
    } else if (metaDataPath !== '' && variable === '') {
      return metaDataPath
    } else if (metaDataPath === '' && variable !== '') {
      return variable
    }
    return ''
  }
}

const documentStateHelper = new DocumentStateHelper()
export default documentStateHelper
