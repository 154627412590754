import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Modal, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Route, Navigate, useLocation, Routes } from 'react-router-dom'
import Idle from './components/Idle'
import NavBar from './components/NavBar'
import Synchronization from './components/Synchronization'
import PrivateRoute from './components/auth/PrivateRoute'
import Clinics from './containers/clinics-page/Clinics'
import Documents from './containers/documents-page/Documents'
import CaseEditor from './containers/editor-page/CaseEditor'
import FollowUpEditor from './containers/editor-page/FollowUpEditor'
import Login from './containers/login-page/Login'
import CaseSummary from './containers/summary-page/CaseSummary'
import FollowUpSummary from './containers/summary-page/FollowUpSummary'
import * as serviceWorker from './serviceWorker'
import remoteLoggingService from './services/logging-handler/RemoteLogging.service'
import navigatorService from './services/navigator/Navigator.service'
import './App.scss'
import './components/NavBar.scss'
import useAppStore from './state/App'
import useAuthStore from './state/Auth'

const App = (): React.ReactElement => {
  const location = useLocation()
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration | null>(null)
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState<boolean>(false)
  const { Header, Content } = Layout
  const authToken = useAuthStore(state => state.authToken)
  const appStore = useAppStore()

  const setNetworkState = async (): Promise<void> => {
    if (await navigatorService.isOnline()) {
      appStore.setOnline()
    } else {
      remoteLoggingService.logInfo('App is offline')
      appStore.setOffline()
    }
  }

  useEffect(() => {
    if (location.pathname !== '/login') {
      appStore.setRedirectUrl(location.pathname)
    }
    setNetworkState()
    window.addEventListener('online', setNetworkState)
    window.addEventListener('offline', setNetworkState)
    serviceWorker.register({
      onUpdate: registration => {
        setServiceWorkerRegistration(registration)
        setIsNewVersionAvailable(true)
      },
    })

    return function cleanup() {
      window.removeEventListener('online', setNetworkState)
      window.removeEventListener('offline', setNetworkState)
    }
  }, [])

  const skipWaitingForNewVersion = (): void => {
    if (serviceWorkerRegistration && serviceWorkerRegistration.waiting) {
      serviceWorkerRegistration.waiting.onstatechange = () => {
        window.location.reload()
      }
      serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
    setIsNewVersionAvailable(false)
  }

  return (
    <Layout className="h-100 app-layout">
      {authToken !== null && <Idle />}
      {authToken !== null && <Synchronization />}
      {authToken !== null && (
        <Header className="app-header px-3 w-100 position-fixed">
          <NavBar />
        </Header>
      )}
      <Content className="app-content p-3" style={{ borderRadius: 32 }}>
        <Routes>
          <Route
            path="/clinics/:clinicId/caseEditor/:formDocumentId/:formMetaDataId"
            element={
              <PrivateRoute authToken={authToken}>
                <CaseEditor />
              </PrivateRoute>
            }
          />

          <Route
            path="/clinics/:clinicId/caseSummary/:formDocumentId/"
            element={
              <PrivateRoute authToken={authToken}>
                <CaseSummary />
              </PrivateRoute>
            }
          />
          <Route
            path="/clinics/:clinicId/followUpSummary/:formDocumentId/"
            element={
              <PrivateRoute authToken={authToken}>
                <FollowUpSummary />
              </PrivateRoute>
            }
          />
          <Route
            path="/clinics/:clinicId"
            element={
              <PrivateRoute authToken={authToken}>
                <Documents />
              </PrivateRoute>
            }
          />
          <Route
            path="/clinics"
            element={
              <PrivateRoute authToken={authToken}>
                <Clinics />
              </PrivateRoute>
            }
          />
          <Route
            path="/clinics/:clinicId/followUpEditor/:formDocumentId/:formMetaDataId"
            element={
              <PrivateRoute authToken={authToken}>
                <FollowUpEditor />
              </PrivateRoute>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route path="about" element={<Navigate to="about-us" />} />

          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
        <Modal
          title="Update Available!"
          centered
          open={isNewVersionAvailable}
          closable={false}
          footer={[
            <Button shape="round" onClick={() => skipWaitingForNewVersion()}>
              Update
            </Button>,
          ]}
        >
          <p>New version available! Please click Update button to update!</p>
        </Modal>
        {authToken === null && (
          <div className="text-center text-white">
            Made with <FontAwesomeIcon icon={['fas', 'heart']} className="app-footer-heart" /> in Hamburg, Germany.
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default App
