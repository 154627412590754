import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { differenceBy, flatten, unionBy } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import { ChangeLogData } from '../../shared/interfaces/ChangeLogData.interface'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import { FormEnumValue, FormMetaDataGroupedEnumValue } from '../../shared/interfaces/IndiformValues.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import SearchInput from '../ui/SearchInput'
import './Symptom.scss'

interface Props {
  enumType: string
  metaDataPath: string
}

type FormEnumValueUI = FormEnumValue & {
  icon: IconName
  checked: boolean | undefined
}

const Symptom = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { enumType, metaDataPath } = props
  const indiFormStore = useIndiFormStore()
  const allSymptomGroups = indiFormService.getGroupedEnumValue(indiFormStore, enumType)
  const [searchText, setSearchText] = React.useState<string>('')
  const [selectedSymptomGroups, setSelectedSymptomGroups] = React.useState<Array<FormMetaDataGroupedEnumValue>>([])
  const [symptomGroups, setSymptomGroups] = React.useState<Array<FormMetaDataGroupedEnumValue>>([])

  const getTransformedSymptomGroups = (): Array<FormMetaDataGroupedEnumValue> =>
    allSymptomGroups.map(symptomGroup => ({
      ...symptomGroup,
      values: symptomGroup.values
        .map(value => ({
          ...value,
          displayLabel: t(`enums.${enumType}.values.${value.id}`) ?? value.text,
        }))
        .sort((a: FormEnumValue, b: FormEnumValue) => a.displayLabel?.localeCompare(b.displayLabel ?? '') ?? 0),
    }))

  const transformedSymptomGroups: Array<FormMetaDataGroupedEnumValue> = getTransformedSymptomGroups()

  React.useEffect(() => {
    const filterSymptomGroupsBasedOnSearchTerm = (): Array<FormMetaDataGroupedEnumValue> => {
      if (searchText === '') {
        return transformedSymptomGroups
      }
      return transformedSymptomGroups.map(symptomGroup => ({
        ...symptomGroup,
        values: symptomGroup.values.filter(value =>
          value.displayLabel?.toLowerCase().includes(searchText.toLowerCase())
        ),
      }))
    }
    const filteredSymptomGroups: Array<FormMetaDataGroupedEnumValue> = filterSymptomGroupsBasedOnSearchTerm()
    setSymptomGroups(filteredSymptomGroups)
  }, [searchText])

  React.useEffect(() => {
    const getSelectedSymptomGroups = (): Array<FormMetaDataGroupedEnumValue> => {
      const symptomGroupValues: Array<EnumValue> = dataContext.getDataPathValue(metaDataPath) || []
      if (symptomGroupValues.length > 0) {
        return transformedSymptomGroups.map(symptomGroup => ({
          ...symptomGroup,
          values: symptomGroup.values.filter(value =>
            symptomGroupValues.some(symptomGroupValue => symptomGroupValue.enumId === value.id)
          ),
        }))
      }
      return []
    }
    setSelectedSymptomGroups(getSelectedSymptomGroups())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const getChangeLogValues = (currentValues: Array<EnumValue>, action: AuditLogAction): Array<EnumValue> => {
    const previousValues: Array<EnumValue> = dataContext.getDataPathValue(metaDataPath) || []
    switch (action) {
      case AuditLogAction.ARRAY_ADD:
        return differenceBy(currentValues, previousValues, 'enumId')
      case AuditLogAction.ARRAY_REMOVE:
        return differenceBy(previousValues, currentValues, 'enumId')
      default:
        return []
    }
  }

  const updateData = async (
    selectedSymptomGroup: Array<FormMetaDataGroupedEnumValue>,
    action: AuditLogAction
  ): Promise<void> => {
    const symptomGroupValues: Array<FormEnumValue> = flatten(
      selectedSymptomGroup.map(symptomGroup => symptomGroup.values)
    )
    const selectedValues: Array<EnumValue> = symptomGroupValues.map(symptomGroupValue =>
      indiFormService.getEnumValueObject(symptomGroupValue.id, symptomGroupValue.text)
    )
    const changeLogValues: Array<EnumValue> | undefined =
      action === AuditLogAction.DELETE ? undefined : getChangeLogValues(selectedValues, action)
    const changeLogData: ChangeLogData = {
      path: `data.case.${metaDataPath}`,
      value: changeLogValues,
    }
    await dataContext.updateData(metaDataPath, selectedValues, action, changeLogData)
  }

  const onSymptomGroupItemClick = async (groupName: string, color: string, value: FormEnumValueUI): Promise<void> => {
    const formEnumValue = value
    const selectedSymptomGroupItem: FormMetaDataGroupedEnumValue = {
      groupName,
      color,
      values: [formEnumValue],
    }
    // Filter out the clicked item from the list of selected symptom Groups
    if (formEnumValue.checked) {
      formEnumValue.checked = false
      const updatedSymptomGroups: Array<FormMetaDataGroupedEnumValue> = selectedSymptomGroups.map(symptomGroup => ({
        ...symptomGroup,
        values: symptomGroup.values.filter(val => val.id !== formEnumValue.id),
      }))
      await updateData(
        updatedSymptomGroups.filter(symptomGroup => symptomGroup.values.length !== 0),
        AuditLogAction.ARRAY_REMOVE
      )
    } else {
      formEnumValue.checked = true
      const updatedGroup = selectedSymptomGroups.find(
        symptomGroup => symptomGroup.groupName === selectedSymptomGroupItem.groupName
      )
      // Add the clicked item along with the group
      if (updatedGroup === undefined) {
        await updateData([...selectedSymptomGroups, selectedSymptomGroupItem], AuditLogAction.ARRAY_ADD)
      } else {
        // Add the clicked item updating the existing group
        const updatedSymptomGroups = selectedSymptomGroups.map(symptomGroup => {
          if (symptomGroup.groupName === selectedSymptomGroupItem.groupName) {
            const updatedValues = unionBy(symptomGroup.values, selectedSymptomGroupItem.values, 'id')
            return {
              ...symptomGroup,
              values: [...updatedValues],
            }
          }
          return symptomGroup
        })
        await updateData(updatedSymptomGroups, AuditLogAction.ARRAY_ADD)
      }
    }
  }

  const renderSymptomGroupItem = (groupName: string, color: string, value: FormEnumValueUI): React.ReactElement => (
    <Col xs={24} sm={12} xl={8} xxl={6} key={value.id} className="p-1">
      <Button
        block
        className={`symptom-button symptom-${color}`}
        onClick={async () => onSymptomGroupItemClick(groupName, color, value)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <FontAwesomeIcon className={`me-2 symptom-icon symptom-${color}`} icon={['fal', 'circle']} />
          <div className="wrap-text">{t(`enums.${enumType}.values.${value.id}`) ?? value.text}</div>
          <div>{value.checked && <FontAwesomeIcon icon={['fal', value.icon]} />}</div>
        </div>
      </Button>
    </Col>
  )

  const renderSymptomGroupHeader = (groupName: string): React.ReactElement => (
    <Col span={24} className="px-2 ">
      <h4 className="m-0">{t(`enums.${enumType}.areas.${groupName}`) ?? groupName}</h4>
    </Col>
  )
  const renderSymptomGroupList = (
    groupName: string,
    color: string,
    values: Array<FormEnumValueUI>
  ): React.ReactElement => <>{values.map(value => renderSymptomGroupItem(groupName, color, value))}</>

  const isSymptomGroupItemSelected = (groupName: string, value: FormEnumValue): boolean => {
    const selectedSymptomGroup: FormMetaDataGroupedEnumValue | undefined = selectedSymptomGroups.find(
      symptomGroup => symptomGroup.groupName === groupName
    )
    if (selectedSymptomGroup !== undefined) {
      const { values = [] } = selectedSymptomGroup
      return values.length > 0 && values.some(val => val.id === value.id)
    }
    return false
  }

  const renderSymptomGroup = (
    symptomGroupedValue: Array<FormMetaDataGroupedEnumValue>,
    iconName: IconName,
    checked?: boolean
  ): React.ReactElement => (
    <>
      {symptomGroupedValue.map((symptomGroup: FormMetaDataGroupedEnumValue, index: number) => {
        if (symptomGroup.values && symptomGroup.values.length > 0) {
          const { groupName = '', values = [], color = 'red' } = symptomGroup
          const symptomGroupValues: Array<FormEnumValueUI> = values.map(value => ({
            ...value,
            icon: iconName,
            checked: checked || isSymptomGroupItemSelected(groupName, value),
          }))
          return (
            <React.Fragment key={index}>
              {renderSymptomGroupHeader(groupName)}
              <Row>{renderSymptomGroupList(groupName, color, symptomGroupValues)}</Row>
            </React.Fragment>
          )
        }
        return null
      })}
    </>
  )

  const renderSelectedSymptomGroups = (symptomGroupsValue: Array<FormMetaDataGroupedEnumValue>): React.ReactElement => (
    <Row className="m-0">
      <Col span={24} className="selected-symptom-container py-1">
        {symptomGroupsValue.length === 0 ? (
          <h4>Nothing defined!</h4>
        ) : (
          renderSymptomGroup(symptomGroupsValue, 'times', true)
        )}
      </Col>
    </Row>
  )

  const renderFilterSection = (): React.ReactElement => (
    <Row>
      <Col span={24} className="my-1">
        Filter
      </Col>
      <Col span={24} className="mb-1">
        <SearchInput onSearchInputChange={setSearchText} isAutoFocused={false} />
      </Col>
    </Row>
  )

  const renderButtonGroup = (): React.ReactElement => {
    const buttonLabel: string = searchText === '' ? t('allButton') : t('allFoundButton')
    return (
      <Row className="my-1">
        <Button className="text-left me-2" onClick={async () => updateData(symptomGroups, AuditLogAction.ARRAY_ADD)}>
          {buttonLabel}
        </Button>
        <Button className="text-left" onClick={async () => updateData([], AuditLogAction.DELETE)}>
          {t('clearButton')}
        </Button>
      </Row>
    )
  }

  return (
    <>
      {renderSelectedSymptomGroups(selectedSymptomGroups)}
      {renderFilterSection()}
      {renderButtonGroup()}
      {renderSymptomGroup(symptomGroups, 'check')}
    </>
  )
}

export default Symptom
