import React, { useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import caseStateService from '../../document-state-service/CaseState.service'
import caseIndexDatabaseService from '../../services/browser-storage/index-database/CaseIndexDatabase.service'
import clinicsService from '../../services/clinics/Clinics.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import useAppStore from '../../state/App'
import useClinicStore from '../../state/Clinic'
import useContextStore from '../../state/Context'
import ObjectUtils from '../../utils/Object'
import SummaryLayout from './SummaryLayout'

type Props = WithTranslation

const CaseSummary = (props: Props): React.ReactElement | null => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { t } = props
  const formDocument = useContextStore(state => state.formDocument)

  const clinicsMetaData = useClinicStore(state => state.clinicsFormMetaData)

  const formMetaData = clinicsService.getClinicFormMetaData(
    clinicsService.getClinicIdFromMatchParams(params),
    clinicsMetaData
  )

  const initFormDocument = async (): Promise<void> => {
    // If location state is not populated then state would be location.state won't be defined.
    // It will populated by DocumentModal to view submitted document summary without loading it in any storage.
    // In other cases, load from IndexDB
    const formDocumentByUuid: FormDocument =
      location.state?.submittedDocument ?? (await caseIndexDatabaseService.getFormDocumentByUuid(params.formDocumentId))
    if (formDocumentByUuid) {
      useContextStore.setState({
        formDocument: formDocumentByUuid,
        formType: IndiFormType.CASE,
        updateData: caseStateService.updateData,
        updateDataChangeLog: caseStateService.updateDataChangeLog,
        updateDocumentStatus: caseStateService.updateDocumentStatus,
        getDataPathValue: caseStateService.getDataPathValue,
        initialized: true,
      })

      const caseNo: string = ObjectUtils.getAt({ data: formDocumentByUuid.document.data }, 'data.case.caseNo')
      useAppStore.getState().setNavbarHeader(`${t('summary.caseSummary')}: ${caseNo}`)
    } else {
      navigate('/clinics')
    }
  }

  useEffect(() => {
    initFormDocument()
  }, [])

  return formDocument ? <SummaryLayout formMetaData={formMetaData as ClinicFormMetaData} /> : null
}

export default withTranslation()(CaseSummary)
