class LanguageCodes {
  public getCountryBasedOnLanguage(languageCode: string): string {
    switch (languageCode) {
      case 'de':
        return 'de_DE'
      case 'ja':
        return 'ja_JP'
      case 'es':
        return 'es_ES'
      case 'it':
        return 'it_IT'
      case 'pt':
        return 'pt_BR'
      case 'en':
      default:
        return 'en_US'
    }
  }
}

const languageCodes = new LanguageCodes()
export default languageCodes
