const enum Roles {
  INDIVUPAD_DOCUMENT_REVIEWER = 'INDIVUPAD_DOCUMENT_REVIEWER',
  INDIVUPAD_CASE_COLLECTOR = 'INDIVUPAD_CASE_COLLECTOR',
  INDIVUPAD_FOLLOWUP_COLLECTOR = 'INDIVUPAD_FOLLOWUP_COLLECTOR',
  INDIVUPAD_ADMIN_DOCUMENT_EDITOR = 'INDIVUPAD_ADMIN_DOCUMENT_EDITOR',
  INDIVUPAD_CASE_SUBMITTED_SUMMARY = 'INDIVUPAD_CASE_SUBMITTED_SUMMARY',
  INDIVUPAD_FOLLOWUP_SUBMITTED_SUMMARY = 'INDIVUPAD_FOLLOWUP_SUBMITTED_SUMMARY',
}

export default Roles
