enum ValidationType {
  MIN = 'min',
  MAX = 'max',
  REGEX = 'regex',
  REQUIRED = 'required',
  REQUIRED_VALUE = 'required_value',
  CREATE_LABEL_IF_GREATER_THAN_ZERO = 'create_label_if_greater_than_zero',
  WHITESPACE_REMOVED = 'whitespace_removed',
  PREPROCESSOR = 'preprocessor',
  ZERO_IS_NULL = 'zero_is_null',
  REQUIRES = 'requires',
}

export default ValidationType
