import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Skeleton } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { cloneDeep, flatten } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import sharedStateHelper from '../../document-state-service/SharedStateHelper.service'
import clinicsService from '../../services/clinics/Clinics.service'
import EditorType from '../../services/indiform/EditorType.enum'
import evaluationService from '../../services/indiform/Evaluation.service'
import useContextStore from '../../state/Context'

interface MenuIcon {
  icon: Array<Record<string, never>>
  iconName: IconName
  prefix: string
}

interface Props {
  formMetaData: Array<Record<string, never>>
  onMenuItemClick: (menuItem: SelectedMenuItem) => void
  selectedMenuItem: string | undefined
  editorType: EditorType
}

export interface FormMenuItem {
  label: string
  icon: IconName
  items: Array<FormMenuItem>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMetaData: any
  metaDataPath: string
  isShown: boolean
}

export interface SelectedMenuItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMetaData: any
  metaDataPath: string
  key: string
}

const IndiformSidebar = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [formMenuItems, setFormMenuItems] = React.useState<Array<FormMenuItem>>([])
  const { formMetaData, selectedMenuItem } = props
  const params = useParams()
  const navigate = useNavigate()
  const buildMenuItem = (
    label: string,
    menuIcon: MenuIcon,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metaData: any,
    metaDataPath = '',
    items: Array<FormMenuItem> = [],
    isShown = true
    // eslint-disable-next-line max-params
  ): FormMenuItem => ({
    label: metaDataPath !== '' && items.length > 0 ? label : t(`form.labels.${label}`),
    icon: menuIcon?.iconName,
    formMetaData: metaData,
    metaDataPath,
    items,
    isShown,
  })

  const isListType = (child: Record<string, unknown>): boolean => {
    const { type = '' } = child
    return type === 'list'
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMenuItem = (metaData: any, listTypePath = ''): FormMenuItem | undefined => {
    const { menuGroup = false, menuLeaf = false, menuIcon, id = '', hideIf, showIf, children = [] } = metaData
    if (menuGroup && menuLeaf && evaluationService.evaluateExpression(hideIf, showIf, dataContext.formDocument)) {
      return buildMenuItem(id, menuIcon, metaData)
    }
    if (menuGroup && !menuLeaf) {
      const isFormMenuItemShown: boolean = evaluationService.evaluateExpression(
        hideIf,
        showIf,
        dataContext.formDocument,
        listTypePath
      )
      const subMenuItems: Array<FormMenuItem> = children
        .map((subMenuChild: Record<string, never>) => {
          const {
            menuLeaf: subMenuChildLeaf = false,
            id: subMenuChildId = '',
            menuIcon: subMenuChildIcon,
            variable = '',
            hideIf: newHideIf,
            showIf: newShowIf,
          } = subMenuChild
          if (isListType(subMenuChild) && !subMenuChildLeaf) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return getListTypeMenuItems(subMenuChild, listTypePath.concat(`.${variable}`))
          }
          if (
            subMenuChildLeaf &&
            evaluationService.evaluateExpression(newHideIf, newShowIf, dataContext.formDocument)
          ) {
            return buildMenuItem(subMenuChildId, subMenuChildIcon, subMenuChild, listTypePath)
          }
          return undefined
        })
        .filter((item: FormMenuItem) => item !== undefined)
      // When the parent element is selected, the form meta data for the first child is preselected
      const firstChildMetaData = subMenuItems[0] ? subMenuItems[0].formMetaData : undefined
      return buildMenuItem(id, menuIcon, firstChildMetaData, listTypePath, subMenuItems, isFormMenuItemShown)
    }
    return undefined
  }

  const updateIconLabelForListFormMenuItems = (formMenuItem: Array<FormMenuItem>): Array<FormMenuItem> => {
    if (formMenuItem.length === 1) {
      return formMenuItem.map(menuItem => {
        const menuItemDetail = menuItem
        menuItemDetail.label = t(`form.labels.add_${menuItemDetail.label}`)
        menuItemDetail.icon = 'plus'
        return menuItemDetail
      })
    }
    return formMenuItem.map((menuItem: FormMenuItem, index: number) => {
      const clonedFormMenuItem = cloneDeep(menuItem)
      clonedFormMenuItem.icon = index === formMenuItem.length - 1 ? 'plus' : menuItem.icon
      clonedFormMenuItem.label =
        index === formMenuItem.length - 1
          ? t(`form.labels.add_${menuItem.label}`)
          : `${t(`form.labels.${menuItem.label}`)} #${index + 1}`
      return clonedFormMenuItem
    })
  }

  const renderListBasedOnData = (
    listFormMetaData: Array<Record<string, unknown>>,
    path: string,
    variable: string
  ): Array<FormMenuItem> => {
    let formMenuItemsProps: Array<FormMenuItem> = []
    const listPathWithoutIndex: string = path.concat(sharedStateHelper.buildDataPath(variable))
    const dataValue: Array<Record<string, never>> | undefined = dataContext.getDataPathValue(listPathWithoutIndex)
    if (dataValue === undefined || dataValue.length === 0) {
      const listPathWithIndex: string = path.concat(sharedStateHelper.buildDataPath(variable, 0))
      formMenuItemsProps = listFormMetaData
        .map((child: Record<string, unknown>) => getMenuItem(child, listPathWithIndex))
        .filter((option: FormMenuItem | undefined): option is FormMenuItem => option !== undefined)
    } else {
      const listFormMenuItems = flatten(Array(dataValue.length + 1).fill(listFormMetaData))
      formMenuItemsProps = listFormMenuItems
        .map((child: Record<string, never>, listIndex: number) => {
          const listPathWithIndex: string = path.concat(sharedStateHelper.buildDataPath(variable, listIndex))
          return getMenuItem(child, listPathWithIndex)
        })
        .filter((option: FormMenuItem | undefined): option is FormMenuItem => option !== undefined)
    }
    return updateIconLabelForListFormMenuItems(flatten(formMenuItemsProps)).filter(formMenuItem => formMenuItem.isShown)
  }

  const getListTypeMenuItems = (metaData: Record<string, never>, path: string): Array<FormMenuItem> => {
    const { children = [] } = metaData
    let formMenuItemsList: Array<FormMenuItem> = []
    children.forEach((subChild: Record<string, never>) => {
      const { children: newChildren = [], variable = '' } = subChild
      if (isListType(subChild)) {
        formMenuItemsList = renderListBasedOnData(newChildren, path, variable)
      } else {
        formMenuItemsList = renderListBasedOnData([subChild], path, variable)
      }
    })
    return formMenuItemsList
  }

  const renderMenuItems = (): Array<FormMenuItem> => {
    let formMenuItemValue: Array<FormMenuItem> = []
    formMetaData.forEach(metaData => {
      const { menuGroup = false, menuLeaf = false } = metaData
      if (!menuGroup && !menuLeaf) {
        // Probably it is list type
        const listTypeMenuItems: Array<FormMenuItem> = getListTypeMenuItems(metaData, '').map(listItem => {
          const listTypeItem = listItem
          listTypeItem.items = flatten(listTypeItem.items)
          return listTypeItem
        })
        formMenuItemValue = [...formMenuItemValue, ...listTypeMenuItems]
      } else {
        const staticMenuItem: FormMenuItem | undefined = getMenuItem(metaData)
        if (staticMenuItem !== undefined) {
          formMenuItemValue = [...formMenuItemValue, staticMenuItem]
        }
      }
    })
    return formMenuItemValue
  }

  React.useEffect(() => {
    setFormMenuItems(renderMenuItems().filter((item: FormMenuItem) => Object.keys(item).length > 0))
  }, [dataContext.formDocument, formMetaData])

  React.useEffect(() => {
    const { onMenuItemClick } = props
    if (selectedMenuItem === undefined && formMenuItems.length > 0) {
      onMenuItemClick({
        formMetaData: formMenuItems[0].formMetaData,
        key: formMenuItems[0].formMetaData.id,
        metaDataPath: formMenuItems[0].metaDataPath,
      })
    }
  }, [formMenuItems])

  const getPath = (path: string, id: string): string => (path ? `${path}.${id}` : id)

  const onMenuItemClick = (item: FormMenuItem, key: string): void => {
    const { onMenuItemClick: onMenuItemClickFromProps, editorType } = props
    const path = getPath(item.metaDataPath, item.formMetaData.id)
    const clinicId: number = clinicsService.getClinicIdFromMatchParams(params)
    onMenuItemClickFromProps({ formMetaData: item.formMetaData, key, metaDataPath: item.metaDataPath })
    navigate(`/clinics/${clinicId}/${editorType}/${params.formDocumentId}/${path}`)
  }

  const renderMenuItem = (item: FormMenuItem, index: number): ItemType => {
    let uniqueKey = `${getPath(item.metaDataPath, item.formMetaData.id)}`
    if (index > 0) {
      uniqueKey = `${uniqueKey}_${index}`
    }

    return {
      key: uniqueKey,
      onClick: item.items?.length > 0 ? undefined : () => onMenuItemClick(item, uniqueKey),
      label: item.label,
      icon: <FontAwesomeIcon className="form-menu-item-icon" icon={['fal', item.icon ?? '']} />,
      children:
        item.items?.length > 0 ? item.items.map((subItem, subIndex) => renderMenuItem(subItem, subIndex)) : undefined,
    }
  }

  if (selectedMenuItem)
    return (
      <Menu
        selectedKeys={[selectedMenuItem]}
        mode="inline"
        style={{ border: '1px solid #e8e8e8' }}
        items={formMenuItems.map((item, index) => renderMenuItem(item, index))}
      />
    )
  return <Skeleton active />
}

export default IndiformSidebar
