import { Input } from 'antd'
import React from 'react'
import indiFormService from '../../services/indiform/IndiForm.service'
import validationService from '../../services/indiform-validation/Validation.service'
import ValidationType from '../../services/indiform-validation/ValidationType.enum'
import './Form.scss'
import useContextStore from '../../state/Context'

interface Props {
  defaultValue: string | undefined
  isDisabled: boolean
  isFullWidth: boolean
  metaDataPath: string
  placeholder: string
  validators: Record<ValidationType, never> | null
}

const FormInput = (props: Props): React.ReactElement => {
  const { validators, metaDataPath, defaultValue, isDisabled, isFullWidth, placeholder } = props
  const dataContext = useContextStore()
  const [value, setValue] = React.useState<string>('')
  const [isValid, setValidity] = React.useState<boolean>(false)

  React.useEffect(() => {
    const isInputValid: boolean = validationService.isFormElementValid(validators, value)
    setValidity(isInputValid)
  }, [value, dataContext.relatedData])

  React.useEffect(() => {
    const getFormElementValue = (): string => {
      const metaDataPathValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
      return metaDataPathValue ?? defaultValue ?? ''
    }
    setValue(getFormElementValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>): void => setValue(event.target.value)

  const updateCase = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const contextValue = dataContext.getDataPathValue(metaDataPath) ?? ''
    if (contextValue !== value) {
      await dataContext.updateData(metaDataPath, value)
    }
  }

  return (
    <Input
      data-testid={metaDataPath}
      className={indiFormService.getFormInputCSSClasses(validators, isValid, isDisabled, isFullWidth)}
      disabled={isDisabled}
      value={value}
      placeholder={placeholder}
      onBlur={updateCase}
      onChange={updateValue}
    />
  )
}

export default FormInput
