import Dexie from 'dexie'
import { File } from '../../../components/ui/file-upload/FileUpload'
import FileStatus from '../../files/FileStatus.enum'
import { BaseIndexDatabaseService } from './BaseIndexDatabase.service'

export interface FileDocument {
  document: File
  originFileObj?: string
}

class FileIndexDatabaseService extends BaseIndexDatabaseService {
  private readonly FILE_DOCUMENT_RECORDS_TABLE_NAME = 'fileDocumentRecords'
  public fileDocumentRecords: Dexie.Table<FileDocument, string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly db: any

  constructor() {
    super()
    this.db = this
    this.fileDocumentRecords = this.table(this.FILE_DOCUMENT_RECORDS_TABLE_NAME)
    this.open()
  }

  public async putFileDocument(fileDocument: FileDocument): Promise<string> {
    const fileDocumentRecord = await this.db.fileDocumentRecords.put(fileDocument)
    return fileDocumentRecord
  }

  public async getFileDocumentByPath(path: string): Promise<FileDocument> {
    const fileDocumentRecord = await this.db.fileDocumentRecords
      .where('[document.filePath+document.status]')
      .anyOf([
        [path, FileStatus.DONE],
        [path, FileStatus.UPLOADING],
      ])
      .first()
    return fileDocumentRecord
  }

  public async deleteFileDocumentByPath(path: string): Promise<number> {
    const fileDocumentRecord = await this.db.fileDocumentRecords
      .where('[document.filePath+document.status]')
      .anyOf([
        [path, FileStatus.DONE],
        [path, FileStatus.UPLOADING],
      ])
      .delete()
    return fileDocumentRecord
  }

  public async getFilesForSync(filePaths: Array<string>): Promise<Array<FileDocument>> {
    return this.db.fileDocumentRecords
      .filter((fileDocument: FileDocument) => {
        const { status, filePath } = fileDocument.document
        const statusCheck: boolean = !status || status !== FileStatus.REMOVED
        const filePathCheck: boolean = !filePath || filePaths.includes(filePath)
        return statusCheck && filePathCheck
      })
      .toArray()
  }
}

const fileIndexDatabaseService = new FileIndexDatabaseService()
export default fileIndexDatabaseService
