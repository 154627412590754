import { Button, Input } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useContextStore from '../../state/Context'

interface Props {
  metaDataPath: string
}

const TimePicker = (props: Props): React.ReactElement => {
  const timeFormat = 'HH:mm'
  const { t } = useTranslation()
  const dataContext = useContextStore()
  const [valueInput, setValue] = React.useState<string>('')
  const { metaDataPath } = props

  React.useEffect(() => {
    const getFormElementValue = (): string => {
      const metaDataPathValue: string = dataContext.getDataPathValue(metaDataPath)
      return metaDataPathValue ?? ''
    }
    setValue(getFormElementValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const reactChangeEvent = event
    const timeRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$/
    let { value } = reactChangeEvent.target
    const { defaultValue } = reactChangeEvent.target
    if (value === '' || value.match(timeRegex)) {
      value =
        value.length === 2 && defaultValue.length < value.length && !value.includes(':') ? value.concat(':') : value
      reactChangeEvent.target.value =
        value.length === 3 && defaultValue.length > value.length ? value.replace(':', '') : value
      await dataContext.updateData(metaDataPath, reactChangeEvent.target.value)
    }
  }

  const onBlur = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.value !== '') {
      let daysJsObject: Dayjs = dayjs()
      const splitArray = event.target.value.split(':')
      daysJsObject = daysJsObject.hour(+splitArray[0])
      daysJsObject = splitArray[1] === undefined ? daysJsObject.minute(0) : daysJsObject.minute(+splitArray[1])
      await dataContext.updateData(metaDataPath, daysJsObject.format(timeFormat))
    }
  }

  const onNowButtonClick = async (): Promise<void> => {
    await dataContext.updateData(metaDataPath, dayjs().format(timeFormat))
  }

  return (
    <div className="w-100 d-flex justify-content-between">
      <Input
        className="me-1"
        type="tel"
        value={valueInput}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={metaDataPath}
      />
      <Button onClick={onNowButtonClick}>{t('currentTimeButton')}</Button>
    </div>
  )
}

export default TimePicker
