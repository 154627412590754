import { Select } from 'antd'
import React from 'react'
import formRenderService from '../../services/indiform/FormRender.service'
import validationService from '../../services/indiform-validation/Validation.service'
import './Form.scss'
import useContextStore from '../../state/Context'

export interface DropdownOption {
  displayLabel: string
  label: string
  value: string | number | undefined
}

interface Props {
  isClearingAllowed: boolean
  options: Array<DropdownOption>
  placeholder: string
  value: string | number | undefined
  onChange: (value: DropdownOption | undefined) => void
  validators: Record<string, never> | null
  metadataPath: string
}

const FormDropDown = (props: Props): React.ReactElement => {
  const { Option } = Select
  const { onChange, isClearingAllowed, validators, placeholder, value, options: optionsFromProps, metadataPath } = props
  const dataContext = useContextStore()
  const [valueFromProp, setValue] = React.useState<string | number | undefined>(value)
  const [isValid, setValidity] = React.useState<boolean>()
  const [options, setOptions] = React.useState<Array<DropdownOption>>([])

  React.useEffect(() => {
    setValue(value)
  }, [dataContext.formDocument, value])

  React.useEffect(() => {
    const isInputValid: boolean = validationService.isFormElementValid(validators, valueFromProp)
    setValidity(isInputValid)
  }, [valueFromProp])

  React.useEffect(() => {
    setOptions(optionsFromProps)
  }, [optionsFromProps])

  const onDropdownValueChange = (changedValue: string | number | undefined): void => {
    const selectedDropDownOption: DropdownOption | undefined = optionsFromProps.find(
      option => option.value === changedValue
    )
    onChange(selectedDropDownOption)
  }

  const selectClassName = (): string => {
    if (validators === null) {
      return ''
    }
    if (isValid) {
      return 'select-success'
    }
    return 'select-error'
  }

  return (
    <Select
      data-testid={metadataPath}
      showSearch
      allowClear={isClearingAllowed}
      className={`w-100 ${selectClassName()}`}
      filterOption={(input, option) =>
        option !== undefined && String(option.children)?.toLowerCase().startsWith(input.toLowerCase())
      }
      optionFilterProp="children"
      placeholder={placeholder}
      value={value}
      onChange={onDropdownValueChange}
    >
      {options.map(option => (
        <Option key={formRenderService.generateRandomKey()} value={option.value ?? ''}>
          {option.displayLabel}
        </Option>
      ))}
    </Select>
  )
}

export default FormDropDown
