import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Alert, Col, List, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchInput from './SearchInput'
import './Form.scss'

export interface ListBoxOption {
  label: string
  value: string | number
  icon?: string | IconName
  isShown?: boolean
}

interface Props {
  favorites: Array<string>
  hasFilter: boolean
  initialNumberOfItemsToBeShown: number
  searchFieldHeader: string
  options: Array<ListBoxOption>
  onChange: (listOption: ListBoxOption) => void
}

const FormListBox = (props: Props): React.ReactElement => {
  const { favorites, options, initialNumberOfItemsToBeShown, hasFilter, searchFieldHeader, onChange } = props
  const { t } = useTranslation()
  const { Item } = List
  const MAX_SEARCH_RESULTS = 200
  const getInitialListOptions = (): Array<ListBoxOption> => {
    if (favorites.length === 0) {
      return options.slice(0, initialNumberOfItemsToBeShown)
    }
    return options.filter(option => favorites.some(favorite => favorite === option.value))
  }
  const initialListOptions: Array<ListBoxOption> = getInitialListOptions()
  const [count, setCount] = React.useState<number>(0)
  const [listOptions, setListOptions] = React.useState<Array<ListBoxOption>>(initialListOptions)

  const [searchText, setSearchText] = React.useState<string>('')

  const filterCasesBasedOnSearchTerm = (): Array<ListBoxOption> =>
    searchText === ''
      ? initialListOptions
      : options.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))

  React.useEffect(() => {
    if (searchText === '') {
      setListOptions(initialListOptions)
      setSearchText('')
      setCount(0)
    } else {
      const filteredListOptions: Array<ListBoxOption> = filterCasesBasedOnSearchTerm()
      setListOptions(filteredListOptions.slice(0, MAX_SEARCH_RESULTS))
      setCount(filteredListOptions.length)
    }
  }, [searchText])

  const onChangeEvent = (paramValue: string | number): void => {
    const selectedListOption: ListBoxOption | undefined = listOptions.find(option => option.value === paramValue)
    if (selectedListOption !== undefined) {
      onChange(selectedListOption)
    }
  }

  const showWarningMessage = (): React.ReactElement | null => {
    const warningMessage: string = t('icdFieldModalManyResultsMessage', {
      filteredCount: MAX_SEARCH_RESULTS,
      totalCount: count,
    })
    return (
      <div className="listbox-warning">
        {count > MAX_SEARCH_RESULTS && <Alert className="text-center" message={warningMessage} type="warning" />}
      </div>
    )
  }

  const renderList = (): React.ReactElement => (
    <List
      className="list-box"
      size="small"
      dataSource={listOptions}
      renderItem={listOption => (
        <Item className="my-1 px-1 cursor-pointer listbox-item" onClick={() => onChangeEvent(listOption.value)}>
          {listOption.label}
        </Item>
      )}
    />
  )

  return (
    <>
      {hasFilter && (
        <Row>
          <Col span={24}>{searchFieldHeader}</Col>
          <Col span={24}>
            <SearchInput isAutoFocused onSearchInputChange={setSearchText} />
          </Col>
          <Col span={24} className="my-1">
            {showWarningMessage()}
          </Col>
          <hr />
        </Row>
      )}
      {renderList()}
    </>
  )
}

export default FormListBox
