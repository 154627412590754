import { Badge, Card, List, Skeleton } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import useClinicStore from '../../state/Clinic'
import ArrayUtils from '../../utils/Array'
import './ClinicList.scss'

interface Props {
  clinics: Array<Clinic>
}

const ClinicList = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const clinicStore = useClinicStore()
  const { isLoadingClinics } = clinicStore
  const { Item } = List
  const { clinics } = props

  const renderClinics = (): React.ReactElement | Array<React.ReactElement> => {
    if (clinics.length === 0) {
      return <div>No clinics found!</div>
    }
    const countries = clinics
      .map(country => country.country)
      .filter((res: string, index: number, self: Array<string>) => self.indexOf(res) === index)
      .sort((a: string, b: string) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
    return countries.map(country => {
      const filteredClinics = ArrayUtils.sortArrayOfObjects(
        clinics.filter(clinic => clinic.country === country),
        'prefix'
      )

      return (
        <div key={country}>
          <h3 className="m-0">{country}</h3>
          <List
            dataSource={filteredClinics}
            renderItem={clinic => (
              <Item className="clinic-item">
                <Badge className="me-2 clinic-prefix" count={clinic.prefix[0]} />
                <Link to={`/clinics/${clinic.id}`} key={clinic.id}>
                  {t(`hospitals.${clinic.id}`)}
                </Link>
              </Item>
            )}
          />
        </div>
      )
    })
  }

  return <Card>{isLoadingClinics ? <Skeleton active /> : renderClinics()}</Card>
}

export default ClinicList
