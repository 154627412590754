import { Input } from 'antd'
import React from 'react'
import validationService from '../../services/indiform-validation/Validation.service'
import ValidationType from '../../services/indiform-validation/ValidationType.enum'
import useContextStore from '../../state/Context'

interface Props {
  isAutoResized: boolean
  metaDataPath: string
  placeholder: string
  validators: Record<ValidationType, never> | null
}

const { TextArea } = Input

const FormTextArea = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const { metaDataPath, validators, isAutoResized, placeholder } = props
  React.useEffect(() => {
    const formElementValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
    setValue(formElementValue ?? '')
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const updateValue = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value
    const isValid: boolean = validationService.isFormElementValid(validators, inputValue)
    if (isValid) {
      setValue(inputValue)
    }
  }

  const updateCase = async (): Promise<void> => {
    const contextValue = dataContext.getDataPathValue(metaDataPath) ?? ''
    if (contextValue !== value) {
      await dataContext.updateData(metaDataPath, value)
    }
  }

  return (
    <TextArea
      autoSize={isAutoResized}
      className="w-100"
      placeholder={placeholder}
      value={value}
      onBlur={updateCase}
      onChange={updateValue}
      data-testid={metaDataPath}
    />
  )
}

export default FormTextArea
