import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FormModal from '../ui/FormModal'

interface Props {
  helpImageName: string
  childrenItemId: string
  helpType: string
}

const ListTypeFormHelp: React.FC<Props> = (props): React.ReactElement | null => {
  const { t } = useTranslation()
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const [isHelpLoaded, setIsHelpLoaded] = React.useState<boolean>(false)
  const [currentImage, setCurrentImage] = React.useState<string>()
  const { helpImageName, childrenItemId, helpType } = props

  const loadImage = async (): Promise<void> => {
    const url = new URL(`../../assets/help/${helpImageName}`, import.meta.url)
    setCurrentImage(url.href)
    setIsHelpLoaded(true)
  }

  useEffect(() => {
    if (helpImageName !== undefined) {
      const executeLoadImage = async (): Promise<void> => {
        await loadImage()
      }

      executeLoadImage().catch(error => console.error(error))
    }
  }, [])

  return (
    <>
      <FontAwesomeIcon
        className="cursor-pointer me-1"
        icon={['fal', 'question-circle']}
        size="lg"
        onClick={() => toggleModal(true)}
      />

      {isHelpLoaded && (
        <FormModal
          header={t(`form.labels.${childrenItemId}`)}
          onCancel={() => toggleModal(false)}
          isVisible={showModal}
          isClosable
          isDismissableMask={false}
          footer={null}
          width="unset"
        >
          {helpType === 'image' && helpImageName && <img src={currentImage} alt="Help" />}
        </FormModal>
      )}
    </>
  )
}

export default ListTypeFormHelp
