import { create } from 'zustand'
import authService from '../services/auth/Auth.service'
import Roles from '../services/auth/Roles.enum'
import clinicsService from '../services/clinics/Clinics.service'
import IndiFormType from '../services/indiform/IndiFormType.enum'
import validationService from '../services/indiform-validation/Validation.service'
import { Clinic } from '../shared/interfaces/Clinic.interface'
import { ClinicFormMetaData } from '../shared/interfaces/ClinicFormMetaData.interface'
import { ClinicMetaDataValidator } from '../shared/interfaces/ClinicValidationMetaData.interface'
import { StoreFormMetaData } from '../shared/interfaces/StoreFormMetaData.interface'
import useIndiFormStore from './IndiForm'

interface ClinicStoreState {
  clinics: Array<Clinic>
  isLoadingClinics: boolean
  currentCasePageNumber: number
  currentFollowUpPageNumber: number
  activeTab: IndiFormType
  clinicsFormMetaData: Record<number, ClinicFormMetaData>
  followUpFormMetaData: Record<number, ClinicFormMetaData>
  clinicMetaDataValidations: Array<ClinicMetaDataValidator>
  searchText: string
}

interface ClinicStoreActions {
  loadingClinics: () => void
  storeClinics: (data: Array<Clinic>) => void
  loadingClinicsFailed: () => void
  loadingClinicFormMetaDataFailed: () => void
  storeClinicFormMetaData: (data: Array<StoreFormMetaData>) => void
  loadingFollowUpFormMetaDataFailed: () => void
  storeFollowUpFormMetaData: (data: Array<StoreFormMetaData>) => void
  setCurrentCasePageNumber: (pageNumber: number) => void
  setCurrentFollowUpPageNumber: (pageNumber: number) => void
  setValidationMetaData: (formMetaData: ClinicFormMetaData) => void
  loadClinics: () => Promise<void>
  loadClinicFormMetaData: (clinics: Array<Clinic>) => Promise<void>
  loadFollowUpFormMetaData: (clinics: Array<Clinic>) => Promise<void>
  setSearchText: (searchTerm: string) => void
  setActiveTab: (tab: IndiFormType) => void
}

const useClinicStore = create<ClinicStoreState & ClinicStoreActions>((set, get) => ({
  clinics: [],
  isLoadingClinics: false,
  searchText: '',
  currentCasePageNumber: 1,
  currentFollowUpPageNumber: 1,
  activeTab: IndiFormType.CASE,
  clinicsFormMetaData: {},
  followUpFormMetaData: {},
  clinicMetaDataValidations: [],

  loadingClinics: () => {
    set({ isLoadingClinics: true })
  },
  storeClinics: (data: Array<Clinic>) => {
    set({ isLoadingClinics: false, clinics: data })
  },
  loadingClinicsFailed: () => {
    set({ isLoadingClinics: false, clinics: [] })
  },
  loadingClinicFormMetaDataFailed: () => {
    const existingMeta = get().clinicsFormMetaData
    set({ clinicsFormMetaData: { ...existingMeta } })
  },
  storeClinicFormMetaData: (data: Array<StoreFormMetaData>) => {
    const formMetaData: Record<number, ClinicFormMetaData> = {}
    data.forEach(item => {
      formMetaData[item.formMetaData.clinicId] = item.formMetaData
    })
    const existingClinicMeta = get().clinicsFormMetaData
    set({
      clinicsFormMetaData: {
        ...formMetaData,
        ...existingClinicMeta,
      },
    })
  },
  loadingFollowUpFormMetaDataFailed: () => {
    const existingMeta = get().followUpFormMetaData
    set({ followUpFormMetaData: { ...existingMeta } })
  },
  storeFollowUpFormMetaData: (data: Array<StoreFormMetaData>) => {
    const formMetaData: Record<number, ClinicFormMetaData> = {}
    data.forEach(item => {
      formMetaData[item.formMetaData.clinicId] = item.formMetaData
    })
    const existingFollowupMeta = get().followUpFormMetaData
    set({
      followUpFormMetaData: {
        ...formMetaData,
        ...existingFollowupMeta,
      },
    })
  },
  setSearchText: (searchTerm: string) => {
    set({ searchText: searchTerm })
  },
  setCurrentCasePageNumber: (pageNumber: number) => {
    set({ currentCasePageNumber: pageNumber })
  },
  setCurrentFollowUpPageNumber: (pageNumber: number) => {
    set({ currentFollowUpPageNumber: pageNumber })
  },
  setValidationMetaData: (formMetaData: ClinicFormMetaData) => {
    const validations = validationService.generateValidationMetaData(formMetaData)
    set({ clinicMetaDataValidations: validations })
  },
  setActiveTab: (tab: IndiFormType) => {
    set({ activeTab: tab })
  },

  loadClinics: async (): Promise<void> => {
    const clinicStore = get()
    const indiFormStore = useIndiFormStore.getState()
    try {
      clinicStore.loadingClinics()
      const response: Array<Clinic> | null = await clinicsService.getClinics()
      if (response !== null) {
        const countryCodes: Array<string> = response
          .map(res => res.country)
          .filter((res, index, self) => self.indexOf(res) === index)

        clinicStore.storeClinics(response)
        clinicStore.loadClinicFormMetaData(response)
        clinicStore.loadFollowUpFormMetaData(response)
        indiFormStore.loadMedicationsFormMetaData(countryCodes)
      } else {
        clinicStore.loadingClinicsFailed()
      }
    } catch (error) {
      clinicStore.loadingClinicsFailed()
    }
  },

  loadClinicFormMetaData: async (clinics: Array<Clinic>): Promise<void> => {
    const clinicStore = get()
    try {
      const response: Array<StoreFormMetaData> | null = await clinicsService.getFormMetaData(clinics)
      if (response !== null) {
        clinicStore.storeClinicFormMetaData(response)
      }
    } catch (error) {
      clinicStore.loadingClinicFormMetaDataFailed()
    }
  },

  loadFollowUpFormMetaData: async (clinics: Array<Clinic>): Promise<void> => {
    const clinicStore = get()
    try {
      const response: Array<StoreFormMetaData> | null = await clinicsService.getFormMetaData(
        clinics,
        IndiFormType.FOLLOWUP
      )
      if (response !== null) {
        clinicStore.storeFollowUpFormMetaData(response)
      }
    } catch (error) {
      clinicStore.loadingFollowUpFormMetaDataFailed()
    }
  },
}))

export const GetDefaultTabValue = (): IndiFormType => {
  const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
  const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
  const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
  const isCaseSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_SUBMITTED_SUMMARY)
  const isFollowUpSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_SUBMITTED_SUMMARY)
  const currentTab = useClinicStore(state => state.activeTab)

  if ((isDocumentReviewer || isCaseCollector || isCaseSummaryRole) && currentTab === IndiFormType.CASE) {
    return IndiFormType.CASE
  } else if ((isFollowUpCollector || isFollowUpSummaryRole) && currentTab === IndiFormType.FOLLOWUP) {
    return IndiFormType.FOLLOWUP
  }
  return IndiFormType.FOLLOWUP
}

export default useClinicStore
