import { Modal } from 'antd'
import React, { ReactNode } from 'react'
import './Form.scss'

interface Props {
  isClosable: boolean
  isDismissableMask: boolean
  header: string
  footer: ReactNode
  isVisible: boolean
  onCancel: () => void
  width: string
  children: ReactNode
}

const FormModal = (props: Props): React.ReactElement => {
  const modalBodyRef = React.useRef<HTMLDivElement | null>(null)
  const [modalHeight, setModalHeight] = React.useState<number | undefined>(undefined)
  const [modalWidth, setModalWidth] = React.useState<number | undefined>(undefined)
  const RANDOM_MAGIC_NUMBER = 220
  const { isVisible, isClosable, footer, isDismissableMask, header, width, children, onCancel } = props

  React.useEffect(() => {
    setTimeout(() => {
      if (modalBodyRef.current !== null && isVisible) {
        const { clientHeight, clientWidth } = modalBodyRef.current
        const { innerHeight, innerWidth } = window

        const maxScreenWidth = innerWidth - 102 // 51 px left and right margin
        const maxScreenHeight = innerHeight - RANDOM_MAGIC_NUMBER
        const verticalScrollbarShown = clientHeight > maxScreenHeight
        const dialogHeight = verticalScrollbarShown ? maxScreenHeight : clientHeight
        // add 20 width if scrollbar is shown
        const dialogWidth =
          clientWidth + (verticalScrollbarShown ? 20 : 0) > maxScreenWidth
            ? maxScreenWidth
            : clientWidth + (verticalScrollbarShown ? 20 : 0)
        setModalHeight(dialogHeight)
        setModalWidth(dialogWidth)
      }
    })
  }, [isVisible])

  const onAfterClose = (): void => {
    setModalHeight(undefined)
    setModalWidth(undefined)
  }

  return (
    <Modal
      centered
      destroyOnClose
      className="form-modal m-3"
      closable={isClosable}
      footer={footer}
      maskClosable={isDismissableMask}
      title={header}
      open={isVisible}
      afterClose={onAfterClose}
      width={width}
      onCancel={onCancel}
    >
      <div
        ref={modalBodyRef}
        style={{
          height: modalHeight ? `${modalHeight}px` : 'unset',
          width: modalWidth ? `${modalWidth}px` : 'unset',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
    </Modal>
  )
}

export default FormModal
