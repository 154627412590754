export enum FormElementType {
  BOOLEAN = 'boolean',
  CHEMO = 'chemo',
  CLEAR_FIX = 'clearFix',
  COMMENT = 'comment',
  DATE = 'date',
  DAY = 'day',
  DECIMAL = 'decimal',
  ENUM = 'enum',
  GROUPED_ENUM = 'grouped_enum',
  HORMONE = 'hormone',
  ICD = 'icd',
  INTEGER = 'integer',
  MEDICATION = 'medication',
  MONTH = 'month',
  MULTI_ENUM = 'multi_enum',
  SAMPLE_COLLECTION = 'sampleCollection',
  OTHER = 'other',
  OUTPUT = 'output',
  PLACEHOLDER = 'placeholder',
  RADIO_BOX = 'radiobox',
  REVIEW = 'review',
  STRING = 'string',
  SYMPTOM = 'symptom',
  TIME = 'time',
  YEAR = 'year',
  FILE = 'file',
  RELATED_DATA = 'related_data',
  QC_LIMITED_DATA = 'qc_limited_data',
}

export default FormElementType
