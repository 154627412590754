import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, Tag } from 'antd'
import React from 'react'
import useContextStore from '../../state/Context'
import { MultiEnumOption } from '../indiform-ui/MultiEnum'
import './Form.scss'

interface Props {
  multiple: boolean
  options: Array<MultiEnumOption>
  filteredOptions: Array<MultiEnumOption>
  renderBackgroundColor: boolean
  values: Array<MultiEnumOption> | undefined
  onChange: (selectedOptions: Array<MultiEnumOption>) => void
}

const FormSelectButton: React.FC<Props> = (props): React.ReactElement => {
  const dataContext = useContextStore()
  const { CheckableTag } = Tag
  const [value, setValue] = React.useState<Array<string | number | undefined>>([])
  const { values, renderBackgroundColor, onChange } = props

  React.useEffect(() => {
    const getFormElementValues: Array<string | number | undefined> =
      values !== undefined ? values.map(val => val.value) : []
    setValue(getFormElementValues)
  }, [dataContext.formDocument, values])

  const isValueChecked = (optionValue: string | number | undefined): boolean => value.some(val => val === optionValue)

  const handleChange = (checked: boolean, selectedValue: string | number | undefined): void => {
    let updatedValues: Array<string | number | undefined> = []
    if (checked) {
      updatedValues = [...value, selectedValue]
    } else {
      updatedValues = value.filter(val => val !== selectedValue)
    }
    const options = updatedValues
      .map(val => props.options.find(option => option.value === val))
      .filter<MultiEnumOption>((opt: MultiEnumOption | undefined): opt is MultiEnumOption => opt !== undefined)
    if (options) {
      onChange(options)
    }
  }

  const getOptionsMapBasedOnBackgroundColor = (): Map<string, Array<MultiEnumOption>> => {
    const optionsMap: Map<string, Array<MultiEnumOption>> = new Map<string, Array<MultiEnumOption>>()
    props.filteredOptions.forEach(option => {
      const backgroundColor: string = option.backgroundColor as string
      if (optionsMap.has(backgroundColor)) {
        const valueArray: Array<MultiEnumOption> | undefined = optionsMap.get(backgroundColor)
        if (valueArray !== undefined) {
          optionsMap.set(backgroundColor, [...valueArray, option])
        }
      } else {
        optionsMap.set(backgroundColor, [option])
      }
    })
    return optionsMap
  }

  const getSelectButtonStyling = (backgroundColor: string): Record<string, unknown> => ({
    backgroundColor,
    borderColor: backgroundColor,
  })

  const getCheckableTagClassNames = (backgroundColor?: string): string =>
    backgroundColor !== undefined ? 'selectbutton-tag w-100 colored-selectbutton-tag' : 'selectbutton-tag w-100'

  const renderSelectButtonOptions = (options: Array<MultiEnumOption>, backgroundColor?: string): React.ReactElement => (
    <Row gutter={16} key={backgroundColor}>
      {options.map(option => (
        <Col
          span={5}
          className={`mx-2 my-1 ${isValueChecked(option.value) ? 'selectbutton-selected' : 'selectbutton-unselected'}`}
          key={option.value}
          style={backgroundColor !== undefined ? getSelectButtonStyling(backgroundColor) : undefined}
        >
          <CheckableTag
            className={getCheckableTagClassNames(backgroundColor)}
            checked={isValueChecked(option.value)}
            onChange={(checked: boolean) => handleChange(checked, option.value)}
          >
            <div className="d-flex justify-content-between align-items-center h-100">
              {option.displayLabel ?? option.text}
              {isValueChecked(option.value) && <FontAwesomeIcon icon={['fal', 'check']} />}
            </div>
          </CheckableTag>
        </Col>
      ))}
    </Row>
  )

  const renderSelectButton = (): React.ReactElement => {
    if (renderBackgroundColor) {
      const optionsMap: Map<string, Array<MultiEnumOption>> = getOptionsMapBasedOnBackgroundColor()
      return <>{Array.from(optionsMap).map(option => renderSelectButtonOptions(option[1], option[0]))}</>
    }
    return renderSelectButtonOptions(props.filteredOptions)
  }

  return renderSelectButton()
}

export default FormSelectButton
