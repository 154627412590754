import { Alert, App, Button, Col, Input, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import caseIndexDatabaseService from '../../services/browser-storage/index-database/CaseIndexDatabase.service'
import followUpIndexDatabaseService from '../../services/browser-storage/index-database/FollowUpIndexDatabase.service'
import casesService from '../../services/documents/Cases.service'
import followUpsService from '../../services/documents/FollowUps.service'
import ApplicationType from '../../services/existing-document-modal/ApplicationType.enum'
import existingDocumentModalService from '../../services/existing-document-modal/ExistingDocumentModal.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ExistingDocument } from '../../shared/interfaces/ExistingDocument.interface'
import { ExistingDocuments } from '../../shared/interfaces/ExistingDocuments.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import Status from '../../shared/interfaces/Status.interface'
import FormModal from '../ui/FormModal'
import '../ui/Form.scss'

enum ModalBodyStatus {
  ERROR = 'error',
  PROMPT = 'prompt',
}

interface Props {
  clinic: Clinic
  setCaseNo: (caseNo: string) => void
  documentType: IndiFormType
  setExistingDocuments: (existingDocuments: ExistingDocuments<Array<FollowUp>>) => void
  isVisible: boolean
  onCancel: () => void
  onExistingDocumentSelected: (formType: IndiFormType) => void
  onSelectExistingFollowUpModal: () => void
}

const LoadExistingDocumentModal: React.FC<Props> = (props): React.ReactElement => {
  const {
    documentType,
    clinic,
    isVisible,
    setExistingDocuments: setExistingDocumentsFromProps,
    setCaseNo,
    onSelectExistingFollowUpModal,
    onCancel,
  } = props
  const { t } = useTranslation()
  const [documentNo, setDocumentNo] = React.useState<string>('')
  const [isSearchInputDisabled, disableSearchInput] = React.useState<boolean>(false)
  const [isSearchDocumentButtonDisabled, disableSearchDocumentButton] = React.useState<boolean>(false)
  const [modalBodyStatusState, setModalBodyStatus] = React.useState<ModalBodyStatus | null>(null)
  const [existingDocuments, setExistingDocuments] = React.useState<ExistingDocument<Case> | undefined>(undefined)
  const [showInEditWarning, setInEditWarning] = React.useState<boolean>(false)
  const { notification } = App.useApp()

  React.useEffect(() => {
    if (documentType === IndiFormType.CASE) {
      setDocumentNo(clinic.prefix[0] ?? '')
    } else {
      setDocumentNo('')
    }
  }, [clinic, isVisible])

  const disableModelElements = (modalBodyStatus?: ModalBodyStatus): void => {
    disableSearchInput(false)
    disableSearchDocumentButton(false)
    setModalBodyStatus(modalBodyStatus ?? null)
  }

  const onModalCancel = (): void => {
    onCancel()
    disableModelElements()
  }

  const getFollowUpDocumentsByCaseNo = async (caseNo: string): Promise<void> => {
    const existingDocumentsList: ExistingDocuments<Array<FollowUp>> | null =
      await followUpsService.getExistingFollowUpDocumentsByCaseNo(caseNo)

    if (existingDocumentsList) {
      if (existingDocumentsList?.indivupad.length === 0) {
        existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
      } else {
        setCaseNo(caseNo)
        setExistingDocumentsFromProps(existingDocumentsList)
        onSelectExistingFollowUpModal()
      }
      onModalCancel()
    }
  }

  const onExistingDocumentSelected = (): void => {
    const { onExistingDocumentSelected: onExistingDocumentSelectedFromProps } = props
    onExistingDocumentSelectedFromProps(documentType)
    disableModelElements()
  }

  const updateCaseOverview = async (document: Case, application: ApplicationType): Promise<void> => {
    try {
      await casesService.updateCaseDocumentInBackend({ document })
      await caseIndexDatabaseService.updateFormDocumentIfUpdatedOrDoesNotExist({ document }, Date.now())
      onExistingDocumentSelected()
      existingDocumentModalService.showSuccessNotification(
        document,
        application,
        documentType,
        documentNo,
        notification,
        t
      )
    } catch (error) {
      existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
      onModalCancel()
    }
  }

  const updateFollowUpOverview = async (document: FollowUp, application: ApplicationType): Promise<void> => {
    try {
      await followUpsService.updateFollowUpDocumentInBackend({ document })
      await followUpIndexDatabaseService.updateFormDocumentIfUpdatedOrDoesNotExist({ document }, Date.now())
      onExistingDocumentSelected()
      existingDocumentModalService.showSuccessNotification(
        document,
        application,
        documentType,
        documentNo,
        notification,
        t
      )
    } catch (error) {
      existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
      onModalCancel()
    }
  }

  const selectExistingDocument = async (
    document: Case | FollowUp | undefined,
    application: ApplicationType
  ): Promise<void> => {
    if (document !== undefined) {
      // Add or Update the case in the IndexDB
      document.status = Status.PULLED_FOR_REVIEW
      if (documentType === IndiFormType.CASE) {
        await updateCaseOverview(document as Case, application)
      } else {
        await updateFollowUpOverview(document as FollowUp, application)
      }
    }
  }

  const getExistingDocumentsByDocumentNo = async (): Promise<void> => {
    disableSearchInput(true)
    disableSearchDocumentButton(true)

    if (documentType === IndiFormType.FOLLOWUP) {
      if (documentNo.includes('-')) {
        await getFollowUpDocumentsByCaseNo(documentNo.split('-')[0])
      } else {
        await getFollowUpDocumentsByCaseNo(documentNo)
      }
    } else {
      const documents: ExistingDocument<Case> | null = await casesService.getSubmittedCaseDocumentsByCaseNo(documentNo)

      if (documents !== null) {
        setExistingDocuments(documents)
        const { indivupad, nonSubmittedDocumentWarning } = documents

        if (indivupad) {
          if (nonSubmittedDocumentWarning) {
            setModalBodyStatus(ModalBodyStatus.PROMPT)
            setInEditWarning(nonSubmittedDocumentWarning)
          } else {
            await selectExistingDocument(indivupad, ApplicationType.INDIVUPAD)
          }
        } else {
          // If document is not available, show error message that the document number is invalid
          disableModelElements(ModalBodyStatus.ERROR)
        }
      } else {
        existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
        onModalCancel()
      }
    }
  }

  const getSubmittedDate = (document: Case | FollowUp): React.ReactElement | null => {
    const { submittedAt = null } = document
    if (submittedAt) {
      const submittedDate = new Date(submittedAt)
      return (
        <small>{`${t('documentList.loadDocumentModal.submissionDate')}: ${submittedDate.toLocaleDateString()}`}</small>
      )
    }
    return null
  }

  const renderLoadButtons = (): React.ReactElement | null =>
    existingDocuments?.indivupad !== null ? (
      <Button
        className="mr-1 load-document-button"
        onClick={() => selectExistingDocument(existingDocuments?.indivupad, ApplicationType.INDIVUPAD)}
      >
        {t('documentList.loadDocumentModal.fromIndivupad')}
        <br />
        {existingDocuments?.indivupad && getSubmittedDate(existingDocuments?.indivupad)}
      </Button>
    ) : null

  const renderConfirmationPopUp = (): React.ReactElement | null =>
    existingDocuments?.indivupad !== null ? (
      <Button
        className="mr-1 load-document-button"
        onClick={() => selectExistingDocument(existingDocuments?.indivupad, ApplicationType.INDIVUPAD)}
      >
        {t('documentList.loadDocumentModal.fromIndivupad')}
        <br />
        {existingDocuments?.indivupad && getSubmittedDate(existingDocuments?.indivupad)}
      </Button>
    ) : null

  const getPlaceholder = (): string =>
    documentType === IndiFormType.CASE ? documentType : `${IndiFormType.CASE}/${IndiFormType.FOLLOWUP}`

  const renderLoadDocumentModalBody = (): React.ReactElement => (
    <>
      <Row>
        <Col className="w-100 d-flex justify-content-between">
          <Input
            className="me-1"
            disabled={isSearchInputDisabled}
            placeholder={
              t('documentList.loadDocumentModal.placeholder', {
                documentType: getPlaceholder(),
              }) ?? ''
            }
            value={documentNo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDocumentNo(event.target.value)}
          />
          <Button disabled={isSearchDocumentButtonDisabled} onClick={getExistingDocumentsByDocumentNo}>
            {t('documentList.loadDocumentModal.searchButtonText', { documentType })}
          </Button>
        </Col>
      </Row>
      <Row className="my-2" style={{ minHeight: '120px' }}>
        {modalBodyStatusState === ModalBodyStatus.PROMPT && (
          <>
            <Alert
              message={t('documentList.loadDocumentModal.documentAvailabilityAlert', { documentType })}
              showIcon
              type="warning"
            />
            <div className="mt-2 text-end w-100 d-flex justify-content-end align-self-end">
              {showInEditWarning ? renderConfirmationPopUp() : renderLoadButtons()}
            </div>
          </>
        )}
        {modalBodyStatusState === ModalBodyStatus.ERROR && (
          <Alert
            message={t('documentList.loadDocumentModal.documentNoError', { documentType, documentNo })}
            type="error"
          />
        )}
      </Row>
    </>
  )

  return (
    <FormModal
      onCancel={onModalCancel}
      header={t('documentList.loadDocumentModal.title', {
        documentType: `${documentType[0].toUpperCase()}${documentType.slice(1)}`,
      })}
      isVisible={isVisible}
      width="50%"
      isClosable={false}
      isDismissableMask={false}
      footer={null}
    >
      {renderLoadDocumentModalBody()}
    </FormModal>
  )
}

export default LoadExistingDocumentModal
