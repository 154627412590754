import { Button, Col, Grid, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import authService from '../../services/auth/Auth.service'
import Roles from '../../services/auth/Roles.enum'
import caseIndexDatabaseService from '../../services/browser-storage/index-database/CaseIndexDatabase.service'
import casesService from '../../services/documents/Cases.service'
import EditorType from '../../services/indiform/EditorType.enum'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import remoteLoggingService from '../../services/logging-handler/RemoteLogging.service'
import { CaseDocument } from '../../shared/interfaces/CaseDocument.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import ObjectUtils from '../../utils/Object'
import SearchInput from '../ui/SearchInput'

interface Props {
  clinic: Clinic
  formType: IndiFormType
  documentsCount: number
  searchText: string
  onSearchInputChange: (searchText: string) => void
  onLoadDocumentButtonClick: () => void
  onLoadSummaryButtonClick: () => void
  onNewFollowUpButtonClick: () => void
}

const DocumentListHeader: React.FC<Props> = (props): React.ReactElement => {
  const { t } = useTranslation()
  const isCaseFormType: boolean = props.formType === IndiFormType.CASE
  const navigate = useNavigate()
  const { useBreakpoint } = Grid
  const breakpoints = useBreakpoint()

  const onNewDocumentButtonClick = async (): Promise<void> => {
    if (isCaseFormType) {
      const document: CaseDocument | undefined = casesService.createAndGetCaseDocument(props.clinic)
      if (document !== undefined) {
        const documentId: string = ObjectUtils.getAt(document, 'document.uuid')
        await caseIndexDatabaseService.putFormDocument(document)
        navigate(`/clinics/${props.clinic.id}/${EditorType.CASE}/${documentId}/caseData`)
      } else {
        await remoteLoggingService.logError(`${props.formType} document is undefined`)
      }
    } else {
      props.onNewFollowUpButtonClick()
    }
  }

  const renderCardHeader = (): React.ReactElement => {
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
    const isCaseSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_SUBMITTED_SUMMARY)
    const isFollowUpSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_SUBMITTED_SUMMARY)

    return (
      <>
        <Row className="justify-content-between mb-3">
          <Col span={3}>
            {isCaseCollector && isCaseFormType && (
              <Button onClick={onNewDocumentButtonClick} key="new-case-button">
                {t('documentList.newCase')}
              </Button>
            )}
            {isFollowUpCollector && !isCaseFormType && (
              <Button onClick={onNewDocumentButtonClick} key="new-follow-up-button">
                {t('documentList.newFollowUp')}
              </Button>
            )}
            {isDocumentReviewer && (
              <Button
                className={
                  (isCaseCollector && isCaseFormType) || (isFollowUpCollector && !isCaseFormType) ? 'ms-3' : ''
                }
                onClick={() => props.onLoadDocumentButtonClick()}
              >
                {isCaseFormType ? t('documentList.loadCase') : t('documentList.loadFollowUp')}
              </Button>
            )}
            {isCaseSummaryRole && isCaseFormType && (
              <Button className="ms-3" onClick={() => props.onLoadSummaryButtonClick()}>
                {t('documentList.loadCaseSummary')}
              </Button>
            )}
            {isFollowUpSummaryRole && !isCaseFormType && (
              <Button className="ms-3" onClick={() => props.onLoadSummaryButtonClick()}>
                {t('documentList.loadFollowUpSummary')}
              </Button>
            )}
          </Col>
          <Col span={breakpoints.xxl ? 9 : 24} className={breakpoints.xxl ? '' : 'mt-2'}>
            <SearchInput onSearchInputChange={props.onSearchInputChange} isAutoFocused={false} />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <div>
            {isCaseFormType ? `${t('documentList.totalCaseCount')}: ` : `${t('documentList.totalFollowUpCount')}: `}
            <span>{props.documentsCount}</span>
          </div>
        </Row>
      </>
    )
  }

  return renderCardHeader()
}

export default DocumentListHeader
