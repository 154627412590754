import { AxiosRequestConfig, AxiosResponse } from 'axios'
import authService from '../services/auth/Auth.service'
import Api from './Api'

interface Headers {
  [key: string]: string
}

// Rollup bundler used by vite has an issue with arrow-body-style
// Having the curly brackets around the function is needed for now
// eslint-disable-next-line arrow-body-style
export const getDefaultBaseUrl = (): string => {
  return process.env.REACT_APP_NODE_ENV === 'local-development' ? 'http://localhost:3001/api' : '/api'
}

export class WebApi {
  private readonly baseUrl: string
  private readonly withDefaultHeaders: boolean

  constructor(withDefaultHeaders = true, baseUrl: string = getDefaultBaseUrl()) {
    this.baseUrl = baseUrl
    this.withDefaultHeaders = withDefaultHeaders
  }

  public async request(requestConfig: AxiosRequestConfig): Promise<AxiosResponse> {
    return Api.request({
      ...requestConfig,
      baseURL: this.baseUrl,
      headers: this.setDefaultHeaders(requestConfig.headers as Headers),
    })
  }

  public buildJsonContentTypeRequest(
    url: string,
    params: Record<string, unknown> | undefined = undefined
  ): Promise<AxiosResponse> {
    return this.request({
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    })
  }

  private setDefaultHeaders(headers: Headers): Headers {
    if (this.withDefaultHeaders) {
      return {
        ...headers,
        Authorization: `Bearer ${authService.authToken}`,
      }
    }
    return headers
  }
}

export const webApi = new WebApi()
