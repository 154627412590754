import { Col } from 'antd'
import React from 'react'

interface Props {
  multiColumnLayout: number
}

const FormPlaceholder = ({ multiColumnLayout = 1 }: Props): React.ReactElement => (
  <Col span={(12 / multiColumnLayout) * 2} className="my-2" />
)

export default FormPlaceholder
