import Search from 'antd/es/input/Search'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isAutoFocused: boolean
  onSearchInputChange: (searchText: string) => void
}

const SearchInput = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const { isAutoFocused, onSearchInputChange } = props

  return (
    <Search
      allowClear
      autoFocus={isAutoFocused}
      enterButton
      placeholder={t('documentList.search') ?? ''}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearchInputChange(event.target.value)}
    />
  )
}

export default SearchInput
