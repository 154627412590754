import Dexie from 'dexie'
import { FormDocument } from '../../../shared/interfaces/FormDocument.interface'
import Status from '../../../shared/interfaces/Status.interface'
import authService from '../../auth/Auth.service'
import Roles from '../../auth/Roles.enum'
import remoteLoggingService from '../../logging-handler/RemoteLogging.service'
import { BaseIndexDatabaseService } from './BaseIndexDatabase.service'

export const DATABASE_NAME = 'indivupad'

export abstract class BaseFormIndexDatabaseService extends BaseIndexDatabaseService {
  abstract DOCUMENT_RECORDS_TABLE_NAME: string
  abstract documentRecords: Dexie.Table<FormDocument, string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract db: any
  protected abstract deleteFormDocumentsIfNotMatching: (
    formDocuments: Array<FormDocument>,
    clinicId: number
  ) => Promise<void>

  protected abstract filterFormDocumentByStatus: (
    clinicId: number,
    formDocument: FormDocument,
    allowedStatuses: Array<string>
  ) => boolean

  public putFormDocument = async (formDocument: FormDocument): Promise<string> =>
    this.db.documentRecords.put(formDocument)

  public deleteFormDocument = async (uuid: string): Promise<string> =>
    this.db.documentRecords.where({ 'document.uuid': uuid }).delete()

  public getFormDocumentByUuid = async (uuid = ''): Promise<FormDocument> => {
    const formDocument: FormDocument = await this.db.documentRecords.where({ 'document.uuid': uuid }).first()
    if (!formDocument) {
      const documentType = this.DOCUMENT_RECORDS_TABLE_NAME.includes('case') ? 'Case' : 'Follow-up'
      await remoteLoggingService.logError(`${documentType} document with uuid ${uuid} is not found`)
    }
    return formDocument
  }

  public getFormDocumentsByClinicId = async <T>(clinicId: number): Promise<Array<T>> => {
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
    const isAdmin: boolean = isDocumentReviewer && isCaseCollector && isFollowUpCollector

    if (isAdmin) {
      return this.db.documentRecords
        .filter((formDocument: FormDocument) =>
          this.filterFormDocumentByStatus(
            clinicId,
            formDocument,
            Object.keys(Status).filter(status => status !== Status.DELETED_BY_CLIENT)
          )
        )
        .toArray()
    } else if (isDocumentReviewer && isCaseCollector) {
      return this.db.documentRecords
        .filter((formDocument: FormDocument) =>
          this.filterFormDocumentByStatus(
            clinicId,
            formDocument,
            Object.keys(Status).filter(status => status !== Status.DELETED_BY_CLIENT)
          )
        )
        .toArray()
    } else if (isDocumentReviewer && isFollowUpCollector) {
      return this.db.documentRecords
        .filter((formDocument: FormDocument) =>
          this.filterFormDocumentByStatus(
            clinicId,
            formDocument,
            Object.keys(Status).filter(status => status !== Status.DELETED_BY_CLIENT)
          )
        )
        .toArray()
    } else if (isDocumentReviewer) {
      return this.db.documentRecords
        .filter((formDocument: FormDocument) =>
          this.filterFormDocumentByStatus(clinicId, formDocument, [
            Status.READY_FOR_REVIEW,
            Status.PULLED_FOR_REVIEW,
            Status.PUSH_REQUESTED_BY_USER,
            Status.IN_REVIEW,
            Status.QC_PASSED_REQUEST_BY_REVIEWER,
            Status.SUBMITTED,
            Status.REVIEW_COMPLETED,
            Status.SUBMIT_FAILED,
          ])
        )
        .toArray()
    }
    return this.db.documentRecords
      .filter((formDocument: FormDocument) =>
        this.filterFormDocumentByStatus(clinicId, formDocument, [
          Status.IN_PROCESS,
          Status.QC_PASSED,
          Status.QC_FAILED,
          Status.MISSING_DATA,
          Status.PUSH_REQUESTED_BY_USER,
          Status.DATA_COMPLETED,
        ])
      )
      .toArray()
  }

  public getDocumentRecordsForSync = async <T>(): Promise<Array<T>> =>
    this.db.documentRecords
      .filter((formDocument: FormDocument) => {
        const { lastSuccessfullSync, lastModifiedAt } = formDocument.document
        return !lastSuccessfullSync || (lastModifiedAt !== undefined && lastModifiedAt >= lastSuccessfullSync)
      })
      .toArray()

  // eslint-disable-next-line @typescript-eslint/ban-types
  public modifyFormDocumentByUuid = async (uuid: string, modificationCallback: Function): Promise<number> =>
    this.db.documentRecords.where({ 'document.uuid': uuid }).modify(modificationCallback)

  public putFormDocumentsIfUpdatedOrDoesNotExist = async (
    formDocuments: Array<FormDocument>,
    syncStarted: number
  ): Promise<Array<void>> =>
    Promise.all(
      formDocuments.map(async (formDocument: FormDocument) =>
        this.updateFormDocumentIfUpdatedOrDoesNotExist(formDocument, syncStarted)
      )
    )

  public markFormDocumentAsPushed = async (uuid: string): Promise<string> => {
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const userName: string | undefined = authService.getCurrentUsername()
    return this.db.documentRecords.where({ 'document.uuid': uuid }).modify((formDocument: FormDocument) => {
      const currentDateTime = Date.now()
      if (isDocumentReviewer) {
        formDocument.document.status = Status.SUBMITTED
        formDocument.document.submittedAt = currentDateTime
        formDocument.document.submittedBy = userName
      } else {
        formDocument.document.status = Status.PUSH_REQUESTED_BY_USER
      }
      formDocument.document.lastModifiedAt = currentDateTime
    })
  }

  public markFormDocumentAsDeletedByClient = async (uuid: string): Promise<string> =>
    this.db.documentRecords.where({ 'document.uuid': uuid }).modify((formDocument: FormDocument) => {
      formDocument.document.status = Status.DELETED_BY_CLIENT
      formDocument.document.lastModifiedAt = Date.now()
    })

  public markFormDocumentAsCompleted = async (uuid: string, status: Status): Promise<string> =>
    this.db.documentRecords.where({ 'document.uuid': uuid }).modify((formDocument: FormDocument) => {
      formDocument.document.status = status
      formDocument.document.lastModifiedAt = Date.now()
    })

  public updateFormDocumentIfUpdatedOrDoesNotExist = async (
    formDocument: FormDocument,
    syncStarted: number
  ): Promise<void> => {
    const existingDocumentRecord: FormDocument = await this.db.documentRecords
      .where({ 'document.uuid': formDocument.document.uuid })
      .first()
    if (existingDocumentRecord === undefined) {
      formDocument.document.lastSuccessfullSync = syncStarted
      await this.putFormDocument(formDocument)
    } else {
      const { lastModifiedAt: existingLastModifiedAt, lastSuccessfullSync: lastSuccessfullySync } =
        existingDocumentRecord.document
      const { lastModifiedAt: newLastModifiedAt } = formDocument.document

      if (
        existingLastModifiedAt !== undefined &&
        lastSuccessfullySync !== undefined &&
        existingLastModifiedAt < lastSuccessfullySync
      ) {
        if (newLastModifiedAt !== undefined && existingLastModifiedAt < newLastModifiedAt) {
          formDocument.document.lastSuccessfullSync = syncStarted
          await this.putFormDocument(formDocument)
        }
      }
    }
  }
}
