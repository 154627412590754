const enum ContextMenuOptionValue {
  SUMMARY = 'summary',
  SUBMIT = 'submit',
  DELETE = 'delete',
  EDIT_CASE_NUMBER = 'edit_case_number',
  DATA_COMPLETED = 'data_completed',
  REVIEW_COMPLETED = 'review_completed',
}

export default ContextMenuOptionValue
