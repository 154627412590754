import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import StoreValueType from './StoreValueType.enum'

class StorageService {
  private get localStorage(): Storage {
    return window.localStorage
  }

  /**
   * Sets the value of the given key.
   */
  public set(key: string, value: StoreValueType): void {
    try {
      this.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      remoteLoggingService.logError(`Failed to set the value ${JSON.stringify(value)} for key ${key} due to ${error}`)
    }
  }

  /**
   * Clears the entire local storage
   */
  public clear(): void {
    try {
      this.localStorage.clear()
    } catch (error) {
      remoteLoggingService.logError(`Failed to clear the local storage due to ${error}`)
    }
  }

  /**
   * Deletes the value of the given key
   */
  public removeItem(key: string): void {
    try {
      this.localStorage.removeItem(key)
    } catch (error) {
      remoteLoggingService.logError(`Failed to remove the key ${key} from the local storage due to ${error}`)
    }
  }

  /**
   * Gets the value of the given key
   */
  public get(key: string): StoreValueType | null {
    let value = null
    try {
      value = this.localStorage.getItem(key)
      if (value !== undefined) {
        value = JSON.parse(value as string)
      }
    } catch (error) {
      remoteLoggingService.logError(`Failed to get the value for the key ${key} due to ${error}`)
    }
    return value
  }
}

const storageService = new StorageService()
export default storageService
