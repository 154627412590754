import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Table } from 'antd'
import { ColumnsType, FilterValue, SortOrder, TablePaginationConfig } from 'antd/es/table/interface'
import { PaginationProps } from 'antd/lib/pagination/Pagination'
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { isBefore } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import staticDocumentListService from '../../services/document-list/StaticDocumentList.service'
import tableFiltersService from '../../services/document-list/TableFilters.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicMetaDataValidator } from '../../shared/interfaces/ClinicValidationMetaData.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import Status from '../../shared/interfaces/Status.interface'
import useClinicStore from '../../state/Clinic'
import ObjectUtils from '../../utils/Object'
import ContextMenu from './ContextMenu'
import DocumentValidationMessages from './DocumentValidationMessages'
import './DocumentList.scss'

interface Props {
  clinic: Clinic
  cases: Array<Case>
  searchText: string
  onDocumentCountChange: (documentsCount: number) => void
  onDocumentMarkCompleted: (documentToBeMarked: Case, status: Status) => void
  onPushClick: (documentToBePushed: Case) => void
  onDeleteClick: (documentToBeDeleted: Case) => void
}

const CaseList: React.FC<Props> = (props): React.ReactElement => {
  const { t } = useTranslation()
  const clinicStore = useClinicStore()
  const { cases, searchText, clinic, onDocumentCountChange, onDocumentMarkCompleted, onPushClick, onDeleteClick } =
    props
  const [caseList, setCases] = React.useState<Array<Case>>(cases)
  const currentPageNumber: number = clinicStore.currentCasePageNumber
  const PAGE_SIZE = 100
  const statuses = caseList.map(({ status }) => status)
  const authors = caseList.map(({ owner }) => owner)
  const sortDirections = ['ascend', 'descend', 'ascend'] as Array<SortOrder>
  const defaultSortOrder = 'ascend' as SortOrder
  const defaultPaginationOptions: PaginationProps = {
    hideOnSinglePage: true,
    defaultCurrent: currentPageNumber,
    pageSize: PAGE_SIZE,
    showQuickJumper: true,
    onChange: async (pageNumber: number) => {
      const currentCasePageNumber = clinicStore.setCurrentCasePageNumber(pageNumber)
      return currentCasePageNumber
    },
  }
  const validators: Array<ClinicMetaDataValidator> = clinicStore.clinicMetaDataValidations

  React.useEffect(() => {
    if (searchText === '') {
      setCases(cases)
      onDocumentCountChange(cases.length)
    } else {
      const filteredCases = cases.filter(clinicCase =>
        clinicCase.data.case.caseNo?.toLowerCase().includes(searchText.toLowerCase())
      )
      setCases(filteredCases)
      onDocumentCountChange(filteredCases.length)
    }
  }, [searchText, cases])

  const getCaseNo = (clinicCase: Case): React.ReactElement | null => {
    const { data, uuid } = clinicCase
    if (clinic !== undefined) {
      return (
        <Link to={`/clinics/${clinic.id}/caseEditor/${uuid}/caseData`}>{data.case.caseNo || '<no case number>'}</Link>
      )
    }
    return null
  }

  const renderCaseHasProjectNumber = (clinicCase: Case): React.ReactElement => (
    <div
      className={`${
        staticDocumentListService.ifCaseHasProjectNumber(clinicCase.data.case.accruals) ? 'fw-bold' : null
      }`}
    >
      {staticDocumentListService.ifCaseHasProjectNumber(clinicCase.data.case.accruals)
        ? t('documentList.table.values.project.yes')
        : t('documentList.table.values.project.no')}
    </div>
  )

  const renderReviewerComment = (clinicCase: Case): React.ReactElement | null => {
    const comment = ObjectUtils.getAt(clinicCase, 'data.case.reviewerComment')
    if (comment) {
      return (
        <div className="text-center">
          <Popover
            content={
              <span className="d-block" data-testid="reviewerComment">
                {comment}
              </span>
            }
            placement="bottomRight"
            trigger="click"
            showArrow={false}
          >
            <Button
              shape="round"
              icon={<FontAwesomeIcon icon={['fal', 'exclamation-triangle']} color="#FFCB0C" />}
              style={{ borderColor: '#FFCB0C' }}
            />
          </Popover>
        </div>
      )
    }
    return null
  }

  const columns: ColumnsType<Case> = [
    {
      title: t('documentList.table.columns.case'),
      dataIndex: 'data.case.caseNo',
      render: (text: string, clinicCase: Case) =>
        staticDocumentListService.isRowDisabled(clinicCase.status)
          ? ObjectUtils.getAt(clinicCase, 'data.case.caseNo')
          : getCaseNo(clinicCase),
      defaultSortOrder,
      sortDirections,
      sorter: (a: Case, b: Case) => {
        const caseANoWithPrefix = ObjectUtils.getAt(a, 'data.case.caseNo') ?? ''
        const caseBNoWithPrefix = ObjectUtils.getAt(b, 'data.case.caseNo') ?? ''
        const caseANo = Number(
          caseANoWithPrefix.replace(clinic?.prefix?.find(pre => caseANoWithPrefix.match(pre)) ?? '', '')
        )
        const caseBNo = Number(
          caseBNoWithPrefix.replace(clinic?.prefix?.find(pre => caseBNoWithPrefix.match(pre)) ?? '', '')
        )

        if (Number.isFinite(caseANo - caseBNo)) {
          return caseANo - caseBNo
        }

        return Number.isFinite(caseANo) ? -1 : 1
      },
    },
    {
      title: t('documentList.table.columns.startDate'),
      dataIndex: 'data.case.startAt',
      render: (text: string, clinicCase: Case) => ObjectUtils.getAt(clinicCase, 'data.case.startAt'),
      sortDirections,
      sorter: (a: Case, b: Case) => (isBefore(b.data.case.startAt ?? 0, a.data.case.startAt ?? 0) ? 1 : -1),
    },
    {
      title: t('documentList.table.columns.author'),
      dataIndex: 'owner',
      sortDirections,
      sorter: (a: Case, b: Case) => a.owner.localeCompare(b.owner),
      filters: tableFiltersService.getAuthorFilter(authors),
      onFilter: (value: React.Key | boolean, clinicCase: Case) => clinicCase.owner.toLowerCase() === value,
    },
    {
      title: t('documentList.table.columns.yearOfBirth'),
      dataIndex: 'data.case.patient.yearOfBirth',
      render: (text: string, clinicCase: Case) => ObjectUtils.getAt(clinicCase, 'data.case.patient.yearOfBirth'),
      sortDirections,
      sorter: (a: Case, b: Case) => {
        const yearOfBirthValueA = ObjectUtils.getAt(a, 'data.case.patient.yearOfBirth') ?? ''
        const yearOfBirthValueB = ObjectUtils.getAt(b, 'data.case.patient.yearOfBirth') ?? ''
        return yearOfBirthValueA - yearOfBirthValueB
      },
    },
    {
      title: t('documentList.table.columns.sex'),
      dataIndex: 'data.case.patient.gender',
      render: (text: string, clinicCase: Case) => {
        const gender = ObjectUtils.getAt(clinicCase, 'data.case.patient.gender')
        return gender ? t(`documentList.table.values.sex.${gender}`) : ''
      },
      sortDirections,
      sorter: (a: Case, b: Case) => {
        const genderValueA = ObjectUtils.getAt(a, 'data.case.patient.gender') ?? ''
        const genderValueB = ObjectUtils.getAt(b, 'data.case.patient.gender') ?? ''
        return genderValueA.localeCompare(genderValueB)
      },
      filters: tableFiltersService.getSexFilter(t),
      onFilter: (value: React.Key | boolean, clinicCase: Case) =>
        ObjectUtils.getAt(clinicCase, 'data.case.patient.gender') === value,
    },
    {
      title: t('documentList.table.columns.organ'),
      dataIndex: 'data.case.accruals[0].tumors[0].organ.enumText',
      render: (text: string, clinicCase: Case) =>
        ObjectUtils.getAt(clinicCase, 'data.case.accruals[0].tumors[0].organ.enumText'),
      sortDirections,
      sorter: (a: Case, b: Case) => {
        const organValueA = ObjectUtils.getAt(a, 'data.case.accruals[0].tumors[0].organ.enumText') ?? ''
        const organValueB = ObjectUtils.getAt(b, 'data.case.accruals[0].tumors[0].organ.enumText') ?? ''
        return organValueA.localeCompare(organValueB)
      },
      filters: tableFiltersService.getOrgansFilter(caseList),
      onFilter: (id: React.Key | boolean, clinicCase: Case) => {
        const organEnumId = ObjectUtils.getAt(clinicCase, 'data.case.accruals[0].tumors[0].organ.enumId')
        return organEnumId === id
      },
    },
    !clinic.isIndivuTypeClinic
      ? {
          title: t('documentList.table.columns.project'),
          render: (text: string, clinicCase: Case) => renderCaseHasProjectNumber(clinicCase),
          sortDirections,
          sorter: (a: Case, b: Case) => {
            const caseAHasProjectNo = staticDocumentListService.ifCaseHasProjectNumber(a.data.case.accruals) ? 1 : -1
            const caseBHasProjectNo = staticDocumentListService.ifCaseHasProjectNumber(b.data.case.accruals) ? 1 : -1
            return caseAHasProjectNo - caseBHasProjectNo
          },
          filters: tableFiltersService.getProjectFilter(t),
          onFilter: (value: React.Key | boolean, clinicCase: Case) =>
            value === 'y'
              ? staticDocumentListService.ifCaseHasProjectNumber(clinicCase.data.case.accruals)
              : !staticDocumentListService.ifCaseHasProjectNumber(clinicCase.data.case.accruals),
        }
      : {},
    {
      title: t('documentList.table.columns.status'),
      dataIndex: 'status',
      render: (text: string, clinicCase: Case) => staticDocumentListService.getStatus(clinicCase.status, t),
      sortDirections,
      sorter: (a: Case, b: Case) => {
        const caseAStatus = staticDocumentListService.getStatus(a.status, t)
        const caseBStatus = staticDocumentListService.getStatus(b.status, t)
        return caseAStatus.localeCompare(caseBStatus)
      },
      filters: tableFiltersService.getStatusFilter(statuses, t),
      onFilter: (value: React.Key | boolean, clinicCase: Case) => clinicCase.status === value,
    },
    {
      dataIndex: 'messages',
      render: (text: string, clinicCase: Case) => (
        <div className="text-center">
          <DocumentValidationMessages messages={clinicCase.messages ?? []} />
        </div>
      ),
    },
    {
      dataIndex: 'reviewerComment',
      render: (text: string, clinicCase: Case) => renderReviewerComment(clinicCase),
    },
    {
      render: (text: string, clinicCase: Case) => (
        <div className="text-end">
          <ContextMenu
            clinic={clinic}
            validators={validators}
            document={clinicCase}
            onDocumentMarkCompleted={(document: Case | FollowUp, status: Status) =>
              onDocumentMarkCompleted(document as Case, status)
            }
            onPushClick={(document: Case | FollowUp) => onPushClick(document as Case)}
            onDeleteClick={(document: Case | FollowUp) => onDeleteClick(document as Case)}
            formType={IndiFormType.CASE}
          />
        </div>
      ),
    },
  ]

  const renderCaseList = (): React.ReactElement => (
    <Table
      columns={columns}
      dataSource={caseList}
      scroll={{ x: true }}
      locale={{ emptyText: 'No cases in inventory. Please create a new case.' }}
      pagination={defaultPaginationOptions}
      rowKey={(clinicCase: Case) => ObjectUtils.getAt(clinicCase, 'uuid')}
      rowClassName={(clinicCase: Case) =>
        staticDocumentListService.isRowDisabled(clinicCase.status) ? 'disabled-row' : ''
      }
      onChange={(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Case> | Array<SorterResult<Case>>,
        extra: TableCurrentDataSource<Case>
      ) => onDocumentCountChange(extra.currentDataSource.length)}
    />
  )

  return renderCaseList()
}
export default CaseList
