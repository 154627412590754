export default abstract class LoggingService {
  public abstract logInfo(...args: Array<Record<never, never>>): void

  public abstract logWarning(...args: Array<Record<never, never>>): void

  public abstract logError(...args: Array<Record<never, never>>): void

  public abstract logDebug(...args: Array<Record<never, never>>): void

  public abstract logVerbose(...args: Array<Record<never, never>>): void
}
