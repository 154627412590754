import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import useContextStore from '../../state/Context'
import FormDropDown, { DropdownOption } from '../ui/FormDropDown'

interface Props {
  metaDataPath: string
}

const Boolean = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const { metaDataPath } = props

  React.useEffect(() => {
    const initialValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
    setValue(initialValue)
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const updateValueAndCase = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    if (selectedOption === undefined) {
      await dataContext.updateData(metaDataPath, selectedOption)
    } else {
      const selectedOptionValue: string = selectedOption.value as string
      await dataContext.updateData(metaDataPath, selectedOptionValue)
    }
  }

  return (
    <FormDropDown
      isClearingAllowed
      options={indiFormService.getBooleanDropDownOptions(t)}
      value={value}
      onChange={updateValueAndCase}
      placeholder=""
      validators={null}
      metadataPath={metaDataPath}
    />
  )
}

export default Boolean
