import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, List } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useAppStore from '../state/App'
import useAuthStore from '../state/Auth'
import { ListBoxOption } from './ui/FormListBox'
import './AppSideBar.scss'

const AppSideBar = (): React.ReactElement => {
  const { t } = useTranslation()
  const { Item } = List
  const [visible, setVisible] = React.useState<boolean>(false)
  const authStore = useAuthStore()
  const appStore = useAppStore()
  const { authToken } = authStore
  const { isOnline } = appStore

  const sideBarItems: Array<ListBoxOption> = [
    {
      label: t('changeUserSettings'),
      value: 'changeUserSettings',
      icon: 'user-edit',
      isShown: isOnline,
    },
    {
      label: 'Logout',
      value: 'logout',
      icon: 'sign-out-alt',
      isShown: true,
    },
  ]

  const onItemClick = (item: ListBoxOption): void => {
    setVisible(false)
    if (item.value === 'changeUserSettings' && authToken !== null) {
      const indiauthClientUrl =
        process.env.REACT_APP_NODE_ENV === 'local-development'
          ? process.env.REACT_APP_OAUTH_CLIENT_URL
          : process.env.REACT_APP_OAUTH_URL
      window.location.replace(`${indiauthClientUrl}/user-settings?access-token=${authToken.accessToken}`)
    } else if (item.value === 'logout') {
      authStore.logout()
      appStore.setNavbarHeader('')
    }
  }

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={['fal', 'bars']} />}
        shape="round"
        onClick={() => setVisible(!visible)}
        data-testid="usermenu-button"
      />
      <Drawer title={null} className="app-sidebar" onClose={() => setVisible(false)} open={visible}>
        <div className="d-flex flex-column justify-content-between h-100">
          <List
            dataSource={sideBarItems.filter(sideBarItem => sideBarItem.isShown)}
            renderItem={sidebarItem => (
              <Item className="cursor-pointer app-sidebar-row" onClick={() => onItemClick(sidebarItem)}>
                <FontAwesomeIcon className="me-2" icon={['fal', sidebarItem.icon as IconName]} />
                {sidebarItem.label}
              </Item>
            )}
          />
          {process.env.REACT_APP_INT_VERSION && (
            <div className="text-center">{`Version: v${process.env.REACT_APP_INT_VERSION}`}</div>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default AppSideBar
