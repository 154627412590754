import { AxiosResponse } from 'axios'
import { isNil, omitBy } from 'lodash-es'
import * as stackTrace from 'stack-trace'
import { Log as ILog } from '../../shared/interfaces/Log.interface'
import logIndexDatabaseService from '../browser-storage/index-database/LogIndexDatabaseService'
import encryptionService from '../encryption/Encryption.service'
import logResourceService from '../rest-resources/LogResource.service'
import LoggingService from './Logging.service'

enum LogLevel {
  Log = 'log',
  Error = 'error',
  Warn = 'warn',
  Debug = 'debug',
  Verbose = 'verbose',
}

class RemoteLoggingService extends LoggingService {
  public logInfo(...args: Array<Record<never, never>>): Promise<void> {
    console.info(...args)
    args.push(LogLevel.Log)
    return this.sendToHost(...args)
  }

  public logWarning(...args: Array<Record<never, never>>): Promise<void> {
    console.warn(...args)
    args.push(LogLevel.Warn)
    return this.sendToHost(...args)
  }

  public logError(...args: Array<Record<never, never>>): Promise<void> {
    console.error(...args)
    args.push(LogLevel.Error)
    return this.sendToHost(...args)
  }

  public logDebug(...args: Array<Record<never, never>>): Promise<void> {
    console.debug(...args)
    args.push(LogLevel.Debug)
    return this.sendToHost(...args)
  }

  public logVerbose(...args: Array<Record<never, never>>): Promise<void> {
    console.trace(...args)
    args.push(LogLevel.Verbose)
    return this.sendToHost(...args)
  }

  private async sendToHost(...args: Array<Record<never, never>>): Promise<void> {
    const argsData: Array<Record<never, never>> = args
      .map(arg => {
        if (arg instanceof Error) {
          return stackTrace.parse(arg)
        }
        try {
          return arg.toString()
        } catch (error) {
          return arg
        }
      })
      .filter(arg => omitBy(arg, isNil))
    const log: ILog = {
      message: argsData[0].toString(),
      logLevel: argsData[1].toString(),
      timestamp: Date.now(),
    }

    const isLogAvailableInDb: boolean = await this.compareLogs(log.message)

    if (!isLogAvailableInDb) {
      await logIndexDatabaseService.putLogEntry(log)
    }
  }

  public async syncLogEntries(): Promise<Array<void>> {
    const logEntries = await logIndexDatabaseService.getLogEntriesForSync()
    return Promise.all(logEntries.map(logEntry => this.syncLog(logEntry as ILog)))
  }

  private async syncLog(log: ILog): Promise<void> {
    const logEntry = await this.sendToBackend(log)
    await logIndexDatabaseService.deleteLogEntry(logEntry.timestamp)
  }

  private async sendToBackend(log: ILog): Promise<ILog> {
    let response: ILog
    try {
      const axiosResponse: AxiosResponse = await logResourceService.log(log)
      response = axiosResponse.data
    } catch (error) {
      await this.logError(`Failed to create log ${JSON.stringify(log)} due to ${error}`)
      throw error
    }
    return response
  }

  private async compareLogs(message: string): Promise<boolean> {
    const encryptedMessage = encryptionService.encrypt(message)
    const indexedDbMessages = await logIndexDatabaseService.getLogsForComparison()
    const encryptedIndexDbMessages = indexedDbMessages.map(log => encryptionService.encrypt(log.message))

    return encryptedIndexDbMessages.includes(encryptedMessage)
  }
}

const remoteLoggingService = new RemoteLoggingService()
export default remoteLoggingService
