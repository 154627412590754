import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormListBox, { ListBoxOption } from '../ui/FormListBox'
import FormModal from '../ui/FormModal'

interface Props {
  favorites: Array<string>
  hasFilter: boolean
  header: string
  metaDataPath: string
}
const Icd = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState<string | undefined>(undefined)
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const indiFormStore = useIndiFormStore()

  const icdCodeOptions: Array<ListBoxOption> = indiFormService.getIcdOptions(indiFormStore)
  const { metaDataPath, header, favorites, hasFilter } = props

  React.useEffect(() => {
    const initialValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
    setValue(initialValue)
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const getIcdCodeLabel = (paramValue: string | undefined): string | undefined =>
    icdCodeOptions.find(icdCode => icdCode.value === paramValue)?.label

  const onChange = async (listOption: ListBoxOption): Promise<void> => {
    await dataContext.updateData(metaDataPath, listOption.value)
    toggleModal(false)
  }

  return (
    <>
      <div
        className="ant-input w-100 form-list-box cursor-pointer"
        role="button"
        tabIndex={0}
        style={{ outline: 'none' }}
        onClick={() => toggleModal(true)}
        onKeyDown={() => toggleModal(true)}
        data-testid={metaDataPath}
      >
        {getIcdCodeLabel(value) ?? null}
      </div>
      <FormModal
        header={header}
        isVisible={showModal}
        width="50%"
        onCancel={() => toggleModal(false)}
        isClosable
        isDismissableMask={false}
        footer={null}
      >
        <FormListBox
          favorites={favorites}
          hasFilter={hasFilter}
          initialNumberOfItemsToBeShown={67}
          searchFieldHeader={t('icdFieldModalSearchIcdLabel')}
          options={icdCodeOptions}
          onChange={onChange}
        />
      </FormModal>
    </>
  )
}

export default Icd
