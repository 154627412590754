import React from 'react'
import authService from '../services/auth/Auth.service'
import Roles from '../services/auth/Roles.enum'
import casesService from '../services/documents/Cases.service'
import followUpsService from '../services/documents/FollowUps.service'
import remoteLoggingService from '../services/logging-handler/RemoteLogging.service'
import useAppStore from '../state/App'

class Synchronization extends React.Component {
  private readonly SYNC_INTERVAL: number = 30 * 1000
  private syncDocumentsTimer: number | null = null
  private isComponentMounted = false

  public componentDidMount(): void {
    this.isComponentMounted = true
    this.initAutomaticReload()
  }

  public componentWillUnmount(): void {
    this.isComponentMounted = false
    this.resetTimer()
  }

  private readonly resetTimer = (): void => {
    if (this.syncDocumentsTimer !== null) {
      clearInterval(this.syncDocumentsTimer)
      this.syncDocumentsTimer = null
    }
  }

  public initAutomaticReload(): void {
    if (this.syncDocumentsTimer === null && this.isComponentMounted) {
      this.syncDocumentsTimer = window.setInterval(async () => {
        await this.syncDocuments()
      }, this.SYNC_INTERVAL)
    }
  }

  private async syncDocuments(): Promise<void> {
    const { isOnline } = useAppStore.getState() // STATE_UPDAT
    this.resetTimer()
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
    const isAdmin: boolean = isDocumentReviewer && isCaseCollector && isFollowUpCollector

    if (isOnline) {
      switch (true) {
        case isAdmin || isDocumentReviewer || (isCaseCollector && isFollowUpCollector):
          await Promise.all([casesService.syncAllCases(), followUpsService.syncAllFollowUps()])
          break
        case isCaseCollector:
          await casesService.syncAllCases()
          break
        case isFollowUpCollector:
          await followUpsService.syncAllFollowUps()
          break
        default:
          break
      }
      await remoteLoggingService.syncLogEntries()
    }
    this.initAutomaticReload()
  }

  public render(): React.ReactElement | null {
    return null
  }
}

export default Synchronization
