import { Col, Row } from 'antd'
import React from 'react'
import { EnumValue } from '../../../shared/interfaces/EnumValue.interface'
import useContextStore from '../../../state/Context'
import FormCalendar, { Picker } from '../../ui/FormCalendar'
import FormInput from '../../ui/FormInput'
import FormInputNumber from '../../ui/FormInputNumber'
import FormLabel from '../../ui/FormLabel'

export interface SchemaAgentValue {
  type: EnumValue
  cycle?: number
  fromDate?: string
  toDate?: string
}

interface Props {
  metaDataPath: string
}
const SchemaAgent = (props: Props): React.ReactElement => {
  const { metaDataPath } = props
  const dataContext = useContextStore()
  const schemaAgents: Array<SchemaAgentValue> = dataContext.getDataPathValue(`${metaDataPath}.agents`) || []
  const getSchemaAgentTypeEnumTextPath = (index: number): string => `${metaDataPath}.agents[${index}].type.enumText`
  const getSchemaAgentCyclePath = (index: number): string => `${metaDataPath}.agents[${index}].cycle`
  const getSchemaAgentFromDatePath = (index: number): string => `${metaDataPath}.agents[${index}].fromDate`
  const getSchemaAgentToDatePath = (index: number): string => `${metaDataPath}.agents[${index}].toDate`

  return (
    <>
      {schemaAgents
        .filter(agent => agent.type?.enumId)
        .map((schemaAgent: SchemaAgentValue, index: number) => (
          <Row gutter={16} key={schemaAgent.type.enumId}>
            <Col span={12}>
              <FormLabel label="chemo_therapy_agent" metaDataPath={metaDataPath} />
              <FormInput
                isDisabled
                metaDataPath={getSchemaAgentTypeEnumTextPath(index)}
                defaultValue={undefined}
                isFullWidth={false}
                placeholder=""
                validators={null}
              />
            </Col>
            <Col span={12}>
              <FormLabel label="chemo_therapy_cycle" metaDataPath={metaDataPath} />
              <FormInputNumber
                metaDataPath={getSchemaAgentCyclePath(index)}
                decimalScale={undefined}
                isDisabled={false}
                isFullWidth={false}
                placeholder=""
                validators={null}
              />
            </Col>
            <Col span={12}>
              <FormLabel label="chemo_therapy_from" metaDataPath={metaDataPath} />
              <FormCalendar
                metaDataPath={getSchemaAgentFromDatePath(index)}
                picker={Picker.MONTH}
                isCurrentOptionEnabled
                placeholder=""
                validators={null}
              />
            </Col>
            <Col span={12}>
              <FormLabel label="chemo_therapy_to" metaDataPath={metaDataPath} />
              <FormCalendar
                metaDataPath={getSchemaAgentToDatePath(index)}
                picker={Picker.MONTH}
                isCurrentOptionEnabled
                placeholder=""
                validators={null}
              />
            </Col>
          </Row>
        ))}
    </>
  )
}

export default SchemaAgent
