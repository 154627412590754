import { Col } from 'antd'
import React from 'react'
import sharedStateHelper from '../../document-state-service/SharedStateHelper.service'
import evaluationService from '../../services/indiform/Evaluation.service'
import { FormElementType } from '../../services/indiform/FormElementType.enum'
import formRenderService from '../../services/indiform/FormRender.service'
import useContextStore from '../../state/Context'
import FormLabel from '../ui/FormLabel'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaData: any
  metaDataPath: string
}

const FormElement = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { metaData, metaDataPath } = props
  const {
    id = '',
    multiColumnLayout = 1,
    active = true,
    variable = '',
    hideIf,
    showIf,
    type = '',
    placeholderIfHidden = false,
  } = metaData
  const updatedPath: string = sharedStateHelper.buildMetaDataPath(metaDataPath, variable)
  const updatedMetaDataPath: string = sharedStateHelper.buildDataPath(updatedPath)
  const isShown: boolean = evaluationService.evaluateExpression(
    hideIf,
    showIf,
    dataContext.formDocument,
    updatedMetaDataPath
  )
  const isLabelShown = ![
    FormElementType.CHEMO,
    FormElementType.HORMONE,
    FormElementType.OTHER,
    FormElementType.REVIEW,
    FormElementType.QC_LIMITED_DATA,
  ].includes(type)

  React.useEffect(() => {
    const updateData = async (): Promise<void> => {
      if (!isShown) {
        const caseDataPathValue: Record<string, unknown> = dataContext.getDataPathValue(updatedMetaDataPath)
        if (
          caseDataPathValue !== undefined &&
          !updatedMetaDataPath.endsWith('.preparation.labels') &&
          !updatedMetaDataPath.endsWith('.metastasissamples')
        ) {
          await dataContext.updateData(updatedMetaDataPath, undefined)
        }
      }
    }
    updateData().then()
  }, [dataContext, dataContext.formDocument, metaDataPath, updatedMetaDataPath, isShown])

  const renderFormElementBasedOnType = (): React.ReactElement | null => {
    if (!isShown && placeholderIfHidden) {
      return <Col span={Math.round(12 / multiColumnLayout) * 2} className="my-2" key={id} />
    }
    if (isShown) {
      return (
        <Col span={Math.round(12 / multiColumnLayout) * 2} className="my-2" key={id}>
          {isLabelShown && <FormLabel label={id} metaDataPath={updatedMetaDataPath} />}
          {formRenderService.renderFormElementBasedOnType(metaData, updatedMetaDataPath)}
        </Col>
      )
    }
    return null
  }

  return <> {active && renderFormElementBasedOnType()} </>
}

export default FormElement
