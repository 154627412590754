import { AxiosResponse } from 'axios'
import { WebApi } from '../../utils/WebApi'
import authService from '../auth/Auth.service'

class AuthResourceService extends WebApi {
  constructor() {
    const oauthApiUrl =
      process.env.REACT_APP_NODE_ENV === 'local-development'
        ? process.env.REACT_APP_OAUTH_API_URL
        : process.env.REACT_APP_OAUTH_URL
    super(false, `${oauthApiUrl}/api`)
  }

  public async login(userName: string, password: string): Promise<AxiosResponse> {
    return this.request({
      url: '/auth/login',
      method: 'POST',
      data: {
        userName,
        password,
      },
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_OAUTH_API_AUTHORIZATION}`,
      },
    })
  }

  public getLastPasswordChangeDate(): Promise<AxiosResponse> {
    return this.request({
      url: '/user/last-password-change-date',
      headers: {
        Authorization: `Bearer ${authService.authToken}`,
      },
    })
  }
}

const authResourceService = new AuthResourceService()
export default authResourceService
