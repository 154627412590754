import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import IndiForm from '../../components/indiform/IndiForm'
import FormModal from '../../components/ui/FormModal'
import { FormType } from '../../services/indiform/FormType.enum'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import './IndiFormContainer.scss'
import useContextStore from '../../state/Context'

type Props = WithTranslation & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaData: any
  metaDataPath: string
}

interface State {
  showModal: boolean
}

interface ArrayListData {
  index: number
  path: string
  value: Array<Record<string, never>> | undefined
}

class IndiFormContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  private readonly getArrayListData = (): ArrayListData | undefined => {
    const { metaDataPath } = this.props
    const arrayListWithIndex: Array<string> = metaDataPath.split('.')
    const lastListElementWithIndex: string | undefined = arrayListWithIndex.pop()
    if (lastListElementWithIndex !== undefined) {
      const indexRegex = /\[([0-9]+)]/
      const indexMatchArray: RegExpMatchArray | null = lastListElementWithIndex.match(indexRegex)
      if (indexMatchArray !== null) {
        const listIndex: number = +indexMatchArray[1]
        const lastListElementWithoutIndex: string = lastListElementWithIndex.replace(/ *\[[^\]]*]/, '')
        const listPath: string = [...arrayListWithIndex, lastListElementWithoutIndex].join('.')
        const contextValue = useContextStore.getState()
        const listValue: Array<Record<string, never>> | undefined = contextValue.getDataPathValue(listPath)
        return {
          index: listIndex,
          path: listPath,
          value: listValue,
        }
      }
    }
    return undefined
  }

  private readonly deleteListItem = async (): Promise<void> => {
    const listIndexValue: ArrayListData | undefined = this.getArrayListData()
    if (listIndexValue !== undefined) {
      const { index, path, value } = listIndexValue
      const contextValue = useContextStore.getState()
      if (value !== undefined) {
        value.splice(index, 1)
        await contextValue.updateData(`${path}[${index}]`, undefined, AuditLogAction.DELETE)
      }
    }
  }

  private isDeleteOptionShown(): boolean {
    const listIndexValue: ArrayListData | undefined = this.getArrayListData()
    if (listIndexValue !== undefined) {
      const { index, value } = listIndexValue
      return !(value === undefined || value.length === 0 || value.length === +index)
    }
    return false
  }

  public render(): React.ReactElement {
    const { metaData, metaDataPath, t } = this.props
    const { showModal } = this.state
    if (metaData !== undefined) {
      const {
        children: formMetaData = [],
        id = '',
        help = false,
        helpLink,
        withDeleteOption = false,
        type = '',
      } = metaData
      return (
        <>
          <header className="d-flex justify-content-between align-items-center px-4 form-main-header">
            <h4 className="m-0">{id !== '' ? t(`form.labels.${id}`) : id}</h4>
            {help && (
              <FontAwesomeIcon
                className="cursor-pointer mb-1"
                icon={['fal', 'question-circle']}
                size="lg"
                onClick={() => this.setState({ showModal: true })}
              />
            )}
            {withDeleteOption && this.isDeleteOptionShown() && (
              <FontAwesomeIcon
                className="cursor-pointer mb-1"
                icon={['fal', 'trash-alt']}
                size="lg"
                onClick={this.deleteListItem}
              />
            )}
          </header>
          {/* In the case of FormType.LIST, we pass the metadata as an array since 
		  we don't generate the form components directly */}
          <IndiForm formMetaData={type === FormType.LIST ? [metaData] : formMetaData} metaDataPath={metaDataPath} />
          <FormModal
            header={t(`form.labels.${id}`)}
            onCancel={() => this.setState({ showModal: false })}
            isVisible={showModal}
            isClosable
            isDismissableMask={false}
            footer={null}
            width="unset"
          >
            {t(`form.help.${id}`)}
            {helpLink !== undefined && (
              <div className="text-end">
                <a
                  className="form-help-link"
                  href={t(`form.helpLinks.${helpLink.id}`) ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  SOP
                  <FontAwesomeIcon className="px-1" icon={['fal', 'share']} />
                </a>
              </div>
            )}
          </FormModal>
        </>
      )
    }
    return <div>Failed to render the form. Please refresh the page and try again.</div>
  }
}

export default withTranslation()(IndiFormContainer)
