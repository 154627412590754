import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Input, Row, Timeline, TimelineItemProps } from 'antd'
import { isEmpty } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import { AuditLogEntry } from '../../shared/interfaces/AuditLogEntry.interface'
import useContextStore from '../../state/Context'
import dayjs from '../../utils/dayjs'
import FormModal from '../ui/FormModal'
import './ChangeLogModal.scss'

export enum ChangeLogElement {
  ICON = 'icon',
  BUTTON = 'button',
}

interface Props {
  label: string
  metaDataPath: string
  type: ChangeLogElement
}

const ChangeLogModal: React.FC<Props> = (props): React.ReactElement | null => {
  const { t } = useTranslation()
  const dataContext = useContextStore()
  const { metaDataPath, type, label } = props
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState<string>('')
  const [changeLogEntries, setChangeLogEntries] = React.useState<Array<AuditLogEntry>>([])

  const isChangeLogAvailable = (): boolean => {
    if (dataContext.formDocument !== undefined) {
      const { firstPushAcknowledgedAt } = dataContext.formDocument.document
      return Boolean(firstPushAcknowledgedAt)
    }
    return false
  }

  const getChangeLogEntries = (): Array<AuditLogEntry> => {
    if (dataContext.formDocument !== undefined && isChangeLogAvailable()) {
      const { firstPushAcknowledgedAt, data } = dataContext.formDocument.document
      const { entries = [] } = data.changeLog
      const firstEntryAfterFirstPush: number = entries.findIndex(
        (entry: AuditLogEntry) => firstPushAcknowledgedAt && firstPushAcknowledgedAt < entry.timestamp
      )
      if (firstEntryAfterFirstPush < 0) {
        return []
      }
      const firstEntryBeforeFirstPush: number = Math.max(0, firstEntryAfterFirstPush - 1)
      const changelogSinceFirstPush: Array<AuditLogEntry> = []
      for (let i = firstEntryBeforeFirstPush; i < entries.length; i++) {
        changelogSinceFirstPush.push(entries[i])
      }
      return changelogSinceFirstPush
    }
    return []
  }

  React.useEffect(() => {
    const filteredChangeLogEntries: Array<AuditLogEntry> = getChangeLogEntries().filter(
      (changeLogEntry: AuditLogEntry) =>
        changeLogEntry.field.replace(/\[\d](?!.*\[)/, '') === `data.${dataContext.formType}.${metaDataPath}`
    )
    setChangeLogEntries(filteredChangeLogEntries)
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const renderChangeLogElement = (): React.ReactElement | null => {
    switch (type) {
      case ChangeLogElement.BUTTON:
        return (
          <Button
            icon={<FontAwesomeIcon className="me-1" icon={['fal', 'history']} size="xs" />}
            onClick={() => toggleModal(true)}
          >
            History
          </Button>
        )
      case ChangeLogElement.ICON:
        return (
          <Button
            className="mx-1 change-log-btn-icon"
            icon={<FontAwesomeIcon className="history-icon" icon={['fal', 'history']} size="xs" />}
            size="small"
            onClick={() => toggleModal(true)}
          />
        )
      default:
        return null
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getEntryValue = (value: any, action: AuditLogAction): string => {
    if (action === AuditLogAction.ARRAY_ADD && isEmpty(value)) {
      return t('form.messages.changed_since_last_push.element_added')
    }
    if ((action === AuditLogAction.ARRAY_REMOVE || action === AuditLogAction.DELETE) && isEmpty(value)) {
      return t('form.messages.changed_since_last_push.element_removed')
    }
    if ((typeof value === 'object' && isEmpty(value)) || typeof value === 'undefined') {
      return ''
    }
    if (typeof value.enumText !== 'undefined') {
      return value.enumText
    }
    if (Array.isArray(value) && value.length === 1 && value[0].enumText !== 'undefined') {
      return value[0].enumText
    }
    return JSON.stringify(value)
  }
  const getChangeLogEntryLabel = (timestamp: number): React.ReactElement => (
    <>
      <FontAwesomeIcon className="me-2" icon={['fal', 'clock']} />
      {dayjs(new Date(timestamp)).format('YYYY-MMM-DD HH:mm:ss')}
    </>
  )

  const getStyleClassForAuditAction = (action: AuditLogAction): string => {
    switch (action) {
      case AuditLogAction.ARRAY_ADD:
        return 'add'
      case AuditLogAction.ARRAY_REMOVE:
        return 'remove'
      case AuditLogAction.DELETE:
        return 'delete'
      default:
        return ''
    }
  }

  const getTimelineItems = (changeLogEntriesList: Array<AuditLogEntry>): Array<TimelineItemProps> =>
    [...changeLogEntriesList].reverse().map((changeLogEntry: AuditLogEntry) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { action, username, timestamp, value, comment } = changeLogEntry
      const optionValue: string = getEntryValue(value, action)
      return {
        label: getChangeLogEntryLabel(timestamp),
        key: `${timestamp}_${value}`,
        children: (
          <React.Fragment key={`${timestamp}_${value}`}>
            <p>
              <FontAwesomeIcon className="me-2" icon={['fal', 'user']} />
              {username}
            </p>
            <p className={`${getStyleClassForAuditAction(action)} mt-1`}>{optionValue}</p>
            <p className="mt-1">{comment}</p>
          </React.Fragment>
        ),
      }
    })

  const submitComment = async (): Promise<void> => {
    const auditLogEntry: AuditLogEntry = [...changeLogEntries].reverse()[0]
    const updatedChangeLogEntry: AuditLogEntry = {
      ...auditLogEntry,
      comment,
    }
    await dataContext.updateDataChangeLog(updatedChangeLogEntry)
    toggleModal(false)
    setComment('')
  }
  const renderFooter: React.ReactElement = (
    <Row>
      <Col span={24}>
        <Input
          placeholder="Enter Comment"
          value={comment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
        />
      </Col>
      <Col span={24} className="mt-2">
        <Button onClick={submitComment}>Comment</Button>
      </Col>
    </Row>
  )

  const renderChangeLog = (): React.ReactElement | null => {
    if (changeLogEntries.length > 0) {
      return (
        <>
          {renderChangeLogElement()}
          <FormModal
            header={t(`form.labels.${label}`)}
            footer={renderFooter}
            onCancel={() => {
              toggleModal(false)
              setComment('')
            }}
            isVisible={showModal}
            isClosable
            isDismissableMask={false}
            width="unset"
          >
            <div>
              <h4>{t('form.labels.changelog')}</h4>
              <Timeline className="pt-2" mode="left" items={getTimelineItems(changeLogEntries)} />
            </div>
          </FormModal>
        </>
      )
    }
    return null
  }

  return renderChangeLog()
}

export default ChangeLogModal
