import { Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useContextStore from '../../state/Context'
import ObjectUtils from '../../utils/Object'

interface Props {
  metaDataPath: string
  translationPath: string
}

const RelatedData = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState<string>('')
  const { metaDataPath, translationPath } = props

  React.useEffect(() => {
    const metaDataPathValue: string = ObjectUtils.getAt(dataContext.relatedData, metaDataPath) ?? ''
    const displayValue: string =
      translationPath && metaDataPathValue ? t(`${translationPath}.${metaDataPathValue}`) : metaDataPathValue
    setValue(displayValue)
  }, [dataContext.relatedData])

  return <Input data-testid={metaDataPath} value={value} disabled />
}

export default RelatedData
