import { Affix, Card, Col, Layout, notification, Row, Skeleton } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SelectedMenuItem } from '../../components/indiform-ui/IndiformSidebar'
import editorService from '../../services/editor/Editor.service'
import useAppStore from '../../state/App'
import useContextStore from '../../state/Context'
import IndiFormContainer from '../indiform-page/IndiFormContainer'
import '../../App.scss'

interface Props {
  children: React.ReactElement
  selectedMenuItem: SelectedMenuItem | undefined
  navBarText: string
}

const EditorLayout = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { navBarText, selectedMenuItem, children } = props
  const navigate = useNavigate()
  const appStore = useAppStore()

  React.useEffect(() => {
    appStore.setNavbarHeader(navBarText)
  }, [navBarText])

  React.useEffect(() => {
    if (dataContext.isLoadingFailed) {
      notification.error({ message: t('form.messages.relatedDataFailed') })
      dataContext.setIsLoadingFailed(false)
    }
  }, [dataContext.isLoadingFailed])

  const validateUserPermissions = (): void => {
    if (!editorService.checkUserPermission(dataContext.formType)) {
      navigate('/clinics')
    }
  }

  React.useEffect(() => {
    validateUserPermissions()
  }, [])

  const renderIndiFormContainer = (): React.ReactElement | null => {
    if (selectedMenuItem !== undefined) {
      const { formMetaData, metaDataPath = '' } = selectedMenuItem
      return (
        <Col className="ps-1" span={18}>
          <IndiFormContainer metaData={formMetaData} metaDataPath={metaDataPath} />
        </Col>
      )
    }
    return null
  }

  return (
    <Layout className="h-100">
      <Card>
        {dataContext.isLoading ? (
          <Skeleton active />
        ) : (
          <Row>
            <Col span={6}>
              <div className="side-menu">
                <Affix offsetTop={73}>
                  <section className="side-menu-inner">{children}</section>
                </Affix>
              </div>
            </Col>
            {renderIndiFormContainer()}
          </Row>
        )}
      </Card>
    </Layout>
  )
}

export default EditorLayout
