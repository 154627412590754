import CryptoJs from 'crypto-js'

class CryptoEncoder {
  private readonly SALT_WORD_COUNT: number = 5
  private readonly ITERATION_COUNT: number = 100

  public encodeUsername(username: string): string {
    const normalizedUsername: string = username.trim().toLowerCase()
    const usernameHash: CryptoJs.lib.WordArray = CryptoJs.SHA256(normalizedUsername)
    return this.getHexDigest(usernameHash)
  }

  public encodePassword(rawPassword: string): string {
    return this.getEncodedPassword(rawPassword)
  }

  public matches(rawPassword: string, encodedPassword: string): boolean {
    if (!encodedPassword || !rawPassword) {
      return false
    }

    const saltHex: string = this.getSaltHexFromPassword(encodedPassword)
    const rawPasswordEncoded: string = this.encodePasswordWithSaltHex(rawPassword, saltHex)
    return rawPasswordEncoded === encodedPassword
  }

  private getEncodedPassword(rawPassword: string): string {
    const saltHex: string = this.generateRandomSaltHex()
    return this.encodePasswordWithSaltHex(rawPassword, saltHex)
  }

  private encodePasswordWithSaltHex(rawPassword: string, saltHex: string): string {
    const passwordHash: CryptoJs.lib.WordArray = CryptoJs.PBKDF2(rawPassword, saltHex, {
      iterations: this.ITERATION_COUNT,
    })
    return saltHex + this.getHexDigest(passwordHash)
  }

  private generateRandomSaltHex(): string {
    const saltBits: CryptoJs.lib.WordArray = CryptoJs.lib.WordArray.random(this.SALT_WORD_COUNT)
    return this.getHexDigest(saltBits)
  }

  private getSaltHexFromPassword(encodedPassword: string): string {
    return encodedPassword.substr(0, this.SALT_WORD_COUNT * 2)
  }

  private getHexDigest(wordArray: CryptoJs.lib.WordArray): string {
    return wordArray.toString(CryptoJs.enc.Hex)
  }
}

const cryptoEncoder = new CryptoEncoder()
export default cryptoEncoder
