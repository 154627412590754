class NavigatorService {
  public async isOnline(): Promise<boolean> {
    if (!navigator.onLine) return false

    try {
      const response = await fetch(window.location.origin, { method: 'HEAD' })
      return response.ok
    } catch {
      return false
    }
  }

  public getBrowserLanguage(): string {
    return navigator.language
  }
}

const navigatorService = new NavigatorService()
export default navigatorService
