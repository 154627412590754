import axios, { AxiosResponse } from 'axios'
import { Medication } from '../../shared/interfaces/IndiformValues.interface'
import { WebApi } from '../../utils/WebApi'

class IndiFormResourceService extends WebApi {
  private readonly formMetaBaseUrl: string = '/form/meta'

  public async fetchEnumFormMetaData(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/enum`)
  }

  public async fetchIcdCodesFormMetaData(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/icdCodes`)
  }

  public async fetchChemicalFormMetaData(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/chemo`)
  }

  public async fetchHormoneFormMetaData(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/hormone`)
  }

  public async fetchOtherFormMetaData(): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/other`)
  }

  public async fetchFormMetaDataMedications(
    countries: Array<string>
  ): Promise<Array<AxiosResponse<Array<Medication>>>> {
    const formMetaMedicationsRequests = countries.map(async country =>
      this.buildJsonContentTypeRequest(`${this.formMetaBaseUrl}/medication`, { country })
    )
    return axios.all(formMetaMedicationsRequests)
  }
}

const indiFormResourceService = new IndiFormResourceService()
export default indiFormResourceService
