import { ThemeConfig } from 'antd'

const BORDER_RADIUS = 32
const BORDER_RADIUS_CONTAINER = {
  borderRadius: BORDER_RADIUS,
  borderRadiusOuter: BORDER_RADIUS,
  borderRadiusLG: BORDER_RADIUS,
  borderRadiusSM: BORDER_RADIUS,
  borderRadiusXS: BORDER_RADIUS,
}

const colors = {
  INDIVUMED_LIGHT_BLUE: '#40a7ff',
  INDIVUMED_DARK_BLUE: '#00519e',
  INDIVUMED_RED: '#bf1b44',
  DANGER_COLOR: '#ff4d4f',
  LIGHTER_GRAY: '#f0f0f0',
  LIGHT_GRAY: '#dedede',
}

const theme: ThemeConfig = {
  token: {
    colorPrimary: colors.INDIVUMED_LIGHT_BLUE,
    fontFamily: 'Ubuntu, sans-serif',
  },
  components: {
    Card: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Button: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Input: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Select: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Modal: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Tag: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Drawer: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Layout: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Tabs: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Radio: {
      ...BORDER_RADIUS_CONTAINER,
    },
    DatePicker: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Menu: {
      ...BORDER_RADIUS_CONTAINER,
    },
    List: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Popover: {
      ...BORDER_RADIUS_CONTAINER,
    },
    Table: {
      ...BORDER_RADIUS_CONTAINER,
    },
  },
}

export default theme
