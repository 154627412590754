import { AxiosResponse } from 'axios'
import { CaseDocument } from '../../shared/interfaces/CaseDocument.interface'
import { WebApi } from '../../utils/WebApi'

class CasesResourceService extends WebApi {
  public fetchCaseDocuments(clinicId: number): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/clinics/${clinicId}/documents`)
  }

  public fetchSubmittedCaseDocumentsByCaseNo(caseNo: string): Promise<AxiosResponse> {
    return this.buildJsonContentTypeRequest(`/case-document/submitted/${caseNo}`)
  }

  public updateCaseDocument(caseDocument: CaseDocument): Promise<AxiosResponse> {
    return this.request({
      url: `/case-document/${caseDocument.document.uuid}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(caseDocument.document),
    })
  }
}

const casesResourceService = new CasesResourceService()
export default casesResourceService
