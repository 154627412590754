import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import clinicsService from '../../services/clinics/Clinics.service'
import indiFormService from '../../services/indiform/IndiForm.service'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import useClinicStore from '../../state/Clinic'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormListBox, { ListBoxOption } from '../ui/FormListBox'
import FormModal from '../ui/FormModal'

interface Props {
  hasFilter: boolean
  header: string
  metaDataPath: string
}

const Medication = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState<EnumValue | undefined>(undefined)
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const { metaDataPath, header, hasFilter } = props
  const params = useParams()
  const medicationFormMetaData = useIndiFormStore(state => state.medicationFormMetaData)
  const clinics = useClinicStore(state => state.clinics)

  React.useEffect(() => {
    const initialValue: EnumValue | undefined = dataContext.getDataPathValue(metaDataPath)
    setValue(initialValue)
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const getCountryCode = (): string => {
    const clinicId: number = clinicsService.getClinicIdFromMatchParams(params)
    const clinic: Clinic | undefined = clinicsService.getClinicById(clinics, clinicId)
    return clinic?.country || ''
  }

  const medicationOptions: Array<ListBoxOption> = indiFormService.getMedicationOptions(
    medicationFormMetaData,
    getCountryCode()
  )
  const onChange = async (listOption: ListBoxOption): Promise<void> => {
    const selectedOption: EnumValue = indiFormService.getEnumValueObject(listOption.value as number, listOption.label)
    setValue(selectedOption)
    await dataContext.updateData(metaDataPath, selectedOption)
    toggleModal(false)
  }

  return (
    <>
      <div
        className="ant-input w-100 form-list-box cursor-pointer"
        role="button"
        tabIndex={0}
        style={{ outline: 'none' }}
        data-testid={metaDataPath}
        onClick={() => toggleModal(true)}
        onKeyDown={() => toggleModal(true)}
      >
        {value !== undefined ? value.enumText : null}
      </div>
      <FormModal
        header={header}
        isVisible={showModal}
        width="75%"
        onCancel={() => toggleModal(false)}
        isClosable
        isDismissableMask={false}
        footer={null}
      >
        <FormListBox
          hasFilter={hasFilter}
          options={medicationOptions}
          searchFieldHeader={t('medicationFieldModalSearchLabel')}
          onChange={onChange}
          favorites={[]}
          initialNumberOfItemsToBeShown={20}
        />
      </FormModal>
    </>
  )
}

export default Medication
