import React from 'react'
import indiFormService, { ChemoOptions } from '../../../services/indiform/IndiForm.service'
import AuditLogAction from '../../../services/indiform/change-log/AuditLogAction.enum'
import { EnumValue } from '../../../shared/interfaces/EnumValue.interface'
import {
  AgentValue,
  ChemicalFormMetaData,
  FormDataAgentGroup,
} from '../../../shared/interfaces/IndiformValues.interface'
import useContextStore from '../../../state/Context'
import useIndiFormStore from '../../../state/IndiForm'
import { DropdownOption } from '../../ui/FormDropDown'
import FormLabel from '../../ui/FormLabel'
import GroupedDropDown, { GroupedListItem } from '../../ui/GroupedDropDown'
import { EnumOptions } from '../Enum'
import GroupAgent from './GroupAgent'
import SchemaAgent, { SchemaAgentValue } from './SchemaAgent'

export interface ChemoDataObject {
  agents: Array<SchemaAgentValue>
  group: EnumValue
  isSchema: 'y' | 'n'
}

interface Props {
  metaDataPath: string
}

const Chemotherapy = (props: Props): React.ReactElement => {
  const { metaDataPath } = props
  const indiFormStore = useIndiFormStore()
  const dataContext = useContextStore()
  const CHEMO_GROUP_PATH = `${metaDataPath}.group`
  const [value, setValue] = React.useState<number | undefined>(undefined)
  const chemoTherapyOptions = indiFormStore.chemicalFormMetaData

  React.useEffect(() => {
    const getChemoGroupValue = (): number | undefined => {
      const chemoGroupPathValue: EnumValue | undefined = dataContext.getDataPathValue(CHEMO_GROUP_PATH)
      return chemoGroupPathValue?.enumId
    }
    setValue(getChemoGroupValue())
  }, [dataContext, dataContext.formDocument])

  const getChemoTherapyOptions = (formMetaData: ChemicalFormMetaData | null): Array<GroupedListItem> => {
    if (formMetaData !== null) {
      return Object.entries(formMetaData).map(([key, values]) => ({
        groupName: key,
        groupDisplayText: key,
        values: values.map((formDataAgentGroup: FormDataAgentGroup) => ({
          id: formDataAgentGroup.id,
          text: formDataAgentGroup.text,
        })),
      }))
    }
    return []
  }

  const isSchema = (paramValue: number | undefined): boolean => {
    if (
      chemoTherapyOptions !== null &&
      paramValue !== undefined &&
      Object.prototype.hasOwnProperty.call(chemoTherapyOptions, ChemoOptions.SCHEMAS)
    ) {
      const schemaValues: Array<FormDataAgentGroup> = chemoTherapyOptions.schemas
      return schemaValues.map(schemaValue => schemaValue.id).some(id => id === paramValue)
    }
    return false
  }
  const getGroupAgentOptions = (): Array<DropdownOption> => {
    if (chemoTherapyOptions !== null && value !== undefined) {
      return indiFormService.getGroupAgents(chemoTherapyOptions[ChemoOptions.GROUPS], ChemoOptions.GROUPS, value)
    }
    return []
  }

  const renderAgent = (): React.ReactElement => {
    if (isSchema(value)) {
      return <SchemaAgent metaDataPath={metaDataPath} />
    }
    return <GroupAgent agentOptions={getGroupAgentOptions()} metaDataPath={metaDataPath} />
  }

  const getSchemaAgents = (enumId: number): Array<AgentValue> => {
    if (
      chemoTherapyOptions !== null &&
      Object.prototype.hasOwnProperty.call(chemoTherapyOptions, ChemoOptions.SCHEMAS)
    ) {
      const schemaValues: Array<FormDataAgentGroup> = chemoTherapyOptions[ChemoOptions.SCHEMAS]
      const schemaAgent: FormDataAgentGroup | undefined = schemaValues.find(schemaValue => schemaValue.id === enumId)
      if (schemaAgent !== undefined) {
        return schemaAgent.agents
      }
    }
    return []
  }

  const updateValueAndCase = async (groupValue: EnumOptions | undefined): Promise<void> => {
    if (groupValue !== undefined) {
      const schema = isSchema(groupValue.id) ? 'y' : 'n'
      const newAgents =
        schema === 'n'
          ? [{ type: undefined }]
          : getSchemaAgents(groupValue.id).map(schemaAgent => ({
              type: schemaAgent.value,
            }))

      const group = {
        enumId: groupValue.id,
        enumText: groupValue.text,
      }
      const oldAgents: Array<string> = dataContext.getDataPathValue(`${metaDataPath}.agents`) || []

      const diff = newAgents.length - oldAgents.length
      if (diff < 0) {
        // entries have to be deleted

        for (let cnt = 0; cnt < Math.abs(diff); cnt++) {
          const changelogEntry = {
            path: `data.${dataContext.formType}.${metaDataPath}.agents[${oldAgents.length - cnt - 1}]`,
            value: undefined,
          }
          // eslint-disable-next-line no-await-in-loop
          await dataContext.updateData(
            `${metaDataPath}.agents`,
            oldAgents.filter((oldAgentItem: string, index: number) => index !== oldAgents.length - cnt - 1),
            AuditLogAction.DELETE,
            changelogEntry
          )
        }
      } else if (diff > 0) {
        // entries have to be added
        for (let cnt = 0; cnt < diff; cnt++) {
          // eslint-disable-next-line no-await-in-loop
          await dataContext.updateData(
            `${metaDataPath}.agents[${oldAgents.length + cnt}]`,
            {},
            AuditLogAction.ARRAY_ADD,
            {
              path: `data.${dataContext.formType}.${metaDataPath}.agents`,
              value: {},
            }
          )
        }
      }
      for (let cnt = 0; cnt < newAgents.length; cnt++) {
        // eslint-disable-next-line no-await-in-loop
        await dataContext.updateData(`${metaDataPath}.agents[${cnt}]`, newAgents[cnt], AuditLogAction.SET)
      }
      await dataContext.updateData(`${metaDataPath}.schema`, schema, AuditLogAction.SET)
      await dataContext.updateData(`${metaDataPath}.group`, group, AuditLogAction.SET)
    } else {
      await dataContext.updateData(metaDataPath, undefined)
    }
  }

  return (
    <>
      <FormLabel label="chemo_therapy_schema" metaDataPath={metaDataPath} />
      <GroupedDropDown
        onMenuItemClick={updateValueAndCase}
        options={getChemoTherapyOptions(chemoTherapyOptions)}
        value={value}
        isClearingAllowed={false}
        placeholder=""
        metaDataPath={metaDataPath}
      />
      {value !== undefined && renderAgent()}
    </>
  )
}

export default Chemotherapy
