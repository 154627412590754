import { create } from 'zustand'

interface AppStoreState {
  isOnline: boolean
  navbarHeader: string
  redirectUrl: string | null
}

interface AppStoreActions {
  setOnline: () => void
  setOffline: () => void
  setNavbarHeader: (header: string) => void
  setRedirectUrl: (url: string | null) => void
  resetRedirectUrl: () => void
}

const useAppStore = create<AppStoreState & AppStoreActions>(set => ({
  isOnline: true,
  navbarHeader: '',
  redirectUrl: null,

  setOnline: () => {
    set({ isOnline: true })
  },
  setOffline: () => {
    set({ isOnline: false })
  },
  setNavbarHeader: (header: string) => {
    set({ navbarHeader: header })
  },
  setRedirectUrl: (url: string | null) => {
    set({ redirectUrl: url })
  },
  resetRedirectUrl: () => {
    set({ redirectUrl: null })
  },
}))

export default useAppStore
