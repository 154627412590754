import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Input, Row, Tag } from 'antd'
import { difference, intersection, uniq } from 'lodash-es'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import { ChangeLogData } from '../../shared/interfaces/ChangeLogData.interface'
import '../ui/Form.scss'
import useContextStore from '../../state/Context'
import FormLabel from '../ui/FormLabel'
import FormModal from '../ui/FormModal'

interface SampleCollectionItem {
  index: number
  label: string
  backgroundColor: string
}

interface Group {
  id: string
  templateRows: Array<{ items: Array<SampleCollectionItem> }>
}

interface Props {
  metaDataPath: string
  groups: Array<Group>
  isAdditionalLabelCollectionShowed: boolean
}

const SampleCollection = (props: Props): React.ReactElement => {
  const { CheckableTag } = Tag
  const { metaDataPath, groups, isAdditionalLabelCollectionShowed } = props
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const [values, setValues] = React.useState<Array<string>>([])
  const [additionalLabelCollectionValue, setAdditionalLabelCollectionValue] = React.useState<string | undefined>()
  const allSelectableItems = groups
    .flatMap(group => group.templateRows.flatMap(templateRow => templateRow.items))
    .map(item => item.label)

  React.useEffect(() => {
    const initialValues = dataContext.getDataPathValue(metaDataPath) ?? []
    setValues(initialValues)
  }, [dataContext, dataContext.formDocument, metaDataPath])

  React.useEffect(() => {
    const initialLCValue = (): string => {
      const diff = difference(values, allSelectableItems)
      return uniq(diff).join(',').replace(/\s/g, '')
    }
    setAdditionalLabelCollectionValue(initialLCValue)
  }, [values])

  const renderButton = (): React.ReactElement => {
    const title: string = values.length === 0 ? t('addButton') : t('changeButton')
    return (
      <Button className="row" onClick={() => toggleModal(true)} data-testid={metaDataPath}>
        {title}
      </Button>
    )
  }

  const changeColorBasedOnBackgroundColor = (backgroundColor: string): string => {
    const hex = backgroundColor.replace(/#/, '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    const brightness = r * 0.299 + g * 0.587 + b * 0.114

    return brightness >= 180 ? 'black' : 'white'
  }

  const getSelectButtonStyling = (backgroundColor: string): Record<string, unknown> => ({
    backgroundColor,
    borderColor: backgroundColor,
    color: changeColorBasedOnBackgroundColor(backgroundColor),
  })

  const isValueChecked = (item: SampleCollectionItem): boolean => values.some(val => val === item.label)

  const updateData = async (updatedValues: Array<string>): Promise<void> => {
    const previousValues: Array<string> = dataContext.getDataPathValue(metaDataPath) || []
    let changeLogValues: Array<string> | undefined
    const changeLogData: Omit<ChangeLogData, 'value'> = {
      path:
        dataContext.formType === IndiFormType.FOLLOWUP ? `data.followUp.${metaDataPath}` : `data.case.${metaDataPath}`,
    }
    if (updatedValues.length === 0) {
      await dataContext.updateData(metaDataPath, updatedValues, AuditLogAction.DELETE, {
        ...changeLogData,
        value: changeLogValues,
      })
    } else {
      const removedValues = difference(previousValues, updatedValues)
      if (removedValues.length > 0) {
        await dataContext.updateData(metaDataPath, updatedValues, AuditLogAction.ARRAY_REMOVE, {
          ...changeLogData,
          value: removedValues,
        })
      }
      const addedValues = difference(updatedValues, previousValues)
      if (addedValues.length > 0) {
        await dataContext.updateData(metaDataPath, updatedValues, AuditLogAction.ARRAY_ADD, {
          ...changeLogData,
          value: addedValues,
        })
      }
    }
  }

  const handleChange = async (checked: boolean, label: string): Promise<void> => {
    const updatedValues: Array<string> = checked ? [...values, label] : values.filter(val => val !== label)
    await updateData(updatedValues)
  }

  const uniqueConcatenationValues = (valueList: Array<string>, labels: Array<string>): Array<string> => {
    const concatenatedList = valueList.concat(labels)
    return concatenatedList.filter((item: string, index: number) => concatenatedList.indexOf(item) === index)
  }

  const onSelectOrClearAll = async (checked: boolean, updatedGroups: Group): Promise<void> => {
    const { templateRows } = updatedGroups
    const labels = templateRows.map(x => x.items.map(y => y.label)).flat()
    const updatedValues = checked ? uniqueConcatenationValues(values, labels) : values.filter(x => !labels.includes(x))
    await updateData(updatedValues)
  }

  const additionalLabelCollectionOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setAdditionalLabelCollectionValue(uniq(event.target.value.split(',')).join(',').replace(/\s/g, ''))

  const additionalLabelCollectionOnBlur = async (): Promise<void> => {
    const selectedSelectableItems = intersection(values, allSelectableItems)
    const updatedValues = [
      ...selectedSelectableItems,
      ...(additionalLabelCollectionValue?.split(',').filter(label => label !== '') ?? []),
    ]
    await updateData(updatedValues)
  }

  const renderFooter = (): React.ReactElement => <Button onClick={() => toggleModal(false)}>{t('closeButton')}</Button>

  const renderModalContents = (): ReactElement => (
    <>
      {groups.map(group => (
        <React.Fragment key={group.id}>
          <header className="px-4 d-flex align-items-center form-sub-header mt-3">
            <h4 className="m-0">{t(`form.labels.${group.id}`)}</h4>
          </header>
          {group.templateRows?.map((row, rowIndex) => (
            <Row gutter={16} key={`${group.id}${rowIndex}`}>
              {row.items?.map(item => (
                <Col key={item.label}>
                  <div className="py-1">
                    <div className="text-center">{item.index}.</div>
                    <CheckableTag
                      checked={isValueChecked(item)}
                      onChange={async (checked: boolean) => handleChange(checked, item.label)}
                      style={
                        item.backgroundColor !== undefined ? getSelectButtonStyling(item.backgroundColor) : undefined
                      }
                      className="selectbutton-tag w-100 colored-selectbutton-tag"
                    >
                      <div className="d-flex justify-content-between align-items-center h-100 selectbutton-text">
                        {item.label}
                        {isValueChecked(item) && <FontAwesomeIcon icon={['fal', 'check']} />}
                      </div>
                    </CheckableTag>
                  </div>
                </Col>
              ))}
            </Row>
          ))}
          <div className="mt-2 text-end w-100 d-flex justify-content-end align-self-end">
            <Button className="me-1" onClick={() => onSelectOrClearAll(true, group)}>
              {t('allButton')}
            </Button>
            <Button onClick={() => onSelectOrClearAll(false, group)}>{t('clearButton')}</Button>
          </div>
        </React.Fragment>
      ))}
      {isAdditionalLabelCollectionShowed && (
        <>
          <header className="px-4 d-flex align-items-center form-sub-header mt-3 mb-2">
            <h4 className="m-0">{t('form.labels.collectionGroupAdditionalLabels')}</h4>
          </header>
          <FormLabel label="AdditionalLabels" metaDataPath="" />
          <Input
            className="mb-1"
            value={additionalLabelCollectionValue}
            onBlur={additionalLabelCollectionOnBlur}
            onChange={additionalLabelCollectionOnChange}
          />
        </>
      )}
    </>
  )

  const renderSelectedLabelsFromGroup = (group: Group): React.ReactElement | null => {
    const selectedItems = group.templateRows
      .flatMap(templateRow => templateRow.items)
      .filter(templateRow => values.includes(templateRow.label))
    if (selectedItems.length > 0) {
      return (
        <div className="mb-1">
          {`${t(`form.labels.${group.id}`)}: `}
          <ul className="comma-separated-list list-inline d-inline p-0">
            {selectedItems.map(item => (
              <li
                style={{
                  backgroundColor: item.backgroundColor,
                  color: changeColorBasedOnBackgroundColor(item.backgroundColor),
                }}
                className="d-inline"
                key={item.label}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }

  const renderAdditionalLabels = (): React.ReactElement | null => {
    if (additionalLabelCollectionValue) {
      return <div className="mb-1">{`${t('form.labels.AdditionalLabels')}: ${additionalLabelCollectionValue}`}</div>
    }
    return null
  }

  const renderValues = (): React.ReactElement | null => {
    if (values.length !== 0) {
      return (
        <>
          {groups.map(group => renderSelectedLabelsFromGroup(group))}
          {renderAdditionalLabels()}
        </>
      )
    }

    return null
  }

  return (
    <>
      <div className="container">
        {renderValues()}
        {renderButton()}
      </div>
      <FormModal
        header={t('form.labels.sampleCollection')}
        footer={renderFooter()}
        onCancel={() => toggleModal(false)}
        isVisible={showModal}
        isClosable
        isDismissableMask={false}
        width="unset"
      >
        {renderModalContents()}
      </FormModal>
    </>
  )
}

export default SampleCollection
