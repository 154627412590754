import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { App, List } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import followUpIndexDatabaseService from '../../services/browser-storage/index-database/FollowUpIndexDatabase.service'
import followUpsService from '../../services/documents/FollowUps.service'
import ApplicationType from '../../services/existing-document-modal/ApplicationType.enum'
import existingDocumentModalService from '../../services/existing-document-modal/ExistingDocumentModal.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ColumnListItem } from '../../shared/interfaces/ColumnListItem.interface'
import { ExistingDocuments } from '../../shared/interfaces/ExistingDocuments.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import Status from '../../shared/interfaces/Status.interface'
import ObjectUtils from '../../utils/Object'
import FormModal from '../ui/FormModal'

interface Props {
  caseNo: string
  clinic: Clinic
  isVisible: boolean
  onCancel: () => void
  existingDocuments: ExistingDocuments<Array<FollowUp>> | null
  onExistingDocumentSelected: (formType: IndiFormType) => void
  isSubmittedDocumentSummary: boolean
}

const SelectExistingFollowUpModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const {
    clinic,
    isSubmittedDocumentSummary,
    existingDocuments,
    caseNo,
    isVisible,
    onExistingDocumentSelected,
    onCancel,
  } = props
  const navigate = useNavigate()
  const [documentNo, setDocumentNo] = React.useState<string>('')
  const { notification } = App.useApp()

  const redirectToSummary = (submittedDocument: Case | FollowUp): void => {
    navigate(`/clinics/${clinic.id}/followUpSummary/${submittedDocument?.uuid}`, {
      state: { submittedDocument: { document: submittedDocument } },
    })
  }

  const updateFollowUpOverview = async (document: FollowUp, application: ApplicationType): Promise<void> => {
    try {
      await followUpsService.updateFollowUpDocumentInBackend({ document })
      await followUpIndexDatabaseService.updateFormDocumentIfUpdatedOrDoesNotExist({ document }, Date.now())
      onExistingDocumentSelected(IndiFormType.FOLLOWUP)
      existingDocumentModalService.showSuccessNotification(
        document,
        application,
        IndiFormType.FOLLOWUP,
        documentNo,
        notification,
        t
      )
    } catch (error) {
      existingDocumentModalService.showErrorNotification(IndiFormType.FOLLOWUP, documentNo, notification, t)
    } finally {
      onCancel()
    }
  }

  const selectExistingDocument = async (document: FollowUp, application: ApplicationType): Promise<void> => {
    setDocumentNo(ObjectUtils.getAt(document, 'data.followUp.hospital.enumText'))
    if (document !== undefined) {
      if (isSubmittedDocumentSummary) {
        redirectToSummary(document)
      } else {
        // Add or Update the case in the IndexDB
        document.status = Status.PULLED_FOR_REVIEW
        await updateFollowUpOverview(document as FollowUp, application)
      }
    }
  }

  const renderFoundFollowUps = (followUp: FollowUp): React.ReactElement => {
    const columns: Array<ColumnListItem> = existingDocumentModalService.getDocumentsColumn(
      followUp,
      IndiFormType.FOLLOWUP,
      t
    )
    return (
      <>
        {' '}
        {columns.map((relatedCase: ColumnListItem, index: number) => (
          <div key={index}>
            <h6>{relatedCase.title}</h6>
            {relatedCase.value}
          </div>
        ))}
      </>
    )
  }

  return (
    <FormModal
      onCancel={() => onCancel()}
      header={t('documentList.selectExistingFollowUpModal.title', {
        foundFollowUpsLength: existingDocuments?.indivupad.length ?? 0,
        caseNo,
      })}
      width="50%"
      isVisible={isVisible}
      isClosable
      isDismissableMask={false}
      footer={null}
    >
      <h4>IndivuPad:</h4>
      <List
        dataSource={existingDocuments?.indivupad}
        renderItem={(followUp: FollowUp) => (
          <List.Item
            key={followUp.uuid}
            actions={[
              <FontAwesomeIcon
                icon={['fal', 'share']}
                onClick={async () => {
                  await selectExistingDocument(followUp, ApplicationType.INDIVUPAD)
                }}
              />,
            ]}
          >
            {renderFoundFollowUps(followUp)}
          </List.Item>
        )}
      />
    </FormModal>
  )
}

export default SelectExistingFollowUpModal
