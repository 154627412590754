import { Button } from 'antd'
import enUS from 'antd/es/date-picker/locale/en_US'
import { PickerProps } from 'antd/lib/date-picker/generatePicker'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import validationService from '../../services/indiform-validation/Validation.service'
import navigatorService from '../../services/navigator/Navigator.service'
import useContextStore from '../../state/Context'
import languageCodes from '../../utils/LanguageCodes'
import dayjs from '../../utils/dayjs'
import DatePicker from './DatePicker'
import './Form.scss'

export enum Picker {
  DATE = 'date',
  MONTH = 'month',
  YEAR = 'year',
}

interface Props {
  metaDataPath: string
  picker: Picker
  placeholder: string
  validators: Record<string, never> | null
  isCurrentOptionEnabled: boolean
}

const FormCalendar = (props: Props): React.ReactElement => {
  const { validators, metaDataPath, picker, isCurrentOptionEnabled, placeholder } = props
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { MonthPicker, YearPicker } = DatePicker
  const [value, setValue] = React.useState<Dayjs | null | undefined>(undefined)
  const [locale, setLocale] = React.useState<typeof enUS>()
  const [isValid, setValidity] = React.useState<boolean>()

  React.useEffect(() => {
    const getFormElementValue = (): Dayjs | null | undefined => {
      const metaDataPathValue: string | null | undefined = dataContext.getDataPathValue(metaDataPath)
      if (typeof metaDataPathValue === 'string') {
        return dayjs(metaDataPathValue)
      }
      return metaDataPathValue
    }

    setValue(getFormElementValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const formatDate = (dateValue: Dayjs | null | undefined): string | undefined => {
    if (dateValue) {
      switch (picker) {
        case Picker.DATE:
          return dayjs(dateValue).format('YYYY-MM-DD')
        case Picker.MONTH:
          return dayjs(dateValue).format('YYYY-MM')
        case Picker.YEAR:
          return dayjs(dateValue).format('YYYY')
        default:
          return dayjs(dateValue).format('YYYY-MM-DD')
      }
    }
    return undefined
  }

  React.useEffect(() => {
    const isInputValid: boolean = validationService.isFormElementValid(validators, formatDate(value))
    setValidity(isInputValid)
  }, [value, isValid])

  React.useEffect(() => {
    // In the browser settings user can select the generic language and the language based on
    // country, English and English(UnitedStates) respectively.

    // Antd Calendar control locale imports work with the language with their
    // country code https://ant.design/docs/react/i18n.

    // Dayjs needs to know the language code without country code.

    // If the user's browser setting is generic language then getCountryBasedOnLanguage will
    // return the default country based on this application.
    const browserLanguageCode: string = navigatorService.getBrowserLanguage()
    const browserLanguageLocale: string = browserLanguageCode.includes('-')
      ? browserLanguageCode.replace('-', '_')
      : languageCodes.getCountryBasedOnLanguage(browserLanguageCode)

    const importLocaleData = async (): Promise<void> => {
      const newLocale = await import(`../../../node_modules/antd/es/date-picker/locale/${browserLanguageLocale}.js`)
      setLocale(newLocale.default)
    }
    importLocaleData()
  }, [])

  const onDateChange = async (date: Dayjs | Array<Dayjs> | null): Promise<void> => {
    if (date === null) {
      await dataContext.updateData(metaDataPath, undefined)
    } else if (!Array.isArray(date)) {
      const formattedDate: string | undefined = formatDate(date)
      await dataContext.updateData(metaDataPath, formattedDate)
    }
  }

  const getDateFormat = (datePicker: Picker, localeObject: typeof enUS | undefined): string => {
    switch (datePicker) {
      case Picker.MONTH:
        return 'MMMM YYYY'
      case Picker.YEAR:
        return 'YYYY'
      case Picker.DATE:
      default:
        return localeObject?.lang.dateFormat || 'MM/DD/YY'
    }
  }

  const selectClassName = (): string => {
    if (validators === null) {
      return ''
    }
    if (isValid) {
      return 'p-success'
    }
    return 'p-error'
  }

  const getClassNameBasedOnValidators = (): string => {
    const baseStyleClasses = 'w-100 me-1'
    const validationStyleClass: string | null = selectClassName()
    return validationStyleClass !== null ? `${validationStyleClass} ${baseStyleClasses}` : `${baseStyleClasses}`
  }

  const renderCalendarView = (): React.ReactElement | null => {
    const calendarProps: PickerProps<Dayjs> = {
      className: getClassNameBasedOnValidators(),
      format: getDateFormat(picker, locale),
      locale,
      placeholder,
      value: value ? dayjs(`${value}`) : null,
      onChange: onDateChange,
    }
    switch (picker) {
      case Picker.YEAR:
        return <YearPicker {...calendarProps} data-testid={metaDataPath} />
      case Picker.MONTH:
        return <MonthPicker {...calendarProps} data-testid={metaDataPath} />
      case Picker.DATE:
        return <DatePicker {...calendarProps} data-testid={metaDataPath} />
      default:
        return null
    }
  }

  const getTranslationKey = (): string => {
    switch (picker) {
      case Picker.YEAR:
        return 'currentYearButton'
      case Picker.MONTH:
        return 'currentMonthButton'
      case Picker.DATE:
        return 'currentDayButton'
      default:
        return 'currentTimeButton'
    }
  }

  return (
    <div className="w-100 d-flex justify-content-between">
      {renderCalendarView()}
      {isCurrentOptionEnabled && (
        <Button onClick={() => onDateChange(dayjs())} data-testid={`${metaDataPath}-button`}>
          {t(getTranslationKey())}
        </Button>
      )}
    </div>
  )
}

export default FormCalendar
