import { Card, Layout, Skeleton, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import CaseList from '../../components/document-list/CaseList'
import DocumentListHeader from '../../components/document-list/DocumentListHeader'
import FollowUpList from '../../components/document-list/FollowUpList'
import RelatedCaseModal from '../../components/document-list/RelatedCaseModal'
import LoadExistingDocumentModal from '../../components/existing-documents/LoadExistingDocumentModal'
import LoadExistingDocumentSummaryModal from '../../components/existing-documents/LoadExistingDocumentSummaryModal'
import SelectExistingFollowUpModal from '../../components/existing-documents/SelectExistingFollowUpModal'
import authService from '../../services/auth/Auth.service'
import Roles from '../../services/auth/Roles.enum'
import caseIndexDatabaseService from '../../services/browser-storage/index-database/CaseIndexDatabase.service'
import followUpIndexDatabaseService from '../../services/browser-storage/index-database/FollowUpIndexDatabase.service'
import clinicsService from '../../services/clinics/Clinics.service'
import casesService from '../../services/documents/Cases.service'
import followUpsService from '../../services/documents/FollowUps.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { ExistingDocuments } from '../../shared/interfaces/ExistingDocuments.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import Status from '../../shared/interfaces/Status.interface'
import useAppStore from '../../state/App'
import useClinicStore, { GetDefaultTabValue } from '../../state/Clinic'

interface TabUI {
  label: string
  key: IndiFormType
}
interface LoadingUI {
  isAfterCareDataLoaded: boolean
  isAfterCareDataAPILoaded: boolean
  isCaseDataLoaded: boolean
  isCaseDataAPILoaded: boolean
  isAfterCareDataLoading: boolean
  isCaseDataLoading: boolean
}

interface TabItem {
  label: string
  key: string
  children: React.ReactElement
}

type Props = WithTranslation

const RELOAD_INTERVAL = 30 * 1000

// eslint-disable-next-line max-lines-per-function
const Documents = (props: Props): React.ReactElement => {
  const reloadTimer = useRef<null | number>(null)
  const clinicId = clinicsService.getClinicIdFromURL()
  const clinicStore = useClinicStore()
  const appStore = useAppStore()
  const [activeTab, setActiveTab] = useState<IndiFormType>(GetDefaultTabValue())
  const clinicFormMetaData = clinicsService.getClinicFormMetaData(clinicId, clinicStore.clinicsFormMetaData)
  const clinic = clinicsService.getClinicById(clinicStore.clinics, clinicId)
  const [cases, setCases] = useState<Array<Case>>([])
  const [followUps, setFollowUps] = useState<Array<FollowUp>>([])
  const [documentsCount, setDocumentsCount] = useState(0)
  const [existingDocuments, setExistingDocuments] = useState<ExistingDocuments<Array<FollowUp>> | null>(null)
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)
  const [showLoadExistingDocumentModal, setShowLoadExistingDocumentModal] = useState<boolean>(false)
  const [showLoadExistingDocumentSummaryModal, setShowLoadExistingDocumentSummaryModal] = useState<boolean>(false)
  const [showCaseNoModal, setShowCaseNoModal] = useState(false)
  const [showSelectExistingFollowUpModal, setShowSelectExistingFollowUpModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [caseNo, setCaseNo] = useState('')
  const [followUpDocumentToBeEdited, setFollowUpDocumentToBeEdited] = useState<FollowUpDocument | null>(null)
  const [isSubmittedDocumentSummary, setIsSubmittedDocumentSummary] = useState(false)
  const [isDocumentIdentifierInEditMode, setIsDocumentIdentifierInEditMode] = useState(false)

  const reloadFollowUpsFromIndexedDb = async (): Promise<void> => {
    const followUpsFromIndexDB: Array<FollowUp> = await followUpsService.getFollowUpDocumentsFromIndexDB(clinicId)
    setFollowUps(followUpsFromIndexDB)
  }

  const reloadCasesFromIndexedDb = async (): Promise<void> => {
    const casesFromIndexDB: Array<Case> = await casesService.getCaseDocumentsFromIndexDB(clinicId)
    setCases(casesFromIndexDB)
  }

  const pushDocument = async (documentToBePushed: Case | FollowUp, formType: IndiFormType): Promise<void> => {
    const indexDBService = formType === IndiFormType.CASE ? caseIndexDatabaseService : followUpIndexDatabaseService
    await indexDBService.markFormDocumentAsPushed(documentToBePushed.uuid)
    if (formType === IndiFormType.CASE) {
      await reloadCasesFromIndexedDb()
    } else {
      await reloadFollowUpsFromIndexedDb()
    }
  }

  const deleteDocument = async (documentToBeDeleted: Case | FollowUp, formType: IndiFormType): Promise<void> => {
    const indexDBService = formType === IndiFormType.CASE ? caseIndexDatabaseService : followUpIndexDatabaseService
    await indexDBService.markFormDocumentAsDeletedByClient(documentToBeDeleted.uuid)
    if (formType === IndiFormType.CASE) {
      await reloadCasesFromIndexedDb()
    } else {
      await reloadFollowUpsFromIndexedDb()
    }
  }

  const markDocumentCompleted = async (
    documentToBeMarked: Case | FollowUp,
    formType: IndiFormType,
    status: Status
  ): Promise<void> => {
    const indexDBService = formType === IndiFormType.CASE ? caseIndexDatabaseService : followUpIndexDatabaseService
    await indexDBService.markFormDocumentAsCompleted(documentToBeMarked.uuid, status)
    if (formType === IndiFormType.CASE) {
      await reloadCasesFromIndexedDb()
    } else {
      await reloadFollowUpsFromIndexedDb()
    }
  }

  const resetTimer = (): void => {
    if (reloadTimer.current !== null) {
      clearInterval(reloadTimer.current)
      reloadTimer.current = null
    }
  }

  const onSelectExistingFollowUpModal = (): void => {
    setShowLoadExistingDocumentModal(false)
    setShowLoadExistingDocumentSummaryModal(false)
    setShowSelectExistingFollowUpModal(true)
  }

  const onExistingDocumentSelected = async (formType: IndiFormType): Promise<void> => {
    if (formType === IndiFormType.CASE) {
      await reloadCasesFromIndexedDb()
    } else {
      await reloadFollowUpsFromIndexedDb()
    }
    setShowLoadExistingDocumentModal(false)
  }

  const onDocumentCountChange = (count: number): void => {
    setDocumentsCount(count)
  }

  const initAutomaticReload = (): void => {
    const { isOnline } = appStore // STATE_UPDATE
    if (reloadTimer.current === null) {
      reloadTimer.current = window.setInterval(async () => {
        if (isOnline) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await reloadDocumentsBasedOnRole(false)
        }
      }, RELOAD_INTERVAL)
    }
  }

  const reloadFollowUps = async (loadObj: LoadingUI, reloadFromIndexedDb: boolean): Promise<void> => {
    resetTimer()
    const loadUIObj = loadObj
    // Get the follow-ups from IndexDB for a faster load time and update the follow-ups from the API
    // if the user is online and the component is mounted
    if (reloadFromIndexedDb) {
      const followUpsFromIndexDB: Array<FollowUp> = await followUpsService.getFollowUpDocumentsFromIndexDB(clinicId)
      setFollowUps(followUpsFromIndexDB)
      loadUIObj.isAfterCareDataLoaded = true
      if (!loadUIObj.isCaseDataLoading || loadUIObj.isCaseDataLoaded) {
        setIsLoadingDocuments(false)
      }
    }
    const updatedFollowUps: Array<FollowUp> | null = await followUpsService.reloadFollowUps(clinicId)
    setFollowUps(updatedFollowUps ?? followUps)
    loadUIObj.isAfterCareDataLoaded = true
    loadUIObj.isAfterCareDataAPILoaded = true
    if (!loadUIObj.isCaseDataLoading || loadUIObj.isCaseDataAPILoaded) {
      initAutomaticReload()
    }
  }

  const reloadCases = async (loadObj: LoadingUI, reloadFromIndexedDb: boolean): Promise<void> => {
    const { isOnline } = appStore // STATE_UPDATE

    resetTimer()
    const loadUIObj = loadObj
    // Get the cases from IndexDB for a faster load time and update the cases from
    // the API if the user is online and the component is mounted
    if (reloadFromIndexedDb && isOnline) {
      const casesFromIndexDB: Array<Case> = await casesService.getCaseDocumentsFromIndexDB(clinicId)
      setCases(casesFromIndexDB)
      loadUIObj.isCaseDataLoaded = true
      if (!loadObj.isAfterCareDataLoading || loadObj.isAfterCareDataLoaded) {
        setIsLoadingDocuments(false)
      }
    }
    const updatedCases: Array<Case> | null = await casesService.reloadCases(clinicId)
    loadUIObj.isCaseDataLoaded = true
    loadUIObj.isCaseDataAPILoaded = true
    setCases(updatedCases ?? [])
    if (!loadObj.isAfterCareDataLoading || loadObj.isAfterCareDataAPILoaded) {
      initAutomaticReload()
    }
  }

  const reloadDocumentsBasedOnRole = async (isFirstLoad: boolean): Promise<void> => {
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
    const isAdmin: boolean = isDocumentReviewer && isCaseCollector && isFollowUpCollector

    const loadObj: LoadingUI = {
      isAfterCareDataLoaded: false,
      isAfterCareDataAPILoaded: false,
      isCaseDataLoaded: false,
      isCaseDataAPILoaded: false,
      isAfterCareDataLoading: isDocumentReviewer || isFollowUpCollector,
      isCaseDataLoading: isDocumentReviewer || isCaseCollector,
    }

    switch (true) {
      case isAdmin || isDocumentReviewer || (isCaseCollector && isFollowUpCollector):
        await Promise.all([reloadCases(loadObj, isFirstLoad), reloadFollowUps(loadObj, isFirstLoad)])
        break
      case isCaseCollector:
        await reloadCases(loadObj, isFirstLoad)
        break
      case isFollowUpCollector:
        await reloadFollowUps(loadObj, isFirstLoad)
        break
      default:
        break
    }
  }

  const renderDocumentListHeader = (): React.ReactElement => (
    <DocumentListHeader
      clinic={clinic as Clinic}
      formType={activeTab}
      documentsCount={documentsCount}
      onLoadDocumentButtonClick={() => {
        setShowLoadExistingDocumentModal(true)
        setIsSubmittedDocumentSummary(false)
      }}
      onLoadSummaryButtonClick={() => {
        setShowLoadExistingDocumentSummaryModal(true)
        setIsSubmittedDocumentSummary(true)
      }}
      onSearchInputChange={(searchTextValue: string) => setSearchText(searchTextValue)}
      searchText={searchText}
      onNewFollowUpButtonClick={() => setShowCaseNoModal(true)}
    />
  )

  const renderDocumentList = (): React.ReactElement => {
    if (activeTab === IndiFormType.CASE) {
      return (
        <CaseList
          clinic={clinic as Clinic}
          cases={cases}
          onDocumentCountChange={onDocumentCountChange}
          searchText={searchText}
          onDocumentMarkCompleted={async (document: Case, status: Status) =>
            markDocumentCompleted(document, IndiFormType.CASE, status)
          }
          onPushClick={async (document: Case) => pushDocument(document, IndiFormType.CASE)}
          onDeleteClick={async (document: Case) => deleteDocument(document, IndiFormType.CASE)}
        />
      )
    }
    return (
      <FollowUpList
        clinic={clinic as Clinic}
        followUps={followUps}
        onDocumentCountChange={onDocumentCountChange}
        searchText={searchText}
        onEditCaseNoClick={(documentToBeEdited: FollowUpDocument) => {
          setShowCaseNoModal(true)
          setFollowUpDocumentToBeEdited(documentToBeEdited)
          setIsDocumentIdentifierInEditMode(true)
        }}
        onDocumentMarkCompleted={async (document: FollowUp, status: Status) =>
          markDocumentCompleted(document, IndiFormType.FOLLOWUP, status)
        }
        onPushClick={async (document: FollowUp) => pushDocument(document, IndiFormType.FOLLOWUP)}
        onDeleteClick={async (document: FollowUp) => deleteDocument(document, IndiFormType.FOLLOWUP)}
      />
    )
  }

  const renderTabs = (tab: TabUI): TabItem => ({
    label: tab.label,
    key: tab.key,
    children: <Card title={renderDocumentListHeader()}>{renderDocumentList()}</Card>,
  })

  const renderTabsBasedOnRole = (): Array<TabItem> => {
    const { t } = props
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isCaseSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_SUBMITTED_SUMMARY)
    const isFollowUpSummaryRole: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_SUBMITTED_SUMMARY)

    const tabs = {
      case: {
        label: t('documentList.cases'),
        key: IndiFormType.CASE,
      },
      followUp: {
        label: t('documentList.followUps'),
        key: IndiFormType.FOLLOWUP,
      },
    }

    if (isDocumentReviewer) {
      return Object.values(tabs).map(tab => renderTabs(tab))
    }
    if (isCaseCollector && isFollowUpCollector) {
      return Object.values(tabs).map(tab => renderTabs(tab))
    }
    if (isCaseSummaryRole && isFollowUpSummaryRole) {
      return Object.values(tabs).map(tab => renderTabs(tab))
    }
    if (isCaseCollector || isCaseSummaryRole) {
      return [renderTabs(tabs.case)]
    }
    return [renderTabs(tabs.followUp)]
  }

  const renderDocuments = (): React.ReactElement => {
    if (clinic !== undefined) {
      return (
        <div>
          {isLoadingDocuments ? (
            <Skeleton active className="p-4" />
          ) : (
            <Tabs
              defaultActiveKey={activeTab}
              className="px-4 pb-4 pt-2 bg-white"
              onChange={activeKey => {
                setSearchText('')
                setActiveTab(activeKey as IndiFormType)
              }}
              items={renderTabsBasedOnRole()}
            />
          )}
          <LoadExistingDocumentModal
            clinic={clinic}
            setCaseNo={caseNumber => setCaseNo(caseNumber)}
            documentType={activeTab}
            setExistingDocuments={(existingDocument: ExistingDocuments<Array<FollowUp>>) =>
              setExistingDocuments(existingDocument)
            }
            isVisible={showLoadExistingDocumentModal}
            onCancel={() => setShowLoadExistingDocumentModal(false)}
            onExistingDocumentSelected={onExistingDocumentSelected}
            onSelectExistingFollowUpModal={onSelectExistingFollowUpModal}
          />
          <LoadExistingDocumentSummaryModal
            clinic={clinic}
            setCaseNo={caseNumber => setCaseNo(caseNumber)}
            documentType={activeTab}
            setExistingDocuments={(existingDocument: ExistingDocuments<Array<FollowUp>>) =>
              setExistingDocuments(existingDocument)
            }
            isVisible={showLoadExistingDocumentSummaryModal}
            onCancel={() => setShowLoadExistingDocumentSummaryModal(false)}
            onSelectExistingFollowUpModal={onSelectExistingFollowUpModal}
          />
          <RelatedCaseModal
            clinic={clinic}
            followUpDocumentToBeEdited={followUpDocumentToBeEdited}
            isDocumentIdentifierInEditMode={isDocumentIdentifierInEditMode}
            isVisible={showCaseNoModal}
            onCancel={() => setShowCaseNoModal(false)}
          />
          <SelectExistingFollowUpModal
            clinic={clinic}
            caseNo={caseNo}
            isVisible={showSelectExistingFollowUpModal}
            existingDocuments={existingDocuments}
            onCancel={() => setShowSelectExistingFollowUpModal(false)}
            onExistingDocumentSelected={onExistingDocumentSelected}
            isSubmittedDocumentSummary={isSubmittedDocumentSummary}
          />
        </div>
      )
    }
    return <>Unable to fetch the clinic. Please try again or refresh the page.</>
  }

  useEffect(() => {
    const { t } = props
    clinicStore.setValidationMetaData(clinicFormMetaData as ClinicFormMetaData)
    appStore.setNavbarHeader(t('documentList.documents'))

    setIsLoadingDocuments(true)
    reloadDocumentsBasedOnRole(true)
    setIsLoadingDocuments(false)

    return () => {
      if (reloadTimer.current !== null) {
        clearInterval(reloadTimer.current)
      }
    }
  }, [])

  useEffect(() => {
    clinicStore.setActiveTab(activeTab)
  }, [activeTab])

  return <Layout>{renderDocuments()}</Layout>
}

export default withTranslation()(Documents)
