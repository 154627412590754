import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormDropDown, { DropdownOption } from '../ui/FormDropDown'

export interface EnumOptions {
  id: number
  text: string
  value: string
}

interface Props {
  enumType: string | null
  options: Array<EnumOptions>
  metaDataPath: string
  variable: string
  validators: Record<string, never> | null
}

const Enum = (props: Props): React.ReactElement => {
  const indiFormStore = useIndiFormStore()

  const dataContext = useContextStore()
  const { t } = useTranslation()
  const [value, setValue] = React.useState<string | number | undefined>(undefined)
  const { enumType, variable, metaDataPath, validators, options } = props

  const availableDropdownOptions: Array<DropdownOption> = indiFormService.getEnumDropDownOptions(
    indiFormStore,
    enumType,
    t
  )

  const dropdownOptions: Array<DropdownOption> =
    availableDropdownOptions.length !== 0
      ? availableDropdownOptions
      : options.map(option => ({
          displayLabel: t(`options.${variable}.${option.value}`) || option.text,
          label: option.text,
          value: option.value,
        }))

  React.useEffect(() => {
    const getInitialValue = (): string | number | undefined => {
      const initialValue: EnumValue | string | undefined = dataContext.getDataPathValue(metaDataPath)
      if (initialValue) {
        if (typeof initialValue === 'string') {
          return initialValue
        }
        const existsInOptions = dropdownOptions.find(option => option.value === initialValue.enumId)
        if (!existsInOptions) {
          return initialValue.enumText
        }
        return initialValue.enumId
      }
      return ''
    }
    setValue(getInitialValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const updateValueAndCase = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    if (selectedOption === undefined) {
      await dataContext.updateData(metaDataPath, selectedOption)
    } else {
      const selectedValueOption: DropdownOption | undefined = dropdownOptions.find(
        option => option.value === selectedOption.value
      )
      if (selectedValueOption !== undefined) {
        if (enumType !== null) {
          const enumOption: EnumValue = indiFormService.getEnumValueObject(
            selectedValueOption.value as number,
            selectedValueOption.label
          )
          await dataContext.updateData(metaDataPath, enumOption)
        } else {
          await dataContext.updateData(metaDataPath, selectedOption.value)
        }
      }
    }
  }

  return (
    <FormDropDown
      isClearingAllowed
      options={dropdownOptions}
      value={value}
      validators={validators}
      onChange={async (selectedOption: DropdownOption | undefined) => {
        await updateValueAndCase(selectedOption)
      }}
      placeholder=""
      metadataPath={metaDataPath}
    />
  )
}

export default Enum
