import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { cloneDeep, flatten } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import sharedStateHelper from '../../document-state-service/SharedStateHelper.service'
import evaluationService from '../../services/indiform/Evaluation.service'
import formRenderService from '../../services/indiform/FormRender.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import { ChangeLogData } from '../../shared/interfaces/ChangeLogData.interface'
import useContextStore from '../../state/Context'
import ChangeLogModal, { ChangeLogElement } from '../indiform-ui/ChangeLogModal'
import './FormType.scss'
import ListTypeFormHelp from './ListTypeFormHelp'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaData: any
  metaDataPath: string
}

const ListTypeForm: React.FC<Props> = (props): React.ReactElement | null => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { id = '', variable = '', children = [], hideIf, showIf } = props.metaData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listChildren, setListChildren] = React.useState<Array<any>>([])
  const [path, setPath] = React.useState<string>('')
  const isShown: boolean = evaluationService.evaluateExpression(
    hideIf,
    showIf,
    dataContext.formDocument,
    props.metaDataPath
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getChildren = (): Array<any> => cloneDeep(children)

  React.useEffect(() => {
    if (isShown) {
      const metaDataPath: string = sharedStateHelper.buildMetaDataPath(props.metaDataPath, variable)
      const initialValue: Array<Record<string, unknown>> = dataContext.getDataPathValue(metaDataPath) || []
      setListChildren(flatten(initialValue.map(() => getChildren())))
      setPath(metaDataPath)
    }
  }, [dataContext.formDocument, props.metaDataPath])

  const addListChildren = async (): Promise<void> => {
    const currentValue: Array<Record<string, unknown>> = dataContext.getDataPathValue(path) || []
    const changeLogData: ChangeLogData = {
      path: dataContext.formType === IndiFormType.FOLLOWUP ? `data.followUp.${path}` : `data.case.${path}`,
      value: {},
    }
    await dataContext.updateData(`${path}[${currentValue.length}]`, {}, AuditLogAction.ARRAY_ADD, changeLogData)
  }

  const deleteListChildren = async (listIndex: number): Promise<void> => {
    const value: Array<Record<string, unknown>> = dataContext.getDataPathValue(path)
    if (value !== undefined && value.length > 0) {
      const changeLogData: ChangeLogData = {
        path:
          dataContext.formType === IndiFormType.FOLLOWUP
            ? `data.followUp.${path}[${listIndex}]`
            : `data.case.${path}[${listIndex}]`,
        value: undefined,
      }
      await dataContext.updateData(
        path,
        value.filter((itemValue: unknown, index: number) => index !== listIndex),
        AuditLogAction.DELETE,
        changeLogData
      )
    }
  }

  const renderListTypeForm = (): React.ReactElement | null => {
    if (isShown) {
      return (
        <Col span={24} className="my-2">
          <Button
            className="me-2"
            onClick={async () => addListChildren()}
            data-testid={`${getChildren()[0].id}_addButton`}
          >
            {id !== '' ? t(`form.labels.${getChildren()[0].id}_addButton`) : id}
          </Button>
          <ChangeLogModal label={getChildren()[0].id} metaDataPath={path} type={ChangeLogElement.BUTTON} />
          {listChildren
            .map((childrenItem, index: number) => {
              if (childrenItem !== undefined) {
                const { id: childrenItemId = '', withHelpOption = false, helpType, helpImageName } = childrenItem
                const metaDataPath: string = sharedStateHelper.buildDataPath(path, index)

                return (
                  <div key={metaDataPath} className="mt-3 mx-2">
                    <header className="d-flex justify-content-between align-items-center px-4 form-sub-header">
                      <h4 className="m-0">
                        {childrenItemId !== '' ? t(`form.labels.${childrenItemId}`) : childrenItemId}
                      </h4>
                      <div>
                        {withHelpOption && (
                          <ListTypeFormHelp
                            helpImageName={helpImageName}
                            childrenItemId={childrenItemId}
                            helpType={helpType}
                          />
                        )}
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          icon={['fal', 'trash-alt']}
                          size="lg"
                          onClick={async () => {
                            await deleteListChildren(index)
                          }}
                        />
                      </div>
                    </header>
                    <Row gutter={16} className="">
                      {formRenderService.renderFormBasedOnType(childrenItem, metaDataPath, true)}
                    </Row>
                  </div>
                )
              }
              return null
            })
            .reverse()}
        </Col>
      )
    }
    return null
  }

  return renderListTypeForm()
}

export default ListTypeForm
