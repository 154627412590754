import Dexie from 'dexie'
import { Case } from '../../../shared/interfaces/Case.interface'
import { CaseDocument } from '../../../shared/interfaces/CaseDocument.interface'
import { FormDocument } from '../../../shared/interfaces/FormDocument.interface'
import { BaseFormIndexDatabaseService } from './BaseFormIndexDatabase.service'

class CaseIndexDatabaseService extends BaseFormIndexDatabaseService {
  public readonly DOCUMENT_RECORDS_TABLE_NAME = 'caseDocumentRecords'
  public documentRecords: Dexie.Table<CaseDocument, string>
  public db: CaseIndexDatabaseService

  constructor() {
    super()
    this.db = this
    this.documentRecords = this.table(this.DOCUMENT_RECORDS_TABLE_NAME)
    this.open()
  }

  public deleteCaseDocumentsIfNotMatching = async (
    caseDocuments: Array<CaseDocument>,
    clinicId: number
  ): Promise<void> => {
    await this.deleteFormDocumentsIfNotMatching(caseDocuments, clinicId)
  }

  protected deleteFormDocumentsIfNotMatching = async (
    formDocuments: Array<FormDocument>,
    clinicId: number
  ): Promise<void> => {
    const localFormDocuments: Array<FormDocument> = await this.db.documentRecords
      .filter((formDocument: FormDocument) => {
        const { lastSuccessfullSync, data } = formDocument.document as Case
        return lastSuccessfullSync && data.case.hospital && data.case.hospital.enumId === clinicId
      })
      .toArray()
    for (let i = 0; i < localFormDocuments.length; i++) {
      if (
        !formDocuments.find(
          (formDocument: FormDocument) => formDocument.document.uuid === localFormDocuments[i].document.uuid
        )
      ) {
        // eslint-disable-next-line no-await-in-loop
        await this.deleteFormDocument(localFormDocuments[i].document.uuid)
      }
    }
  }

  public filterCaseDocumentByStatus = (
    clinicId: number,
    caseDocument: CaseDocument,
    allowedStatuses: Array<string>
  ): boolean => this.filterFormDocumentByStatus(clinicId, caseDocument, allowedStatuses)

  protected filterFormDocumentByStatus = (
    clinicId: number,
    formDocument: FormDocument,
    allowedStatuses: Array<string>
  ): boolean => {
    const { data, status } = formDocument.document as Case
    const matchesClinic: boolean = data.case.hospital && data.case.hospital.enumId === clinicId
    return allowedStatuses.includes(status) && matchesClinic
  }
}

const caseIndexDatabaseService = new CaseIndexDatabaseService()
export default caseIndexDatabaseService
