/* eslint-disable no-constant-condition */
import { Case } from '../../shared/interfaces/Case.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import ObjectUtils from '../../utils/Object'

class EvaluationService {
  private readonly INDEX_PLACEHOLDER = '/VARIABLE-INDEX-PLACEHOLDER/'
  public evaluateExpression(
    hideIfExpression: string | undefined,
    showIfExpression: string | undefined,
    documentData: FormDocument | undefined,
    path: string | undefined = undefined
  ): boolean {
    let DATA_OBJECT_PATH_VALUE
    if (documentData) {
      DATA_OBJECT_PATH_VALUE = Object.prototype.hasOwnProperty.call(documentData.document.data, 'followUp')
        ? 'followUp'
        : 'case'
    }
    const DATA_OBJECT_PATH = `data.${DATA_OBJECT_PATH_VALUE}`
    const documentDataPath: string | undefined = path !== undefined ? DATA_OBJECT_PATH.concat(`.${path}`) : path
    if (documentData !== undefined && hideIfExpression !== undefined) {
      return !this.evaluateFlag(hideIfExpression, documentData.document, documentDataPath)
    } else if (documentData !== undefined && showIfExpression !== undefined) {
      return this.evaluateFlag(showIfExpression, documentData.document, documentDataPath)
    }

    return true
  }

  private getIndexes(path: string | undefined): Array<{ value: string }> {
    if (path !== undefined) {
      const indexRegex = /\[\d*\]/g
      let result: RegExpExecArray | null = null
      const indexes: Array<{ value: string }> = []
      do {
        result = indexRegex.exec(path)
        if (result === null) break
        indexes.push({
          value: result[0].substr(1, result[0].indexOf(']') - 1),
        })
      } while (true)
      return indexes
    }
    return []
  }

  private getDocumentVariables(conditionExpression: string): Array<{ index: number; value: string }> {
    const dataRegex = /data\.[^\s\\]*/g
    let result: RegExpExecArray | null = null
    const indices: Array<{ index: number; value: string }> = []

    while (true) {
      result = dataRegex.exec(conditionExpression)
      if (result === null) break
      indices.push({
        index: result.index,
        value: result[0],
      })
    }

    const rootRegex = /root\.[^\s\\]*/g

    while (true) {
      result = rootRegex.exec(conditionExpression)
      if (result === null) break
      indices.push({
        index: result.index,
        value: result[0],
      })
    }
    return indices
  }

  private replaceConditionParams(
    conditionExpression: string,
    functionRef: string,
    documentDataRef: string,
    indexes: Array<{ value: string }>,
    documentVariables: Array<{ index: number; value: string }>
  ): string {
    let newCondition: string = conditionExpression
    let addedIndex = 0
    const newDocumentVariables = documentVariables
    for (let i = 0; i < newDocumentVariables.length; i++) {
      // remove functions like '.filter() from replacement
      if (newDocumentVariables[i].value.indexOf('(') > 0) {
        newDocumentVariables[i].value = newDocumentVariables[i].value.substring(
          0,
          newDocumentVariables[i].value.indexOf('(')
        )
        newDocumentVariables[i].value = newDocumentVariables[i].value.substring(
          0,
          newDocumentVariables[i].value.lastIndexOf('.')
        )
      }

      // replace index placeholders with indexes of current path
      let count = 0
      const oldValue: string = newDocumentVariables[i].value

      while (newDocumentVariables[i].value.indexOf(this.INDEX_PLACEHOLDER) > 0) {
        const indexOfPlaceholder: number = newDocumentVariables[i].value.indexOf(this.INDEX_PLACEHOLDER)
        newDocumentVariables[i].value = `${newDocumentVariables[i].value.substring(0, indexOfPlaceholder)}${
          indexes[count].value
        }${newDocumentVariables[i].value.substring(indexOfPlaceholder + this.INDEX_PLACEHOLDER.length)}`
        count += 1
      }
      if (newDocumentVariables[i].value.startsWith('root.')) {
        newDocumentVariables[i].value = newDocumentVariables[i].value.substring('root.'.length)
      }

      const replacement = `${functionRef}(${documentDataRef}, '${newDocumentVariables[i].value}')`
      newCondition = `${newCondition.substring(
        0,
        newDocumentVariables[i].index + addedIndex
      )}${replacement}${newCondition.substring(newDocumentVariables[i].index + addedIndex + oldValue.length)}`
      addedIndex += replacement.length - oldValue.length
    }
    return newCondition
  }

  private evaluateFlag(conditionExpression: string, documentData: Case | FollowUp, path: string | undefined): boolean {
    const { getAt } = ObjectUtils // needed to get direct reference _objects__WEBPACK_IMPORTED_MODULE_0__["getAt"]
    // needed to get reference to variable name (as the reference Name might be minified in prod)
    const functionRefName: string = Object.keys({ getAt })[0]
    // needed to get reference to variable name (as the reference Name might be minified in prod)
    const documentDataRef: string = Object.keys({ documentData })[0]

    const indexes: Array<{ value: string }> = this.getIndexes(path)
    const documentVariables: Array<{ index: number; value: string }> = this.getDocumentVariables(conditionExpression)
    const condition: string = this.replaceConditionParams(
      conditionExpression,
      functionRefName,
      documentDataRef,
      indexes,
      documentVariables
    )
    // eslint-disable-next-line no-eval
    return eval(condition)
  }
}

const evaluationService = new EvaluationService()
export default evaluationService
