import { TFunction } from 'i18next'
import { flatten } from 'lodash-es'
import { Case } from '../../shared/interfaces/Case.interface'
import { CaseData } from '../../shared/interfaces/CaseData.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicMetaDataValidator } from '../../shared/interfaces/ClinicValidationMetaData.interface'
import { ContextMenuOption } from '../../shared/interfaces/ContextMenuOption.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FollowUpData } from '../../shared/interfaces/FollowUpData.interface'
import Status from '../../shared/interfaces/Status.interface'
import authService from '../auth/Auth.service'
import Roles from '../auth/Roles.enum'
import IndiFormType from '../indiform/IndiFormType.enum'
import validationService from '../indiform-validation/Validation.service'
import ContextMenuOptionValue from './ContextMenuOptionValue.enum'

class StaticDocumentListService {
  public getContextMenuOptions = (t: TFunction): Array<ContextMenuOption> => [
    {
      icon: 'edit',
      label: t('documentList.contextMenu.edit_case_number'),
      value: ContextMenuOptionValue.EDIT_CASE_NUMBER,
    },
    {
      icon: 'check',
      label: t('documentList.contextMenu.markDataCompleted'),
      value: ContextMenuOptionValue.DATA_COMPLETED,
    },
    {
      icon: 'check',
      label: t('documentList.contextMenu.markReviewCompleted'),
      value: ContextMenuOptionValue.REVIEW_COMPLETED,
    },
    {
      icon: 'eye',
      label: t('documentList.contextMenu.summary'),
      value: ContextMenuOptionValue.SUMMARY,
    },
    {
      icon: 'upload',
      label: t('documentList.contextMenu.submit'),
      value: ContextMenuOptionValue.SUBMIT,
    },
    {
      icon: 'trash-alt',
      label: t('documentList.contextMenu.delete'),
      value: ContextMenuOptionValue.DELETE,
    },
  ]

  public isRowDisabled = (documentStatus: Status): boolean => {
    const statuses: Array<Status> = [
      Status.PUSH_REQUESTED_BY_USER,
      Status.QC_PASSED_REQUEST_BY_REVIEWER,
      Status.SUBMITTED,
    ]
    return statuses.some(status => status === documentStatus)
  }

  public ifCaseHasProjectNumber = (accruals: Array<Record<string, never>>): boolean => {
    let hasProjectNumber = false
    if (accruals && accruals.length > 0) {
      const tumors = flatten(accruals.map((accrual: Record<string, never>) => accrual.tumors))
      hasProjectNumber = tumors.some(
        (tumor: Record<string, string>) => tumor && tumor.projectcodes && tumor.projectcodes.length > 0
      )
    }

    return hasProjectNumber
  }

  public isMenuOptionShown = (menuOption: ContextMenuOption, formType: IndiFormType): boolean => {
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)

    switch (menuOption.value) {
      case ContextMenuOptionValue.EDIT_CASE_NUMBER:
        return formType === IndiFormType.FOLLOWUP
      case ContextMenuOptionValue.DATA_COMPLETED:
        return !isDocumentReviewer && (isCaseCollector || isFollowUpCollector)
      case ContextMenuOptionValue.REVIEW_COMPLETED:
        return isDocumentReviewer
      case ContextMenuOptionValue.DELETE:
      case ContextMenuOptionValue.SUBMIT:
      case ContextMenuOptionValue.SUMMARY:
      default:
        return true
    }
  }

  public isMenuOptionEnabled = (
    document: Case | FollowUp,
    menuOption: ContextMenuOption,
    formType: IndiFormType,
    isDocumentValid: boolean
  ): boolean => {
    const { status } = document
    switch (menuOption.value) {
      case ContextMenuOptionValue.DATA_COMPLETED:
        return status !== Status.DATA_COMPLETED && isDocumentValid
      case ContextMenuOptionValue.REVIEW_COMPLETED:
        return status !== Status.REVIEW_COMPLETED && isDocumentValid
      case ContextMenuOptionValue.SUBMIT:
        return isDocumentValid
      case ContextMenuOptionValue.DELETE:
      case ContextMenuOptionValue.SUMMARY:
      default:
        return true
    }
  }

  public isDocumentValid = (
    document: Case | FollowUp,
    formType: IndiFormType,
    clinic: Clinic,
    validationMetaData: Array<ClinicMetaDataValidator> | undefined
  ): boolean => {
    const data =
      formType === IndiFormType.CASE ? (document.data as CaseData).case : (document.data as FollowUpData).followUp
    const { followUpVisit, consentDeclared } = data || {}
    const followUpNoExists: boolean =
      document.messages?.find(message => message.text.includes('Follow-up number already exists.')) !== undefined
    const caseNoExists: boolean =
      document.messages?.find(message => message.text.includes('Case number already exists.')) !== undefined
    let isValid = false
    if (formType === IndiFormType.CASE) {
      isValid = !(
        !validationService.isDocumentValid(document, validationMetaData) ||
        consentDeclared !== 'y' ||
        caseNoExists
      )
    } else {
      isValid = !(followUpVisit === undefined || followUpNoExists)
    }
    return isValid
  }

  public getStatus(status: Status, t: TFunction): string {
    switch (status) {
      case Status.IN_PROCESS:
        return t('documentList.table.values.status.inProcess')
      case Status.QC_PASSED:
        return t('documentList.table.values.status.qcPassed')
      case Status.QC_FAILED:
        return t('documentList.table.values.status.qcFailed')
      case Status.MISSING_DATA:
        return t('documentList.table.values.status.missingData')
      case Status.SUBMITTED:
      case Status.QC_PASSED_REQUEST_BY_REVIEWER:
      case Status.PUSH_REQUESTED_BY_USER:
        return `${t('documentList.table.values.status.pushing')} ...`
      case Status.READY_FOR_REVIEW:
        return t('documentList.table.values.status.readyForReview')
      case Status.IN_REVIEW:
        return t('documentList.table.values.status.inReview')
      case Status.PULLED_FOR_REVIEW:
        return t('documentList.table.values.status.pulledForReview')
      case Status.SUBMIT_FAILED:
        return t('documentList.table.values.status.submitFailed')
      case Status.DATA_COMPLETED:
        return t('documentList.table.values.status.dataCompleted')
      case Status.REVIEW_COMPLETED:
        return t('documentList.table.values.status.reviewCompleted')
      default:
        return ''
    }
  }
}

const staticDocumentListService = new StaticDocumentListService()
export default staticDocumentListService
