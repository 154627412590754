import { Button, Col, Input, Popconfirm, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate } from 'react-router-dom'
import authService from '../../services/auth/Auth.service'
import EditorType from '../../services/indiform/EditorType.enum'
import Status from '../../shared/interfaces/Status.interface'
import useContextStore from '../../state/Context'
import dayjs from '../../utils/dayjs'
import FormModal from '../ui/FormModal'

const Review = (): React.ReactElement | null => {
  const dataContext = useContextStore()
  const [reviewerComment, setReviewerComment] = React.useState<string | undefined>(undefined)
  const [showMissingDataModal, toggleMissingDataModal] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const path = useMatch('/clinics/:clinicId/:editorType/:formDocumentId/:formMetaDataId')
  const navigate = useNavigate()

  const isEditorTypeCase: boolean = path?.params.editorType === EditorType.CASE
  const reviewerCommentMetaDataPath = 'reviewerComment'

  React.useEffect(() => {
    const formElementValue: string | undefined = dataContext.getDataPathValue(reviewerCommentMetaDataPath)
    setReviewerComment(formElementValue ?? '')
  }, [dataContext, dataContext.formDocument])

  const onReviewButtonClick = async (): Promise<void> => {
    const owner: string | undefined = authService.getCurrentUsername()
    await dataContext.updateDocumentStatus(Status.IN_REVIEW, {
      reviewBy: owner,
      reviewAt: Date.now(),
    })
  }

  const renderReviewButton = (): React.ReactElement => (
    <Button onClick={onReviewButtonClick} className="reviewer-button">
      {t(isEditorTypeCase ? 'form.labels.review_reviewCase' : 'form.labels.review_reviewFollowUp')}
    </Button>
  )

  const renderReviewDetails = (reviewBy: string, reviewAt: number | undefined): React.ReactElement => (
    <Row className="mb-4">
      <Col span={24}>{t('form.labels.review_reviewBy')}</Col>
      <Col span={24}>{reviewBy}</Col>
      <br />
      <Col span={24}>{t('form.labels.review_reviewAt')}</Col>
      {reviewAt !== undefined && (
        <Col span={24}>{dayjs(new Date(reviewAt)).format('YYYY-MMM-DD HH:mm:ss').toUpperCase()}</Col>
      )}
    </Row>
  )

  const renderReviewContent = (): React.ReactElement | null => {
    if (dataContext.formDocument !== undefined) {
      const { reviewBy, reviewAt } = dataContext.formDocument.document
      return !reviewBy || reviewBy === '' ? renderReviewButton() : renderReviewDetails(reviewBy, reviewAt)
    }
    return null
  }

  const renderSubmitDetails = (
    submittedBy: string | undefined,
    submittedAt: number | undefined
  ): React.ReactElement => (
    <Row>
      <Col span={24}>{t('form.labels.review_submittedBy')}</Col>
      {submittedBy !== undefined && <Col span={24}>{submittedBy}</Col>}
      <br />
      <Col span={24}>{t('form.labels.review_submittedAt')}</Col>
      {submittedAt !== undefined && (
        <Col span={24}>{dayjs(new Date(submittedAt)).format('YYYY-MMM-DD HH:mm:ss').toUpperCase()}</Col>
      )}
    </Row>
  )

  const updateReviewerComment = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value
    setReviewerComment(inputValue)
  }

  const updateDocumentStatus = async (status: Status): Promise<void> => {
    const owner: string | undefined = authService.getCurrentUsername()
    await dataContext.updateDocumentStatus(
      status,
      status === Status.SUBMITTED ? { submittedBy: owner, submittedAt: Date.now() } : {}
    )
    navigate(`/clinics/${path?.params?.clinicId}`)
  }

  const updateDocument = async (): Promise<void> => {
    const contextValue = dataContext.getDataPathValue(reviewerCommentMetaDataPath) ?? ''
    if (contextValue !== reviewerComment) {
      await dataContext.updateData(reviewerCommentMetaDataPath, reviewerComment)
    }
    await updateDocumentStatus(Status.MISSING_DATA)
  }

  const renderSubmitButton = (): React.ReactElement => (
    <Popconfirm
      title={t(
        `documentList.contextMenu.${isEditorTypeCase ? 'submitCaseConfirmation' : 'submitFollowUpConfirmation'}`
      )}
      okText={t('form.labels.yes')}
      cancelText={t('form.labels.no')}
      onConfirm={async () => updateDocumentStatus(Status.SUBMITTED)}
    >
      <Button className="reviewer-button">
        {t(isEditorTypeCase ? 'form.labels.review_submitCase' : 'form.labels.review_submitFollowUp')}
      </Button>
    </Popconfirm>
  )

  const renderInProcessButton = (): React.ReactElement => (
    <Popconfirm
      title={t('form.messages.reviewer.inProcessConfirmation')}
      okText={t('form.labels.yes')}
      cancelText={t('form.labels.no')}
      onConfirm={async () => updateDocumentStatus(Status.IN_PROCESS)}
    >
      <Button className="ms-2 reviewer-button">{t('form.labels.review_inProcess')}</Button>
    </Popconfirm>
  )

  const renderMissingDataButton = (): React.ReactElement => (
    <Button className="ms-2 reviewer-button" onClick={() => toggleMissingDataModal(true)}>
      {t('form.labels.review_missingData')}
    </Button>
  )

  const renderMissingDataModal = (): React.ReactElement => {
    const { TextArea } = Input

    return (
      <FormModal
        header={t('documentList.contextMenu.markMissingDataConfirmation')}
        isVisible={showMissingDataModal}
        width="50%"
        onCancel={() => toggleMissingDataModal(false)}
        footer={[
          <Button key="review-modal-btn-cancel" onClick={() => toggleMissingDataModal(false)}>
            {t('form.labels.no')}
          </Button>,
          <Button key="review-modal-btn-ok" type="primary" onClick={updateDocument}>
            {t('form.labels.yes')}
          </Button>,
        ]}
        isClosable
        isDismissableMask={false}
      >
        <TextArea rows={3} className="w-100" value={reviewerComment} onChange={updateReviewerComment} />
      </FormModal>
    )
  }

  const renderSubmitContent = (): React.ReactElement | null => {
    if (dataContext.formDocument !== undefined) {
      const { reviewBy, submittedBy, submittedAt, messages } = dataContext.formDocument.document
      const caseNoExists: boolean =
        messages?.find(message => message.text.includes('Case number already exists.')) !== undefined
      const followUpNoExists: boolean =
        messages?.find(message => message.text.includes('Follow-up number already exists.')) !== undefined

      if (submittedBy || reviewBy) {
        return (
          <>
            {submittedBy ? renderSubmitDetails(submittedBy, submittedAt) : null}
            {!caseNoExists && !followUpNoExists ? renderSubmitButton() : null}
            {renderInProcessButton()}
            {!caseNoExists && !followUpNoExists ? renderMissingDataButton() : null}
            {showMissingDataModal ? renderMissingDataModal() : null}
          </>
        )
      }
      return null
    }
    return null
  }

  return (
    <>
      {renderReviewContent()}
      {renderSubmitContent()}
    </>
  )
}

export default Review
