import { Layout } from 'antd'
import React from 'react'
import SummaryTable from '../../components/summary/SummaryTable'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import './SummaryLayout.scss'

interface Props {
  formMetaData: ClinicFormMetaData
}

const SummaryLayout = (props: Props): React.ReactElement => {
  const { formMetaData } = props
  const { children = [] } = formMetaData

  return (
    <Layout>
      <SummaryTable formMetaData={children} />
    </Layout>
  )
}

export default SummaryLayout
