import { Col, Row } from 'antd'
import React from 'react'
import indiFormService, { TherapyType } from '../../services/indiform/IndiForm.service'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import { OtherFormMetaData } from '../../shared/interfaces/IndiformValues.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormDropDown, { DropdownOption } from '../ui/FormDropDown'
import FormLabel from '../ui/FormLabel'

interface Props {
  metaDataPath: string
}

const OtherTherapy: React.FC<Props> = (props): React.ReactElement | null => {
  const dataContext = useContextStore()
  const otherMetaData: OtherFormMetaData | null = useIndiFormStore(state => state.otherFormMetaData)

  const OTHER_THERAPY_AGENT_GROUP_PATH = `${props.metaDataPath}.agentGroup`
  const OTHER_THERAPY_AGENT_PATH = `${props.metaDataPath}.agent`

  const [agentGroup, setAgentGroup] = React.useState<number | undefined>(undefined)
  const [agent, setAgent] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    const getOtherTherapyAgentGroupValue = (): number | undefined => {
      const value: EnumValue | undefined = dataContext.getDataPathValue(OTHER_THERAPY_AGENT_GROUP_PATH)
      return value?.enumId as number
    }

    const getOtherTherapyAgentValue = (): number | undefined => {
      const value: EnumValue | undefined = dataContext.getDataPathValue(OTHER_THERAPY_AGENT_PATH)
      return value?.enumId as number
    }

    setAgentGroup(getOtherTherapyAgentGroupValue())
    setAgent(getOtherTherapyAgentValue())
  }, [dataContext, dataContext.formDocument, props.metaDataPath])

  const setOtherTherapyAgentValue = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    if (selectedOption === undefined) {
      await dataContext.updateData(OTHER_THERAPY_AGENT_PATH, selectedOption)
    } else {
      const value: EnumValue = indiFormService.getEnumValueObject(selectedOption.value as number, selectedOption.label)
      await dataContext.updateData(OTHER_THERAPY_AGENT_PATH, value)
    }
  }

  const setOtherTherapyAgentGroupValue = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    await setOtherTherapyAgentValue(undefined)
    if (selectedOption === undefined) {
      await dataContext.updateData(OTHER_THERAPY_AGENT_GROUP_PATH, selectedOption)
    } else {
      const value: EnumValue = indiFormService.getEnumValueObject(selectedOption.value as number, selectedOption.label)
      await dataContext.updateData(OTHER_THERAPY_AGENT_GROUP_PATH, value)
    }
  }

  const renderOtherTherapy = (): React.ReactElement | null => {
    if (otherMetaData !== null) {
      return (
        <Row gutter={16}>
          <Col span={12}>
            <FormLabel label="group" metaDataPath={props.metaDataPath} />
            <FormDropDown
              options={indiFormService.getGroupOptions(otherMetaData, TherapyType.OTHER)}
              value={agentGroup}
              onChange={setOtherTherapyAgentGroupValue}
              isClearingAllowed={false}
              placeholder=""
              validators={null}
              metadataPath={props.metaDataPath}
            />
          </Col>
          <Col span={12}>
            <FormLabel label="agent" metaDataPath={props.metaDataPath} />
            <FormDropDown
              options={indiFormService.getGroupAgents(otherMetaData, TherapyType.OTHER, agentGroup as number)}
              value={agent}
              onChange={setOtherTherapyAgentValue}
              isClearingAllowed={false}
              placeholder=""
              validators={null}
              metadataPath={props.metaDataPath}
            />
          </Col>
        </Row>
      )
    }
    return null
  }

  return renderOtherTherapy()
}

export default OtherTherapy
