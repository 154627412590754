import { cloneDeep } from 'lodash-es'
import { AuditLogEntry } from '../../../shared/interfaces/AuditLogEntry.interface'
import { CaseData } from '../../../shared/interfaces/CaseData.interface'
import { FollowUpData } from '../../../shared/interfaces/FollowUpData.interface'
import ObjectUtils from '../../../utils/Object'
import AuditLogAction from './AuditLogAction.enum'
import BaseChangeLogService from './BaseChangeLog.service'

class CaseChangeLogService extends BaseChangeLogService {
  private readonly ACCRUALS_PATH: string = 'data.case.accruals'
  private readonly accrualRegex: RegExp = new RegExp(`${this.ACCRUALS_PATH}\\[\\d]`, 'gm')
  private readonly tumorRegex: RegExp = new RegExp(`${this.ACCRUALS_PATH}\\[\\d]\\.tumors\\[\\d]`, 'gm')

  public getUpdatedChangeLogEntries = (
    dataObject: { data: CaseData | FollowUpData },
    changeLogEntry: AuditLogEntry
  ): Array<AuditLogEntry> => {
    let updatedChangeLogEntries: Array<AuditLogEntry> = cloneDeep(dataObject.data.changeLog.entries) || []
    const { action, field, timestamp } = changeLogEntry
    const accrualFields: Array<string> = this.getInitialAccrualFields((dataObject.data as CaseData).case)
    const tumorFieldMap: Map<string, Array<string>> = new Map<string, Array<string>>(
      this.getInitialTumorFieldMap((dataObject.data as CaseData).case)
    )
    const accuralFieldWithIndex: string = this.getAccrualFieldWithIndex(field) // data.case.accruals[0]
    const tumorFieldWithIndex: string = this.getTumorField(field, true) // data.case.accruals[0].tumors[0]
    const tumorFieldWithoutIndex: string = this.getTumorField(field, false) // data.case.accruals[0].tumors

    if (action === AuditLogAction.SET || action === AuditLogAction.ARRAY_ADD) {
      // Executed when new value is set on accrual
      if (accuralFieldWithIndex !== '' && !this.isAccuralArrayAddEntryIncluded(accrualFields, accuralFieldWithIndex)) {
        // Generate array add change log entry for the accrual
        const accuralArrayAddChangeLogEntry: AuditLogEntry | null = this.getAuditLogDataChange(
          AuditLogAction.ARRAY_ADD,
          this.ACCRUALS_PATH,
          {},
          '',
          timestamp - 2
        )
        // Add the change log entry to the list updatedChangeLogEntries
        if (accuralArrayAddChangeLogEntry !== null) {
          updatedChangeLogEntries = [...updatedChangeLogEntries, accuralArrayAddChangeLogEntry]
        }
      }
      // Executed when new value is set on tumor
      if (
        tumorFieldWithoutIndex !== '' &&
        !this.isTumorArrayAddEntryIncluded(tumorFieldMap, accuralFieldWithIndex, tumorFieldWithIndex)
      ) {
        // Generate array add change log entry for the tumor
        const tumorArrayAddChangeLogEntry: AuditLogEntry | null = this.getAuditLogDataChange(
          AuditLogAction.ARRAY_ADD,
          tumorFieldWithoutIndex,
          {},
          '',
          timestamp - 1
        )
        // Add the change log entry to the list updatedChangeLogEntries
        if (tumorArrayAddChangeLogEntry !== null) {
          updatedChangeLogEntries = [...updatedChangeLogEntries, tumorArrayAddChangeLogEntry]
        }
      }
    }

    return updatedChangeLogEntries
  }

  public getInitialTumorFieldMap(clinicCase: Record<string, unknown>): Map<string, Array<string>> {
    const tumorFieldMap: Map<string, Array<string>> = new Map<string, Array<string>>()
    const accruals: Array<never> = ObjectUtils.getAt(clinicCase, 'accruals') ?? []
    accruals.forEach((accrual: string, index: number) => {
      const tumorCount: number =
        ObjectUtils.getAt(clinicCase, `accruals[${index}].tumors`) !== undefined
          ? ObjectUtils.getAt(clinicCase, `accruals[${index}].tumors`).length
          : 0
      tumorFieldMap.set(
        `data.case.accruals[${index}]`,
        Array(tumorCount)
          .fill(`data.case.accruals[${index}]`)
          .map((accrualItem: string, indexValue: number) => `${accrualItem}.tumors[${indexValue}]`)
      )
    })
    return tumorFieldMap
  }

  private isTumorArrayAddEntryIncluded(
    tumorFieldMap: Map<string, Array<string>>,
    accuralFieldWithIndex: string,
    tumorFieldWithIndex: string
  ): boolean {
    const tumorFields: Array<string> = tumorFieldMap.has(accuralFieldWithIndex)
      ? (tumorFieldMap.get(accuralFieldWithIndex) as Array<string>)
      : []
    return tumorFields.find(tumorField => tumorField === tumorFieldWithIndex) !== undefined
  }

  private isAccuralArrayAddEntryIncluded(accrualFields: Array<string>, accuralFieldWithIndex: string): boolean {
    return accrualFields.find(accuralField => accuralField === accuralFieldWithIndex) !== undefined
  }

  // withIndex = true => data.case.accruals[0].tumors[0].classification.organ => data.case.accruals[0].tumors[0]
  // withIndex = false => data.case.accruals[0].tumors[0].classification.organ => data.case.accruals[0].tumors
  private getTumorField(field: string, withIndex: boolean): string {
    const tumorRegex: RegExp = withIndex ? this.tumorRegex : new RegExp(`${this.ACCRUALS_PATH}\\[\\d]\\.tumors`)
    const match: RegExpMatchArray | null = field.match(tumorRegex)
    return match !== null ? match[0] : ''
  }

  public getInitialAccrualFields(clinicCase: Record<string, unknown>): Array<string> {
    const accrualsCount: number =
      ObjectUtils.getAt(clinicCase, 'accruals') !== undefined ? ObjectUtils.getAt(clinicCase, 'accruals').length : 0
    return Array(accrualsCount)
      .fill('data.case.accruals')
      .map((accrual: string, index: number) => `${accrual}[${index}]`)
  }

  // data.case.accruals[0].tumors[0].classification.organ => data.case.accruals[0]
  // data.case.accruals[1].operable => data.case.accruals[1]
  private getAccrualFieldWithIndex(field: string): string {
    const accrualRegex = new RegExp(this.accrualRegex, 'gm')
    const match: RegExpMatchArray | null = field.match(accrualRegex)
    return match !== null ? match[0] : ''
  }
}

const caseChangeLogService = new CaseChangeLogService()
export default caseChangeLogService
