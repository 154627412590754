import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Table } from 'antd'
import { ColumnsType, FilterValue, SortOrder, TablePaginationConfig } from 'antd/es/table/interface'
import { PaginationProps } from 'antd/lib/pagination/Pagination'
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { isBefore } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import staticDocumentListService from '../../services/document-list/StaticDocumentList.service'
import tableFiltersService from '../../services/document-list/TableFilters.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import Status from '../../shared/interfaces/Status.interface'
import useClinicStore from '../../state/Clinic'
import ObjectUtils from '../../utils/Object'
import ContextMenu from './ContextMenu'
import DocumentValidationMessages from './DocumentValidationMessages'
import './DocumentList.scss'

interface Props {
  clinic: Clinic
  followUps: Array<FollowUp>
  onDocumentCountChange: (documentsCount: number) => void
  searchText: string
  onEditCaseNoClick: (followUpDocument: FollowUpDocument) => void
  onDocumentMarkCompleted: (documentToBeMarked: FollowUp, status: Status) => void
  onPushClick: (documentToBePushed: FollowUp) => void
  onDeleteClick: (documentToBeDeleted: FollowUp) => void
}

const FollowUpList: React.FC<Props> = (props): React.ReactElement => {
  const clinicStore = useClinicStore()
  const { t } = useTranslation()
  const {
    followUps,
    searchText,
    clinic,
    onDocumentCountChange,
    onEditCaseNoClick,
    onDocumentMarkCompleted,
    onPushClick,
    onDeleteClick,
  } = props
  const [followUpList, setFollowUps] = React.useState<Array<FollowUp>>(props.followUps)
  const currentPageNumber: number = clinicStore.currentFollowUpPageNumber
  const PAGE_SIZE = 100
  const statuses = followUpList.map(({ status }) => status)
  const authors = followUpList.map(({ owner }) => owner)
  const sortDirections = ['ascend', 'descend', 'ascend'] as Array<SortOrder>
  const defaultSortOrder = 'ascend' as SortOrder
  const defaultPaginationOptions: PaginationProps = {
    hideOnSinglePage: true,
    defaultCurrent: currentPageNumber,
    pageSize: PAGE_SIZE,
    showQuickJumper: true,
    onChange: async (pageNumber: number) => {
      const getPageNumber = clinicStore.setCurrentCasePageNumber(pageNumber)
      return getPageNumber
    },
  }

  React.useEffect(() => {
    if (searchText === '') {
      setFollowUps(followUps)
      onDocumentCountChange(followUps.length)
    } else {
      const filteredFollowUps = followUps.filter(followUp =>
        followUp.data.followUp.followUpNo.toLowerCase().includes(searchText.toLowerCase())
      )
      setFollowUps(filteredFollowUps)
      onDocumentCountChange(filteredFollowUps.length)
    }
  }, [searchText, followUps])

  const getFollowUpNo = (followUp: FollowUp): React.ReactElement => {
    const { data, uuid } = followUp
    return (
      <Link to={`/clinics/${clinic.id}/followUpEditor/${uuid}/followUpRegistration`}>
        {data.followUp.followUpNo || '<no follow-up number>'}
      </Link>
    )
  }

  const renderReviewerComment = (followUp: FollowUp): React.ReactElement | null => {
    const comment = ObjectUtils.getAt(followUp, 'data.followUp.reviewerComment')
    if (comment) {
      return (
        <div className="text-center">
          <Popover
            content={<span className="d-block">{comment}</span>}
            placement="bottomRight"
            trigger="click"
            showArrow={false}
          >
            <Button
              shape="round"
              icon={<FontAwesomeIcon icon={['fal', 'exclamation-triangle']} color="#FFCB0C" />}
              style={{ borderColor: '#FFCB0C' }}
            />
          </Popover>
        </div>
      )
    }
    return null
  }

  const columns: ColumnsType<FollowUp> = [
    {
      title: t('documentList.table.columns.followUpNo'),
      dataIndex: 'data.followUp.followUpNo',
      render: (text: string, followUp: FollowUp) =>
        staticDocumentListService.isRowDisabled(followUp.status)
          ? ObjectUtils.getAt(followUp, 'data.followUp.followUpNo')
          : getFollowUpNo(followUp),
      defaultSortOrder,
      sortDirections,
      sorter: (a: FollowUp, b: FollowUp) => {
        const followUpANo = Number(ObjectUtils.getAt(a, 'data.followUp.followUpNo') ?? '')
        const followUpBNo = Number(ObjectUtils.getAt(b, 'data.followUp.followUpNo') ?? '')
        if (Number.isFinite(followUpANo - followUpBNo)) {
          return followUpANo - followUpBNo
        }

        return Number.isFinite(followUpANo) ? -1 : 1
      },
    },
    {
      title: t('documentList.table.columns.caseNo'),
      dataIndex: 'data.followUp.caseNo',
      render: (text: string, followUp: FollowUp) => {
        const caseNo = ObjectUtils.getAt(followUp, 'data.followUp.caseNo')
        return caseNo && caseNo !== '' ? caseNo : '<no case number>'
      },
      sortDirections,
      sorter: (a: FollowUp, b: FollowUp) => {
        const caseANoWithPrefix = ObjectUtils.getAt(a, 'data.followUp.caseNo') ?? ''
        const caseBNoWithPrefix = ObjectUtils.getAt(b, 'data.followUp.caseNo') ?? ''
        const caseANo = Number(
          caseANoWithPrefix.replace(props.clinic?.prefix?.find(pre => caseANoWithPrefix.match(pre)) ?? '', '')
        )
        const caseBNo = Number(
          caseBNoWithPrefix.replace(props.clinic?.prefix?.find(pre => caseBNoWithPrefix.match(pre)) ?? '', '')
        )

        if (Number.isFinite(caseANo - caseBNo)) {
          return caseANo - caseBNo
        }

        return Number.isFinite(caseANo) ? -1 : 1
      },
    },
    {
      title: t('documentList.table.columns.dataCollectionDate'),
      dataIndex: 'data.followUp.followUpDate',
      render: (text: string, followUp: FollowUp) => ObjectUtils.getAt(followUp, 'data.followUp.followUpDate'),
      sortDirections,
      sorter: (a: FollowUp, b: FollowUp) =>
        isBefore(b.data.followUp.followUpDate ?? 0, a.data.followUp.followUpDate ?? 0) ? 1 : -1,
    },
    {
      title: t('documentList.table.columns.author'),
      dataIndex: 'owner',
      sortDirections,
      sorter: (a: FollowUp, b: FollowUp) => a.owner.localeCompare(b.owner),
      filters: tableFiltersService.getAuthorFilter(authors),
      onFilter: (value: React.Key | boolean, followUp: FollowUp) => followUp.owner.toLowerCase() === value,
    },
    {
      title: t('documentList.table.columns.status'),
      dataIndex: 'status',
      render: (text: string, followUp: FollowUp) => staticDocumentListService.getStatus(followUp.status, t),
      sortDirections,
      sorter: (a: FollowUp, b: FollowUp) => {
        const followUpAStatus = staticDocumentListService.getStatus(a.status, t)
        const followUpBStatus = staticDocumentListService.getStatus(b.status, t)
        return followUpAStatus.localeCompare(followUpBStatus)
      },
      filters: tableFiltersService.getStatusFilter(statuses, t),
      onFilter: (value: React.Key | number | boolean, followUp: FollowUp) => followUp.status === value,
    },
    {
      dataIndex: 'messages',
      render: (text: string, followUp: FollowUp) => (
        <div className="text-center">
          <DocumentValidationMessages messages={followUp.messages ?? []} />
        </div>
      ),
    },
    {
      dataIndex: 'reviewerComment',
      render: (text: string, followUp: FollowUp) => renderReviewerComment(followUp),
    },
    {
      render: (text: string, followUp: FollowUp) => (
        <div className="text-end">
          <ContextMenu
            clinic={props.clinic}
            validators={undefined}
            document={followUp}
            formType={IndiFormType.FOLLOWUP}
            onEditCaseNoClick={onEditCaseNoClick}
            onDocumentMarkCompleted={(document: Case | FollowUp, status: Status) =>
              onDocumentMarkCompleted(document as FollowUp, status)
            }
            onPushClick={(document: Case | FollowUp) => onPushClick(document as FollowUp)}
            onDeleteClick={(document: Case | FollowUp) => onDeleteClick(document as FollowUp)}
          />
        </div>
      ),
    },
  ]

  const renderCardBody = (): React.ReactElement => (
    <Table
      columns={columns}
      dataSource={followUpList}
      scroll={{ x: true }}
      locale={{ emptyText: 'No follow-up in inventory. Please create a new follow-up.' }}
      pagination={defaultPaginationOptions}
      rowKey={(followUp: FollowUp) => ObjectUtils.getAt(followUp, 'uuid')}
      rowClassName={(followUp: FollowUp) =>
        staticDocumentListService.isRowDisabled(followUp.status) ? 'disabled-row' : ''
      }
      onChange={(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<FollowUp> | Array<SorterResult<FollowUp>>,
        extra: TableCurrentDataSource<FollowUp>
      ) => props.onDocumentCountChange(extra.currentDataSource.length)}
    />
  )

  return renderCardBody()
}

export default FollowUpList
