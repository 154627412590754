import { cloneDeep } from 'lodash-es'
import { AuditLogEntry } from '../../../shared/interfaces/AuditLogEntry.interface'
import { CaseData } from '../../../shared/interfaces/CaseData.interface'
import { FollowUpData } from '../../../shared/interfaces/FollowUpData.interface'
import authService from '../../auth/Auth.service'
import remoteLoggingService from '../../logging-handler/RemoteLogging.service'
import AuditLogAction from './AuditLogAction.enum'

export default abstract class BaseChangeLogService {
  abstract getUpdatedChangeLogEntries: (
    dataObject: { data: CaseData | FollowUpData },
    changeLogEntry: AuditLogEntry
  ) => Array<AuditLogEntry>

  public getUpdatedChangeLog(
    dataObject: { data: CaseData | FollowUpData },
    action: AuditLogAction,
    field: string,
    value: unknown,
    comment = ''
  ): Array<AuditLogEntry> {
    const auditLogEntry: AuditLogEntry | null = this.getAuditLogDataChange(action, field, value, comment)
    if (auditLogEntry !== null) {
      let clonedChangeLog: Array<AuditLogEntry> = this.getUpdatedChangeLogEntries(dataObject, auditLogEntry)
      clonedChangeLog = [...this.checkLastEntryAndUpdateChangeLog(clonedChangeLog, auditLogEntry)]
      clonedChangeLog = [...clonedChangeLog, auditLogEntry]
      return clonedChangeLog
    }
    return dataObject.data.changeLog.entries
  }

  private checkLastEntryAndUpdateChangeLog(
    changeLog: Array<AuditLogEntry>,
    auditLogEntry: AuditLogEntry
  ): Array<AuditLogEntry> {
    if (changeLog.length === 0) {
      return changeLog
    }
    const clonedChangeLog: Array<AuditLogEntry> = cloneDeep(changeLog)
    const lastAuditLogEntry: AuditLogEntry = clonedChangeLog[clonedChangeLog.length - 1]
    const setAction: boolean = AuditLogAction.SET === auditLogEntry.action
    const sameUser: boolean = lastAuditLogEntry.username === auditLogEntry.username
    const sameField: boolean = lastAuditLogEntry.field === auditLogEntry.field
    const within30Seconds = lastAuditLogEntry.timestamp / 1000 + 30 >= auditLogEntry.timestamp / 1000
    if (setAction && sameUser && within30Seconds && sameField) {
      clonedChangeLog.pop()
    }
    return clonedChangeLog
  }

  public getAuditLogDataChange(
    action: AuditLogAction,
    field: string,
    value: unknown,
    comment = '',
    timestamp: number = new Date().getTime()
  ): AuditLogEntry | null {
    const userName: string | undefined = authService.getCurrentUsername()
    if (
      (!field.startsWith('data.') && field !== 'status') ||
      field.startsWith('data.changeLog') ||
      userName === undefined
    ) {
      remoteLoggingService.logError(`Audit log entry for field ${field} not allowed.`)
      return null
    }
    return {
      action,
      comment,
      field,
      timestamp,
      username: userName,
      value,
    }
  }
}
