import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import IndiformSidebar, { SelectedMenuItem } from '../../components/indiform-ui/IndiformSidebar'
import followUpStateService from '../../document-state-service/FollowUpState.service'
import followUpIndexDatabaseService from '../../services/browser-storage/index-database/FollowUpIndexDatabase.service'
import clinicsService from '../../services/clinics/Clinics.service'
import EditorType from '../../services/indiform/EditorType.enum'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import useClinicStore from '../../state/Clinic'
import useContextStore from '../../state/Context'
import ObjectUtils from '../../utils/Object'
import EditorLayout from './EditorLayout'

const FollowUpEditor = (): React.ReactElement => {
  const uuidRegex = /((\w{4,12}-?)){5}/
  const isContextInitialized = useContextStore(state => state.initialized)
  const clinicsFormMetaData = useClinicStore(state => state.followUpFormMetaData)
  const [formDocument, setFormDocument] = useState<FormDocument | undefined>()
  const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedMenuItem | undefined>(undefined)
  const [navBarText, setNavBarText] = useState('')
  const [followUpFormMetaData, setFollowUpFormMetaData] = useState<Partial<ClinicFormMetaData> | undefined>()
  const params = useParams()
  const navigate = useNavigate()
  const clinicId: number = clinicsService.getClinicIdFromMatchParams(params)
  const location = useLocation()

  const initializeFormDocument = async (): Promise<void> => {
    const formDocumentByUuid = await followUpIndexDatabaseService.getFormDocumentByUuid(params.formDocumentId)
    setFormDocument(formDocumentByUuid)
    const followUpNo: string = ObjectUtils.getAt({ data: formDocumentByUuid.document.data }, 'data.followUp.followUpNo')
    setNavBarText(`Follow-up: ${followUpNo}`)
  }

  const updateClinicsFormMetaData = (): void => {
    if (formDocument) {
      const documentClinicId = ObjectUtils.getAt({ data: formDocument.document.data }, 'data.followUp.hospital.enumId')
      if (documentClinicId !== clinicId) {
        navigate(`/clinics/${clinicId}/followUpEditor/${params.formDocumentId}/${selectedMenuItem}`)
      } else {
        const clinicFormMetaData = clinicsService.getClinicFormMetaData(clinicId, clinicsFormMetaData)
        setFollowUpFormMetaData(clinicFormMetaData)
      }
    }
  }

  const onMenuItemClick = (menuItem: SelectedMenuItem): void => {
    setSelectedMenuItem(menuItem)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const uuidPath = uuidRegex.exec(location.pathname)
      // // submittedFormDocument would be sent by followUpSummary to view submitted follow-up summary,
      // // formDocument would be populated by indexDB otherwise.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const formDocumentByUuid: FormDocument = await followUpIndexDatabaseService.getFormDocumentByUuid(uuidPath?.[0])
      await followUpStateService.loadRelatedCaseData(formDocumentByUuid)
      useContextStore.setState({
        formDocument: formDocumentByUuid,
        formType: IndiFormType.FOLLOWUP,
        updateData: followUpStateService.updateData,
        updateDataChangeLog: followUpStateService.updateDataChangeLog,
        updateDocumentStatus: followUpStateService.updateDocumentStatus,
        getDataPathValue: followUpStateService.getDataPathValue,
        initialized: true,
      })
      initializeFormDocument()
    }

    fetchData()
  }, [])

  useEffect(() => {
    updateClinicsFormMetaData()
  }, [clinicsFormMetaData, formDocument])

  return (
    <>
      {isContextInitialized && (
        <EditorLayout selectedMenuItem={selectedMenuItem} navBarText={navBarText}>
          <IndiformSidebar
            formMetaData={(followUpFormMetaData?.children as []) ?? []}
            onMenuItemClick={onMenuItemClick}
            selectedMenuItem={selectedMenuItem?.key}
            editorType={EditorType.FOLLOWUP}
          />
        </EditorLayout>
      )}
    </>
  )
}

export default FollowUpEditor
