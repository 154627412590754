import { AxiosResponse } from 'axios'
import { TFunction } from 'i18next'
import { createSelector } from 'reselect'
import { MultiEnumOption } from '../../components/indiform-ui/MultiEnum'
import { DropdownOption } from '../../components/ui/FormDropDown'
import { ListBoxOption } from '../../components/ui/FormListBox'
import { RadioOption } from '../../components/ui/FormRadioButton'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import {
  ChemicalFormMetaData,
  EnumFormMetaData,
  FormDataAgentGroup,
  FormEnumValue,
  GroupedEnumFormMetaData,
  HormoneFormMetaData,
  IcdCodesFormMetaData,
  IcdCodeValue,
  Medication,
  MedicationFormMetaData,
  OtherFormMetaData,
} from '../../shared/interfaces/IndiformValues.interface'
import { IndiFormStoreState } from '../../state/IndiForm'
import ArrayUtils from '../../utils/Array'
import ValidationType from '../indiform-validation/ValidationType.enum'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import indiFormResourceService from '../rest-resources/IndiFormResource.service'

export enum ChemoOptions {
  GROUPS = 'groups',
  SCHEMAS = 'schemas',
}

export enum TherapyType {
  HORMONE = 'hormone',
  OTHER = 'other',
}

class IndiFormService {
  private readonly getIcdCodes = createSelector(
    (state: IndiFormStoreState) => state.icdCodesFormMetaData,
    (icdData: IcdCodesFormMetaData | null) => (icdData !== null ? icdData.icdCodes : [])
  )

  private readonly getEnumValue = createSelector(
    (state: IndiFormStoreState) => state.enumFormMetaData,
    (state: IndiFormStoreState, enumType: string | null) => enumType,
    (enumFormMetaData, enumType) =>
      enumFormMetaData !== null && enumType !== null && Object.prototype.hasOwnProperty.call(enumFormMetaData, enumType)
        ? (enumFormMetaData as EnumFormMetaData)[enumType]
        : []
  )

  public getGroupedEnumValue = createSelector(
    (state: IndiFormStoreState) => state.enumFormMetaData,
    (state: IndiFormStoreState, enumType: string) => enumType,
    (enumFormMetaData, enumType) =>
      enumFormMetaData !== null && Object.prototype.hasOwnProperty.call(enumFormMetaData, enumType)
        ? (enumFormMetaData as GroupedEnumFormMetaData)[enumType]
        : []
  )

  public async getEnumFormMetaData(): Promise<EnumFormMetaData | GroupedEnumFormMetaData | null> {
    let response: EnumFormMetaData | null = null
    try {
      const axiosResponse: AxiosResponse = await indiFormResourceService.fetchEnumFormMetaData()
      response = axiosResponse.data as EnumFormMetaData
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch enum due to ${error}`)
    }
    return response
  }

  public async getIcdCodesFormMetaData(): Promise<IcdCodesFormMetaData | null> {
    let response: IcdCodesFormMetaData | null = null
    try {
      const axiosResponse: AxiosResponse = await indiFormResourceService.fetchIcdCodesFormMetaData()
      response = axiosResponse.data as IcdCodesFormMetaData
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch icd codes due to ${error}`)
    }
    return response
  }

  public async getChemicalFormMetaData(): Promise<ChemicalFormMetaData | null> {
    let response: ChemicalFormMetaData | null = null
    try {
      const axiosResponse: AxiosResponse = await indiFormResourceService.fetchChemicalFormMetaData()
      response = axiosResponse.data as ChemicalFormMetaData
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch the chemical form meta data due to ${error}`)
    }
    return response
  }

  public async getHormoneFormMetaData(): Promise<HormoneFormMetaData | null> {
    let response: HormoneFormMetaData | null = null
    try {
      const axiosResponse: AxiosResponse = await indiFormResourceService.fetchHormoneFormMetaData()
      response = axiosResponse.data as HormoneFormMetaData
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch the hormone form meta data due to ${error}`)
    }
    return response
  }

  public async getOtherFormMetaData(): Promise<OtherFormMetaData | null> {
    let response: OtherFormMetaData | null = null
    try {
      const axiosResponse: AxiosResponse = await indiFormResourceService.fetchOtherFormMetaData()
      response = axiosResponse.data as OtherFormMetaData
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch the other form meta data due to ${error}`)
    }
    return response
  }

  public async getFormMetaDataMedications(countries: Array<string>): Promise<Array<MedicationFormMetaData> | null> {
    let response: Array<MedicationFormMetaData> | null = null
    try {
      const axiosResponse: Array<AxiosResponse<Array<Medication>>> =
        await indiFormResourceService.fetchFormMetaDataMedications(countries)
      response = axiosResponse.map(res => {
        const { country = null } = res.config.params
        return {
          country,
          medications: res.data,
        }
      })
    } catch (error) {
      await remoteLoggingService.logError(`Failed to fetch the form meta data medications due to ${error}`)
    }
    return response
  }

  public getEnumDropDownOptions(
    state: IndiFormStoreState,
    enumType: string | undefined | null,
    t: TFunction
  ): Array<DropdownOption> {
    if (enumType !== undefined) {
      const formMetaDataEnumValue = this.getEnumValue(state, enumType)
      return this.buildEnumDropDownOptions(formMetaDataEnumValue, enumType, t)
    }
    return []
  }

  private buildEnumDropDownOptions(
    formEnumValue: Array<FormEnumValue>,
    enumType: string | null,
    t: TFunction
  ): Array<DropdownOption> {
    if (formEnumValue.length !== 0 && enumType !== null) {
      const dropDownOptions: Array<DropdownOption> = formEnumValue.map(enumValue => ({
        displayLabel: t(`enums.${enumType}.values.${enumValue.id}`) ?? enumValue.text,
        label: enumValue.text,
        value: enumValue.id,
      }))
      return ArrayUtils.sortArrayOfObjects(dropDownOptions, 'label')
    }
    return []
  }

  public getMultiEnumOptions(
    state: IndiFormStoreState,
    enumType: string | undefined,
    t: TFunction
  ): Array<MultiEnumOption> {
    if (enumType !== undefined) {
      const formMetaDataEnumValue = this.getEnumValue(state, enumType)
      return this.buildMultiEnumOptions(formMetaDataEnumValue, enumType, t)
    }
    return []
  }

  private buildMultiEnumOptions(
    formEnumValue: Array<FormEnumValue>,
    enumType: string,
    t: TFunction
  ): Array<MultiEnumOption> {
    if (formEnumValue.length !== 0) {
      const multiEnumOptions: Array<MultiEnumOption> = formEnumValue.map(enumValue => ({
        displayLabel: t(`enums.${enumType}.values.${enumValue.id}`) ?? enumValue.text,
        text: enumValue.text,
        value: enumValue.id,
      }))
      return ArrayUtils.sortArrayOfObjects(multiEnumOptions, 'label')
    }
    return []
  }

  public getEnumRadioOptions(state: IndiFormStoreState, enumType: string, t: TFunction): Array<RadioOption> {
    const formMetaDataEnumValue = this.getEnumValue(state, enumType)
    return this.buildEnumRadioOptions(formMetaDataEnumValue, enumType, t)
  }

  private buildEnumRadioOptions(
    formEnumValue: Array<FormEnumValue>,
    enumType: string,
    t: TFunction
  ): Array<RadioOption> {
    if (formEnumValue.length !== 0) {
      const radioOptions: Array<RadioOption> = formEnumValue.map(enumValue => ({
        displayLabel: t(`enums.${enumType}.values.${enumValue.id}`) || enumValue.text,
        id: enumValue.id,
        text: enumValue.text,
        value: enumValue.id,
      }))
      return ArrayUtils.sortArrayOfObjects(radioOptions, 'text')
    }
    return []
  }

  public getIcdOptions(state: IndiFormStoreState): Array<ListBoxOption> {
    const icdCodes: Array<IcdCodeValue> = this.getIcdCodes(state)
    return icdCodes.map(icdCode => ({
      label: `${icdCode.icdCode} ${icdCode.description}`,
      value: icdCode.icdCode,
    }))
  }

  public getMedicationOptions(
    medications: Record<string, Array<Medication>> | null,
    countryCode: string
  ): Array<ListBoxOption> {
    if (medications !== null && Object.prototype.hasOwnProperty.call(medications, countryCode)) {
      return medications[countryCode].map(medication => ({
        label: medication.name,
        value: medication.code,
      }))
    }
    return []
  }

  public getGroupOptions(
    metaData: HormoneFormMetaData | OtherFormMetaData,
    therapyType: TherapyType
  ): Array<DropdownOption> {
    switch (therapyType) {
      case TherapyType.HORMONE:
        return (metaData as HormoneFormMetaData).therapyAgentGroup.map(hormoneTherapy => ({
          displayLabel: hormoneTherapy.text,
          label: hormoneTherapy.text,
          value: hormoneTherapy.id,
        }))
      case TherapyType.OTHER:
        return (metaData as OtherFormMetaData).otherTherapies.map(otherTherapy => ({
          displayLabel: otherTherapy.text,
          label: otherTherapy.text,
          value: otherTherapy.id,
        }))
      default:
        return []
    }
  }

  public getGroupAgents(
    metaData: HormoneFormMetaData | OtherFormMetaData | Array<FormDataAgentGroup>,
    type: TherapyType | ChemoOptions,
    groupId: number
  ): Array<DropdownOption> {
    switch (type) {
      case TherapyType.HORMONE: {
        const selectedHormoneGroup: FormDataAgentGroup | undefined = (
          metaData as HormoneFormMetaData
        ).therapyAgentGroup.find(groupValue => groupValue.id === groupId)
        return this.mapAgentGroup(selectedHormoneGroup)
      }
      case TherapyType.OTHER: {
        const selectedOtherGroup: FormDataAgentGroup | undefined = (metaData as OtherFormMetaData).otherTherapies.find(
          groupValue => groupValue.id === groupId
        )
        return this.mapAgentGroup(selectedOtherGroup)
      }
      case ChemoOptions.GROUPS: {
        const selectedGroup: FormDataAgentGroup | undefined = (metaData as Array<FormDataAgentGroup>).find(
          groupValue => groupValue.id === groupId
        )
        return this.mapAgentGroup(selectedGroup)
      }
      default:
        return []
    }
  }

  private mapAgentGroup(agentGroup: FormDataAgentGroup | undefined): Array<DropdownOption> {
    if (agentGroup !== undefined) {
      return agentGroup.agents.map(groupValue => ({
        displayLabel: groupValue.text,
        label: groupValue.text,
        value: groupValue.id,
      }))
    }
    return []
  }

  public getEnumValueObject(enumId: number, enumText: string): EnumValue {
    return { enumId, enumText }
  }

  public getBooleanDropDownOptions(t: TFunction): Array<DropdownOption> {
    return [
      {
        displayLabel: t('form.labels.yes'),
        label: 'yes',
        value: 'y',
      },
      {
        displayLabel: t('form.labels.no'),
        label: 'no',
        value: 'n',
      },
      {
        displayLabel: t('form.labels.unknown'),
        label: 'unknown',
        value: 'u',
      },
    ]
  }

  public getFormInputCSSClasses(
    validators: Record<ValidationType, unknown> | null,
    isValid: boolean,
    disabled: boolean,
    fullWidth: boolean
  ): string {
    let cssClasses = ''
    if (validators !== null) {
      const validationClass: string = isValid ? 'p-success' : 'p-error'
      cssClasses = cssClasses.concat(validationClass)
    }
    if (disabled) {
      cssClasses = cssClasses.concat(' form-element-disabled')
    }
    if (fullWidth) {
      cssClasses = cssClasses.concat(' w-100')
    }
    return cssClasses
  }
}

const indiFormService = new IndiFormService()
export default indiFormService
