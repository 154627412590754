/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import { FollowUpData } from '../../../shared/interfaces/FollowUpData.interface'
import ObjectUtils from '../../../utils/Object'
import CalculatedField from './CalculatedField.enum'
import TranslatedField from './TranslatedField.enum'

class Rules {
  private deriveBmiRule(documentData: any, basePath: string): string | undefined {
    const bodyHeight: string | undefined = ObjectUtils.getAt(documentData, `${basePath}.bodyHeight`)
    const bodyWeight: string | undefined = ObjectUtils.getAt(documentData, `${basePath}.weight`)
    return this.calcBmi(bodyHeight, bodyWeight)
  }

  private calcBmi(bodyHeight: string | undefined, bodyWeight: string | undefined): string | undefined {
    try {
      const height: number = parseInt(bodyHeight !== undefined ? bodyHeight : '', 10)
      const weight: number = parseInt(bodyWeight !== undefined ? bodyWeight : '', 10)
      const bmi: number = weight / ((height / 100) * (height / 100))

      if (!bmi || Number.isNaN(bmi) || bmi <= 0) {
        return undefined
      }

      return bmi.toFixed(1)
    } catch (e) {
      return undefined
    }
  }

  public getBmiValue(documentData: any, basePath: string): string | undefined {
    return this.deriveBmiRule(documentData, basePath)
  }

  private deriveBsaRule(documentData: any, basePath: string): string | undefined {
    const bodyHeight: string | undefined = ObjectUtils.getAt(documentData, `${basePath}.bodyHeight`)
    const bodyWeight: string | undefined = ObjectUtils.getAt(documentData, `${basePath}.weight`)
    return this.calcBsa(bodyHeight, bodyWeight)
  }

  private calcBsa(bodyHeight: string | undefined, bodyWeight: string | undefined): string | undefined {
    try {
      const height: number = parseInt(bodyHeight !== undefined ? bodyHeight : '', 10)
      const weight: number = parseInt(bodyWeight !== undefined ? bodyWeight : '', 10)
      const bsa: number = Math.sqrt(weight * height) * (167.2 / 10000)

      if (!bsa || Number.isNaN(bsa) || bsa <= 0) {
        return undefined
      }

      return bsa.toFixed(3)
    } catch (e) {
      return undefined
    }
  }

  public getBsaValue(documentData: any, basePath: string): string | undefined {
    return this.deriveBsaRule(documentData, basePath)
  }

  public applyFollowUpNoRules(dataObject: { data: FollowUpData }, path: string): string | null {
    const caseNoPath = 'data.followUp.caseNo'
    const followUpVisitPath = 'data.followUp.followUpVisit'
    const followUpNoPath = 'data.followUp.followUpNo'
    const oldFollowUpNo: string = ObjectUtils.getAt(dataObject, followUpNoPath)
    // only change followup no if the number was not already set using the old pattern
    // Because otherwise if we would change the followupNo a new entry in the indivunet db
    // would be generated leading to a duplicate followup
    if (followUpVisitPath === path && !oldFollowUpNo.match(/.*FUP-\d{4}-\d{1,2}$/)) {
      const caseNo: string = ObjectUtils.getAt(dataObject, caseNoPath)
      const followUpVisit: string | undefined = ObjectUtils.getAt(dataObject, followUpVisitPath)
      return followUpVisit ? `${caseNo}-FUP-${followUpVisit}` : `${caseNo}-FUP`
    }
    return null
  }

  public getColdIschemiaTime(
    dataObject: any,
    basePath: string,
    fieldPath: string,
    calculationField: string,
    resectionTimeField: string
  ): number {
    const time: string | undefined = ObjectUtils.getAt(
      dataObject,
      fieldPath.includes(resectionTimeField) ? `${basePath}.${calculationField}` : fieldPath
    )
    const resectionCompleted: string | undefined = ObjectUtils.getAt(dataObject, `${basePath}.${resectionTimeField}`)
    return this.calculateColdIschemiaTime(time, resectionCompleted)
  }

  private calculateColdIschemiaTime(time: string | undefined, resectionCompleted: string | undefined): number {
    return dayjs(time, 'HH:mm').diff(dayjs(resectionCompleted, 'HH:mm'), 'minute')
  }

  public getColdIschemiaTimeFieldPath(path: string): string | undefined {
    const lookup: Map<CalculatedField, TranslatedField> = new Map([
      [CalculatedField.METASTASIS_PREPARATION_FREEZE_TIME, TranslatedField.METASTASIS_CIT_PREPARATION_FREEZE_TIME],
      [CalculatedField.METASTASIS_PREPARATION_FORMALIN_TIME, TranslatedField.METASTASIS_CIT_PREPARATION_FORMALIN_TIME],
      [CalculatedField.PREPARATION_FREEZE_TIME, TranslatedField.PREPARATION_CIT_FREEZE_TIME],
      [CalculatedField.PREPARATION_FORMALIN_TIME, TranslatedField.PREPARATION_CIT_FORMALIN_TIME],
    ])

    return lookup.get(path as CalculatedField)
  }
}

const rules = new Rules()
export default rules
