import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import IndiformSidebar, { SelectedMenuItem } from '../../components/indiform-ui/IndiformSidebar'
import caseStateService from '../../document-state-service/CaseState.service'
import caseIndexDatabaseService from '../../services/browser-storage/index-database/CaseIndexDatabase.service'
import clinicsService from '../../services/clinics/Clinics.service'
import EditorType from '../../services/indiform/EditorType.enum'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import useClinicStore from '../../state/Clinic'
import useContextStore from '../../state/Context'
import ObjectUtils from '../../utils/Object'
import EditorLayout from './EditorLayout'

const CaseEditor = (): React.ReactElement => {
  const uuidRegex = /((\w{4,12}-?)){5}/
  const clinicsFormMetaData = useClinicStore(state => state.clinicsFormMetaData)
  const isContextInitialized = useContextStore(state => state.initialized)
  const [formDocument, setFormDocument] = useState<FormDocument | undefined>()
  const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedMenuItem | undefined>(undefined)
  const [clinicFormMetaData, setClinicFormMetaData] = useState<Partial<ClinicFormMetaData> | undefined>()
  const [navBarText, setNavBarText] = useState('')

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const clinicId: number = clinicsService.getClinicIdFromMatchParams(params)

  const initializeFormDocument = async (): Promise<void> => {
    const formDocumentById = await caseIndexDatabaseService.getFormDocumentByUuid(params.formDocumentId)
    setFormDocument(formDocumentById)
    const caseNo: string = ObjectUtils.getAt({ data: formDocumentById?.document.data }, 'data.case.caseNo')
    setNavBarText(`Case: ${caseNo}`)
  }

  const updateClinicsFormMetaData = (): void => {
    if (formDocument) {
      const documentClinicId = ObjectUtils.getAt({ data: formDocument.document.data }, 'data.case.hospital.enumId')
      if (documentClinicId !== clinicId) {
        navigate(`/clinics/${clinicId}/caseEditor/${params.formDocumentId}/${selectedMenuItem}`)
      } else {
        const clinicMetaData = clinicsService.getClinicFormMetaData(clinicId, clinicsFormMetaData)
        setClinicFormMetaData(clinicMetaData)
      }
    }
  }

  const onMenuItemClick = (menuItem: SelectedMenuItem): void => {
    setSelectedMenuItem(menuItem)
  }

  useEffect(() => {
    if (location.pathname) {
      const fetchData = async (): Promise<void> => {
        const uuidPath = uuidRegex.exec(location.pathname)
        // submittedFormDocument would be sent by caseSummary to view submitted case summary,
        // formDocument would be populated by indexDB otherwise.
        const formDocumentByUuid: FormDocument = await caseIndexDatabaseService.getFormDocumentByUuid(uuidPath?.[0])
        useContextStore.setState({
          formDocument: formDocumentByUuid,
          formType: IndiFormType.CASE,
          updateData: caseStateService.updateData,
          updateDataChangeLog: caseStateService.updateDataChangeLog,
          updateDocumentStatus: caseStateService.updateDocumentStatus,
          getDataPathValue: caseStateService.getDataPathValue,
          initialized: true,
        })

        await initializeFormDocument()
      }
      fetchData()
    }
  }, [location.pathname])

  useEffect(() => {
    updateClinicsFormMetaData()
  }, [clinicsFormMetaData, formDocument])

  return (
    <>
      {isContextInitialized && (
        <EditorLayout selectedMenuItem={selectedMenuItem} navBarText={navBarText}>
          <IndiformSidebar
            formMetaData={(clinicFormMetaData?.children as []) ?? []}
            onMenuItemClick={onMenuItemClick}
            selectedMenuItem={selectedMenuItem?.key}
            editorType={EditorType.CASE}
          />
        </EditorLayout>
      )}
    </>
  )
}

export default CaseEditor
