import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { getDefaultBaseUrl } from './utils/WebApi'

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    preload: ['de', 'en', 'es', 'it', 'pt', 'jp'],
    maxRetries: 0,
    fallbackLng: 'en',
    lng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {},
    backend: {
      loadPath: `${getDefaultBaseUrl()}/form/meta/translation/{{lng}}`,
    },
  })

export default i18n
