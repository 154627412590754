import Dexie from 'dexie'

export const DATABASE_NAME = 'indivupad'

export abstract class BaseIndexDatabaseService extends Dexie {
  protected constructor() {
    super(DATABASE_NAME)
    this.version(4).stores({
      caseDocumentRecords: 'document.uuid, document.lastModifiedAt',
    })
    this.version(5).stores({
      followUpDocumentRecords: 'document.uuid, document.lastModifiedAt',
    })
    this.version(6).stores({
      fileDocumentRecords: 'document.uid, document.lastModified, [document.filePath+document.status]',
    })
    this.version(7).stores({
      logRecords: 'timestamp',
    })
  }
}
