/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Card } from 'antd'
import { flatten, intersection } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'
import sharedStateHelper from '../../document-state-service/SharedStateHelper.service'
import filesService from '../../services/files/Files.service'
import evaluationService from '../../services/indiform/Evaluation.service'
import { FormElementType } from '../../services/indiform/FormElementType.enum'
import { FormType } from '../../services/indiform/FormType.enum'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import useContextStore from '../../state/Context'
import ObjectUtils from '../../utils/Object'
import { MultiEnumOption } from '../indiform-ui/MultiEnum'
import './SummaryTable.scss'

interface Props {
  formMetaData: Array<Record<string, never>>
}

const SummaryTable = (props: Props): React.ReactElement | null => {
  const formDocument = useContextStore(state => state.formDocument)
  const { t } = useTranslation()
  const { formMetaData } = props
  const [showJson, setShowJson] = React.useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderSummaryBasedOnType = (child: any, path = ''): React.ReactElement | null => {
    const {
      id = '',
      variable = '',
      type = '',
      children = [],
      active = true,
      hideIf = '',
      showIf = '',
      noHeader = false,
      options = [],
    } = child
    const metaDataPath: string = sharedStateHelper.buildMetaDataPath(path, variable)
    if (!evaluationService.evaluateExpression(hideIf, showIf, formDocument, metaDataPath)) {
      return null
    }
    switch (type) {
      case FormType.COMPOSITE:
      case FormType.FIELD_GROUP:
        return renderCompositeTypeSummary(id, children, metaDataPath, noHeader)
      case FormType.LIST:
        return renderListTypeSummary(id, metaDataPath, children)
      default:
        if (children.length === 0) {
          return renderSummaryField(id, metaDataPath, type, active, options)
        }
        return renderSummaryGroup(id, metaDataPath, children)
    }
  }

  const renderSummaryField = (
    id: string,
    path: string,
    type: FormElementType,
    active: boolean,
    options: Array<Record<string, unknown>> = []
  ): React.ReactElement | null => {
    const caseDataValue = useContextStore.getState().getDataPathValue(path)
    switch (type) {
      case FormElementType.CHEMO:
        return renderChemoSummary(caseDataValue)
      case FormElementType.HORMONE:
      case FormElementType.OTHER:
        return renderMultiFieldSummary(caseDataValue, type)
      case FormElementType.FILE:
        return getFileFieldValue(caseDataValue)
      default:
        return active ? (
          <div>
            {id !== '' && <strong>{t(`form.labels.${id}`)}: </strong>}
            {getSummaryFieldValue(caseDataValue, type, options, path)}
          </div>
        ) : null
    }
  }

  const renderSummaryGroup = (id: string, path: string, children: Array<Record<string, never>>): React.ReactElement => (
    <div className="pt-2">
      <div className="mb-2">
        <h4>{t(`form.labels.${id}`)}</h4>
      </div>
      {children.map((child: Record<string, never>, index: number) => (
        <React.Fragment key={index}>{renderSummaryBasedOnType(child, path)}</React.Fragment>
      ))}
    </div>
  )

  const renderListTypeSummary = (
    id: string,
    variable: string,
    children: Array<Record<string, never>>
  ): React.ReactElement | null => {
    const listValue: Array<Record<string, never>> | undefined = useContextStore.getState().getDataPathValue(variable)
    if (listValue === undefined) {
      return null
    }
    const listChildren: Array<Record<string, never>> = flatten(listValue.map(value => children))
    return (
      <>
        {listChildren.map((child: Record<string, never>, index: number) => {
          const caseDataPath: string = sharedStateHelper.buildDataPath(variable, index)
          return <React.Fragment key={index}>{renderSummaryBasedOnType(child, caseDataPath)}</React.Fragment>
        })}
      </>
    )
  }

  const renderSummary = (paramChildren: Array<Record<string, never>>): Array<React.ReactElement | null> =>
    paramChildren.map((child: Record<string, never>, index: number) => {
      const { id = '', variable = '', children = [], active = true, hideIf = '', showIf = '', type } = child
      if (!evaluationService.evaluateExpression(hideIf, showIf, formDocument)) {
        return null
      }
      let summaryContent: React.ReactElement | null = null
      if (type === FormType.LIST) {
        summaryContent = renderListTypeSummary(id, variable, children)
      } else {
        summaryContent =
          children.length === 0
            ? renderSummaryField(id, variable, type, active)
            : renderSummaryGroup(id, variable, children)
      }
      return <React.Fragment key={index}>{summaryContent}</React.Fragment>
    })

  const renderSummaryTable = (): React.ReactElement => (
    <table className="table table-bordered table-condensed">
      <tbody>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formMetaData.map((metaData: any, index: number) => {
            const { id = '', children = [] } = metaData
            // Ignore reviewerData
            if (id === 'reviewerData') {
              return null
            }
            return (
              <tr key={index} className="summary-table-row">
                <th>{t(`form.labels.${id}`)}</th>
                <td className="summary-table-cell">{renderSummary(children)}</td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )

  const renderCompositeTypeSummary = (
    id: string,
    children: Array<Record<string, never>>,
    path: string,
    noHeader = false
  ): React.ReactElement => (
    <div className="summary-composite-type">
      <div className="mb-2">
        {!noHeader && <h4 className="summary-composite-type-header">{t(`form.labels.${id}`)}</h4>}
      </div>
      {children.map((child: Record<string, never>, index: number) => (
        <React.Fragment key={index}>{renderSummaryBasedOnType(child, path)}</React.Fragment>
      ))}
    </div>
  )

  const renderChemoSummary = (value: Record<string, never>): React.ReactElement => {
    const { group, agents = [], isSchema } = value
    return (
      <>
        <div>
          <strong>{t('form.labels.chemo_agent_group')}: </strong>
          {getSummaryFieldValue(group, FormElementType.CHEMO, [])}
        </div>
        <div>
          <strong>isSchema: </strong>
          {isSchema}
        </div>
        {(agents ?? []).map((agent: Record<string, never>, index: number) => (
          <div key={index} className="summary-chemo-agents">
            <div>
              <strong>{t('form.labels.chemo_type')}: </strong>
              {getSummaryFieldValue(agent.type, FormElementType.CHEMO, [])}
            </div>
            <div>
              <strong>{t('form.labels.chemo_therapy_cycle')}: </strong>
              {agent.cycle}
            </div>
            <div>
              <strong>{t('form.labels.chemo_therapy_from')}: </strong>
              {agent.fromDate}
            </div>
            <div>
              <strong>{t('form.labels.chemo_therapy_to')}: </strong>
              {agent.toDate}
            </div>
          </div>
        ))}
      </>
    )
  }

  /**
   * This function renders two fields (Agent & Agent Group) for the type hormone and other therapy
   */
  const renderMultiFieldSummary = (value: Record<string, never>, type: FormElementType): React.ReactElement => {
    const { agentGroup, agent } = value
    return (
      <>
        <div>
          <strong>{t('form.labels.agent')}: </strong>
          {getSummaryFieldValue(agent, type, [])}
        </div>
        <div>
          <strong>{t('form.labels.agent_group')}: </strong>
          {getSummaryFieldValue(agentGroup, type, [])}
        </div>
      </>
    )
  }

  const getFileFieldValue = (value: string): React.ReactElement => (
    <div>
      <strong>{t('form.labels.file')}: </strong>
      {value ? (
        <a
          role="link"
          onClick={() => filesService.onFileClick(value, t)}
          onKeyDown={() => filesService.onFileClick(value, t)}
          tabIndex={0}
        >
          {value}
        </a>
      ) : (
        <p>{value}</p>
      )}
    </div>
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSummaryFieldValue = (value: any, type: FormElementType, options: Array<any>, path?: string): string => {
    if (value === undefined && type !== FormElementType.RELATED_DATA) {
      return ''
    }
    switch (type) {
      case FormElementType.BOOLEAN:
      case FormElementType.COMMENT:
      case FormElementType.DATE:
      case FormElementType.DAY:
      case FormElementType.DECIMAL:
      case FormElementType.ICD:
      case FormElementType.INTEGER:
      case FormElementType.MONTH:
      case FormElementType.OUTPUT:
      case FormElementType.STRING:
      case FormElementType.TIME:
      case FormElementType.YEAR:
        return value
      case FormElementType.RADIO_BOX:
      case FormElementType.ENUM:
        return getSummaryFieldValueBasedOnType(value)
      case FormElementType.CHEMO:
      case FormElementType.HORMONE:
      case FormElementType.OTHER:
      case FormElementType.MEDICATION:
      case FormElementType.GROUPED_ENUM:
        return getEnumValue(value)
      case FormElementType.SAMPLE_COLLECTION:
      case FormElementType.MULTI_ENUM:
      case FormElementType.SYMPTOM:
        if (Array.isArray(value)) {
          // Intersection used to find the solid sample values from the options meta data
          let valueFromParams = value
          valueFromParams =
            options.length > 0
              ? intersection(
                  valueFromParams,
                  (options as Array<MultiEnumOption>).map(option => option.value)
                )
              : valueFromParams
          return valueFromParams.map((val: string | EnumValue) => getSummaryFieldValueBasedOnType(val)).join(', ')
        }
        return ''
      case FormElementType.RELATED_DATA:
        return path ? ObjectUtils.getAt(useContextStore.getState().relatedData, path) : ''
      case FormElementType.CLEAR_FIX:
      case FormElementType.PLACEHOLDER:
      default:
        return ''
    }
  }

  const getSummaryFieldValueBasedOnType = (value: string | EnumValue): string =>
    typeof value === 'string' ? value : getEnumValue(value)

  const getEnumValue = (value: EnumValue): string => value?.enumText

  const renderShowJsonButton = (): React.ReactElement => {
    const buttonText: string = showJson ? 'Hide raw data (json)' : 'Show raw data (json)'
    return <Button onClick={() => setShowJson(!showJson)}>{buttonText}</Button>
  }

  const renderJson = (): React.ReactElement => <pre>{JSON.stringify(formDocument, null, '\t')}</pre>

  if (formDocument !== undefined) {
    return (
      <Card>
        <div>{renderSummaryTable()}</div>
        <div className="text-end">{renderShowJsonButton()}</div>
        {showJson && renderJson()}
      </Card>
    )
  }
  return null
}

export default SummaryTable
