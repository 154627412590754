enum Status {
  'IN_PROCESS' = 'IN_PROCESS',
  'PUSH_REQUESTED_BY_USER' = 'PUSH_REQUESTED_BY_USER',
  'READY_FOR_REVIEW' = 'READY_FOR_REVIEW',
  'PULLED_FOR_REVIEW' = 'PULLED_FOR_REVIEW',
  'IN_REVIEW' = 'IN_REVIEW',
  'DELETED_BY_CLIENT' = 'DELETED_BY_CLIENT',
  'SUBMITTED' = 'SUBMITTED',
  'SUBMIT_FAILED' = 'SUBMIT_FAILED',
  'QC_PASSED' = 'QC_PASSED',
  'QC_PASSED_REQUEST_BY_REVIEWER' = 'QC_PASSED_REQUEST_BY_REVIEWER',
  'QC_FAILED' = 'QC_FAILED',
  'DATA_COMPLETED' = 'DATA_COMPLETED',
  'REVIEW_COMPLETED' = 'REVIEW_COMPLETED',
  'MISSING_DATA' = 'MISSING_DATA',
}

export default Status
