import { Row } from 'antd'
import React from 'react'
import formRenderService from '../../services/indiform/FormRender.service'

interface Props {
  formMetaData: Array<Record<string, never>>
  metaDataPath: string
}

const IndiForm = (props: Props): React.ReactElement => {
  const { formMetaData, metaDataPath } = props

  if (formMetaData.length === 0) {
    return <div>Failed to render the form. Please refresh the page and try again.</div>
  }
  return (
    <Row gutter={16} className="m-2">
      {formMetaData.map(metaData => formRenderService.renderFormBasedOnType(metaData, metaDataPath))}
    </Row>
  )
}

export default IndiForm
