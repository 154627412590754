import Dexie from 'dexie'
import { Log } from '../../../shared/interfaces/Log.interface'
import { BaseIndexDatabaseService } from './BaseIndexDatabase.service'

class LogIndexDatabaseService extends BaseIndexDatabaseService {
  private readonly LOG_RECORDS_SYNC_LIMIT = 20
  public readonly LOG_RECORDS_TABLE_NAME = 'logRecords'
  public logRecords: Dexie.Table<Log, number>
  public db: LogIndexDatabaseService

  constructor() {
    super()
    this.db = this
    this.logRecords = this.table(this.LOG_RECORDS_TABLE_NAME)
    this.open()
  }

  public putLogEntry(log: Log): Promise<number> {
    return this.db.logRecords.put(log)
  }

  public deleteLogEntry = (timestamp: number): Promise<number> => this.db.logRecords.where({ timestamp }).delete()

  public async getLogEntriesForSync(): Promise<Array<Log>> {
    return this.db.logRecords.limit(this.LOG_RECORDS_SYNC_LIMIT).toArray()
  }

  public async getLogsForComparison(): Promise<Array<Log>> {
    return this.db.logRecords.orderBy('timestamp').toArray()
  }
}

const logIndexDatabaseService = new LogIndexDatabaseService()
export default logIndexDatabaseService
