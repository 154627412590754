import { AxiosResponse } from 'axios'
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode'
import i18n from '../../i18n'
import cryptoEncoder from '../../utils/CryptoEncoder'
import storageService from '../browser-storage/Storage.service'
import StoreValueType from '../browser-storage/StoreValueType.enum'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import authResourceService from '../rest-resources/AuthResource.service'

export interface AuthTokenObject {
  accessToken: string
}

export interface JwtTokenProperties {
  authorities: Array<string>
  // eslint-disable-next-line camelcase
  client_id: string
  exp: number
  jti: string
  grants: Array<string>
  organizations: Array<string>
  userName: string
  language: string
  internalUser: boolean
}

class AuthService {
  public async login(userName: string, password: string): Promise<AuthTokenObject | null> {
    let response: AuthTokenObject | null = null
    try {
      const axiosResponse: AxiosResponse = await authResourceService.login(userName, password)
      response = axiosResponse.data as AuthTokenObject
      this.authToken = response.accessToken
      this.setAuthCredentials(userName, password)
      const { decodedToken } = this
      if (decodedToken !== undefined) {
        await this.setUserLanguage(decodedToken.language)
      }
    } catch (error) {
      await remoteLoggingService.logError(`Login failed for user ${userName} due to ${error}`)
    }
    return response
  }

  public async loginOffline(userName: string, password: string): Promise<AuthTokenObject | null> {
    if (this.matchesAuthCredentials(userName, password)) {
      const userLanguage = this.getLanguage()
      await this.setUserLanguage(userLanguage)
      return {
        accessToken: this.authToken,
      }
    }
    return null
  }

  public async getLastPasswordChangeDate(): Promise<number | null> {
    let response: number | null = null
    try {
      const axiosResponse: AxiosResponse = await authResourceService.getLastPasswordChangeDate()
      response = axiosResponse.data as number
    } catch (error) {
      await remoteLoggingService.logError(`Failed to get last password change date due to ${error}`)
    }
    return response
  }

  private setAuthCredentials(userName: string, password: string): void {
    const encodedPassword: string = cryptoEncoder.encodePassword(password)
    const credentialsKey: string = this.getCredentialsKey(userName)
    storageService.set(credentialsKey, encodedPassword as StoreValueType.String)
  }

  private privAuthToken: string | undefined = undefined
  public get authToken(): string {
    if (!this.privAuthToken) {
      const token = storageService.get('auth.authToken') as string
      this.privAuthToken = token
    }
    return this.privAuthToken
  }

  private set authToken(authToken: string) {
    this.privAuthToken = authToken
    storageService.set('auth.authToken', authToken as StoreValueType.String)
    this.privDecodedToken = jwtDecode<JwtTokenProperties>(this.authToken)
  }

  private privDecodedToken: JwtTokenProperties | undefined = undefined
  public get decodedToken(): JwtTokenProperties | undefined {
    if (!this.privDecodedToken) {
      const decodedToken = jwtDecode<JwtTokenProperties>(this.authToken)
      this.privDecodedToken = decodedToken
    }
    return this.privDecodedToken
  }

  private getCredentialsKey(username: string): string {
    const encodedUsername = cryptoEncoder.encodeUsername(username)
    return `auth.credentials.${encodedUsername}`
  }

  private getAuthCredentials(username: string): string | null {
    const credentialsKey = this.getCredentialsKey(username)
    return storageService.get(credentialsKey) as string
  }

  private async setUserLanguage(language: string): Promise<void> {
    await i18n.changeLanguage(language)
  }

  public matchesAuthCredentials(userName: string, password: string): boolean {
    const storedEncodedPassword: string | null = this.getAuthCredentials(userName)
    if (!storedEncodedPassword) {
      return false
    }

    return cryptoEncoder.matches(password, storedEncodedPassword)
  }

  public hasRole(role: string): boolean {
    if (!this.authToken) {
      return false
    }

    return this.decodedToken?.authorities.includes(role) ?? false
  }

  public getLanguage(): string {
    const decodedToken = jwtDecode<JwtTokenProperties>(this.authToken)
    return decodedToken.language
  }

  public getCurrentUsername(): string | undefined {
    const authToken: JwtTokenProperties | undefined = this.decodedToken
    return authToken?.userName
  }
}

const authService = new AuthService()
export default authService
