import { create } from 'zustand'
import indiFormService from '../services/indiform/IndiForm.service'
import {
  ChemicalFormMetaData,
  EnumFormMetaData,
  GroupedEnumFormMetaData,
  HormoneFormMetaData,
  IcdCodesFormMetaData,
  Medication,
  MedicationFormMetaData,
  OtherFormMetaData,
} from '../shared/interfaces/IndiformValues.interface'

export interface IndiFormStoreState {
  enumFormMetaData: EnumFormMetaData | GroupedEnumFormMetaData | null
  icdCodesFormMetaData: IcdCodesFormMetaData | null
  chemicalFormMetaData: ChemicalFormMetaData | null
  hormoneFormMetaData: HormoneFormMetaData | null
  otherFormMetaData: OtherFormMetaData | null
  medicationFormMetaData: Record<string, Array<Medication>> | null
}

interface IndiFormStoreActions {
  storeEnumFormMetaData: (data: EnumFormMetaData | GroupedEnumFormMetaData) => void
  loadingEnumFormMetaDataFailed: (error: Error) => void
  storeIcdCodesFormMetaData: (data: IcdCodesFormMetaData) => void
  loadingIcdCodesFormMetaDataFailed: (error: Error) => void
  storeChemicalFormMetaData: (data: ChemicalFormMetaData) => void
  loadingChemicalFormMetaDataFailed: (error: Error) => void
  storeHormoneFormMetaData: (data: HormoneFormMetaData) => void
  loadingHormoneFormMetaDataFailed: (error: Error) => void
  storeOtherFormMetaData: (data: OtherFormMetaData) => void
  loadingOtherFormMetaDataFailed: (error: Error) => void
  storeMedicationsFormMetaData: (data: Array<MedicationFormMetaData>) => void
  loadingMedicationsFormMetaDataFailed: (error: Error) => void
  loadEnumFormMetaData: () => Promise<void>
  loadIcdCodesFormMetaData: () => Promise<void>
  loadChemicalFormMetaData: () => Promise<void>
  loadHormoneFormMetaData: () => Promise<void>
  loadOtherFormMetaData: () => Promise<void>
  loadMedicationsFormMetaData: (countries: Array<string>) => Promise<void>
}

const useIndiFormStore = create<IndiFormStoreState & IndiFormStoreActions>((set, get) => ({
  enumFormMetaData: null,
  icdCodesFormMetaData: null,
  chemicalFormMetaData: null,
  hormoneFormMetaData: null,
  otherFormMetaData: null,
  medicationFormMetaData: null,

  storeEnumFormMetaData: (data: EnumFormMetaData | GroupedEnumFormMetaData) => {
    set({ enumFormMetaData: data })
  },
  loadingEnumFormMetaDataFailed: (error: Error) => {
    set({ enumFormMetaData: null })
  },
  storeIcdCodesFormMetaData: (data: IcdCodesFormMetaData) => {
    set({ icdCodesFormMetaData: data })
  },
  loadingIcdCodesFormMetaDataFailed: (error: Error) => {
    set({ icdCodesFormMetaData: null })
  },
  storeChemicalFormMetaData: (data: ChemicalFormMetaData) => {
    set({ chemicalFormMetaData: data })
  },
  loadingChemicalFormMetaDataFailed: (error: Error) => {
    set({ chemicalFormMetaData: null })
  },
  storeHormoneFormMetaData: (data: HormoneFormMetaData) => {
    set({ hormoneFormMetaData: data })
  },
  loadingHormoneFormMetaDataFailed: (error: Error) => {
    set({ hormoneFormMetaData: null })
  },
  storeOtherFormMetaData: (data: OtherFormMetaData) => {
    set({ otherFormMetaData: data })
  },
  loadingOtherFormMetaDataFailed: (error: Error) => {
    set({ otherFormMetaData: null })
  },
  storeMedicationsFormMetaData: (data: Array<MedicationFormMetaData>) => {
    const formMetaDataMedications: Record<string, Array<Medication>> = {}
    data.forEach(item => {
      formMetaDataMedications[item.country] = item.medications
    })
    const existingMetaData = get().medicationFormMetaData
    set({
      medicationFormMetaData: {
        ...existingMetaData,
        ...formMetaDataMedications,
      },
    })
  },
  loadingMedicationsFormMetaDataFailed: (error: Error) => {
    const existingMeta = get().medicationFormMetaData
    set({ medicationFormMetaData: { ...existingMeta } })
  },

  loadEnumFormMetaData: async (): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: EnumFormMetaData | GroupedEnumFormMetaData | null = await indiFormService.getEnumFormMetaData()
      if (response !== null) {
        indiFormStore.storeEnumFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingEnumFormMetaDataFailed(error as Error)
    }
  },

  loadIcdCodesFormMetaData: async (): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: IcdCodesFormMetaData | null = await indiFormService.getIcdCodesFormMetaData()
      if (response !== null) {
        indiFormStore.storeIcdCodesFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingIcdCodesFormMetaDataFailed(error as Error)
    }
  },

  loadChemicalFormMetaData: async (): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: ChemicalFormMetaData | null = await indiFormService.getChemicalFormMetaData()
      if (response !== null) {
        indiFormStore.storeChemicalFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingChemicalFormMetaDataFailed(error as Error)
    }
  },

  loadHormoneFormMetaData: async (): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: HormoneFormMetaData | null = await indiFormService.getHormoneFormMetaData()
      if (response !== null) {
        indiFormStore.storeHormoneFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingHormoneFormMetaDataFailed(error as Error)
    }
  },

  loadOtherFormMetaData: async (): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: OtherFormMetaData | null = await indiFormService.getOtherFormMetaData()
      if (response !== null) {
        indiFormStore.storeOtherFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingOtherFormMetaDataFailed(error as Error)
    }
  },

  loadMedicationsFormMetaData: async (countries: Array<string>): Promise<void> => {
    const indiFormStore = get()
    try {
      const response: Array<MedicationFormMetaData> | null = await indiFormService.getFormMetaDataMedications(countries)
      if (response !== null) {
        indiFormStore.storeMedicationsFormMetaData(response)
      }
    } catch (error) {
      indiFormStore.loadingMedicationsFormMetaDataFailed(error as Error)
    }
  },
}))

export default useIndiFormStore
