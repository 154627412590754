import { Col, Row } from 'antd'
import React from 'react'
import indiFormService from '../../../services/indiform/IndiForm.service'
import { EnumValue } from '../../../shared/interfaces/EnumValue.interface'
import useContextStore from '../../../state/Context'
import FormCalendar, { Picker } from '../../ui/FormCalendar'
import FormDropDown, { DropdownOption } from '../../ui/FormDropDown'
import FormInputNumber from '../../ui/FormInputNumber'
import FormLabel from '../../ui/FormLabel'

interface Props {
  agentOptions: Array<DropdownOption>
  metaDataPath: string
}

const GroupAgent = (props: Props): React.ReactElement => {
  const { metaDataPath, agentOptions } = props
  const dataContext = useContextStore()
  const GROUP_AGENT_TYPE_PATH = `${metaDataPath}.agents[0].type`
  const GROUP_AGENT_CYCLE_PATH = `${metaDataPath}.agents[0].cycle`
  const GROUP_AGENT_FROM_DATE_PATH = `${metaDataPath}.agents[0].fromDate`
  const GROUP_AGENT_TO_DATE_PATH = `${metaDataPath}.agents[0].toDate`

  const getGroupAgentTypeValue = (): number | undefined => {
    const value: EnumValue | undefined = dataContext.getDataPathValue(GROUP_AGENT_TYPE_PATH)
    return value !== undefined ? value.enumId : value
  }

  const setGroupAgentTypeValue = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    if (selectedOption === undefined) {
      await dataContext.updateData(GROUP_AGENT_TYPE_PATH, selectedOption)
    } else {
      const value: EnumValue = indiFormService.getEnumValueObject(selectedOption.value as number, selectedOption.label)
      await dataContext.updateData(GROUP_AGENT_TYPE_PATH, value)
    }
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
        <FormLabel label="chemo_therapy_agent" metaDataPath={metaDataPath} />
        <FormDropDown
          options={agentOptions}
          value={getGroupAgentTypeValue()}
          onChange={setGroupAgentTypeValue}
          isClearingAllowed={false}
          placeholder=""
          validators={null}
          metadataPath={metaDataPath}
        />
      </Col>
      <Col span={12}>
        <FormLabel label="chemo_therapy_cycle" metaDataPath={metaDataPath} />
        <FormInputNumber
          metaDataPath={GROUP_AGENT_CYCLE_PATH}
          decimalScale={undefined}
          isDisabled={false}
          isFullWidth
          placeholder=""
          validators={null}
        />
      </Col>
      <Col span={12}>
        <FormLabel label="chemo_therapy_from" metaDataPath={metaDataPath} />
        <FormCalendar
          metaDataPath={GROUP_AGENT_FROM_DATE_PATH}
          picker={Picker.MONTH}
          placeholder=""
          validators={null}
          isCurrentOptionEnabled={false}
        />
      </Col>
      <Col span={12}>
        <FormLabel label="chemo_therapy_to" metaDataPath={metaDataPath} />
        <FormCalendar
          metaDataPath={GROUP_AGENT_TO_DATE_PATH}
          picker={Picker.MONTH}
          placeholder=""
          validators={null}
          isCurrentOptionEnabled={false}
        />
      </Col>
    </Row>
  )
}

export default GroupAgent
