import { AxiosResponse } from 'axios'
import { Log } from '../../shared/interfaces/Log.interface'
import { WebApi } from '../../utils/WebApi'

class LogResourceService extends WebApi {
  public log(log: Log): Promise<AxiosResponse> {
    return this.request({
      url: '/logger',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(log),
    })
  }
}

const logResourceService = new LogResourceService()
export default logResourceService
