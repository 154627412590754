import { App } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import useAppStore from '../state/App'
import useAuthStore from '../state/Auth'

const Idle = (): React.ReactElement | null => {
  const TIMEOUT_MILLISECONDS: number = 1000 * 60 * 15
  const WARNING_MILLISECONDS: number = 1000 * 60
  const DEBOUNCE_DELAY_MILLISECONDS = 250
  const [isWarningAlertShown, setIsWarningAlertShown] = useState(false)
  const authStore = useAuthStore()
  const appStore = useAppStore()
  const { notification } = App.useApp()

  const showWarningAlert = (): void => {
    setIsWarningAlertShown(true)
  }

  const unauthenticateUser = (): void => {
    setIsWarningAlertShown(false)
    appStore.setNavbarHeader('')
    authStore.logout()
  }

  const setAuthenticationTimer = useIdleTimer({
    onIdle: unauthenticateUser,
    timeout: TIMEOUT_MILLISECONDS,
    throttle: DEBOUNCE_DELAY_MILLISECONDS,
  })

  const setWarningTimer = useIdleTimer({
    onIdle: showWarningAlert,
    onActive: () => {
      setIsWarningAlertShown(false)
      setAuthenticationTimer.reset()
      setWarningTimer.reset()
    },
    timeout: TIMEOUT_MILLISECONDS - WARNING_MILLISECONDS,
    throttle: DEBOUNCE_DELAY_MILLISECONDS,
  })

  useEffect(() => {
    setWarningTimer.start()
    setAuthenticationTimer.start()
  }, [])

  useEffect(() => {
    if (isWarningAlertShown) {
      notification.warning({ message: 'About to logout in 60 seconds.' })
    }
  }, [isWarningAlertShown])

  return null
}

export default Idle
