import { App, Card } from 'antd'
import React from 'react'
import { Navigate } from 'react-router-dom'
import LoginForm from '../../components/auth/LoginForm'
import './Login.scss'
import useAppStore from '../../state/App'
import useAuthStore from '../../state/Auth'

const Login = (): React.ReactElement => {
  const appStore = useAppStore()
  const authStore = useAuthStore()
  const { isOnline } = appStore
  const authToken = useAuthStore(state => state.authToken)
  const { notification } = App.useApp()

  const onLoginFormSubmit = (userName: string, password: string): void => {
    authStore.login(userName, password, isOnline, notification)
  }

  const getRedirectUrl = (): string | null => {
    const { redirectUrl } = useAppStore.getState()

    if (redirectUrl !== null) {
      const regex =
        /clinics\/[0-9]{6}\/caseEditor\/[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi

      if (regex.test(redirectUrl)) {
        const indexMatchArray: RegExpMatchArray | null = redirectUrl.match(regex)
        return indexMatchArray !== null ? `${indexMatchArray[0]}/caseData` : redirectUrl
      }
    }
    return redirectUrl
  }

  const redirectUrl: string | null = getRedirectUrl()

  if (authToken === null) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center app-background">
        <div className="text-center ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-6 ant-col-xxl-4">
          <Card>
            <div className="fw-bold login-card-text">INDIVUPAD</div>
            <div className="fw-light login-card-text">Comprehensive Clinical Data Collection</div>
            <LoginForm onSubmit={onLoginFormSubmit} />
          </Card>
        </div>
      </div>
    )
  }
  if (redirectUrl !== null) {
    appStore.resetRedirectUrl()
    return <Navigate to={redirectUrl} />
  }
  return <Navigate to="/clinics" />
}

export default Login
