import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios'
import { cloneDeep } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { AuditLogEntry } from '../../shared/interfaces/AuditLogEntry.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ExistingDocument } from '../../shared/interfaces/ExistingDocument.interface'
import { ExistingDocuments } from '../../shared/interfaces/ExistingDocuments.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import { RelatedDataObject } from '../../shared/interfaces/RelatedDataObject.interface'
import Status from '../../shared/interfaces/Status.interface'
import ObjectUtils from '../../utils/Object'
import authService from '../auth/Auth.service'
import followUpIndexDatabaseService from '../browser-storage/index-database/FollowUpIndexDatabase.service'
import filesService from '../files/Files.service'
import AuditLogAction from '../indiform/change-log/AuditLogAction.enum'
import followUpChangeLogService from '../indiform/change-log/FollowUpChangeLog.service'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import followUpsResourceService from '../rest-resources/FollowUpsResourse.service'

class FollowUpsService {
  public createAndGetFollowUpDocument(clinic: Clinic, relatedObject: RelatedDataObject): FollowUpDocument | undefined {
    const owner: string | undefined = authService.getCurrentUsername()
    const relatedData = {
      caseNo: ObjectUtils.getAt(relatedObject, 'data.case.caseNo'),
    }
    const auditLogCollection = [
      { field: 'data.followUp.followUpNo', value: `${relatedData.caseNo}-FUP` },
      { field: 'data.followUp.caseNo', value: relatedData.caseNo },
      { field: 'data.followUp.hospital', value: { enumId: clinic.id, enumText: clinic.name } },
    ]
    if (owner !== undefined) {
      const entries: Array<AuditLogEntry> = auditLogCollection
        .map(auditLogItem => this.getAuditLogByField(auditLogItem.field, auditLogItem.value))
        .filter<AuditLogEntry>(
          (auditLogEntry: AuditLogEntry | null): auditLogEntry is AuditLogEntry => auditLogEntry !== null
        )
      return {
        document: {
          uuid: uuid(),
          status: Status.IN_PROCESS,
          data: {
            followUp: {
              ...relatedData,
              followUpNo: `${relatedData.caseNo}-FUP`,
              hospital: {
                enumId: clinic.id,
                enumText: clinic.name,
              },
            },
            changeLog: {
              entries,
            },
          },
          owner,
          messages: [],
          lastModifiedAt: Date.now(),
          pushRequestedAt: undefined,
          pushAcknowledgedAt: undefined,
        },
      }
    }
    return owner
  }

  public async getCaseDataByCaseNo(caseNo: string): Promise<RelatedDataObject | null> {
    let response: RelatedDataObject | null = null
    try {
      const axiosResponse: AxiosResponse = await followUpsResourceService.getCaseDataByCaseNo(caseNo)
      response = axiosResponse.data
    } catch (error) {
      await remoteLoggingService.logError(`Loading case ${caseNo} failed due to ${error}`)
    }
    return response
  }

  private getAuditLogByField(field: string, value: string): AuditLogEntry | null {
    return followUpChangeLogService.getAuditLogDataChange(AuditLogAction.SET, field, value, '')
  }

  public async getFollowUpDocumentsFromIndexDB(clinicId: number): Promise<Array<FollowUp>> {
    let response: Array<FollowUp> = []
    try {
      const followUpDocuments: Array<FollowUpDocument> =
        await followUpIndexDatabaseService.getFormDocumentsByClinicId(clinicId)
      response = followUpDocuments.map(followUpDocument => followUpDocument.document)
    } catch (error) {
      await remoteLoggingService.logError(
        `Loading follow-ups from the IndexDB for the clinic id ${clinicId} failed due to ${error}`
      )
    }
    return response
  }

  public async getFollowUpDocumentsFromAPI(clinicId: number): Promise<Array<FollowUp> | null> {
    let response: Array<FollowUp> | null = null
    try {
      const axiosResponse: AxiosResponse = await followUpsResourceService.fetchFollowUpDocuments(clinicId)
      response = axiosResponse.data.entries as Array<FollowUp>
    } catch (error) {
      await remoteLoggingService.logError(
        `Loading follow-ups from the API for the clinic id ${clinicId} failed due to ${error}`
      )
    }
    return response
  }

  public async getSubmittedFollowUpDocumentsByCaseNo(
    caseNo: string
  ): Promise<ExistingDocuments<Array<FollowUp>> | null> {
    let response: ExistingDocuments<Array<FollowUp>> | null = null
    try {
      const axiosResponse: AxiosResponse =
        await followUpsResourceService.fetchSubmittedFollowUpDocumentsByCaseNo(caseNo)
      response = axiosResponse.data as ExistingDocuments<Array<FollowUp>>
    } catch (error) {
      await remoteLoggingService.logError(`Failed to get the follow-up documents for case no ${caseNo} due to ${error}`)
    }
    return response
  }

  public async getExistingFollowUpDocumentsByCaseNo(
    caseNo: string
  ): Promise<ExistingDocuments<Array<FollowUp>> | null> {
    let response: ExistingDocument<Array<FollowUp>> | null = null
    try {
      const axiosResponse: AxiosResponse = await followUpsResourceService.fetchExistingFollowUpDocumentsByCaseNo(caseNo)
      response = axiosResponse.data as ExistingDocument<Array<FollowUp>>
    } catch (error) {
      await remoteLoggingService.logError(`Failed to get the follow-up documents for case no ${caseNo} due to ${error}`)
    }
    return response
  }

  public async updateFollowUpDocumentInBackend(followUpDocument: FollowUpDocument): Promise<FollowUpDocument | null> {
    const axiosResponse: AxiosResponse = await followUpsResourceService.updateFollowUpDocument(followUpDocument)
    return axiosResponse.data
  }

  private mapEntriesToFollowUpDocuments(entries: Array<FollowUp>): Array<FollowUpDocument> {
    return entries.map(entry => ({
      document: entry,
    }))
  }

  public async reloadFollowUps(clinicId: number): Promise<Array<FollowUp> | null> {
    try {
      const syncStart: number = Date.now()
      const response = await this.getFollowUpDocumentsFromAPI(clinicId)
      if (response !== null) {
        const followUpDocuments: Array<FollowUpDocument> = this.mapEntriesToFollowUpDocuments(response)
        await followUpIndexDatabaseService.putFormDocumentsIfUpdatedOrDoesNotExist(followUpDocuments, syncStart)
        await followUpIndexDatabaseService.deleteFollowUpDocumentsIfNotMatching(followUpDocuments, clinicId)
      }
      return this.getFollowUpDocumentsFromIndexDB(clinicId)
    } catch (error) {
      await remoteLoggingService.logError(`Failed to reload follow-ups due to ${error}`)
      return this.getFollowUpDocumentsFromIndexDB(clinicId)
    }
  }

  public async syncAllFollowUps(): Promise<Array<number | undefined>> {
    const followUpDocuments: Array<FollowUpDocument> = await followUpIndexDatabaseService.getDocumentRecordsForSync()
    return Promise.all(followUpDocuments.map(followUpDocument => this.syncFollowUp(followUpDocument)))
  }

  private async syncFollowUp(followUpDocument: FollowUpDocument): Promise<number | undefined> {
    const syncStart: number = Date.now()
    try {
      await filesService.syncFollowUpFiles(followUpDocument.document.data.followUp)
      const updatedFollowUpDocument = await this.updateFollowUpDocumentInBackend(followUpDocument)
      if (updatedFollowUpDocument !== null) {
        const updatedFollowUp: FollowUp = updatedFollowUpDocument.document
        if (updatedFollowUp) {
          const { uuid: followUpDocumentUuid, lastModifiedAt, messages: newMessages } = updatedFollowUp
          return followUpIndexDatabaseService.modifyFormDocumentByUuid(
            followUpDocumentUuid,
            (existingFormDocument: FormDocument) => {
              const { lastModifiedAt: existingLastModifiedAt, messages: existingMessages } =
                existingFormDocument.document
              if (
                !existingLastModifiedAt ||
                (lastModifiedAt !== undefined && lastModifiedAt > existingLastModifiedAt)
              ) {
                existingFormDocument.document = cloneDeep(updatedFollowUp)
              } else if (cloneDeep(existingMessages) !== cloneDeep(newMessages)) {
                existingFormDocument.document.messages = updatedFollowUp.messages
              }
              existingFormDocument.document.lastSuccessfullSync = syncStart
            }
          )
        }
      }
    } catch (error) {
      await remoteLoggingService.logError(
        `Failed to sync the follow-up ${JSON.stringify(followUpDocument.document.data.followUp)} due to error ${error}`
      )
      if (error instanceof AxiosError && error.response?.status === HttpStatusCode.NotAcceptable) {
        await followUpIndexDatabaseService.deleteFormDocument(followUpDocument.document.uuid)
      }
    }
    return undefined
  }
}

const followUpsService = new FollowUpsService()
export default followUpsService
