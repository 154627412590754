import { InputNumber } from 'antd'
import { isNumber } from 'lodash-es'
import React from 'react'
import indiFormService from '../../services/indiform/IndiForm.service'
import validationService from '../../services/indiform-validation/Validation.service'
import ValidationType from '../../services/indiform-validation/ValidationType.enum'
import useContextStore from '../../state/Context'

interface Props {
  decimalScale: number | undefined
  isDisabled: boolean
  isFullWidth: boolean
  metaDataPath: string
  placeholder: string
  validators: Record<ValidationType, never> | null
}

const FormInputNumber = (props: Props): React.ReactElement => {
  const { decimalScale, validators, metaDataPath, isDisabled, isFullWidth, placeholder } = props
  const regEx = decimalScale
    ? new RegExp(`^\\s*(?=.*[0-9])\\d*(?:\\.\\d{0,${decimalScale}})?\\s*$`)
    : /^\s*(?=.*[0-9])\d*?\s*$/
  const dataContext = useContextStore()
  const [value, setValue] = React.useState<number | undefined>(undefined)
  const [isValid, setValidity] = React.useState<boolean>(validators === null)

  React.useEffect(() => {
    const isInputValid: boolean = validationService.isFormElementValid(validators, value)
    setValidity(isInputValid)
  }, [value])

  React.useEffect(() => {
    const getFormElementValue = (): number | undefined => {
      const metaDataPathValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
      return metaDataPathValue !== undefined ? +metaDataPathValue : metaDataPathValue
    }
    setValue(getFormElementValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const updateValue = async (updateValueParam: number | undefined): Promise<void> => {
    const updatedMetaDataPathValue = await dataContext.updateData(metaDataPath, updateValueParam)
    return updatedMetaDataPathValue
  }

  const onInputChange = async (onChangeEventValue: number | string | undefined | null): Promise<void> => {
    if (onChangeEventValue !== undefined && onChangeEventValue !== null) {
      if (isNumber(onChangeEventValue)) {
        if (validators !== null && Object.prototype.hasOwnProperty.call(validators, ValidationType.MAX)) {
          const { max } = validators
          if (onChangeEventValue <= max || max === undefined) {
            await updateValue(onChangeEventValue)
          }
        } else {
          await updateValue(onChangeEventValue)
        }
      } else {
        await updateValue(undefined)
      }
    }
  }

  return (
    <InputNumber
      data-testid={metaDataPath}
      className={indiFormService.getFormInputCSSClasses(validators, isValid, isDisabled, isFullWidth)}
      disabled={isDisabled}
      min={validators?.min ? +validators.min : undefined}
      max={validators?.max ? +validators.max : undefined}
      placeholder={placeholder}
      value={value}
      parser={(val: string | undefined) => {
        if (val !== undefined && val !== '') {
          return regEx.test(val.replace(',', '.')) ? Number(val.replace(',', '.')) : Number(val.slice(0, -1))
        }
        return ''
      }}
      onChange={onInputChange}
    />
  )
}

export default FormInputNumber
