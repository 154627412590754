import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import { RelatedDataObject } from '../../shared/interfaces/RelatedDataObject.interface'
import ObjectUtils from '../../utils/Object'
import followUpIndexDatabaseService from '../browser-storage/index-database/FollowUpIndexDatabase.service'
import followUpsService from '../documents/FollowUps.service'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import AuditLogAction from './change-log/AuditLogAction.enum'
import followUpChangeLogService from './change-log/FollowUpChangeLog.service'

class RelatedCaseModalService {
  public async createAndGetFollowUpDocument(
    clinic: Clinic,
    relatedObject: RelatedDataObject,
    followUpDocumentToBeEdited: FollowUpDocument | null
  ): Promise<string> {
    const followUpDocument: FollowUpDocument | undefined =
      followUpDocumentToBeEdited !== null
        ? ((await followUpIndexDatabaseService.getFormDocumentByUuid(
            followUpDocumentToBeEdited.document.uuid
          )) as FollowUpDocument)
        : followUpsService.createAndGetFollowUpDocument(clinic, relatedObject)
    if (followUpDocument !== undefined) {
      if (followUpDocumentToBeEdited !== null) {
        this.updateFollowUpDocument(relatedObject, followUpDocument)
      }
      await followUpIndexDatabaseService.putFormDocument(followUpDocument)
    } else {
      await remoteLoggingService.logError('FollowUp document is undefined')
    }

    return followUpDocument ? ObjectUtils.getAt(followUpDocument, 'document.uuid') : ''
  }

  private updateFollowUpDocument(relatedObject: RelatedDataObject, followUpDocument: FollowUpDocument): void {
    const caseNo: string = ObjectUtils.getAt(relatedObject, 'data.case.caseNo')
    const followUpVisit: string | undefined = ObjectUtils.getAt(
      followUpDocument,
      'document.data.followUp.followUpVisit'
    )
    const prefixType = 'caseNo'
    const followUpNo: string = followUpVisit !== undefined ? `${caseNo}-FUP-${followUpVisit}` : `${caseNo}-FUP`
    ObjectUtils.setAt(followUpDocument, `document.data.followUp.${prefixType}`, caseNo)
    ObjectUtils.setAt(followUpDocument, 'document.data.followUp.followUpNo', followUpNo)
    const followUpDocumentValue = followUpDocument
    followUpDocumentValue.document.data.changeLog.entries = followUpChangeLogService.getUpdatedChangeLog(
      { data: followUpDocument.document.data },
      AuditLogAction.SET,
      `data.followUp.${prefixType}`,
      caseNo
    )
    followUpDocumentValue.document.data.changeLog.entries = followUpChangeLogService.getUpdatedChangeLog(
      { data: followUpDocument.document.data },
      AuditLogAction.SET,
      'data.followUp.followUpNo',
      followUpNo
    )
    followUpDocumentValue.document.lastModifiedAt = Date.now()
  }
}

const relatedCaseModalService = new RelatedCaseModalService()
export default relatedCaseModalService
