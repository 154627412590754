import { ColumnFilterItem } from 'antd/es/table/interface'
import { TFunction } from 'i18next'
import { uniqBy } from 'lodash-es'
import { Case } from '../../shared/interfaces/Case.interface'
import Status from '../../shared/interfaces/Status.interface'
import ObjectUtils from '../../utils/Object'
import staticDocumentListService from './StaticDocumentList.service'

class TableFiltersService {
  public getOrgansFilter = (documents: Array<Case>): Array<ColumnFilterItem> => {
    const filteredOrgans = documents
      .map(document => {
        const organEnumId = ObjectUtils.getAt(document, 'data.case.accruals[0].tumors[0].organ.enumId')
        const organEnumText = ObjectUtils.getAt(document, 'data.case.accruals[0].tumors[0].organ.enumText')
        if (organEnumId !== undefined) {
          return { text: organEnumText, value: organEnumId }
        }
        return undefined
      })
      .filter(organ => organ !== undefined)

    return uniqBy(filteredOrgans, 'value') as Array<ColumnFilterItem>
  }

  public getStatusFilter = (statuses: Array<Status>, t: TFunction): Array<ColumnFilterItem> =>
    uniqBy(
      statuses.map(status => ({
        text: staticDocumentListService.getStatus(status, t),
        value: status,
      })),
      'value'
    ) as Array<ColumnFilterItem>

  public getAuthorFilter = (authors: Array<string>): Array<ColumnFilterItem> =>
    uniqBy(
      authors.map(author => ({
        text: author,
        value: author.toLowerCase(),
      })),
      'value'
    ) as Array<ColumnFilterItem>

  public getSexFilter = (t: TFunction): Array<ColumnFilterItem> => [
    {
      text: t('documentList.table.filters.sex.male'),
      value: 'm',
    },
    {
      text: t('documentList.table.filters.sex.female'),
      value: 'f',
    },
  ]

  public getProjectFilter = (t: TFunction): Array<ColumnFilterItem> => [
    {
      text: t('documentList.table.values.project.yes'),
      value: 'y',
    },
    {
      text: t('documentList.table.values.project.no'),
      value: 'n',
    },
  ]
}

const tableFiltersService = new TableFiltersService()
export default tableFiltersService
