/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { App, Button, Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { ShowUploadListInterface, UploadFile } from 'antd/lib/upload/interface'
import React from 'react'
import { useTranslation } from 'react-i18next'
import fileIndexDatabaseService, {
  FileDocument,
} from '../../../services/browser-storage/index-database/FileIndexDatabase.service'
import filesService from '../../../services/files/Files.service'
import IndiFormType from '../../../services/indiform/IndiFormType.enum'
import useContextStore from '../../../state/Context'
import FileAutoRenameModal from './FileAutoRenameModal'

interface Props {
  metaDataPath: string
  isMultiple: boolean
  accept: string
  pathPrefix: string | undefined
}

export interface File extends UploadFile {
  filePath: string
}

const FileUpload = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const { notification } = App.useApp()
  const showUploadList: ShowUploadListInterface = { showRemoveIcon: false }
  const dataContext = useContextStore()
  const [fileName, setFileName] = React.useState<string | undefined>(undefined)
  const [filePath, setFilePath] = React.useState<string | undefined>(undefined)
  const [showAutoRenameModal, toggleAutoRenameModal] = React.useState<boolean>(false)
  const [fileObj, setFile] = React.useState<UploadChangeParam | undefined>(undefined)
  const maxFileSizeInMb = 100
  const byte = 1024
  const { isMultiple, accept, metaDataPath, pathPrefix } = props

  const parseFilePath = (filePathParams: string | undefined): string | undefined =>
    filePathParams ? filePathParams.split(/[/]+/).pop() : undefined

  React.useEffect(() => {
    const dataPathValue: string | undefined = dataContext.getDataPathValue(metaDataPath)
    setFilePath(dataPathValue)
    const name: string | undefined = parseFilePath(dataPathValue)
    setFileName(name)
  }, [dataContext.formDocument, metaDataPath])

  const toMegabyte = (size: number): number => size / byte / byte

  const onChange = async (value: UploadChangeParam): Promise<void> => {
    const isFileLessThanThreshold: boolean = toMegabyte(Number(value.file.size)) < maxFileSizeInMb

    if (isFileLessThanThreshold) {
      setFile(value)
      toggleAutoRenameModal(true)
    } else {
      notification.error({
        message: t('fileUpload.sizeError'),
      })
    }
  }

  const generateDefaultPath = (file: UploadFile): string => {
    const uuid: string | undefined = dataContext.formDocument?.document.uuid
    if (dataContext.formType === IndiFormType.FOLLOWUP) {
      return `indivunet/documents/follow-up/${uuid}/${pathPrefix}/${Date.now()}-${file.name}`
    }
    const fileType: string = dataContext.getDataPathValue(`${metaDataPath.replace('location', 'type')}.enumText`)
    return `${uuid}/${fileType}/${Date.now()}-${file.name}`
  }

  const generateAutoRenamePath = (file: UploadFile): string => {
    const uuid: string | undefined = dataContext.formDocument?.document.uuid
    if (dataContext.formType === IndiFormType.FOLLOWUP) {
      return `indivunet/documents/follow-up/${uuid}/${pathPrefix}/${uuid}-${Date.now()}.${file.name.split('.').pop()}`
    }
    const fileType: string = dataContext.getDataPathValue(`${metaDataPath.replace('location', 'type')}.enumText`)
    return `${uuid}/${fileType}/${uuid}-${Date.now()}.${file.name.split('.').pop()}`
  }

  const saveFile = async (generatedFilePath: string): Promise<void> => {
    if (fileObj) {
      const updatedFile = { filePath: generatedFilePath, ...fileObj.file }
      const fileObject: FileDocument = {
        document: updatedFile,
      }
      try {
        fileObject.document.status = 'done'
        await fileIndexDatabaseService.putFileDocument(fileObject)
        notification.success({
          message: t('fileUpload.success', { fileName: fileObj.file.name }),
        })
        await dataContext.updateData(metaDataPath, generatedFilePath)
      } catch (error) {
        console.error(error)
        fileObject.document.status = 'error'
        notification.error({
          message: t('fileUpload.error', { fileName: fileObj.file.name }),
        })
        setFile(undefined)
      }
    }
  }

  const generatePath = async (autoGenerate: boolean): Promise<void> => {
    toggleAutoRenameModal(false)

    if (fileObj) {
      const generatedFilePath: string = autoGenerate
        ? generateAutoRenamePath(fileObj.file)
        : generateDefaultPath(fileObj.file)
      await saveFile(generatedFilePath)
    }
  }

  const onApprove = async (): Promise<void> => {
    await generatePath(true)
  }

  const onCancel = async (): Promise<void> => {
    await generatePath(false)
  }

  return (
    <>
      <Upload
        customRequest={async () => Promise.resolve()}
        showUploadList={showUploadList}
        onChange={onChange}
        multiple={isMultiple}
        accept={accept}
      >
        <Button data-testid={`${metaDataPath}-uploadButton`} icon={<FontAwesomeIcon icon={faUpload} fixedWidth />}>
          {t('uploadButton')}
        </Button>
      </Upload>
      <div className="mt-3">
        {filePath ? (
          <a data-testid={metaDataPath} onClick={() => filesService.onFileClick(filePath, t)}>
            {fileName}
          </a>
        ) : (
          <p>{fileName}</p>
        )}
      </div>
      <FileAutoRenameModal isVisible={showAutoRenameModal} onCancel={onCancel} onApprove={onApprove} />
    </>
  )
}

export default FileUpload
