import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import {
  faEye,
  faRibbon,
  faVial,
  faTrashAlt,
  faUpload,
  faExclamationTriangle,
  faEllipsisH,
  faBars,
  faUser,
  faSignOutAlt,
  faTimesCircle,
  faCheck,
  faQuestionCircle,
  faShare,
  faHistory,
  faClock,
  faPlus,
  faTimes,
  faCircle,
  faHospital,
  faHeartbeat,
  faNotesMedical,
  faThermometer,
  faUserInjured,
  faUserFriends,
  faUserMd,
  faUserEdit,
  faAllergies,
  faPills,
  faBullseye,
  faRadiation,
  faCapsules,
  faUtensils,
  faDiagnoses,
  faProcedures,
  faDisease,
  faMicroscope,
  faStethoscope,
  faChartNetwork,
  faDna,
  faFingerprint,
  faToolbox,
  faSearch,
  faTint,
  faPrescriptionBottle,
  faScalpel,
  faVenus,
  faHighlighter,
  faImages,
  faLineColumns,
  faEdit,
  faLock,
} from '@fortawesome/pro-light-svg-icons'
import { ConfigProvider, App as AntdApp } from 'antd'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Global.scss'
import theme from './utils/ThemeConfig'

library.add(
  faHeart as IconDefinition,
  faBullseye,
  faRadiation,
  faCapsules,
  faEye,
  faEdit,
  faPrescriptionBottle,
  faRibbon,
  faVial,
  faUpload,
  faTrashAlt,
  faExclamationTriangle,
  faEllipsisH,
  faBars,
  faUser,
  faUserEdit,
  faSignOutAlt,
  faTimesCircle,
  faCheck,
  faQuestionCircle,
  faShare,
  faHistory,
  faClock,
  faPlus,
  faTimes,
  faCircle,
  faHospital,
  faHeartbeat,
  faNotesMedical,
  faThermometer,
  faUserInjured,
  faUserFriends,
  faUserMd,
  faAllergies,
  faPills,
  faUtensils,
  faDiagnoses,
  faProcedures,
  faDisease,
  faMicroscope,
  faStethoscope,
  faChartNetwork,
  faDna,
  faFingerprint,
  faToolbox,
  faSearch,
  faTint,
  faScalpel,
  faVenus,
  faHighlighter,
  faImages,
  faLineColumns,
  faLock
)

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <ConfigProvider theme={theme}>
      <AntdApp className="h-100">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AntdApp>
    </ConfigProvider>
  )
}
