import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormModal from '../FormModal'

interface Props {
  isVisible: boolean
  onCancel: () => Promise<void>
  onApprove: () => Promise<void>
}

const FileAutoRenameModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const { isVisible, onCancel, onApprove } = props
  return (
    <FormModal
      header={t('fileUpload.autoRenameHeaderText')}
      isVisible={isVisible}
      width="50%"
      onCancel={onCancel}
      footer={[
        <Button key="auto-rename-btn-cancel" onClick={onCancel}>
          {t('form.labels.no')}
        </Button>,
        <Button key="auto-rename-btn-approve" type="primary" onClick={onApprove}>
          {t('form.labels.yes')}
        </Button>,
      ]}
      isClosable
      isDismissableMask={false}
    >
      <div>{t('fileUpload.autoRename')}</div>
    </FormModal>
  )
}

export default FileAutoRenameModal
