import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, Layout } from 'antd'
import React from 'react'
import useAuthStore from '../../state/Auth'

interface Props {
  onSubmit: (userName: string, password: string) => void
}

const LoginForm = (props: Props): React.ReactElement => {
  const indiauthClientUrl =
    process.env.REACT_APP_NODE_ENV === 'local-development'
      ? process.env.REACT_APP_OAUTH_CLIENT_URL
      : process.env.REACT_APP_OAUTH_URL
  const { Item } = Form
  const { Password } = Input

  const loading: boolean = useAuthStore(state => state.loading)

  // FIXME: Type the store value (https://github.com/react-component/field-form/issues/70,
  // https://github.com/ant-design/ant-design/issues/21195)
  const onLoginSubmit = (values: Record<string, string>): void => {
    const { onSubmit } = props
    const { username: userName, password } = values
    onSubmit(userName, password)
  }

  return (
    <Layout>
      <Form name="login" onFinish={onLoginSubmit} className="mx-4 mt-4">
        <Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input prefix={<FontAwesomeIcon icon={['fal', 'user']} />} placeholder="Username" />
        </Item>
        <Item name="password" rules={[{ required: true, message: 'Please input your password!' }]} className="m-0">
          <Password prefix={<FontAwesomeIcon icon={['fal', 'lock']} />} placeholder="Password" />
        </Item>
        <Item className="d-flex justify-content-end">
          <a style={{ color: '#40a7ff', fontSize: 12 }} href={`${indiauthClientUrl}/forgot-password`}>
            Forgot password?
          </a>
        </Item>
        <Item className="text-center m-0">
          <Button shape="round" type="primary" htmlType="submit" className="w-100" loading={loading}>
            Login
          </Button>
        </Item>
      </Form>
    </Layout>
  )
}

export default LoginForm
