import { Alert, Button, Col, Input, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import followUpsService from '../../services/documents/FollowUps.service'
import EditorType from '../../services/indiform/EditorType.enum'
import relatedCaseModalService from '../../services/indiform/RelatedCaseModal.service'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import { RelatedDataObject } from '../../shared/interfaces/RelatedDataObject.interface'
import ObjectUtils from '../../utils/Object'
import FormModal from '../ui/FormModal'

interface Props {
  clinic: Clinic
  isVisible: boolean
  isDocumentIdentifierInEditMode: boolean
  followUpDocumentToBeEdited: FollowUpDocument | null
  onCancel: () => void
}

const RelatedCaseModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation()
  const [caseNo, setCaseNo] = React.useState<string>('')
  const [isLoadButtonDisabled, toggleLoadButtonDisabled] = React.useState<boolean>(true)
  const [isErrorAlertShown, toggleErrorAlert] = React.useState<boolean>(false)
  const [isInvalidCaseNoAlertShown, toggleInvalidCaseNoAlert] = React.useState<boolean>(false)
  const modalType = 'caseNoModal'
  const navigate = useNavigate()
  const { clinic, followUpDocumentToBeEdited, isVisible, isDocumentIdentifierInEditMode, onCancel } = props

  React.useEffect(() => {
    if (followUpDocumentToBeEdited !== null) {
      const relatedCaseNo: string = ObjectUtils.getAt(followUpDocumentToBeEdited, 'document.data.followUp.caseNo')
      setCaseNo(relatedCaseNo)
    }
  }, [followUpDocumentToBeEdited])

  React.useEffect(() => {
    if (!isVisible) {
      setCaseNo('')
      toggleErrorAlert(false)
      toggleInvalidCaseNoAlert(false)
      toggleLoadButtonDisabled(true)
    }
  }, [isVisible])

  React.useEffect(() => {
    toggleLoadButtonDisabled(caseNo === '')
  }, [caseNo])

  const createFollowUpDocument = async (relatedCaseObject: RelatedDataObject): Promise<void> => {
    const documentId = await relatedCaseModalService.createAndGetFollowUpDocument(
      clinic,
      relatedCaseObject,
      followUpDocumentToBeEdited
    )
    navigate(`/clinics/${clinic.id}/${EditorType.FOLLOWUP}/${documentId}/followUpRegistration`)
  }
  const validateCaseNo = async (): Promise<void> => {
    toggleLoadButtonDisabled(true)
    if (clinic.prefix.some(pre => caseNo.startsWith(pre))) {
      const relatedCaseObject: RelatedDataObject | null = await followUpsService.getCaseDataByCaseNo(caseNo)
      if (relatedCaseObject) {
        setCaseNo(ObjectUtils.getAt(relatedCaseObject, 'data.case.caseNo'))
        await createFollowUpDocument(relatedCaseObject)
      } else {
        toggleErrorAlert(true)
      }
    } else {
      toggleInvalidCaseNoAlert(true)
    }
    toggleLoadButtonDisabled(false)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCaseNo(event.target.value)
    toggleErrorAlert(false)
    toggleInvalidCaseNoAlert(false)
  }

  return (
    <FormModal
      header={t(`documentList.${modalType}.title`)}
      isVisible={isVisible}
      width="50%"
      onCancel={onCancel}
      isClosable
      isDismissableMask={false}
      footer={null}
    >
      <Row>
        <Col className="w-100 d-flex justify-content-between">
          <Input
            className="me-1"
            placeholder={t(`documentList.${modalType}.placeholder`) ?? ''}
            value={caseNo}
            onChange={onInputChange}
          />
          <Button disabled={isLoadButtonDisabled} onClick={validateCaseNo}>
            {isDocumentIdentifierInEditMode
              ? t('documentList.caseNoModal.updateIdentifier')
              : t(`documentList.${modalType}.validateButtonText`)}
          </Button>
        </Col>
      </Row>
      <Row className="my-2" style={{ minHeight: '50px' }}>
        <Col>
          {isErrorAlertShown && (
            <Alert message={t(`documentList.${modalType}.errorMessage`, { caseNo })} showIcon type="warning" />
          )}
        </Col>
        <Col>
          {isInvalidCaseNoAlertShown && (
            <Alert
              message={t(`documentList.${modalType}.invalidCaseNoErrorMessage`, { prefix: clinic.prefix })}
              showIcon
              type="warning"
            />
          )}
        </Col>
      </Row>
    </FormModal>
  )
}

export default RelatedCaseModal
