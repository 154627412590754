import { AxiosResponse } from 'axios'
import { Params } from 'react-router-dom'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { StoreFormMetaData } from '../../shared/interfaces/StoreFormMetaData.interface'
import IndiFormType from '../indiform/IndiFormType.enum'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import clinicsResourceService from '../rest-resources/ClinicsResource.service'

class ClinicsService {
  public async getClinics(): Promise<Array<Clinic> | null> {
    let response: Array<Clinic> | null = null
    try {
      const axiosResponse: AxiosResponse = await clinicsResourceService.fetchClinics()
      response = axiosResponse.data as Array<Clinic>
    } catch (error) {
      await remoteLoggingService.logError(`Loading clinics failed due to ${error}`)
    }
    return response
  }

  public async getFormMetaData(
    clinics: Array<Clinic>,
    indiFormType: IndiFormType = IndiFormType.CASE
  ): Promise<Array<StoreFormMetaData> | null> {
    let response: Array<StoreFormMetaData> | null = null
    try {
      const axiosResponse: Array<AxiosResponse<ClinicFormMetaData>> =
        await clinicsResourceService.fetchClinicsFormMetaData(clinics, indiFormType)
      response = axiosResponse.map(res => ({ formMetaData: res.data }))
    } catch (error) {
      await remoteLoggingService.logError(`Loading clinic meta data failed due to ${error}`)
    }
    return response
  }

  public getClinicFormMetaData(
    clinicId: number,
    clinicsFormMetaData: Record<number, ClinicFormMetaData>
  ): ClinicFormMetaData | Record<string, never> {
    return Object.prototype.hasOwnProperty.call(clinicsFormMetaData, clinicId) ? clinicsFormMetaData[clinicId] : {}
  }

  public getClinicById(clinics: Array<Clinic>, clinicId: number): Clinic | undefined {
    return clinics.find(clinic => clinic.id === clinicId)
  }

  public getClinicIdFromMatchParams(params: Params<string>): number {
    return parseInt(params.clinicId ?? '', 10)
  }

  public getClinicIdFromURL(): number {
    const clinicIdRegex = /[0-9]{6}/
    const regexpResult = clinicIdRegex.exec(window.location.pathname)
    return parseInt(regexpResult?.[0] ?? '', 10)
  }
}

const clinicsService = new ClinicsService()
export default clinicsService
