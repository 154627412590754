import { create } from 'zustand'
import IndiFormType from '../services/indiform/IndiFormType.enum'
import AuditLogAction from '../services/indiform/change-log/AuditLogAction.enum'
import { AuditLogEntry } from '../shared/interfaces/AuditLogEntry.interface'
import { ChangeLogData } from '../shared/interfaces/ChangeLogData.interface'
import { FormDocument } from '../shared/interfaces/FormDocument.interface'
import { RelatedDataObject } from '../shared/interfaces/RelatedDataObject.interface'
import Status from '../shared/interfaces/Status.interface'

interface DocumentDataContextStore {
  formType?: IndiFormType | undefined
  formDocument?: FormDocument | undefined
  relatedData?: RelatedDataObject | null
  isLoading?: boolean
  isLoadingFailed?: boolean
  initialized: boolean
}

interface DocumentDataContextActions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDataPathValue: (metaDataPath: string) => any
  updateData: (
    path: string,
    value: unknown,
    action?: AuditLogAction,
    changeLogValue?: ChangeLogData | undefined
  ) => Promise<void>
  updateDocumentStatus: (status: Status, value: Record<string, unknown>) => Promise<void>
  updateDataChangeLog: (changeLog: AuditLogEntry) => Promise<void>
  resetState: () => void
  setIsLoadingFailed: (isFailed: boolean) => void
}

const useContextStore = create<DocumentDataContextStore & DocumentDataContextActions>((set, get) => ({
  formType: undefined,
  formDocument: undefined,
  relatedData: null,
  isLoading: false,
  isLoadingFailed: false,
  initialized: false,
  getDataPathValue: (metaDataPath: string) => {},
  updateData: async (
    path: string,
    value: unknown,
    action?: AuditLogAction,
    changeLogValue?: ChangeLogData | undefined
  ) => {},
  updateDocumentStatus: async (status: Status, value: Record<string, unknown>) => {},
  updateDataChangeLog: async (changeLog: AuditLogEntry) => {},
  resetState: () => {
    set({
      formType: undefined,
      formDocument: undefined,
      relatedData: null,
      isLoading: false,
      isLoadingFailed: false,
      initialized: false,
      getDataPathValue: (metaDataPath: string) => {},
      updateData: async (
        path: string,
        value: unknown,
        action?: AuditLogAction,
        changeLogValue?: ChangeLogData | undefined
      ) => {},
      updateDocumentStatus: async (status: Status, value: Record<string, unknown>) => {},
      updateDataChangeLog: async (changeLog: AuditLogEntry) => {},
    })
  },
  setIsLoadingFailed: (isFailed: boolean) => {
    set({ isLoadingFailed: isFailed })
  },
}))

export default useContextStore
