import { Mutex } from 'async-mutex'
import { cloneDeep, pullAt } from 'lodash-es'
import followUpIndexDatabaseService from '../services/browser-storage/index-database/FollowUpIndexDatabase.service'
import followUpsService from '../services/documents/FollowUps.service'
import AuditLogAction from '../services/indiform/change-log/AuditLogAction.enum'
import followUpChangeLogService from '../services/indiform/change-log/FollowUpChangeLog.service'
import rules from '../services/indiform/field-calculation/Rules'
import remoteLoggingService from '../services/logging-handler/RemoteLogging.service'
import { AuditLogEntry } from '../shared/interfaces/AuditLogEntry.interface'
import { CaseData } from '../shared/interfaces/CaseData.interface'
import { ChangeLogData } from '../shared/interfaces/ChangeLogData.interface'
import { FollowUp } from '../shared/interfaces/FollowUp.interface'
import { FollowUpData } from '../shared/interfaces/FollowUpData.interface'
import { FormDocument } from '../shared/interfaces/FormDocument.interface'
import { RelatedDataObject } from '../shared/interfaces/RelatedDataObject.interface'
import Status from '../shared/interfaces/Status.interface'
import useAppStore from '../state/App'
import useContextStore from '../state/Context'
import ArrayUtils from '../utils/Array'
import ObjectUtils from '../utils/Object'

class FollowUpStateService {
  private readonly DATA_OBJECT_PATH = 'data.followUp'
  private readonly indexRegex = /\[([0-9]+)]/
  private readonly lastIndexRegex = /\[\d](?!.*\[)/
  private readonly mutex = new Mutex()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly getDataPathValue = (metaDataPath: string): any => {
    const form = useContextStore.getState().formDocument
    if (form !== undefined) {
      const path: string = this.DATA_OBJECT_PATH.concat(`.${metaDataPath}`)
      const dataObject: { data: CaseData | FollowUpData } = {
        data: form.document.data,
      }
      return ObjectUtils.getAt(dataObject, path, undefined)
    }
    return undefined
  }

  public readonly loadRelatedCaseData = async (formDocument: FormDocument): Promise<void> => {
    const caseNo: string = ObjectUtils.getAt(formDocument.document, 'data.followUp.caseNo')
    let relatedData: RelatedDataObject | null = null
    useContextStore.setState({ isLoading: true })
    if (caseNo) {
      relatedData = await followUpsService.getCaseDataByCaseNo(caseNo)
    } else {
      await remoteLoggingService.logError('Cannot load related-data since Case No or Match Code is undefined.')
    }
    await useContextStore.setState({ relatedData, isLoading: false, isLoadingFailed: Boolean(!relatedData) })
  }

  private readonly updateNavbarHeader = (path: string, value: unknown): void => {
    if (path === 'followUpNo') {
      useAppStore.getState().setNavbarHeader(`Follow-up: ${value}`)
    }
  }

  private readonly getChangeLogPath = (
    path: string,
    action: AuditLogAction,
    changeLogData: ChangeLogData | undefined
  ): string => {
    if (action === AuditLogAction.SET || changeLogData === undefined) {
      return path
    }
    const { path: changeLogPath } = changeLogData
    return changeLogPath
  }

  private readonly getUpdatedChangeLog = (
    dataObject: { data: CaseData | FollowUpData },
    action: AuditLogAction,
    path: string,
    value: unknown,
    comment = ''
  ): Array<AuditLogEntry> => followUpChangeLogService.getUpdatedChangeLog(dataObject, action, path, value, comment)

  private readonly getChangeLogValue = (
    value: unknown,
    action: AuditLogAction,
    changeLogData: ChangeLogData | undefined
  ): unknown => {
    if (action === AuditLogAction.SET || changeLogData === undefined) {
      return value
    }
    const { value: changeLogValue } = changeLogData
    return changeLogValue
  }

  private readonly getPathWithoutIndex = (path: string): string => {
    const arrayListWithIndex: Array<string> = path.split('.')
    const lastListElementWithIndex: string | undefined = arrayListWithIndex.pop()
    if (lastListElementWithIndex !== undefined) {
      const indexMatchArray: RegExpMatchArray | null = lastListElementWithIndex.match(this.indexRegex)
      if (indexMatchArray !== null) {
        const lastListElementWithoutIndex: string = lastListElementWithIndex.replace(/ *\[[^\]]*]/, '')
        return [...arrayListWithIndex, lastListElementWithoutIndex].join('.')
      }
    }
    return ''
  }

  private readonly getLastArrayIndex = (field: string): number | null => {
    const lastArrayIndexMatch: RegExpMatchArray | null = field.match(this.lastIndexRegex)
    if (lastArrayIndexMatch !== null) {
      const lastArrayIndex: string = lastArrayIndexMatch[0] // [1]
      const indexMatch: RegExpMatchArray | null = lastArrayIndex.match(this.indexRegex)
      if (indexMatch !== null) {
        return lastArrayIndex.match(this.indexRegex) !== null ? +indexMatch[1] : null // 1
      }
    }
    return null
  }

  private readonly setDataObjectPathValue = (
    dataObject: { data: CaseData | FollowUpData },
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): void => {
    if (value === undefined || ArrayUtils.isArrayAndEmpty(value)) {
      const regex = /\[\d]$/
      if (regex.test(path)) {
        // For List deletion
        const pathWithoutIndex: string = this.getPathWithoutIndex(path)
        const updatedValue = this.getDataPathValue(pathWithoutIndex)
        const index: number | null = this.getLastArrayIndex(path)
        if (index !== null) {
          pullAt(updatedValue, index)
        }
      } else {
        // For Radio box deletion
        ObjectUtils.unsetAt(dataObject, path)
      }
    } else {
      ObjectUtils.setAt(dataObject, path, value)
    }
  }

  private readonly updateFormDocument = async (): Promise<void> => {
    const form = useContextStore.getState().formDocument
    if (form !== undefined) {
      await followUpIndexDatabaseService.putFormDocument(form)
    }
  }

  private readonly applyRules = (dataObject: { data: CaseData | FollowUpData }, path: string): void => {
    // Update the rules only when the height or weight is updated
    if (
      [`${this.DATA_OBJECT_PATH}.bodyHeight`, `${this.DATA_OBJECT_PATH}.weight`].some((value: string) => value === path)
    ) {
      // BMI Rule
      const bmiValue: string | undefined = rules.getBmiValue(dataObject, this.DATA_OBJECT_PATH)
      const bmiPath: string = this.DATA_OBJECT_PATH.concat('.bodyMassIndex')
      Object.assign(dataObject.data.changeLog, {
        entries: this.getUpdatedChangeLog(dataObject, AuditLogAction.SET, bmiPath, bmiValue),
      })
      this.setDataObjectPathValue(dataObject, bmiPath, bmiValue)
      // BSA Rule
      const bsaValue: string | undefined = rules.getBsaValue(dataObject, this.DATA_OBJECT_PATH)
      const bsaPath: string = this.DATA_OBJECT_PATH.concat('.bodySurfaceArea')
      Object.assign(dataObject.data.changeLog, {
        entries: this.getUpdatedChangeLog(dataObject, AuditLogAction.SET, bsaPath, bsaValue),
      })
      this.setDataObjectPathValue(dataObject, bsaPath, bsaValue)
    }
  }

  private readonly addGeneratedFollowUpNoToChangeLog = (
    dataObject: { data: FollowUpData },
    generatedFollowUpNo: string
  ): void => {
    const followUpNo: string = ObjectUtils.getAt(dataObject, `${this.DATA_OBJECT_PATH}.followUpNo`)
    const followUpNoPath = `${this.DATA_OBJECT_PATH}.followUpNo`
    const followUpDataObject = dataObject
    if (followUpNo !== generatedFollowUpNo) {
      followUpDataObject.data.changeLog.entries = this.getUpdatedChangeLog(
        dataObject,
        AuditLogAction.SET,
        followUpNoPath,
        generatedFollowUpNo
      )
      this.setDataObjectPathValue(followUpDataObject, followUpNoPath, generatedFollowUpNo)
      this.updateNavbarHeader('followUpNo', generatedFollowUpNo)
    }
  }

  public readonly updateData = async (
    path: string,
    value: unknown,
    action: AuditLogAction = AuditLogAction.SET,
    changeLogData: ChangeLogData | undefined = undefined
  ): Promise<void> => {
    await this.mutex.runExclusive(async () => {
      let metaDataPath = path
      const form = useContextStore.getState().formDocument
      if (form !== undefined && metaDataPath !== '') {
        this.updateNavbarHeader(metaDataPath, value)
        metaDataPath = this.DATA_OBJECT_PATH.concat(`.${metaDataPath}`)
        const clonedFormDocument = cloneDeep(form)
        const changeLogPath: string = this.getChangeLogPath(metaDataPath, action, changeLogData)
        const changeLogValue: unknown = this.getChangeLogValue(value, action, changeLogData)
        const clonedData: FollowUpData = clonedFormDocument.document.data as FollowUpData
        const dataObject: { data: FollowUpData } = {
          data: {
            followUp: clonedData.followUp,
            changeLog: {
              entries: this.getUpdatedChangeLog({ data: clonedData }, action, changeLogPath, changeLogValue),
            },
          },
        }
        this.setDataObjectPathValue(dataObject, metaDataPath, value)
        if (action !== AuditLogAction.DELETE) {
          this.applyRules(dataObject, metaDataPath)
          const generatedFollowUpNo: string | null = rules.applyFollowUpNoRules(dataObject, metaDataPath)
          if (generatedFollowUpNo) {
            this.addGeneratedFollowUpNoToChangeLog(dataObject, generatedFollowUpNo)
          }
        }
        clonedFormDocument.document = {
          ...clonedFormDocument.document,
          ...dataObject,
          lastModifiedAt: Date.now(),
          lastSuccessfullSync: await this.updateLastSuccessfullSync(),
          status: clonedFormDocument.document.status,
        }

        useContextStore.setState({ formDocument: clonedFormDocument })
        await this.updateFormDocument()
      } else {
        await remoteLoggingService.logError(
          `Cannot set the value ${value} since follow-up data document is undefined and path is empty.`
        )
      }
    })
  }

  public updateLastSuccessfullSync = async (): Promise<number | undefined> => {
    const { formDocument } = useContextStore.getState()

    if (formDocument !== undefined) {
      const followUpDocument = await followUpIndexDatabaseService.getFormDocumentByUuid(formDocument.document.uuid)
      return followUpDocument.document.lastSuccessfullSync
    }
    return undefined
  }

  private readonly updateFormDocumentStatus = (
    status: Status,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    formDocument: FormDocument
  ): FormDocument => {
    const clonedFormDocument = cloneDeep(formDocument)
    const { reviewBy, reviewAt, submittedBy, submittedAt } = value
    switch (status) {
      case Status.QC_PASSED_REQUEST_BY_REVIEWER:
      case Status.SUBMITTED:
        clonedFormDocument.document = {
          ...clonedFormDocument.document,
          lastModifiedAt: Date.now(),
          status,
          submittedAt,
          submittedBy,
        }
        break
      default:
        clonedFormDocument.document = {
          ...clonedFormDocument.document,
          lastModifiedAt: Date.now(),
          status,
          reviewAt,
          reviewBy,
        }
        break
    }
    return clonedFormDocument
  }

  public readonly updateDocumentStatus = async (status: Status, value: unknown): Promise<void> => {
    const form = useContextStore.getState().formDocument
    if (form !== undefined) {
      useContextStore.setState({ formDocument: this.updateFormDocumentStatus(status, value, form) })
      await this.updateFormDocument()
    } else {
      await remoteLoggingService.logError(
        `Cannot update the document status to ${status} since follow-up data document is undefined.`
      )
    }
  }

  public readonly updateDataChangeLog = async (changeLog: AuditLogEntry): Promise<void> => {
    const form = useContextStore.getState().formDocument
    if (form) {
      const clonedFormDocument = cloneDeep(form)
      const { action, field, value, comment } = changeLog
      const { data } = clonedFormDocument.document
      const dataObject: { data: FollowUpData } = {
        data: {
          followUp: (data as FollowUpData).followUp,
          changeLog: {
            entries: this.getUpdatedChangeLog(
              { data: clonedFormDocument.document.data },
              action,
              field,
              value,
              comment
            ),
          },
        },
      }
      clonedFormDocument.document = {
        ...(clonedFormDocument.document as FollowUp),
        ...dataObject,
      }

      useContextStore.setState({ formDocument: clonedFormDocument })
      await this.updateFormDocument()
    } else {
      await remoteLoggingService.logError(
        `Cannot update the change log to ${JSON.stringify(changeLog)} since follow-up data document is undefined.`
      )
    }
  }
}

const followUpStateService = new FollowUpStateService()
export default followUpStateService
