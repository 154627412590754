import React from 'react'
import { useTranslation } from 'react-i18next'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import {
  EnumFormMetaData,
  FormMetaDataGroupedEnumValue,
  GroupedEnumFormMetaData,
} from '../../shared/interfaces/IndiformValues.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import GroupedDropDown, { GroupedListItem } from '../ui/GroupedDropDown'
import { EnumOptions } from './Enum'

interface Props {
  enumType: string | null
  metaDataPath: string
}

const GroupedEnum = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const indiFormStore = useIndiFormStore()
  const [value, setValue] = React.useState<number | undefined>(undefined)
  const { t } = useTranslation()
  const { metaDataPath, enumType } = props
  React.useEffect(() => {
    const getInitialValue = (): number | undefined => {
      const initialValue: EnumValue | undefined = dataContext.getDataPathValue(metaDataPath)
      return initialValue?.enumId
    }
    setValue(getInitialValue())
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const getGroupedEnumOptions = (
    formMetaDataEnum: EnumFormMetaData | GroupedEnumFormMetaData | null,
    enumTypeReference: string | null
  ): Array<GroupedListItem> => {
    let groupedEnumMetaData: Array<FormMetaDataGroupedEnumValue> = []
    if (enumTypeReference !== null) {
      groupedEnumMetaData = (formMetaDataEnum as GroupedEnumFormMetaData)[enumTypeReference]
    }

    return groupedEnumMetaData.map(metaData => ({
      groupName: metaData.groupName,
      groupDisplayText: t(`enums.${enumTypeReference}.groups.${metaData.groupName}`),
      values: metaData.values.map(metaDataValue => ({
        id: metaDataValue.id,
        value: metaDataValue.text,
        text:
          t(`enums.${enumTypeReference}.values.${metaDataValue.id}`) ===
          `enums.${enumTypeReference}.values.${metaDataValue.id}`
            ? metaDataValue.text
            : t(`enums.${enumTypeReference}.values.${metaDataValue.id}`),
      })),
    }))
  }

  const groupedEnumOptions: Array<GroupedListItem> = getGroupedEnumOptions(indiFormStore.enumFormMetaData, enumType)

  const updateCase = async (selectedOption: EnumOptions | undefined): Promise<void> => {
    if (selectedOption !== undefined) {
      await dataContext.updateData(metaDataPath, {
        enumId: selectedOption.id,
        enumText: selectedOption.value,
      })
    } else {
      await dataContext.updateData(metaDataPath, undefined)
    }
  }

  return (
    <GroupedDropDown
      options={groupedEnumOptions}
      value={value}
      onMenuItemClick={updateCase}
      isClearingAllowed={false}
      placeholder=""
      metaDataPath={metaDataPath}
    />
  )
}

export default GroupedEnum
