import { Button, Popconfirm } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import authService from '../../services/auth/Auth.service'
import clinicsService from '../../services/clinics/Clinics.service'
import Status from '../../shared/interfaces/Status.interface'
import useContextStore from '../../state/Context'

const QCIndivutypeLimitedData: React.FC = (): React.ReactElement | null => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const onQCEvaluation = async (status: Status): Promise<void> => {
    const owner: string | undefined = authService.getCurrentUsername()
    await dataContext.updateDocumentStatus(status, {
      submittedBy: owner,
      submittedAt: Date.now(),
    })
    const clinicId: number = clinicsService.getClinicIdFromMatchParams(params)
    navigate(`/clinics/${clinicId}`)
  }

  const renderSubmitContent = (): React.ReactElement | null => {
    if (dataContext.formDocument !== undefined) {
      const { reviewBy } = dataContext.formDocument.document
      if (reviewBy && reviewBy !== '') {
        return (
          <>
            <Popconfirm
              title={t('form.messages.reviewer.qcPassConfirmation')}
              okText={t('form.labels.yes')}
              cancelText={t('form.labels.no')}
              onConfirm={() => onQCEvaluation(Status.QC_PASSED_REQUEST_BY_REVIEWER)}
            >
              <Button className="reviewer-button">{t('documentList.table.values.status.qcPassed')}</Button>
            </Popconfirm>
            <Popconfirm
              title={t('form.messages.reviewer.qcFailConfirmation')}
              okText={t('form.labels.yes')}
              cancelText={t('form.labels.no')}
              onConfirm={() => onQCEvaluation(Status.QC_FAILED)}
            >
              <Button danger className="ms-2 reviewer-button">
                {t('documentList.table.values.status.qcFailed')}
              </Button>
            </Popconfirm>
          </>
        )
      }
    }
    return null
  }

  return renderSubmitContent()
}

export default QCIndivutypeLimitedData
