import React from 'react'
import CompositeTypeForm from '../../components/indiform/CompositeTypeForm'
import FormElement from '../../components/indiform/FormElement'
import ListTypeForm from '../../components/indiform/ListTypeForm'
import Boolean from '../../components/indiform-ui/Boolean'
import Chemotherapy from '../../components/indiform-ui/Chemotherapy/Chemotherapy'
import Enum from '../../components/indiform-ui/Enum'
import GroupedEnum from '../../components/indiform-ui/GroupedEnum'
import HormoneTherapy from '../../components/indiform-ui/HormoneTherapy'
import Icd from '../../components/indiform-ui/Icd'
import Medication from '../../components/indiform-ui/Medication'
import MultiEnum from '../../components/indiform-ui/MultiEnum'
import OtherTherapy from '../../components/indiform-ui/OtherTherapy'
import QCIndivutypeLimitedData from '../../components/indiform-ui/QCIndivutypeLimitedData'
import RelatedData from '../../components/indiform-ui/RelatedData'
import Review from '../../components/indiform-ui/Review'
import SampleCollection from '../../components/indiform-ui/SampleCollection'
import Symptom from '../../components/indiform-ui/Symptom'
import FormCalendar, { Picker } from '../../components/ui/FormCalendar'
import FormInput from '../../components/ui/FormInput'
import FormInputNumber from '../../components/ui/FormInputNumber'
import FormPlaceholder from '../../components/ui/FormPlaceholder'
import FormRadioButton from '../../components/ui/FormRadioButton'
import FormTextArea from '../../components/ui/FormTextArea'
import TimePicker from '../../components/ui/TimePicker'
import FileUpload from '../../components/ui/file-upload/FileUpload'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import { FormElementType } from './FormElementType.enum'
import { FormType } from './FormType.enum'

class FormRenderService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderListTypeForm(metaData: any, metaDataPath: string): React.ReactElement {
    return <ListTypeForm metaData={metaData} metaDataPath={metaDataPath} />
  }

  private renderCompositeTypeForm(
    metaData: Record<string, never>,
    metaDataPath: string,
    noHeader: boolean
  ): React.ReactElement {
    return <CompositeTypeForm metaData={metaData} metaDataPath={metaDataPath} noHeader={noHeader} />
  }

  private renderFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    return <FormElement metaData={metaData} metaDataPath={metaDataPath} />
  }

  public renderFormBasedOnType(
    metaData: Record<string, never>,
    metaDataPath: string,
    noHeader = false
  ): React.ReactElement {
    const { id = this.generateRandomKey(), type } = metaData
    let form: React.ReactElement | null = null

    switch (type) {
      case FormType.COMPOSITE:
      case FormType.FIELD_GROUP:
        form = this.renderCompositeTypeForm(metaData, metaDataPath, noHeader)
        break
      case FormType.LIST:
        form = this.renderListTypeForm(metaData, metaDataPath)
        break
      default:
        form = this.renderFormElement(metaData, metaDataPath)
    }
    return <React.Fragment key={id}>{form}</React.Fragment>
  }

  private renderBooleanTypeFormElement(metaDataPath: string): React.ReactElement {
    return <Boolean metaDataPath={metaDataPath} />
  }

  private renderChemoTypeFormElement(metaDataPath: string): React.ReactElement {
    return <Chemotherapy metaDataPath={metaDataPath} />
  }

  private renderCommentTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = null } = metaData
    return <FormTextArea metaDataPath={metaDataPath} validators={validators} isAutoResized placeholder="" />
  }

  private renderDayTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = null, isCurrentOptionEnabled = true } = metaData
    return (
      <FormCalendar
        metaDataPath={metaDataPath}
        validators={validators}
        isCurrentOptionEnabled={isCurrentOptionEnabled}
        picker={Picker.DATE}
        placeholder=""
      />
    )
  }

  private renderDecimalTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = null, decimalScale } = metaData
    return (
      <FormInputNumber
        decimalScale={decimalScale}
        metaDataPath={metaDataPath}
        validators={validators}
        isDisabled={false}
        isFullWidth
        placeholder=""
      />
    )
  }

  private renderEnumTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { enumType = null, options = [], variable = '', validators = null } = metaData
    return (
      <Enum
        enumType={enumType}
        metaDataPath={metaDataPath}
        options={options}
        variable={variable}
        validators={validators}
      />
    )
  }

  private renderGroupedEnumTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { enumType = null } = metaData
    return <GroupedEnum enumType={enumType} metaDataPath={metaDataPath} />
  }

  private renderHormoneTypeFormElement(metaDataPath: string): React.ReactElement {
    return <HormoneTherapy metaDataPath={metaDataPath} />
  }

  private renderIcdTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { favorites = [] } = metaData
    return <Icd favorites={favorites} header="Disease ICD Code" metaDataPath={metaDataPath} hasFilter />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderIntegerTypeFormElement(metaData: any, metaDataPath: string): React.ReactElement {
    const { validators = null, max, decimalScale } = metaData

    if (validators !== null && max) {
      validators.max = max
    }

    return (
      <FormInputNumber
        decimalScale={decimalScale}
        metaDataPath={metaDataPath}
        validators={validators}
        isDisabled={false}
        isFullWidth
        placeholder=""
      />
    )
  }

  private renderMedicationTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    return <Medication header="Medications" hasFilter metaDataPath={metaDataPath} />
  }

  private renderMonthTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = null, isCurrentOptionEnabled = true } = metaData
    return (
      <FormCalendar
        metaDataPath={metaDataPath}
        picker={Picker.MONTH}
        validators={validators}
        isCurrentOptionEnabled={isCurrentOptionEnabled}
        placeholder=""
      />
    )
  }

  private renderMultiEnumTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { enumType, id = '', showFilter = true } = metaData
    return (
      <MultiEnum header={id} enumType={enumType} metaDataPath={metaDataPath} isFilterShown={showFilter} hasFooter />
    )
  }

  private renderSampleCollectionFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { groups, showAdditionalLabelCollection } = metaData
    return (
      <SampleCollection
        groups={groups}
        isAdditionalLabelCollectionShowed={showAdditionalLabelCollection}
        metaDataPath={metaDataPath}
      />
    )
  }

  private renderOtherTypeFormElement(metaDataPath: string): React.ReactElement {
    return <OtherTherapy metaDataPath={metaDataPath} />
  }

  private renderOutputTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { defaultValue = '' } = metaData
    return (
      <FormInput
        metaDataPath={metaDataPath}
        defaultValue={defaultValue}
        isDisabled
        isFullWidth
        placeholder=""
        validators={null}
      />
    )
  }

  private renderPlaceholderTypeFormElement(metaData: Record<string, never>): React.ReactElement {
    const { multiColumnLayout = 1 } = metaData
    return <FormPlaceholder multiColumnLayout={multiColumnLayout} />
  }

  private renderRadioBoxTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { enumType = null, variable = '', validators = null, options = [] } = metaData
    return (
      <FormRadioButton
        enumType={enumType}
        metaDataPath={metaDataPath}
        options={options}
        variable={variable}
        validators={validators}
      />
    )
  }

  private renderReviewTypeFormElement(): React.ReactElement {
    return <Review />
  }

  private renderQCLimitedDataTypeFormElement(): React.ReactElement {
    return <QCIndivutypeLimitedData />
  }

  private renderStringTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = null, defaultValue, disabled = false } = metaData
    return (
      <FormInput
        defaultValue={defaultValue}
        isDisabled={disabled}
        metaDataPath={metaDataPath}
        validators={validators}
        isFullWidth
        placeholder=""
      />
    )
  }

  private renderSymptomTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { enumType = '' } = metaData
    return <Symptom enumType={enumType} metaDataPath={metaDataPath} />
  }

  private renderTimeTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    return <TimePicker metaDataPath={metaDataPath} />
  }

  private renderYearTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { validators = {}, isCurrentOptionEnabled = true } = metaData
    return (
      <FormCalendar
        metaDataPath={metaDataPath}
        picker={Picker.YEAR}
        validators={validators}
        isCurrentOptionEnabled={isCurrentOptionEnabled}
        placeholder=""
      />
    )
  }

  private renderFileTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { accept, pathPrefix } = metaData
    return <FileUpload metaDataPath={metaDataPath} accept={accept} pathPrefix={pathPrefix} isMultiple={false} />
  }

  private renderRelatedDataTypeFormElement(metaData: Record<string, never>, metaDataPath: string): React.ReactElement {
    const { translationPath } = metaData
    return <RelatedData metaDataPath={metaDataPath} translationPath={translationPath} />
  }

  public renderFormElementBasedOnType(
    metaData: Record<string, never>,
    metaDataPath: string
  ): React.ReactElement | null {
    switch (metaData.type) {
      case FormElementType.BOOLEAN:
        return this.renderBooleanTypeFormElement(metaDataPath)
      case FormElementType.CHEMO:
        return this.renderChemoTypeFormElement(metaDataPath)
      case FormElementType.CLEAR_FIX:
        return <div />
      case FormElementType.COMMENT:
        return this.renderCommentTypeFormElement(metaData, metaDataPath)
      case FormElementType.DATE:
        return this.renderDayTypeFormElement(metaData, metaDataPath)
      case FormElementType.DAY:
        return this.renderDayTypeFormElement(metaData, metaDataPath)
      case FormElementType.DECIMAL:
        return this.renderDecimalTypeFormElement(metaData, metaDataPath)
      case FormElementType.ENUM:
        return this.renderEnumTypeFormElement(metaData, metaDataPath)
      case FormElementType.GROUPED_ENUM:
        return this.renderGroupedEnumTypeFormElement(metaData, metaDataPath)
      case FormElementType.HORMONE:
        return this.renderHormoneTypeFormElement(metaDataPath)
      case FormElementType.ICD:
        return this.renderIcdTypeFormElement(metaData, metaDataPath)
      case FormElementType.INTEGER:
        return this.renderIntegerTypeFormElement(metaData, metaDataPath)
      case FormElementType.MEDICATION:
        return this.renderMedicationTypeFormElement(metaData, metaDataPath)
      case FormElementType.MONTH:
        return this.renderMonthTypeFormElement(metaData, metaDataPath)
      case FormElementType.MULTI_ENUM:
        return this.renderMultiEnumTypeFormElement(metaData, metaDataPath)
      case FormElementType.SAMPLE_COLLECTION:
        return this.renderSampleCollectionFormElement(metaData, metaDataPath)
      case FormElementType.OTHER:
        return this.renderOtherTypeFormElement(metaDataPath)
      case FormElementType.OUTPUT:
        return this.renderOutputTypeFormElement(metaData, metaDataPath)
      case FormElementType.PLACEHOLDER:
        return this.renderPlaceholderTypeFormElement(metaData)
      case FormElementType.RADIO_BOX:
        return this.renderRadioBoxTypeFormElement(metaData, metaDataPath)
      case FormElementType.REVIEW:
        return this.renderReviewTypeFormElement()
      case FormElementType.QC_LIMITED_DATA:
        return this.renderQCLimitedDataTypeFormElement()
      case FormElementType.STRING:
        return this.renderStringTypeFormElement(metaData, metaDataPath)
      case FormElementType.SYMPTOM:
        return this.renderSymptomTypeFormElement(metaData, metaDataPath)
      case FormElementType.TIME:
        return this.renderTimeTypeFormElement(metaData, metaDataPath)
      case FormElementType.YEAR:
        return this.renderYearTypeFormElement(metaData, metaDataPath)
      case FormElementType.FILE:
        return this.renderFileTypeFormElement(metaData, metaDataPath)
      case FormElementType.RELATED_DATA:
        return this.renderRelatedDataTypeFormElement(metaData, metaDataPath)
      default:
        remoteLoggingService.logError(`The form type ${metaData.type} doesn't exist.`)
        return null
    }
  }

  public generateRandomKey(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }
}

const formRenderService = new FormRenderService()
export default formRenderService
