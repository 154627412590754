import { Alert, App, Button, Col, Input, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import casesService from '../../services/documents/Cases.service'
import followUpsService from '../../services/documents/FollowUps.service'
import existingDocumentModalService from '../../services/existing-document-modal/ExistingDocumentModal.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ExistingDocument } from '../../shared/interfaces/ExistingDocument.interface'
import { ExistingDocuments } from '../../shared/interfaces/ExistingDocuments.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import FormModal from '../ui/FormModal'
import '../ui/Form.scss'

enum ModalBodyStatus {
  ERROR = 'error',
}

interface Props {
  clinic: Clinic
  setCaseNo: (caseNo: string) => void
  documentType: IndiFormType
  setExistingDocuments: (existingDocuments: ExistingDocuments<Array<FollowUp>>) => void
  isVisible: boolean
  onCancel: () => void
  onSelectExistingFollowUpModal: () => void
}

const LoadExistingDocumentSummaryModal: React.FC<Props> = (props): React.ReactElement => {
  const {
    documentType,
    clinic,
    isVisible,
    setExistingDocuments: setExistingDocumentsFromProps,
    setCaseNo,
    onSelectExistingFollowUpModal,
    onCancel,
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [documentNo, setDocumentNo] = React.useState<string>('')
  const [isSearchInputDisabled, disableSearchInput] = React.useState<boolean>(false)
  const [isSearchDocumentButtonDisabled, disableSearchDocumentButton] = React.useState<boolean>(false)
  const [modalBodyStatusState, setModalBodyStatus] = React.useState<ModalBodyStatus | null>(null)
  const { notification } = App.useApp()

  React.useEffect(() => {
    if (documentType === IndiFormType.CASE) {
      setDocumentNo(clinic.prefix[0] ?? '')
    } else {
      setDocumentNo('')
    }
  }, [clinic, isVisible])

  const disableModelElements = (modalBodyStatus?: ModalBodyStatus): void => {
    disableSearchInput(false)
    disableSearchDocumentButton(false)
    setModalBodyStatus(modalBodyStatus ?? null)
  }

  const onModalCancel = (): void => {
    onCancel()
    disableModelElements()
  }

  const getFollowUpDocumentsByCaseNo = async (caseNo: string): Promise<void> => {
    const existingDocumentsList: ExistingDocuments<Array<FollowUp>> | null =
      await followUpsService.getSubmittedFollowUpDocumentsByCaseNo(caseNo)

    if (existingDocumentsList) {
      if (existingDocumentsList?.indivupad.length === 0) {
        existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
      } else {
        setCaseNo(caseNo)
        setExistingDocumentsFromProps(existingDocumentsList)
        onSelectExistingFollowUpModal()
      }
      onModalCancel()
    }
  }

  const redirectToSummary = (submittedDocument: Case | FollowUp): void => {
    navigate(
      `/clinics/${clinic.id}/${documentType === IndiFormType.CASE ? 'caseSummary' : 'followUpSummary'}/${
        submittedDocument?.uuid
      }`,
      {
        state: { submittedDocument: { document: submittedDocument } },
      }
    )
  }

  const selectExistingDocument = async (document: Case | FollowUp | undefined): Promise<void> => {
    if (document !== undefined) {
      redirectToSummary(document)
    }
  }

  const getExistingDocumentsByDocumentNo = async (): Promise<void> => {
    disableSearchInput(true)
    disableSearchDocumentButton(true)

    if (documentType === IndiFormType.FOLLOWUP) {
      if (documentNo.includes('-')) {
        await getFollowUpDocumentsByCaseNo(documentNo.split('-')[0])
      } else {
        await getFollowUpDocumentsByCaseNo(documentNo)
      }
    } else {
      const documents: ExistingDocument<Case> | null = await casesService.getSubmittedCaseDocumentsByCaseNo(documentNo)

      if (documents !== null) {
        const { indivupad } = documents

        if (indivupad) {
          await selectExistingDocument(indivupad)
        } else {
          // If document is not available in both, show error message that the document number is invalid
          disableModelElements(ModalBodyStatus.ERROR)
        }
      } else {
        existingDocumentModalService.showErrorNotification(documentType, documentNo, notification, t)
        onModalCancel()
      }
    }
  }

  const getPlaceholder = (): string =>
    documentType === IndiFormType.CASE ? documentType : `${IndiFormType.CASE}/${IndiFormType.FOLLOWUP}`

  const renderLoadDocumentSummaryModalBody = (): React.ReactElement => (
    <>
      <Row>
        <Col className="w-100 d-flex justify-content-between">
          <Input
            className="me-1"
            disabled={isSearchInputDisabled}
            placeholder={
              t('documentList.loadDocumentModal.placeholder', {
                documentType: getPlaceholder(),
              }) ?? ''
            }
            value={documentNo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDocumentNo(event.target.value)}
          />
          <Button disabled={isSearchDocumentButtonDisabled} onClick={getExistingDocumentsByDocumentNo}>
            {t('documentList.loadDocumentModal.searchButtonText', { documentType })}
          </Button>
        </Col>
      </Row>
      <Row className="my-2" style={{ minHeight: '120px' }}>
        {modalBodyStatusState === ModalBodyStatus.ERROR && (
          <Alert
            message={t('documentList.loadDocumentModal.documentNoError', { documentType, documentNo })}
            type="error"
          />
        )}
      </Row>
    </>
  )

  return (
    <FormModal
      onCancel={onModalCancel}
      header={t('documentList.loadDocumentModal.summaryTitle', {
        documentType: `${documentType[0].toUpperCase()}${documentType.slice(1)}`,
      })}
      isVisible={isVisible}
      width="50%"
      isClosable={false}
      isDismissableMask={false}
      footer={null}
    >
      {renderLoadDocumentSummaryModalBody()}
    </FormModal>
  )
}

export default LoadExistingDocumentSummaryModal
