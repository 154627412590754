import { Col, Row } from 'antd'
import React from 'react'
import indiFormService, { TherapyType } from '../../services/indiform/IndiForm.service'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import { HormoneFormMetaData } from '../../shared/interfaces/IndiformValues.interface'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'
import FormDropDown, { DropdownOption } from '../ui/FormDropDown'
import FormLabel from '../ui/FormLabel'

interface Props {
  metaDataPath: string
}

const HormoneTherapy: React.FC<Props> = (props): React.ReactElement | null => {
  const dataContext = useContextStore()
  const indiFormStore = useIndiFormStore()
  const hormoneMetaData: HormoneFormMetaData | null = indiFormStore.hormoneFormMetaData
  const HORMONE_THERAPY_AGENT_GROUP_PATH = `${props.metaDataPath}.agentGroup`
  const HORMONE_THERAPY_AGENT_PATH = `${props.metaDataPath}.agent`

  const [agentGroup, setAgentGroup] = React.useState<number | undefined>(undefined)
  const [agent, setAgent] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    const getHormoneTherapyAgentGroupValue = (): number | undefined => {
      const value: EnumValue | undefined = dataContext.getDataPathValue(HORMONE_THERAPY_AGENT_GROUP_PATH)
      return value?.enumId as number
    }

    const getHormoneTherapyAgentValue = (): number | undefined => {
      const value: EnumValue | undefined = dataContext.getDataPathValue(HORMONE_THERAPY_AGENT_PATH)
      return value?.enumId as number
    }

    setAgentGroup(getHormoneTherapyAgentGroupValue())
    setAgent(getHormoneTherapyAgentValue())
  }, [dataContext, dataContext.formDocument, props.metaDataPath])

  const setHormoneTherapyAgentValue = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    if (selectedOption === undefined) {
      await dataContext.updateData(HORMONE_THERAPY_AGENT_PATH, selectedOption)
    } else {
      const value: EnumValue = indiFormService.getEnumValueObject(selectedOption.value as number, selectedOption.label)
      await dataContext.updateData(HORMONE_THERAPY_AGENT_PATH, value)
    }
  }

  const setHormoneTherapyAgentGroupValue = async (selectedOption: DropdownOption | undefined): Promise<void> => {
    await setHormoneTherapyAgentValue(undefined)
    if (selectedOption === undefined) {
      await dataContext.updateData(HORMONE_THERAPY_AGENT_GROUP_PATH, selectedOption)
    } else {
      const value: EnumValue = indiFormService.getEnumValueObject(selectedOption.value as number, selectedOption.label)
      await dataContext.updateData(HORMONE_THERAPY_AGENT_GROUP_PATH, value)
    }
  }

  const renderHormoneTherapy = (): React.ReactElement | null => {
    if (hormoneMetaData !== null) {
      return (
        <Row gutter={16}>
          <Col span={12}>
            <FormLabel label="group" metaDataPath={props.metaDataPath} />
            <FormDropDown
              options={indiFormService.getGroupOptions(hormoneMetaData, TherapyType.HORMONE)}
              value={agentGroup}
              onChange={setHormoneTherapyAgentGroupValue}
              isClearingAllowed={false}
              placeholder=""
              validators={null}
              metadataPath={props.metaDataPath}
            />
          </Col>
          <Col span={12}>
            <FormLabel label="agent" metaDataPath={props.metaDataPath} />
            <FormDropDown
              options={indiFormService.getGroupAgents(hormoneMetaData, TherapyType.HORMONE, agentGroup as number)}
              value={agent}
              onChange={setHormoneTherapyAgentValue}
              isClearingAllowed={false}
              placeholder=""
              validators={null}
              metadataPath={props.metaDataPath}
            />
          </Col>
        </Row>
      )
    }
    return null
  }

  return renderHormoneTherapy()
}

export default HormoneTherapy
