import { Alert, Layout } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ClinicList from '../../components/clinics/ClinicList'
import useAppStore from '../../state/App'
import useAuthStore from '../../state/Auth'
import useClinicStore from '../../state/Clinic'

type Props = WithTranslation

const Clinics = (props: Props): React.ReactElement => {
  const clinicStore = useClinicStore()
  const appStore = useAppStore()

  const { clinics, setCurrentCasePageNumber, setCurrentFollowUpPageNumber } = clinicStore

  useEffect(() => {
    appStore.setNavbarHeader('Hospitals')
    setCurrentCasePageNumber(1)
    setCurrentFollowUpPageNumber(1)
  }, [])

  const renderPasswordExpirationAlert = (): React.ReactElement | null => {
    const { t } = props
    const { lastPasswordChangeDate } = useAuthStore.getState()
    if (lastPasswordChangeDate !== null) {
      const ninetyDaysFromLastPasswordChange: number = lastPasswordChangeDate + 90 * 24 * 60 * 60 * 1000
      const differenceInDays: number = dayjs(ninetyDaysFromLastPasswordChange).diff(dayjs(new Date()), 'day')
      if (differenceInDays >= 0 && differenceInDays <= 15) {
        return <Alert message={t('form.messages.passwordExpire', { differenceInDays })} type="warning" />
      }
    }
    return null
  }

  return (
    <Layout>
      <div className="my-1 text-center">{renderPasswordExpirationAlert()}</div>
      <ClinicList clinics={clinics} />
    </Layout>
  )
}

export default withTranslation()(Clinics)
