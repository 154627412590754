/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, set, unset } from 'lodash-es'

export default class ObjectUtils {
  public static getAt = (obj: any, path: string, defaultValue?: string | Array<Record<string, never>>): any =>
    get(obj, path, defaultValue)

  public static setAt = (obj: any, path: string, value: string): any => set(obj, path, value)

  public static unsetAt = (obj: Record<string, unknown>, path: string): boolean => unset(obj, path)
}
