/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajv, { JSONSchemaType } from 'ajv'
import { Case } from '../../shared/interfaces/Case.interface'
import { ClinicFormMetaData } from '../../shared/interfaces/ClinicFormMetaData.interface'
import { ClinicMetaDataValidator } from '../../shared/interfaces/ClinicValidationMetaData.interface'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import ObjectUtils from '../../utils/Object'
import ValidationType from './ValidationType.enum'

// TODO: Include validations for the remaining stuff
class ValidationService {
  private readonly ajv = new Ajv({ coerceTypes: true })

  public generateValidationMetaData(formMetaData: ClinicFormMetaData): Array<ClinicMetaDataValidator> {
    const generatedValidations = formMetaData.children?.flatMap((metaData: object) =>
      this.checkFormMetaValidation(metaData)
    )
    const validations = generatedValidations?.filter(
      (validation): validation is ClinicMetaDataValidator => validation !== null
    )
    return validations
  }

  private getValidationSchema(validators: Record<string, never>): JSONSchemaType<any> {
    const { required, ...validatorValues } = validators

    const schema = {
      type: 'object',
      properties: {
        value: validatorValues,
      },
      required: required && ['value'],
    }

    return schema as JSONSchemaType<any>
  }

  public isFormElementValid(
    validators: Record<string, never> | null,
    formElementValue: string | number | EnumValue | undefined
  ): boolean {
    if (validators === null) {
      return true
    }
    const validate = this.ajv.compile(this.getValidationSchema(validators))
    return validate({ value: formElementValue })
  }

  public isDocumentValid(
    document: Case | FollowUp,
    clinicFormMetaData: Array<ClinicMetaDataValidator> | undefined
  ): boolean {
    if (clinicFormMetaData) {
      return !clinicFormMetaData?.some(validation => {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const value: any = ObjectUtils.getAt(document, validation.metaDataPath)
        if (value !== undefined && value !== null) {
          return !this.isFormElementValid(validation.validator, value)
        }

        if (validation.metaDataPath === 'files.type') {
          return false
        }
        return true
      })
    }
    return true
  }

  private checkFormMetaValidation(formMetaData: any, childVariable = ''): Array<ClinicMetaDataValidator | null> {
    const parentVariable: string = this.buildVariableValidationPath(formMetaData.variable, childVariable)
    if (Array.isArray(formMetaData.children)) {
      return this.checkFormMetaValidation(formMetaData.children, parentVariable)
    } else if (Array.isArray(formMetaData)) {
      const validations = formMetaData.flatMap(field => {
        const validationVariable: string = this.buildVariableValidationPath(parentVariable, field.variable)
        if (Array.isArray(field.children)) {
          return this.checkFormMetaValidation(field.children, validationVariable)
        }
        const metaDataPath = this.buildVariableValidationPath(childVariable, field.variable)
        return this.validateDocumentRequiredField(field.validators, metaDataPath)
      })
      return validations
    }
    return []
  }

  private buildVariableValidationPath(parentVariable: string, childVariable: string): string {
    if (parentVariable && childVariable) {
      return parentVariable.concat('.', childVariable)
    } else if (parentVariable) {
      return parentVariable
    }
    return childVariable
  }

  private validateDocumentRequiredField(
    validator: Record<string, never>,
    metaDataPath: string
  ): ClinicMetaDataValidator | null {
    if (validator) {
      if (Object.entries(validator).some(([key, value]) => key === ValidationType.REQUIRED)) {
        return {
          validator,
          metaDataPath,
        }
      }
    }
    return null
  }
}

const validationService = new ValidationService()
export default validationService
