import { Col } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import sharedStateHelper from '../../document-state-service/SharedStateHelper.service'
import evaluationService from '../../services/indiform/Evaluation.service'
import formRenderService from '../../services/indiform/FormRender.service'
import './FormType.scss'
import useContextStore from '../../state/Context'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaData: any
  metaDataPath: string
  noHeader: boolean
}

const CompositeTypeForm: React.FC<Props> = (props): React.ReactElement | null => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { id = '', children = [], noHeader, hideIf, showIf, variable = '' } = props.metaData

  const isShown = (): boolean =>
    evaluationService.evaluateExpression(hideIf, showIf, dataContext.formDocument, props.metaDataPath)

  React.useEffect(() => {
    if (!isShown()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      children.forEach(async (child: any) => {
        const { variable: variableFromChild = '' } = child
        const updatedPath: string = sharedStateHelper.buildMetaDataPath(props.metaDataPath, variableFromChild)
        const metaDataPath: string = sharedStateHelper.buildDataPath(updatedPath)
        const caseDataPathValue = dataContext.getDataPathValue(metaDataPath)
        if (caseDataPathValue !== undefined) {
          await dataContext.updateData(metaDataPath, undefined)
        }
      })
    }
  }, [dataContext.formDocument])

  const renderCompositeTypeForm = (): React.ReactElement | null => {
    const updatedPath: string = sharedStateHelper.buildMetaDataPath(props.metaDataPath, variable)
    if (isShown()) {
      return (
        <>
          {!noHeader && !props.noHeader && (
            <Col span={24} className="p-0 my-2">
              <header className="px-4 d-flex align-items-center form-sub-header">
                <h4 className="m-0">{id !== '' ? t(`form.labels.${id}`) : id}</h4>
              </header>
            </Col>
          )}

          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            children.map((child: any) => formRenderService.renderFormBasedOnType(child, updatedPath, noHeader))
          }
        </>
      )
    }
    return null
  }

  return renderCompositeTypeForm()
}

export default CompositeTypeForm
