import authService from '../auth/Auth.service'
import Roles from '../auth/Roles.enum'
import IndiFormType from '../indiform/IndiFormType.enum'

class EditorService {
  public checkUserPermission(formType: IndiFormType | undefined): boolean {
    const isCaseCollector: boolean = authService.hasRole(Roles.INDIVUPAD_CASE_COLLECTOR)
    const isDocumentReviewer: boolean = authService.hasRole(Roles.INDIVUPAD_DOCUMENT_REVIEWER)
    const isFollowUpCollector: boolean = authService.hasRole(Roles.INDIVUPAD_FOLLOWUP_COLLECTOR)

    switch (formType) {
      case IndiFormType.CASE:
        return isCaseCollector || isDocumentReviewer
      case IndiFormType.FOLLOWUP:
        return isFollowUpCollector || isDocumentReviewer
      default:
        return false
    }
  }
}

const editorService = new EditorService()
export default editorService
