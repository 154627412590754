import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'
import { useTranslation } from 'react-i18next'
import indiFormService from '../../services/indiform/IndiForm.service'
import AuditLogAction from '../../services/indiform/change-log/AuditLogAction.enum'
import validationService from '../../services/indiform-validation/Validation.service'
import { EnumValue } from '../../shared/interfaces/EnumValue.interface'
import './Form.scss'
import useContextStore from '../../state/Context'
import useIndiFormStore from '../../state/IndiForm'

export interface RadioOption {
  id: number
  text: string
  value: string | number
  displayLabel: string
}

interface Props {
  enumType: string | null
  metaDataPath: string
  options: Array<RadioOption>
  variable: string
  validators: Record<string, never> | null
}

const FormRadioButton = (props: Props): React.ReactElement => {
  const dataContext = useContextStore()
  const { t } = useTranslation()
  const { Group } = Radio
  const { enumType, metaDataPath, validators, variable, options } = props
  const indiFormStore = useIndiFormStore()

  const radioOptions: Array<RadioOption> = indiFormService.getEnumRadioOptions(indiFormStore, enumType as string, t)

  const [value, setValue] = React.useState<string | number | EnumValue | undefined>(undefined)
  const [isValid, setValidity] = React.useState<boolean>()
  const getOptionsList = options.length > 0 ? options : radioOptions

  React.useEffect(() => {
    const formElementValue: string | EnumValue | undefined = dataContext.getDataPathValue(metaDataPath)
    if (options.length > 0 || formElementValue === undefined) {
      setValue(formElementValue)
    } else {
      const { enumId } = formElementValue as EnumValue
      setValue(enumId)
    }
  }, [dataContext, dataContext.formDocument, metaDataPath])

  const onChange = async (event: RadioChangeEvent): Promise<void> => {
    if (options.length > 0) {
      await dataContext.updateData(metaDataPath, event.target.value)
    } else {
      const radioOption: RadioOption | undefined = radioOptions.find(
        radioOptionItem => radioOptionItem.value === event.target.value
      )
      if (radioOption !== undefined) {
        const optionValue: EnumValue = indiFormService.getEnumValueObject(radioOption.value as number, radioOption.text)
        await dataContext.updateData(metaDataPath, optionValue)
      }
    }
  }

  const clearValue = async (): Promise<void> => {
    setValue(undefined)
    await dataContext.updateData(metaDataPath, undefined, AuditLogAction.DELETE)
  }

  React.useEffect(() => {
    const isInputValid: boolean = validationService.isFormElementValid(validators, value)
    setValidity(isInputValid)
  }, [value])

  const selectClassName = (): string => {
    if (validators === null) {
      return ''
    }
    if (isValid) {
      return 'p-success'
    }
    return 'p-error'
  }

  return (
    <div className="d-flex align-items-center">
      <div className={`ant-input w-100 ${selectClassName()}`}>
        <Group className="d-flex flex-wrap" onChange={onChange} value={value}>
          {getOptionsList.map(radioOption => (
            <Radio key={`${metaDataPath}${radioOption.id}`} value={radioOption.value} data-testid={metaDataPath}>
              {enumType !== null ? radioOption.displayLabel : t(`options.${variable}.${radioOption.value}`)}
            </Radio>
          ))}
        </Group>
      </div>
      {value !== undefined && (
        <FontAwesomeIcon className="cursor-pointer ms-2" icon={['fal', 'times-circle']} onClick={clearValue} />
      )}
    </div>
  )
}

export default FormRadioButton
