import CryptoJs from 'crypto-js'

class EncryptionService {
  private readonly encoding = CryptoJs.enc.Hex

  public encrypt(content: string): string {
    return CryptoJs.MD5(content).toString(this.encoding)
  }
}

const encryptionService = new EncryptionService()
export default encryptionService
