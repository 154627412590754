enum CalculatedField {
  PREPARATION_FREEZE_TIME = 'preparation.freezeTime',
  PREPARATION_FORMALIN_TIME = 'preparation.formalinTime',
  PREPARATION_RESECTION_COMPLETED = 'resectionTimes.resectionCompleted',
  METASTASIS_PREPARATION_FREEZE_TIME = 'metastasispreparations.metastasispreparation.freezeTime',
  METASTASIS_PREPARATION_FORMALIN_TIME = 'metastasispreparations.metastasispreparation.formalinTime',
  METASTASIS_PREPARATION_RESECTION_COMPLETED = 'metastasispreparations.metastasispreparation.resectionCompleted',
}

export default CalculatedField
