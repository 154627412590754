import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { App, Button, Dropdown, MenuProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ContextMenuOptionValue from '../../services/document-list/ContextMenuOptionValue.enum'
import staticDocumentListService from '../../services/document-list/StaticDocumentList.service'
import IndiFormType from '../../services/indiform/IndiFormType.enum'
import { Case } from '../../shared/interfaces/Case.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ClinicMetaDataValidator } from '../../shared/interfaces/ClinicValidationMetaData.interface'
import { ContextMenuOption } from '../../shared/interfaces/ContextMenuOption.interface'
import { FollowUp } from '../../shared/interfaces/FollowUp.interface'
import { FollowUpDocument } from '../../shared/interfaces/FollowUpDocument.interface'
import Status from '../../shared/interfaces/Status.interface'

interface OwnProps {
  clinic: Clinic
  document: Case | FollowUp
  validators: Array<ClinicMetaDataValidator> | undefined
  onEditCaseNoClick?: (followUpDocument: FollowUpDocument) => void
  onDocumentMarkCompleted: (documentToBeMarked: Case | FollowUp, status: Status) => void
  onPushClick: (documentToBePushed: Case | FollowUp) => void
  onDeleteClick: (documentToBeDeleted: Case | FollowUp) => void
  formType: IndiFormType
}

type Props = OwnProps

const ContextMenu: React.FC<Props> = (props): React.ReactElement => {
  const { modal } = App.useApp()
  const { t } = useTranslation()
  const { clinic, document, formType, validators } = props
  const isDocumentValid: boolean = staticDocumentListService.isDocumentValid(document, formType, clinic, validators)
  const navigate = useNavigate()

  const getConfirmationTitle = (menuOption: ContextMenuOption): string => {
    const translationFormType = formType === IndiFormType.CASE ? 'Case' : 'FollowUp'
    switch (menuOption.value) {
      case ContextMenuOptionValue.SUBMIT:
        return t(`documentList.contextMenu.submit${translationFormType}Confirmation`)
      case ContextMenuOptionValue.DELETE:
        return t(`documentList.contextMenu.delete${translationFormType}Confirmation`)
      case ContextMenuOptionValue.DATA_COMPLETED:
        return t(`documentList.contextMenu.markDataCompleted${translationFormType}Confirmation`)
      case ContextMenuOptionValue.REVIEW_COMPLETED:
        return t(`documentList.contextMenu.markReviewCompleted${translationFormType}Confirmation`)
      default:
        return ''
    }
  }

  const onOkConfirmation = (menuOption: ContextMenuOption): void => {
    switch (menuOption.value) {
      case ContextMenuOptionValue.SUBMIT:
        props.onPushClick(document)
        break
      case ContextMenuOptionValue.DELETE:
        props.onDeleteClick(document)
        break
      case ContextMenuOptionValue.DATA_COMPLETED:
        if (props.onDocumentMarkCompleted) props.onDocumentMarkCompleted(document, Status.DATA_COMPLETED)
        break
      case ContextMenuOptionValue.REVIEW_COMPLETED:
        if (props.onDocumentMarkCompleted) props.onDocumentMarkCompleted(document, Status.REVIEW_COMPLETED)
        break
      default:
        break
    }
  }

  const renderConfirmModal = (menuOption: ContextMenuOption): void => {
    let showConfirmModal = true
    modal.confirm({
      title: getConfirmationTitle(menuOption),
      okText: t('form.labels.yes'),
      cancelText: t('form.labels.no'),
      onOk: () => {
        onOkConfirmation(menuOption)
        showConfirmModal = false
      },
      open: showConfirmModal,
    })
  }

  const onDropDownItemClick = (menuOption: ContextMenuOption, documentData: Case | FollowUp): void => {
    switch (menuOption.value) {
      case ContextMenuOptionValue.EDIT_CASE_NUMBER:
        if (props.onEditCaseNoClick) props.onEditCaseNoClick({ document: documentData as FollowUp })
        break
      case ContextMenuOptionValue.SUMMARY:
        navigate(
          `/clinics/${clinic.id}/${formType === IndiFormType.CASE ? 'caseSummary' : 'followUpSummary'}/${
            documentData.uuid
          }`
        )
        break
      case ContextMenuOptionValue.DATA_COMPLETED:
      case ContextMenuOptionValue.REVIEW_COMPLETED:
      case ContextMenuOptionValue.SUBMIT:
      case ContextMenuOptionValue.DELETE:
        renderConfirmModal(menuOption)
        break
      default:
        break
    }
  }

  const renderContextDropDown = (): React.ReactElement => {
    const items = staticDocumentListService
      .getContextMenuOptions(t)
      .filter((menuOption: ContextMenuOption) => staticDocumentListService.isMenuOptionShown(menuOption, formType))
      .map((menuOption: ContextMenuOption, index: number) => ({
        key: index,
        disabled: !staticDocumentListService.isMenuOptionEnabled(document, menuOption, formType, isDocumentValid),
        onClick: () => onDropDownItemClick(menuOption, document),
        label: menuOption.label,
        icon: <FontAwesomeIcon className="form-menu-item-icon" icon={['fal', menuOption.icon]} />,
      }))

    const menu: MenuProps = {
      items,
    }

    return (
      <Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
        <Button shape="round" icon={<FontAwesomeIcon icon={['fal', 'ellipsis-h']} />} />
      </Dropdown>
    )
  }

  return renderContextDropDown()
}

export default ContextMenu
