import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLogModal, { ChangeLogElement } from '../indiform-ui/ChangeLogModal'
import FormModal from './FormModal'

interface Props {
  label: string
  metaDataPath: string
}

const FormLabel = (props: Props): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const [showModal, toggleModal] = React.useState<boolean>(false)
  const { label, metaDataPath } = props

  return (
    <div className="d-flex justify-content-between align-items-center py-1 w-100">
      <div>
        {label !== '' ? t(`form.labels.${label}`) : label}
        <ChangeLogModal label={label} metaDataPath={metaDataPath} type={ChangeLogElement.ICON} />
      </div>
      {i18n.exists(`form.help.${label}`) && (
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={['fal', 'question-circle']}
          onClick={() => toggleModal(true)}
        />
      )}
      <FormModal
        header={t(`form.labels.${label}`)}
        onCancel={() => toggleModal(false)}
        isVisible={showModal}
        isClosable
        isDismissableMask={false}
        footer={null}
        width="unset"
      >
        <div style={{ whiteSpace: 'pre-line' }}>{t(`form.help.${label}`)}</div>
      </FormModal>
    </div>
  )
}

export default FormLabel
