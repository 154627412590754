import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthTokenObject } from '../../services/auth/Auth.service'

const PrivateRoute = ({
  children,
  authToken,
}: {
  children: React.ReactElement
  authToken: AuthTokenObject | null
}): React.ReactElement => {
  if (authToken) {
    return children
  }
  return <Navigate replace to="/login" />
}

export default PrivateRoute
