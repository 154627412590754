import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import React from 'react'
import { ValidationMessage } from '../../shared/interfaces/ValidationMessage.interface'

interface Props {
  messages: Array<ValidationMessage>
}

const DocumentValidationMessages: React.FC<Props> = (props): React.ReactElement | null => {
  const renderDocumentValidationMessages = (): React.ReactElement | null => {
    const { messages } = props
    if (messages.length > 0) {
      const content: React.ReactElement = (
        <div data-testid="validationMessages">
          {messages.map((message: ValidationMessage, index: number) => (
            <span className="d-block" key={index}>
              {message.text}
            </span>
          ))}
        </div>
      )
      return (
        <Popover content={content} placement="bottomRight" trigger="click" showArrow={false}>
          <Button shape="round" icon={<FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />} danger />
        </Popover>
      )
    }
    return null
  }

  return renderDocumentValidationMessages()
}

export default DocumentValidationMessages
