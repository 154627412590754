import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios'
import { cloneDeep } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { AuditLogEntry } from '../../shared/interfaces/AuditLogEntry.interface'
import { Case } from '../../shared/interfaces/Case.interface'
import { CaseDocument } from '../../shared/interfaces/CaseDocument.interface'
import { Clinic } from '../../shared/interfaces/Clinic.interface'
import { ExistingDocument } from '../../shared/interfaces/ExistingDocument.interface'
import { FormDocument } from '../../shared/interfaces/FormDocument.interface'
import Status from '../../shared/interfaces/Status.interface'
import authService from '../auth/Auth.service'
import caseIndexDatabaseService from '../browser-storage/index-database/CaseIndexDatabase.service'
import filesService from '../files/Files.service'
import AuditLogAction from '../indiform/change-log/AuditLogAction.enum'
import caseChangeLogService from '../indiform/change-log/CaseChangeLog.service'
import remoteLoggingService from '../logging-handler/RemoteLogging.service'
import navigatorService from '../navigator/Navigator.service'
import casesResourceService from '../rest-resources/CasesResource.service'

class CasesService {
  public createAndGetCaseDocument(clinic: Clinic): CaseDocument | undefined {
    const owner: string | undefined = authService.getCurrentUsername()
    if (owner !== undefined) {
      const entries: Array<AuditLogEntry> = [
        this.getAuditLogForInitialCaseNo(clinic),
        this.getAuditLogForHospital(clinic),
      ].filter<AuditLogEntry>(
        (auditLogEntry: AuditLogEntry | null): auditLogEntry is AuditLogEntry => auditLogEntry !== null
      )
      return {
        document: {
          uuid: uuid(),
          status: Status.IN_PROCESS,
          data: {
            case: {
              caseNo: clinic.prefix[0],
              hospital: {
                enumId: clinic.id,
                enumText: clinic.name,
              },
            },
            changeLog: {
              entries,
            },
          },
          owner,
          messages: [],
          lastModifiedAt: Date.now(),
          pushRequestedAt: undefined,
          pushAcknowledgedAt: undefined,
        },
      }
    }
    return owner
  }

  private getAuditLogForInitialCaseNo(clinic: Clinic): AuditLogEntry | null {
    return caseChangeLogService.getAuditLogDataChange(AuditLogAction.SET, 'data.case.caseNo', clinic.prefix[0], '')
  }

  private getAuditLogForHospital(clinic: Clinic): AuditLogEntry | null {
    return caseChangeLogService.getAuditLogDataChange(
      AuditLogAction.SET,
      'data.case.hospital',
      {
        enumId: clinic.id,
        enumText: clinic.name,
      },
      ''
    )
  }

  public async getCaseDocumentsFromIndexDB(clinicId: number): Promise<Array<Case>> {
    let response: Array<Case> = []
    try {
      const caseDocuments: Array<CaseDocument> = await caseIndexDatabaseService.getFormDocumentsByClinicId(clinicId)
      response = caseDocuments.map(caseDocument => caseDocument.document)
    } catch (error) {
      await remoteLoggingService.logError(
        `Loading cases from the IndexDB for the clinic id ${clinicId} failed due to ${error}`
      )
    }
    return response
  }

  public async getCaseDocumentsFromAPI(clinicId: number): Promise<Array<Case> | null> {
    let response: Array<Case> | null = null
    try {
      const axiosResponse: AxiosResponse = await casesResourceService.fetchCaseDocuments(clinicId)
      response = axiosResponse.data.entries as Array<Case>
    } catch (error) {
      await remoteLoggingService.logError(
        `Loading cases from the API for the clinic id ${clinicId} failed due to ${error}`
      )
    }
    return response
  }

  public async getSubmittedCaseDocumentsByCaseNo(caseNo: string): Promise<ExistingDocument<Case> | null> {
    let response: ExistingDocument<Case> | null = null
    try {
      const axiosResponse: AxiosResponse = await casesResourceService.fetchSubmittedCaseDocumentsByCaseNo(caseNo)
      response = axiosResponse.data as ExistingDocument<Case>
    } catch (error) {
      await remoteLoggingService.logError(`Failed to get the case documents for the case no ${caseNo} due to ${error}`)
    }
    return response
  }

  public async updateCaseDocumentInBackend(caseDocument: CaseDocument): Promise<CaseDocument | null> {
    const axiosResponse: AxiosResponse = await casesResourceService.updateCaseDocument(caseDocument)
    return axiosResponse.data
  }

  private mapEntriesToCaseDocuments(entries: Array<Case>): Array<CaseDocument> {
    return entries.map(entry => ({
      document: entry,
    }))
  }

  public async reloadCases(clinicId: number): Promise<Array<Case> | null> {
    if (!(await navigatorService.isOnline())) {
      return this.getCaseDocumentsFromIndexDB(clinicId)
    }
    try {
      const syncStart: number = Date.now()
      const response = await this.getCaseDocumentsFromAPI(clinicId)
      if (response !== null) {
        const caseDocuments: Array<CaseDocument> = this.mapEntriesToCaseDocuments(response)
        await caseIndexDatabaseService.putFormDocumentsIfUpdatedOrDoesNotExist(caseDocuments, syncStart)
        await caseIndexDatabaseService.deleteCaseDocumentsIfNotMatching(caseDocuments, clinicId)
      }
      return this.getCaseDocumentsFromIndexDB(clinicId)
    } catch (error) {
      await remoteLoggingService.logError(`Failed to reload cases due to ${error}`)
      return this.getCaseDocumentsFromIndexDB(clinicId)
    }
  }

  public async syncAllCases(): Promise<Array<number | undefined>> {
    const caseDocuments = await caseIndexDatabaseService.getDocumentRecordsForSync()
    return Promise.all(caseDocuments.map(caseDocument => this.syncCase(caseDocument as CaseDocument)))
  }

  private async syncCase(caseDocument: CaseDocument): Promise<number | undefined> {
    const syncStart: number = Date.now()
    try {
      await filesService.syncCaseFiles(caseDocument.document.data.case)
      const updatedCaseDocument = await this.updateCaseDocumentInBackend(caseDocument)
      if (updatedCaseDocument !== null) {
        const updatedCase: Case = updatedCaseDocument.document
        if (updatedCase) {
          const { uuid: newUuid, lastModifiedAt, messages: newMessages } = updatedCase
          return caseIndexDatabaseService.modifyFormDocumentByUuid(newUuid, (existingFormDocument: FormDocument) => {
            const { lastModifiedAt: existingLastModifiedAt, messages: existingMessages } = existingFormDocument.document
            if (!existingLastModifiedAt || (lastModifiedAt !== undefined && lastModifiedAt > existingLastModifiedAt)) {
              existingFormDocument.document = cloneDeep(updatedCase)
            } else if (cloneDeep(existingMessages) !== cloneDeep(newMessages)) {
              existingFormDocument.document.messages = updatedCase.messages
            }
            existingFormDocument.document.lastSuccessfullSync = syncStart
          })
        }
      }
    } catch (error) {
      await remoteLoggingService.logError(
        `Failed to sync the case ${JSON.stringify(caseDocument.document.data.case)} due to error ${error}`
      )
      if (error instanceof AxiosError && error.response?.status === HttpStatusCode.NotAcceptable) {
        await caseIndexDatabaseService.deleteFormDocument(caseDocument.document.uuid)
      }
    }
    return undefined
  }
}

const casesService = new CasesService()
export default casesService
