class ArrayUtils {
  public static sortArrayOfObjects = <T>(array: Array<T>, objectProperty: string): Array<T> =>
    array.sort((a: T, b: T) => {
      if (a[objectProperty as keyof T] < b[objectProperty as keyof T]) {
        return -1
      }
      if (a[objectProperty as keyof T] > b[objectProperty as keyof T]) {
        return 1
      }
      return 0
    })

  public static isArrayAndEmpty = (value: Array<Record<string, unknown>>): boolean =>
    Array.isArray(value) && value.length === 0
}

export default ArrayUtils
