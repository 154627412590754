import { Mutex } from 'async-mutex'
import { cloneDeep, pullAt } from 'lodash-es'
import caseIndexDatabaseService from '../services/browser-storage/index-database/CaseIndexDatabase.service'
import fileIndexDatabaseService from '../services/browser-storage/index-database/FileIndexDatabase.service'
import evaluationService from '../services/indiform/Evaluation.service'
import indiFormService from '../services/indiform/IndiForm.service'
import AuditLogAction from '../services/indiform/change-log/AuditLogAction.enum'
import caseChangeLogService from '../services/indiform/change-log/CaseChangeLog.service'
import CalculatedField from '../services/indiform/field-calculation/CalculatedField.enum'
import rules from '../services/indiform/field-calculation/Rules'
import remoteLoggingService from '../services/logging-handler/RemoteLogging.service'
import { AuditLogEntry } from '../shared/interfaces/AuditLogEntry.interface'
import { Case } from '../shared/interfaces/Case.interface'
import { CaseData } from '../shared/interfaces/CaseData.interface'
import { CaseDocument } from '../shared/interfaces/CaseDocument.interface'
import { ChangeLogData } from '../shared/interfaces/ChangeLogData.interface'
import { EnumValue } from '../shared/interfaces/EnumValue.interface'
import { FollowUpData } from '../shared/interfaces/FollowUpData.interface'
import { FormDocument } from '../shared/interfaces/FormDocument.interface'
import { EnumFormMetaData, FormEnumValue } from '../shared/interfaces/IndiformValues.interface'
import Status from '../shared/interfaces/Status.interface'
import useAppStore from '../state/App'
import useContextStore from '../state/Context'
import useIndiFormStore from '../state/IndiForm'
import ArrayUtils from '../utils/Array'
import ObjectUtils from '../utils/Object'

class CaseStateService {
  public readonly DATA_OBJECT_PATH = 'data.case'
  public readonly FILE_REGEX = /\b(?:files)\b/
  public readonly indexRegex = /\[([0-9]+)]/
  public readonly lastIndexRegex = /\[\d](?!.*\[)/
  private readonly mutex = new Mutex()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly getDataPathValue = (metaDataPath: string): any => {
    const { formDocument } = useContextStore.getState()
    if (formDocument !== undefined) {
      const path: string = this.DATA_OBJECT_PATH.concat(`.${metaDataPath}`)
      const dataObject: { data: CaseData | FollowUpData } = {
        data: formDocument.document.data,
      }
      return ObjectUtils.getAt(dataObject, path, undefined)
    }
    return undefined
  }

  public readonly deleteFileByPath = async (changeLogPath: string): Promise<void> => {
    const filePath: string = changeLogPath.replace(`${this.DATA_OBJECT_PATH}.`, '')
    const file: Record<string, never> | undefined = this.getDataPathValue(filePath)

    if (file !== undefined && file.location) {
      await fileIndexDatabaseService.deleteFileDocumentByPath(file.location)
    }
  }

  public readonly getUpdatedChangeLog = (
    dataObject: { data: CaseData | FollowUpData },
    action: AuditLogAction,
    path: string,
    value: unknown,
    comment = ''
  ): Array<AuditLogEntry> => caseChangeLogService.getUpdatedChangeLog(dataObject, action, path, value, comment)

  public readonly updateNavbarHeader = (path: string, value: unknown): void => {
    if (path === 'caseNo') {
      useAppStore.getState().setNavbarHeader(`Case: ${value}`)
    }
  }

  public readonly getPathWithoutIndex = (path: string): string => {
    const arrayListWithIndex: Array<string> = path.split('.')
    const lastListElementWithIndex: string | undefined = arrayListWithIndex.pop()
    if (lastListElementWithIndex !== undefined) {
      const indexMatchArray: RegExpMatchArray | null = lastListElementWithIndex.match(this.indexRegex)
      if (indexMatchArray !== null) {
        const lastListElementWithoutIndex: string = lastListElementWithIndex.replace(/ *\[[^\]]*]/, '')
        return [...arrayListWithIndex, lastListElementWithoutIndex].join('.')
      }
    }
    return ''
  }

  public readonly getLastArrayIndex = (field: string): number | null => {
    const lastArrayIndexMatch: RegExpMatchArray | null = field.match(this.lastIndexRegex)
    if (lastArrayIndexMatch !== null) {
      const lastArrayIndex: string = lastArrayIndexMatch[0] // [1]
      const indexMatch: RegExpMatchArray | null = lastArrayIndex.match(this.indexRegex)
      if (indexMatch !== null) {
        return lastArrayIndex.match(this.indexRegex) !== null ? +indexMatch[1] : null // 1
      }
    }
    return null
  }

  public readonly setDataObjectPathValue = (
    dataObject: { data: CaseData | FollowUpData },
    path: string,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    value: any
  ): void => {
    if (value === undefined || ArrayUtils.isArrayAndEmpty(value)) {
      const regex = /\[\d]$/
      if (regex.test(path)) {
        // For List deletion
        const pathWithoutIndex: string = this.getPathWithoutIndex(path)
        const updatedValue = this.getDataPathValue(pathWithoutIndex)
        const index: number | null = this.getLastArrayIndex(path)
        if (index !== null) {
          pullAt(updatedValue, index)
        }
      } else {
        // For Radio box deletion
        ObjectUtils.unsetAt(dataObject, path)
      }
    } else {
      ObjectUtils.setAt(dataObject, path, value)
    }
  }

  public readonly setPtnmEditionDefaultOption = (
    formDataObject: { data: CaseData | FollowUpData },
    formDocument: FormDocument,
    path: string
  ): void => {
    const tumorRegex = /data.case.accruals\[\d*].tumors\[\d*]/
    const matches: RegExpMatchArray | null = path.match(tumorRegex)
    if (matches !== null) {
      const ptnmEditionPath = `${matches[0]}.classification.ptnmEdition`
      const ptnmEditionValue: EnumValue | undefined = ObjectUtils.getAt(formDataObject, ptnmEditionPath)
      const formObject = formDataObject
      if (ptnmEditionValue === undefined) {
        const { enumFormMetaData } = useIndiFormStore.getState()
        const enumType = 'PtnmEdition'
        const enumValues: Array<FormEnumValue> =
          enumFormMetaData !== null && Object.prototype.hasOwnProperty.call(enumFormMetaData, enumType)
            ? (enumFormMetaData as EnumFormMetaData)[enumType]
            : []
        const defaultPtnmEditionId = 35300004
        const hideIf =
          'data.case.accruals[/VARIABLE-INDEX-PLACEHOLDER/]' +
          ".tumors[/VARIABLE-INDEX-PLACEHOLDER/].classification.gradingType == 'who'"
        const defaultPtnmEditionValue: FormEnumValue | undefined = enumValues.find(
          enumValue => enumValue.id === defaultPtnmEditionId
        )
        if (
          defaultPtnmEditionValue !== undefined &&
          evaluationService.evaluateExpression(hideIf, undefined, formDocument, ptnmEditionPath)
        ) {
          const enumObject: EnumValue = indiFormService.getEnumValueObject(
            defaultPtnmEditionValue.id,
            defaultPtnmEditionValue.text
          )
          formObject.data.changeLog.entries = this.getUpdatedChangeLog(
            formDataObject,
            AuditLogAction.SET,
            ptnmEditionPath,
            enumObject
          )
          this.setDataObjectPathValue(formDataObject, ptnmEditionPath, enumObject)
        }
      }
    }
  }

  public readonly updateFormDocument = async (): Promise<void> => {
    const { formDocument } = useContextStore.getState()
    if (formDocument !== undefined) {
      await caseIndexDatabaseService.putFormDocument(formDocument)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly updateFormDocumentStatus = (status: Status, value: any, formDocument: FormDocument): FormDocument => {
    const clonedFormDocument = cloneDeep(formDocument)
    const { reviewBy, reviewAt, submittedBy, submittedAt } = value
    switch (status) {
      case Status.QC_PASSED_REQUEST_BY_REVIEWER:
      case Status.SUBMITTED:
        clonedFormDocument.document = {
          ...clonedFormDocument.document,
          lastModifiedAt: Date.now(),
          status,
          submittedAt,
          submittedBy,
        }
        break
      default:
        clonedFormDocument.document = {
          ...clonedFormDocument.document,
          lastModifiedAt: Date.now(),
          status,
          reviewAt,
          reviewBy,
        }
        break
    }
    return clonedFormDocument
  }

  public readonly applyRules = (dataObject: { data: CaseData | FollowUpData }, path: string): void => {
    // Update the rules only when the height or weight is updated
    if (
      [`${this.DATA_OBJECT_PATH}.bodyHeight`, `${this.DATA_OBJECT_PATH}.weight`].some((value: string) => value === path)
    ) {
      // BMI Rule
      const bmiValue: string | undefined = rules.getBmiValue(dataObject, this.DATA_OBJECT_PATH)
      const bmiPath: string = this.DATA_OBJECT_PATH.concat('.bodyMassIndex')
      Object.assign(dataObject.data.changeLog, {
        entries: this.getUpdatedChangeLog(dataObject, AuditLogAction.SET, bmiPath, bmiValue),
      })
      this.setDataObjectPathValue(dataObject, bmiPath, bmiValue)
      // BSA Rule
      const bsaValue: string | undefined = rules.getBsaValue(dataObject, this.DATA_OBJECT_PATH)
      const bsaPath: string = this.DATA_OBJECT_PATH.concat('.bodySurfaceArea')
      Object.assign(dataObject.data.changeLog, {
        entries: this.getUpdatedChangeLog(dataObject, AuditLogAction.SET, bsaPath, bsaValue),
      })
      this.setDataObjectPathValue(dataObject, bsaPath, bsaValue)
    } else if (Object.values(CalculatedField).some((value: CalculatedField) => path.includes(value))) {
      // Cold Ischemia Times calculation
      const tumorRegex = /data.case.accruals\[\d*].tumors\[\d*]/
      const matches: RegExpMatchArray | null = path.match(tumorRegex)
      if (matches !== null) {
        const fieldName = path.split('].').pop() ?? ''
        switch (fieldName) {
          case CalculatedField.METASTASIS_PREPARATION_RESECTION_COMPLETED:
            this.updateColdIschemiaTimes(
              dataObject,
              matches[0],
              path,
              [
                CalculatedField.METASTASIS_PREPARATION_FREEZE_TIME,
                CalculatedField.METASTASIS_PREPARATION_FORMALIN_TIME,
              ],
              CalculatedField.METASTASIS_PREPARATION_RESECTION_COMPLETED
            )
            break
          case CalculatedField.METASTASIS_PREPARATION_FREEZE_TIME:
          case CalculatedField.METASTASIS_PREPARATION_FORMALIN_TIME:
            this.updateColdIschemiaTimes(
              dataObject,
              matches[0],
              path,
              [fieldName],
              CalculatedField.METASTASIS_PREPARATION_RESECTION_COMPLETED
            )
            break
          case CalculatedField.PREPARATION_FREEZE_TIME:
          case CalculatedField.PREPARATION_FORMALIN_TIME:
            this.updateColdIschemiaTimes(
              dataObject,
              matches[0],
              path,
              [fieldName],
              CalculatedField.PREPARATION_RESECTION_COMPLETED
            )
            break
          case CalculatedField.PREPARATION_RESECTION_COMPLETED:
            this.updateColdIschemiaTimes(
              dataObject,
              matches[0],
              path,
              [CalculatedField.PREPARATION_FREEZE_TIME, CalculatedField.PREPARATION_FORMALIN_TIME],
              CalculatedField.PREPARATION_RESECTION_COMPLETED
            )
            break
          default:
            break
        }
      }
    }
  }

  private readonly updateColdIschemiaTimes = (
    dataObject: { data: CaseData | FollowUpData },
    basePath: string,
    path: string,
    fieldsToCalculate: Array<string>,
    resectionTimeField: string
  ): void => {
    // eslint-disable-next-line no-restricted-syntax
    for (const calculationField of fieldsToCalculate) {
      const coldIschemiaTime: number = rules.getColdIschemiaTime(
        dataObject,
        basePath,
        path,
        calculationField,
        resectionTimeField
      )
      const citField: string | undefined = rules.getColdIschemiaTimeFieldPath(calculationField)

      if (citField && !Number.isNaN(coldIschemiaTime)) {
        Object.assign(dataObject.data.changeLog, {
          entries: this.getUpdatedChangeLog(
            dataObject,
            AuditLogAction.SET,
            `${basePath}.${citField}`,
            coldIschemiaTime <= 0 ? undefined : coldIschemiaTime
          ),
        })
        this.setDataObjectPathValue(
          dataObject,
          `${basePath}.${citField}`,
          coldIschemiaTime <= 0 ? undefined : coldIschemiaTime
        )
      }
    }
  }

  public readonly getChangeLogPath = (
    path: string,
    action: AuditLogAction,
    changeLogData: ChangeLogData | undefined
  ): string => {
    if (action === AuditLogAction.SET || changeLogData === undefined) {
      return path
    }
    const { path: changeLogPath } = changeLogData
    return changeLogPath
  }

  public readonly getChangeLogValue = (
    value: unknown,
    action: AuditLogAction,
    changeLogData: ChangeLogData | undefined
  ): unknown => {
    if (action === AuditLogAction.SET || changeLogData === undefined) {
      return value
    }
    const { value: changeLogValue } = changeLogData
    return changeLogValue
  }

  public updateData = async (
    path: string,
    value: unknown,
    action: AuditLogAction = AuditLogAction.SET,
    changeLogData: ChangeLogData | undefined = undefined
  ): Promise<void> => {
    await this.mutex.runExclusive(async () => {
      let metaDataPath = path
      const { formDocument } = useContextStore.getState()
      if (formDocument !== undefined && metaDataPath !== '') {
        this.updateNavbarHeader(metaDataPath, value)
        metaDataPath = this.DATA_OBJECT_PATH.concat(`.${metaDataPath}`)
        const clonedFormDocument = cloneDeep(formDocument)
        const changeLogPath: string = this.getChangeLogPath(metaDataPath, action, changeLogData)
        const changeLogValue: unknown = this.getChangeLogValue(value, action, changeLogData)
        const dataObject: { data: CaseData } = {
          data: {
            case: (clonedFormDocument.document.data as CaseData).case,
            changeLog: {
              entries: this.getUpdatedChangeLog(
                { data: clonedFormDocument.document.data },
                action,
                changeLogPath,
                changeLogValue
              ),
            },
          },
        }
        this.setDataObjectPathValue(dataObject, metaDataPath, value)
        if (action !== AuditLogAction.DELETE) {
          this.applyRules(dataObject, metaDataPath)
          this.setPtnmEditionDefaultOption(dataObject, clonedFormDocument, metaDataPath)
        }
        clonedFormDocument.document = {
          ...(clonedFormDocument as CaseDocument).document,
          ...dataObject,
          lastModifiedAt: Date.now(),
          lastSuccessfullSync: await this.updateLastSuccessfullSync(),
          status: clonedFormDocument.document.status,
        }
        if (changeLogPath.match(this.FILE_REGEX) && action === AuditLogAction.DELETE) {
          await this.deleteFileByPath(changeLogPath)
        }

        useContextStore.setState({ formDocument: clonedFormDocument })
        await this.updateFormDocument()
      } else {
        await remoteLoggingService.logError(
          `Cannot set the value ${value} since case data document is undefined and path is empty.`
        )
      }
    })
  }

  public updateLastSuccessfullSync = async (): Promise<number | undefined> => {
    const { formDocument } = useContextStore.getState()

    if (formDocument !== undefined) {
      const caseDocument = await caseIndexDatabaseService.getFormDocumentByUuid(formDocument.document.uuid)
      return caseDocument.document.lastSuccessfullSync
    }
    return undefined
  }

  public updateDocumentStatus = async (status: Status, value: unknown): Promise<void> => {
    const { formDocument } = useContextStore.getState()

    if (formDocument !== undefined) {
      useContextStore.setState({ formDocument: this.updateFormDocumentStatus(status, value, formDocument) })
      await this.updateFormDocument()
    } else {
      await remoteLoggingService.logError(
        `Cannot update the document status to ${status} since case data document is undefined.`
      )
    }
  }

  public updateDataChangeLog = async (changeLog: AuditLogEntry): Promise<void> => {
    const { formDocument } = useContextStore.getState()

    if (formDocument !== undefined) {
      const clonedFormDocument = cloneDeep(formDocument)
      const { action, field, value, comment } = changeLog
      const { data } = clonedFormDocument.document
      const dataObject: { data: CaseData } = {
        data: {
          case: (data as CaseData).case,
          changeLog: {
            entries: this.getUpdatedChangeLog(
              { data: clonedFormDocument.document.data },
              action,
              field,
              value,
              comment
            ),
          },
        },
      }

      clonedFormDocument.document = {
        ...(clonedFormDocument.document as Case),
        ...dataObject,
      }

      useContextStore.setState({ formDocument: clonedFormDocument })
      await this.updateFormDocument()
    } else {
      await remoteLoggingService.logError(
        `Cannot update the change log to ${JSON.stringify(changeLog)} since case data document is undefined.`
      )
    }
  }
}

const caseStateService = new CaseStateService()
export default caseStateService
