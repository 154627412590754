import { NotificationInstance } from 'antd/es/notification/interface'
import { create } from 'zustand'
import authService, { AuthTokenObject } from '../services/auth/Auth.service'
import useClinicStore from './Clinic'
import useIndiFormStore from './IndiForm'

interface AuthStoreState {
  authToken: AuthTokenObject | null
  lastPasswordChangeDate: number | null
  loading: boolean
}

interface AuthStoreAction {
  loginFailure: () => void
  logout: () => void
  login: (userName: string, password: string, isOnline: boolean, notification: NotificationInstance) => Promise<void>
}

const useAuthStore = create<AuthStoreState & AuthStoreAction>((set, get) => ({
  authToken: null,
  lastPasswordChangeDate: null,
  loading: false,

  loginFailure: () => {
    set({ authToken: null, loading: false })
  },
  logout: () => {
    set({ lastPasswordChangeDate: null, authToken: null })
  },

  login: async (
    userName: string,
    password: string,
    isOnline: boolean,
    notification: NotificationInstance
  ): Promise<void> => {
    set({ loading: true })
    const authStore = get()
    const indiFormStore = useIndiFormStore.getState()
    const clinicStore = useClinicStore.getState()

    let response: AuthTokenObject | null
    try {
      if (isOnline) {
        response = await authService.login(userName, password)
      } else {
        response = await authService.loginOffline(userName, password)
      }

      if (response !== null) {
        let passwordChangeDate: number | null = null
        try {
          passwordChangeDate = await authService.getLastPasswordChangeDate()
        } catch (error) {
          console.error(`Fetching password expiry date failed due to ${error}`)
        }

        set({ authToken: response, lastPasswordChangeDate: passwordChangeDate, loading: false })

        clinicStore.loadClinics()
        indiFormStore.loadEnumFormMetaData()
        indiFormStore.loadChemicalFormMetaData()
        indiFormStore.loadHormoneFormMetaData()
        indiFormStore.loadIcdCodesFormMetaData()
        indiFormStore.loadOtherFormMetaData()
      } else {
        authStore.loginFailure()
        notification.error({ message: 'Invalid credentials or not authorized.' })
      }
    } catch (error) {
      authStore.loginFailure()
    }
  },
}))

export default useAuthStore
